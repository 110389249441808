import React from 'react';
import { bool, number, string } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faBan } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      marginBottom: 8
    }
  },
  boxIcon: {
    [theme.breakpoints.down('md')]: {
      lineHeight: 2.2
    }
  }
}));

const getColor = (isColored, color, other) => (isColored ? color : other);

const makeIconAndText = (status, openings) => {
  if (status === 5) {
    return { icon: faCheckCircle, text: 'done' };
  }
  if (status === 7) {
    return { icon: faBan, text: 'canceled' };
  }
  if ([1, 2, 3, 4, 8, 11, 12].includes(status)) {
    return { icon: faFileSignature, text: [1, 11].includes(status) && openings > 1 ? 'someDone' : 'progress' };
  }
  return { icon: null, text: null };
};

const OrderStatus = ({ statusId, openings, acceptedCount, isColored, variant }) => {
  const { t } = useTranslation(['general']);
  const theme = useTheme();
  const classes = useStyles();

  const { icon, text } = makeIconAndText(statusId, openings);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" className={isColored ? classes.box : null}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          color={getColor(isColored, theme.palette.primary.main, theme.palette.secondary.main)}
          style={{ fontSize: 14 }}
        />
      )}{' '}
      <Box ml={2}>
        <Typography variant={variant} color={getColor(isColored, 'primary', 'secondary')}>
          {text ? t(`general:orders.status.${text}`, { done: acceptedCount, openings }) : null}
        </Typography>
      </Box>
    </Box>
  );
};

OrderStatus.propTypes = {
  statusId: number.isRequired,
  openings: number.isRequired,
  acceptedCount: number.isRequired,
  variant: string,
  isColored: bool
};

OrderStatus.defaultProps = {
  variant: 'body2',
  isColored: false
};

export default OrderStatus;
