import React, { useMemo } from 'react';
import { compose, pathOr } from 'ramda';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { bool, func, object, string } from 'prop-types';

import ConfirmScreen from 'components/ConfirmScreen';
import { formatTotalHours } from 'utils/date';
import { asFloatingHours } from 'utils/tempWorkers';
import { useTranslation } from 'react-i18next';

const HoursDialog = ({ isOpen, title, data, range, isOverMaxHours, onCancel, onConfirm, classes }) => {
  const { t } = useTranslation(['general']);

  const actions = useMemo(() => {
    if (isOverMaxHours) {
      return (
        <Button onClick={() => onCancel(false)} color="primary" variant="contained">
          {t('general:edit')}
        </Button>
      );
    }
    return (
      <Box className={classes.actions}>
        <Button onClick={() => onCancel(false)} color="primary" variant="outlined">
          {t('general:cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {t('general:confirm')}
        </Button>
      </Box>
    );
  });

  const getContentText = () => {
    if (isOverMaxHours) return t('general:contract.over11HoursADay');
    return t('general:contract.between9and11HoursaDay');
  };
  return (
    <Dialog open={isOpen} onClose={() => onCancel(false)} fullWidth maxWidth="md">
      <DialogTitle disableTypography id="confirm-dialog-title">
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ConfirmScreen
          extension
          firstName={pathOr('', ['interimWorker', 'firstName'], data)}
          lastName={pathOr('', ['interimWorker', 'lastName'], data)}
          from={range.from}
          to={range.to}
          totalHours={formatTotalHours(asFloatingHours(data.scheduleDays))}
          description={getContentText()}
          isError={isOverMaxHours}
        />
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

HoursDialog.propTypes = {
  isOpen: bool,
  title: string,
  data: object.isRequired,
  range: object.isRequired,
  isOverMaxHours: bool,
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  classes: object.isRequired
};

HoursDialog.defaultProps = {
  isOpen: false,
  isOverMaxHours: true,
  title: ''
};

const styles = createStyles(() => ({
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    flex: 1,
    justifyContent: 'space-around',
    '& > button': {
      minWidth: '150px',
      margin: '8px'
    }
  }
}));

export default compose(withStyles(styles))(HoursDialog);
