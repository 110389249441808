/* eslint-disable max-lines */
import { format, isSameDay } from 'date-fns';
import { isValidDate } from 'ramda-adjunct';
import {
  toIsoDateString,
  days,
  getWeekDays,
  minutesToPickerValue,
  mondayWeek,
  formatDate,
  convertMinutesToTime,
  valueToMinutes,
  convertTimeStringToHoursAndMinutes
} from 'utils/date';
import { mapObjIndexed, findIndex, path, isNil, clone, pathOr, uniqBy, reverse, sortWith, ascend, map } from 'ramda';

export { default as BonusSelector } from './BonusSelector';
export { default as EditDaySchedule } from './EditDaySchedule';

export const getTotalFromPickers = (values) => {
  const filteredValues = map(
    (item) => convertTimeStringToHoursAndMinutes(item.stringValue, 'h'),
    values.filter((item) => item)
  );
  const totalMinutes = filteredValues.reduce((acc, v) => {
    return acc + (v.hours * 60 + v.minutes);
  }, 0);
  return totalMinutes;
};

export const getTotal = (type, storedHours) => {
  return days.reduce((acc, day) => {
    return acc + parseInt(path([day, type], storedHours) || 0, 10);
  }, 0);
};

export const getInputsValues = (storedHours, bonuses, filteredBonuses) => {
  return days.map((day) => {
    const storedDay = storedHours[day];
    if (storedDay) {
      const validatedSources = !!filteredBonuses.find((b) => b.source === 2);
      if (validatedSources) {
        const dayWithRightSource = bonuses.find(
          (b) => b.premium_code === 1101 && isSameDay(new Date(b.date), storedDay.date) && b.source === 2
        );
        const minutes = dayWithRightSource?.minutes || 0;
        return {
          pickerValue: minutesToPickerValue(parseInt(minutes, 10)),
          stringValue: convertMinutesToTime(minutes, 'h')
        };
      }
      const minutes = !isNil(storedDay.performedMinutes) ? storedDay.performedMinutes : storedDay.total_hours;
      return {
        pickerValue: minutesToPickerValue(parseInt(minutes, 10)),
        stringValue: convertMinutesToTime(minutes, 'h')
      };
    }
    return null;
  });
};

export const getHoursWithDate = (weekDays, contractHours) => {
  return weekDays
    .map((day) => {
      const dayName = days[day.getDay()];
      return { ...contractHours[dayName], date: toIsoDateString(day) };
    })
    .filter((hwd) => hwd.id);
};

export const getNewBonuses = (hoursWithDate, selected, storedBonuses, appendableCodes) => {
  return hoursWithDate
    .map((schedule) =>
      selected.map((s) => {
        const foundStoredBonus = storedBonuses.find(
          (bonus) => bonus.premium_code === s && bonus.date === schedule.date
        );
        return (
          foundStoredBonus || {
            ...appendableCodes.find((bonus) => bonus.premium_code === s),
            date: schedule.date,
            contract_id: schedule.id,
            source: 1,
            minutes: 0,
            amount: 0
          }
        );
      })
    )
    .flat();
};

export const fillPerformedHours = (from, changedHours, contractHours, premiums) => {
  return Object.keys(changedHours).reduce((acc, key) => {
    let premium;
    let performedPremium;
    let date;
    let addedTime;
    const weekDays = getWeekDays(from, 1);
    if (key !== 'total') {
      date = weekDays[mondayWeek[findIndex((d) => d === key, days)]];
      const isoDate = toIsoDateString(date);
      premium = premiums.find((pm) => pm.date === isoDate && pm.premium_code !== 1101);
      performedPremium = premiums.find((pm) => pm.date === isoDate && pm.premium_code === 1101);
      addedTime = premium?.premium_code === 1001 ? premium.minutes : 0;
    }
    return {
      ...acc,
      ...(changedHours[key] && {
        [key]: {
          ...changedHours[key],
          performedMinutes:
            (!isNil(performedPremium?.minutes)
              ? performedPremium?.minutes
              : parseInt(pathOr(0, [key, 'total_hours'], contractHours), 10)) + addedTime,
          ...(date && {
            date
          }),
          ...(premium && {
            reason: premium
          }),
          ...(performedPremium && {
            difference:
              performedPremium.minutes + addedTime - parseInt(pathOr(0, [key, 'total_hours'], contractHours), 10)
          })
        }
      })
    };
  }, {});
};

export const getNewHoursAndPremiums = (data, storedHours, contractHours, premiums) => {
  const performedPremium = premiums.find((p) => p.date === toIsoDateString(data.date) && p.premium_code === 1101);
  return {
    hours: {
      ...storedHours,
      [data.dayName]: {
        start_am: data.startAm ? `${format(data.startAm, 'HH:mm')}:00` : null,
        start_pm: data.startPm ? `${format(data.startPm, 'HH:mm')}:00` : null,
        end_am: data.endAm ? `${format(data.endAm, 'HH:mm')}:00` : null,
        end_pm: data.endPm ? `${format(data.endPm, 'HH:mm')}:00` : null,
        total_hours: data.total_hours,
        performedMinutes: data.performedMinutes,
        difference: data.difference || 0,
        date: data.date,
        reason: data.reason,
        id: data.contractId
      }
    },
    premiums: [
      ...premiums.filter((p) => p.date !== toIsoDateString(data.date)),
      {
        ...performedPremium,
        minutes: data.difference > 0 ? contractHours[data.dayName].total_hours : valueToMinutes(data.performedMinutes)
      },
      {
        ...data.reason,
        date: toIsoDateString(data.date),
        minutes: Math.abs(data.difference),
        amount: 0,
        contract_id: data.contractId
      }
    ]
  };
};

const getPremiumMinutes = (premium) => {
  if (!premium || premium.premium_code === 1001) return 0;
  return -premium.minutes;
};

const getDWHBonusMinutes = (bonus, changedHours) => {
  if (bonus?.source === 0) {
    if ([1, 3].includes(bonus?.smile_code_type)) {
      const bonusDayName = days[new Date(bonus.date).getDay()];
      return (
        path([bonusDayName, 'performedMinutes'], changedHours) || path([bonusDayName, 'total_hours'], changedHours) || 0
      );
    }
    if (bonus?.smile_code_type === 4) return bonus?.minutes || 1;
  }
  return bonus?.minutes;
};

export const filterBonuses = (allBonuses, changedHours) => {
  const validatedSources = !!allBonuses.find((b) => b.source === 2);
  return uniqBy(
    (item) => `${item.premium_code}-${item.date}`,
    reverse(
      allBonuses
        .filter((bonus) => (validatedSources ? bonus.source === 2 : true))
        .map((bonus) => {
          return {
            ...bonus,
            minutes: getDWHBonusMinutes(bonus, changedHours)
          };
        })
    )
  );
};

const getPerformedTime = (performedPremium, hours, foundPremium) => {
  if (!isNil(performedPremium?.minutes)) return performedPremium?.minutes;
  return parseInt(hours?.total_hours, 10) + getPremiumMinutes(foundPremium);
};

export const fillPremiumsForEmptyDays = ({ changedHours, contractHours, allBonuses, date }) => {
  const weekDays = getWeekDays(date, 1);
  const populatedBonuses = filterBonuses(allBonuses, changedHours);
  const premiums = clone(populatedBonuses).filter((pc) => pc.is_phantom === 1 && pc.premium_code !== 2060);
  let formattedPremiums = [...premiums];
  mapObjIndexed((val, key) => {
    if (val && key !== 'total') {
      const isoDate = toIsoDateString(weekDays[mondayWeek[findIndex((d) => d === key, days)]]);
      const foundPremium = premiums.find((item) => item.premium_code !== 1101 && item.date === isoDate);
      const performedPremium = populatedBonuses.find((item) => item.premium_code === 1101 && item.date === isoDate);
      formattedPremiums = Object.assign([], formattedPremiums, {
        [formattedPremiums.length]: {
          contract_id: val.id,
          premium_code: 1101,
          is_phantom: 1,
          is_active: 1,
          date: isoDate,
          minutes: getPerformedTime(performedPremium, contractHours[key], foundPremium),
          amount: 0,
          source: performedPremium?.source || null
        }
      });
    }
  }, changedHours);

  return formattedPremiums;
};

export const getUnusedContractBonuses = (from, scheduleDays) => {
  const weekDays = getWeekDays(new Date(from));
  return scheduleDays.map((sd) => {
    const isoDate = formatDate(weekDays[mondayWeek[days.findIndex((d) => sd.day === d)]], false);
    return {
      premium_code: 1101,
      date: isoDate,
      is_phantom: 1,
      is_active: 1,
      minutes: 0,
      amount: 0
    };
  });
};

export const fillBonusesForEmptyDays = ({ changedHours, allBonuses, date }) => {
  const weekDays = getWeekDays(date, 1);
  const populatedBonuses = filterBonuses(allBonuses, changedHours);
  const bonuses = clone(populatedBonuses).filter(
    (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
  );
  let formattedBonuses = [...bonuses];
  mapObjIndexed((val, key) => {
    if (val && key !== 'total') {
      const isoDate = toIsoDateString(
        weekDays[
          findIndex((d) => d === key, ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
        ]
      );
      if (bonuses.length) {
        formattedBonuses = bonuses.reduce(
          (acc, bonus) => {
            const foundBonus = acc.find((item) => {
              return item.premium_code === bonus.premium_code && item.date === isoDate;
            });
            if (!foundBonus) {
              return [
                ...acc,
                {
                  ...bonus,
                  contract_id: val.id,
                  amount: bonus.source === 0 ? bonus.amount : 0,
                  minutes: bonus.source === 0 ? getDWHBonusMinutes({ ...bonus, date: isoDate }, changedHours) : 0,
                  date: isoDate
                }
              ];
            }
            return acc;
          },
          [...formattedBonuses]
        );
      }
    }
  }, changedHours);
  return sortWith(
    [ascend((bonus) => bonus.source), ascend((bonus) => (bonus.premium_code === 2060 ? 5000 : bonus.premium_code))],
    formattedBonuses
  );
};

export const getChangedBonuses = ({ premiumCode, smileCode, pdate, storedBonuses }, event) => {
  const bonusIdx = storedBonuses.findIndex((b) => b.premium_code === premiumCode && b.date === toIsoDateString(pdate));
  const newBonuses = [...storedBonuses];
  if (bonusIdx === -1) return newBonuses;
  if ([1, 3].includes(smileCode)) {
    newBonuses[bonusIdx].minutes = isValidDate(event) ? event.getHours() * 60 + event.getMinutes() : 0;
  } else if (smileCode === 2) {
    const { value } = event.target;
    const parsed = parseFloat(value.replace(',', '.'));
    newBonuses[bonusIdx].amount = parsed >= 0 ? parsed : 0;
  } else {
    const { checked } = event.target;
    newBonuses[bonusIdx].minutes = checked ? 1 : 0;
  }
  return newBonuses;
};
