import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { isNil } from 'ramda';
import { isValidDate } from 'ramda-adjunct';
import { isSameDay } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { mondayWeek, toIsoDateString } from 'utils/date';
import { TableCell, Typography } from '@material-ui/core';
import PropTypes, { array, bool, func, object, string, oneOfType } from 'prop-types';

import FrLocaleUtilsProvider from 'components/FrLocaleUtilsProvider';
import CustomTimePicker from 'components/CustomTimePicker';

const editedStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green,
    color: 'white',
    borderRadius: 3,
    padding: '0 3px',
    marginRight: -3
  }
}));

const makeClassName = (classes, day, add = []) =>
  clsx(classes.dayCell, ...add, isSameDay(day, new Date()) ? classes.todayCell : '');

const PerformedCell = forwardRef(
  ({ value, stringValue, onChange, onValidDateChange, day, editMode, storedPremiums, classes, ...props }, ref) => {
    const formatted = stringValue || '00h00';
    const typoClass = editedStyles();
    return (
      <TableCell align="right" className={makeClassName(classes, day)} {...props}>
        {!isNil(value) && (
          <>
            {!editMode ? (
              <Typography
                variant="body2"
                component="span"
                {...(day &&
                  storedPremiums.find((sp) => sp.date === toIsoDateString(day) && sp.premium_code !== 1101) && {
                    className: typoClass.root
                  })}>
                {formatted}
              </Typography>
            ) : (
              <FrLocaleUtilsProvider>
                <CustomTimePicker
                  inputRef={ref}
                  inputProps={{ tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1, inputRef: ref, ref }}
                  id={`performedHours-${day}`}
                  ampm={false}
                  format="HH'h'mm"
                  mask="__h__"
                  autoOk
                  value={value}
                  helperText={null}
                  variant="inline"
                  onChange={(event) => {
                    onChange(event);
                    if (isValidDate(event) && event !== value) onValidDateChange(event);
                  }}
                  onBlur={(_, val) => {
                    if (!isValidDate(val)) {
                      onChange('');
                      onValidDateChange('');
                    }
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    disableUnderline: true,
                    classes: { root: classes.bonusInputRoot, input: classes.bonusInput }
                  }}
                  keyboardIcon={false}
                  InputAdornmentProps={{ className: classes.adornment, classes: { root: classes.keyboardIcon } }}
                />
              </FrLocaleUtilsProvider>
            )}
          </>
        )}
      </TableCell>
    );
  }
);

PerformedCell.propTypes = {
  value: oneOfType([PropTypes.instanceOf(Date), string]),
  stringValue: string,
  onChange: func,
  onValidDateChange: func,
  day: PropTypes.instanceOf(Date),
  editMode: bool,
  classes: object.isRequired,
  storedPremiums: array.isRequired
};

PerformedCell.defaultProps = {
  value: null,
  stringValue: null,
  onChange: () => null,
  onValidDateChange: () => null,
  day: null,
  editMode: false
};

export default PerformedCell;
