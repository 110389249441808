import { createStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default createStyles((theme) => ({
  firstAndLastCell: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  todayCell: {
    backgroundColor: transparentize(0.8, theme.palette.primary.main)
  },
  dayCell: {
    maxWidth: 83,
    padding: '6px 8px 6px 8px',
    width: 'auto'
  },
  tableRow: {
    verticalAlign: 'baseline'
  },
  noBorderRow: {
    '& th,td': {
      borderBottom: 0
    }
  },
  noPaddingTopRow: {
    '& th,td': {
      paddingTop: 0
    }
  },
  noPaddingBottomRow: {
    '& th,td': {
      paddingBottom: 0
    }
  },
  hiddenRow: {
    visibility: 'collapse',
    '& th,td': {
      borderBottom: 0
    }
  },
  iconColor: {
    color: theme.palette.secondary.main
  },

  iconColorSuccess: {
    color: theme.palette.green
  },

  iconColorDanger: {
    color: theme.palette.primary.main
  },

  clickableRow: {
    cursor: 'pointer'
  },
  borderLessCell: {
    borderBottom: 'none'
  },
  firstScheduleCell: {
    whiteSpace: 'nowrap',
    paddingLeft: '2rem'
  },
  adornment: {
    display: 'none'
  },
  bonusInput: {
    fontSize: '1.4rem',
    lineHeight: '1.43',
    textAlign: 'right'
  },
  bonusInputRoot: {
    backgroundColor: theme.palette.action.hover,
    padding: '0',
    borderRadius: 4,
    maxWidth: 80,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    '&.Mui-error': {
      backgroundColor: theme.palette.error.main
    }
  }
}));
