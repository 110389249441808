import { createStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default createStyles((theme) => ({
  firstAndLastCell: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  headRow: {
    verticalAlign: 'baseline',
    '& th,td': {
      borderBottom: 0
    }
  },
  calendarCell: {
    paddingLeft: 0
  },
  dayCell: {
    maxWidth: 85,
    width: 85,
    '& .fa-filter': {
      display: 'none'
    },
    '&:hover .fa-filter': {
      display: 'inline-block'
    }
  },
  todayCell: {
    backgroundColor: transparentize(0.8, theme.palette.primary.main),
    '&:not(last-of-type)': {
      borderBottom: `1px solid ${transparentize(0.8, theme.palette.primary.main)}`
    }
  },
  filteredDayCell: {
    '& .fa-filter': {
      display: 'inline'
    }
  },
  lastCell: {
    width: 55
  },
  noBorderRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0
    }
  },
  noPaddingRow: {
    '& th,td': {
      paddingTop: 0
    }
  },
  iconColor: {
    color: theme.palette.secondary.main
  },
  bigIcon: {
    fontSize: 20
  },
  mediumIcon: {
    fontSize: 13,
    width: 13
  },
  dayFilterTooltip: {
    width: 165,
    textAlign: 'center'
  }
}));
