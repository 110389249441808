import { toast } from 'react-toastify';
import i18n from 'translate/i18n';
import { isNil, pathOr, path } from 'ramda';
import * as service from './service';

export const contracts = {
  state: {
    savedCandidate: null,
    parentContract: [],
    appendableCodes: [],
    appendablePremiums: [],
    reasonsList: []
  },
  reducers: {
    gotParentContract: (state, parentContract) => ({ ...state, parentContract }),
    setCandidate: (state, savedCandidate) => ({ ...state, savedCandidate }),
    dumpCandidate: (state) => ({ ...state, savedCandidate: null }),
    dumpParent: (state) => ({ ...state, parentContract: [] }),
    gotAppendableCodes: (state, appendableCodes) => ({ ...state, appendableCodes }),
    gotAppendablePremiums: (state, appendablePremiums) => ({ ...state, appendablePremiums }),
    gotReasonsList: (state, reasonsList) => ({ ...state, reasonsList })
  },
  effects: (dispatch) => ({
    async getParent(data) {
      const parent = await service.getParent(data);
      if (!isNil(parent)) {
        this.gotParentContract(parent);
      } else {
        this.dumpParent();
      }
    },
    async postContract({ isEditing, ...data }) {
      const { orderId } = data;
      const newContract = await service.addContract(data, isEditing ? orderId : null);
      if (newContract) {
        this.dumpCandidate();
        this.dumpParent();
        dispatch.events.sendMessage(
          i18n.t(`general:${isEditing ? 'modificationSuccess' : 'contract.newContractSuccess'}`)
        );
      }
      return newContract;
    },
    async updateEvaluation(data) {
      await service.updateEvaluation(data);
    },
    async getAppendableCodes(data) {
      const appendableCodes = await service.getAppendableCodes(data);
      if (appendableCodes) {
        this.gotAppendableCodes(appendableCodes);
      } else {
        await toast.error(i18n.t('errors:default'));
      }
    },
    async getAppendablePremiums() {
      const appendablePremiums = await service.getAppendablePremiums();
      if (appendablePremiums) {
        this.gotAppendablePremiums(appendablePremiums);
      } else {
        await toast.error(i18n.t('errors:default'));
      }
    },
    async getReasons(lang) {
      const reasonsList = await service.getReasons(lang);
      if (reasonsList) {
        this.gotReasonsList(reasonsList);
      } else {
        await toast.error(i18n.t('errors:default'));
      }
    },
    async doesPayrollExist(data) {
      const payrollExists = await service.doesPayrollExist(data);
      if (payrollExists) {
        await toast.error(i18n.t('errors:payrollExists'));
      }
      return payrollExists;
    },
    async editSchedule(data) {
      const schedule = await service.editSchedule(data);
      if (schedule) {
        dispatch.events.sendMessage(i18n.t('general:savedSuccessfully'));
      } else {
        await toast.error(i18n.t('errors:failedSave'));
      }
      return schedule;
    },
    exportXLS: service.exportXLS,
    async extendContract({ isEditing, ...data }) {
      const { orderId } = data;
      const extendedContract = await service.extendContract(data, isEditing ? orderId : null);
      if (!extendedContract?.errors) {
        this.dumpCandidate();
        this.dumpParent();
        dispatch.events.sendMessage(i18n.t(`general:${isEditing ? 'modificationSuccess' : 'extend.extensionSuccess'}`));
      } else {
        await toast.error(i18n.t('errors:failedSave'));
      }
      return extendedContract;
    },
    async postPayroll({ data, uploadData }, rootState) {
      if (!pathOr(false, ['geo', 'geoData'], rootState)) await dispatch.geo.getGeoData();
      const geoData = pathOr([], ['geo', 'geoData'], rootState);
      const country = geoData.find((c) => c.phone === `+${data.phoneIdentifier}`);
      const phoneIdentifier = country?.id;

      const create = await service.addPayroll({ ...data, phoneIdentifier });
      if (data && create?.payroll?.id) {
        if (uploadData) {
          const upload = await service.payrollUpload({ ...uploadData, payRollId: create.payroll.id });
          if (upload) {
            dispatch.events.sendMessage(i18n.t('general:payroll.newPayrollSuccess'));
            this.setCandidate({
              ...create.payroll,
              firstName: create.payroll.first_name,
              lastName: create.payroll.last_name
            });
          }
        } else {
          dispatch.events.sendMessage(i18n.t('general:payroll.newPayrollSuccess'));
          this.setCandidate({
            ...create.payroll,
            firstName: create.payroll.first_name,
            lastName: create.payroll.last_name
          });
        }
        return create;
      }
      if (path(['message', 400], create)) {
        await toast.error(i18n.t(create.message[400]));
      } else {
        await toast.error(i18n.t('errors:failedSave'));
      }
      return null;
    }
  })
};
