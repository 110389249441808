import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Check, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Box, Collapse, List, ListItem, ListItemText, MenuItem, Select } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

import i18n from 'translate/i18n';

const Translate = ({ classes, large, update, isLogged, hasAcceptedGdpr, isSelect }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['translate']);
  const currentLanguage = i18n.language;

  const updateLang = (language) => {
    i18n.changeLanguage(language);
    if (isLogged && hasAcceptedGdpr) update({ language });
  };

  const selectLang = () => (
    <>
      <Box mt={large ? 0 : 2}>
        <Select
          value={currentLanguage}
          autoWidth
          className={classes.select}
          SelectDisplayProps={{ className: classes.selectedOption }}
          onOpen={setOpen}
          onBlur={() => setOpen(false)}
          onChange={(e) => {
            setOpen(false);
            updateLang(e.target.value);
          }}>
          <MenuItem value="fr">
            {t('translate:fr')} {currentLanguage === 'fr' && <Check visibility={open ? 'visible' : 'hidden'} />}
          </MenuItem>
          <MenuItem value="nl">
            {t('translate:nl')} {currentLanguage === 'nl' && <Check visibility={open ? 'visible' : 'hidden'} />}
          </MenuItem>
        </Select>
      </Box>
    </>
  );

  return isSelect ? (
    selectLang()
  ) : (
    <>
      <Box mt={large ? 0 : 2}>
        <ListItem button style={{ height: large ? 48 : 32 }} onClick={() => setOpen((current) => !current)}>
          <ListItemText primary={t(`translate:${currentLanguage}`)} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" className={classes.nested}>
          <ListItem button onClick={() => updateLang('fr')}>
            <ListItemText primary={t('translate:fr')} />
            {currentLanguage === 'fr' && <Check />}
          </ListItem>
          <ListItem button onClick={() => updateLang('nl')}>
            <ListItemText primary={t('translate:nl')} />
            {currentLanguage === 'nl' && <Check />}
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

Translate.propTypes = {
  classes: object.isRequired,
  update: func.isRequired,
  large: bool,
  isLogged: bool.isRequired,
  hasAcceptedGdpr: bool,
  isSelect: bool
};

Translate.defaultProps = {
  large: false,
  hasAcceptedGdpr: true,
  isSelect: false
};

const styles = createStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  selectedOption: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '24px'
  },
  select: {
    '&:before': {
      display: 'none'
    },
    '&:after': {
      marginTop: '12px'
    }
  }
}));

const mapState = ({ user: { isLogged } }) => ({
  isLogged
});

const mapDispatch = ({ user: { update } }) => ({ update });

export default connect(mapState, mapDispatch)(withStyles(styles)(Translate));
