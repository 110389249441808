import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TableCell, Typography, Tooltip } from '@material-ui/core';
import { pathOr } from 'ramda';
import PropTypes, { object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { isSameDay } from 'date-fns';

import { premiumIcons } from 'utils/premium';
import { convertMinutesToTime, days } from 'utils/date';

const FAIconWithRef = React.forwardRef((props, ref) => {
  return <FontAwesomeIcon {...props} forwardedRef={ref} />;
});

const makeClassName = (classes, day, add = []) =>
  clsx(classes.dayCell, classes.borderLessCell, ...add, isSameDay(day, new Date()) ? classes.todayCell : '');

const ReadOnlyTimeCell = ({ day, mode, contractHours, storedHours, classes }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  if (!day) return <TableCell align="right" key={day} className={makeClassName(classes, day)} />;

  const dayName = days[day.getDay()];
  const dayHours = mode === 'difference' ? storedHours[dayName] : contractHours[dayName];

  const iconText = () => (
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      {pathOr(0, ['reason', 'icon', 'length'], dayHours) > 1 && (
        <Box pr={1}>
          <Tooltip title={dayHours.reason[`description_${i18n.language}`]}>
            <FAIconWithRef color={theme.palette.green} icon={premiumIcons[dayHours.reason.icon]} fixedWidth />
          </Tooltip>
        </Box>
      )}
      <Typography variant="body2" color="secondary">
        {dayHours[mode] < 0 ? '-' : '+'}
        {convertMinutesToTime(Math.abs(dayHours[mode]), 'h')}
      </Typography>
    </Box>
  );

  return (
    <TableCell align="right" key={day} className={makeClassName(classes, day)}>
      {!!pathOr(false, [mode], dayHours) &&
        (mode === 'difference' ? (
          iconText()
        ) : (
          <Typography variant="body2" color="secondary">
            {convertMinutesToTime(dayHours[mode], 'h')}
          </Typography>
        ))}
    </TableCell>
  );
};

ReadOnlyTimeCell.propTypes = {
  day: PropTypes.instanceOf(Date),
  contractHours: object,
  mode: string,
  storedHours: object,
  classes: object.isRequired
};

ReadOnlyTimeCell.defaultProps = {
  day: null,
  contractHours: {},
  mode: 'difference',
  storedHours: {}
};

export default ReadOnlyTimeCell;
