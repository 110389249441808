import React from 'react';
import { object, node } from 'prop-types';
import { Box, Select, InputBase } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BooststrapInput = withStyles(() => ({
  root: {
    overflow: 'hidden'
  }
}))(InputBase);

const styles = makeStyles(() => ({
  select: {
    maxWidth: 400,
    paddingRight: '40px'
  },
  nativeInput: {
    border: 'none'
  }
}));

const IconSelect = ({ children, icon, ...props }) => {
  const classes = styles();
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box mr={1}>
        <FontAwesomeIcon icon={icon} style={{ fontSize: '20px' }} color={theme.palette.text.secondary} fixedWidth />
      </Box>
      <Select classes={classes} input={<BooststrapInput />} {...props}>
        {children}
      </Select>
    </Box>
  );
};

IconSelect.propTypes = {
  icon: object.isRequired,
  children: node.isRequired
};

export default IconSelect;
