/* eslint-disable complexity */
import React from 'react';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { array, bool, func, object, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel, TableCell, TableRow, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { pathOr, pick, path, prop, clone, and, propOr, isEmpty, filter } from 'ramda';
import { isSameDay } from 'date-fns';

import { days, hoursFromMinutes } from 'utils/date';

import { BonusesDisplay } from 'components/PerformanceSchedule/Bonuses';
import { fillBonusesForEmptyDays, filterBonuses } from 'components/PerformanceSchedule/common';
import HourCell from './HourCell';
import Status from './Status';
import styles from './TempWorkerRow.styles';

const AlignMiddleTableCell = withStyles(() => ({
  root: {
    verticalAlign: 'middle'
  }
}))((props) => <TableCell {...props} />);

const TempWorkerRow = ({
  classes,
  show,
  history,
  showDetails,
  showBonuses,
  data,
  endActions = [],
  weekDays,
  rowLabel,
  isLast,
  checked,
  onCheckChange
}) => {
  const { t } = useTranslation(['tempWorkers']);
  const filledBonuses = fillBonusesForEmptyDays({
    changedHours: path(['info', 'timesheetScheduleDays'], data),
    contractHours: prop('hours', data),
    allBonuses: clone(pathOr([], ['info', 'contractHasPremiumCodes'], data)),
    date: new Date(path(['info', 'from'], data))
  });

  const getHours = (day) => {
    const hours = pathOr({}, ['info', 'timesheetScheduleDays', days[day.getDay()]], data);
    const changedHours = path(['info', 'timesheetScheduleDays'], data);
    const allBonuses = clone(pathOr([], ['info', 'contractHasPremiumCodes'], data));
    const populatedBonuses = filterBonuses(allBonuses, changedHours);
    const filteredBonuses = clone(populatedBonuses).filter(
      (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
    );
    const validatedSources = !!filteredBonuses.find((b) => b.source === 2);
    const bonus = allBonuses.find((b) => b.premium_code === 1101 && isSameDay(new Date(b.date), day) && b.source === 2);
    if (validatedSources && !isEmpty(hours)) {
      const minutes = propOr(0, 'minutes', bonus);
      const newHours = { ...hours, performedMinutes: minutes, total_hours: minutes };
      return newHours;
    }
    return hours;
  };

  const getTotalHours = () => {
    const weekDaysMap = filter(
      (item) => item,
      weekDays.map((day) => {
        const val = getHours(day);
        return isEmpty(val) ? null : val;
      })
    );
    const total = weekDaysMap.reduce((acc, curr) => {
      const minutes = curr.performedMinutes || curr.total_hours;
      return acc + minutes;
    }, 0);
    return hoursFromMinutes(total);
  };

  return (
    <>
      <TableRow
        key={rowLabel}
        className={clsx(classes.clickableRow, classes.tableRow, {
          [classes.noBorderRow]: showDetails || showBonuses || isLast,
          [classes.noPaddingBottomRow]: showDetails || showBonuses,
          [classes.hiddenRow]: !show
        })}
        onClick={(e) => {
          e.stopPropagation();
          history.push({
            pathname: '/prestation',
            state: { data, navigationBack: onCheckChange ? 'confirm-prestation' : '' }
          });
        }}>
        <TableCell component="th" scope="row" className={classes.firstAndLastCell}>
          {onCheckChange ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => onCheckChange(e.target.checked, data)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <Box whiteSpace="nowrap">
                  <Typography variant="body2">{rowLabel}</Typography>
                </Box>
              }
            />
          ) : (
            <Typography variant="body2">{rowLabel}</Typography>
          )}
        </TableCell>
        <TableCell align="right">
          <Status data={data} />
        </TableCell>
        {weekDays.map((day) => {
          return (
            <TableCell key={day.getDay()} align="right" className={isSameDay(day, new Date()) ? classes.todayCell : ''}>
              <HourCell hours={getHours(day)} isEdited={false} />
            </TableCell>
          );
        })}
        <TableCell align="right">{getTotalHours()}</TableCell>
        <TableCell align="right" className={classes.firstAndLastCell}>
          {endActions.map((renderAction) => (
            <Box px={1} key={renderAction}>
              {renderAction()}
            </Box>
          ))}
        </TableCell>
      </TableRow>
      {!!showDetails && (
        <TableRow
          className={clsx(classes.tableRow, classes.noPaddingTopRow, {
            [classes.noBorderRow]: showBonuses || isLast,
            [classes.noPaddingBottomRow]: showBonuses,
            [classes.hiddenRow]: !show
          })}>
          <TableCell component="th" scope="row">
            <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
              <FontAwesomeIcon fixedWidth icon={faClock} className={clsx(classes.iconColor)} />
              <Box ml={1}>
                <Typography variant="body2" color="secondary">
                  {t('tempWorkers:timesheet')}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell align="right" />
          {weekDays.map((day) => (
            <AlignMiddleTableCell
              key={day.getDay()}
              align="right"
              className={isSameDay(day, new Date()) ? classes.todayCell : ''}>
              <HourCell
                hours={pathOr({}, ['info', 'timesheetScheduleDays', days[day.getDay()]], data)}
                contractHours={pathOr({}, ['info', 'scheduleDays', days[day.getDay()]], data)}
                showDetails
              />
            </AlignMiddleTableCell>
          ))}
          <TableCell align="right" />
        </TableRow>
      )}
      {and(showBonuses, show) && (
        <BonusesDisplay
          isHomePage
          editMode={false}
          weekDays={weekDays}
          storedBonuses={filledBonuses}
          classes={pick(['dayCell', 'borderLessCell', 'todayCell', ...(isLast ? ['noBorderRow'] : [])], classes)}
        />
      )}
    </>
  );
};

TempWorkerRow.propTypes = {
  classes: object.isRequired,
  history: object.isRequired,
  show: bool,
  showDetails: bool,
  showBonuses: bool,
  data: object,
  endActions: array,
  weekDays: array,
  rowLabel: string,
  isLast: bool,
  checked: bool,
  onCheckChange: func
};

TempWorkerRow.defaultProps = {
  show: false,
  showDetails: false,
  showBonuses: false,
  data: {},
  endActions: [],
  weekDays: [],
  rowLabel: '',
  isLast: false,
  checked: false,
  onCheckChange: null
};

export default withStyles(styles)(withRouter(TempWorkerRow));
