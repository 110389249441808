import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody, IconButton, Typography, CircularProgress, LinearProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Add } from '@material-ui/icons';

import { Modal } from 'components';
import { useHeader } from 'hooks';

import InfiniteScroll from 'react-infinite-scroller';
import NewPayroll from 'components/NewPayroll/NewPayroll';
import MobileFab from 'components/MobileFab';
import { eventGA } from 'utils/analytics';
import NewContractHandler from 'components/NewContractHandler/NewContractHandler';
import WorkerMobileRow from './WorkerRow.mobile';
import MyWorkersFilterMobile from './MyWorkersFilter.mobile';
import WorkerDetailMobile from './WorkerDetail.mobile';

const MyWorkersMobile = ({
  workerList,
  isWorkerListLoading,
  costCenters,
  handleContract,
  search,
  setSearch,
  hasMore,
  newContractValues,
  loadMore
}) => {
  const { t } = useTranslation();
  const { search: headerSearch, setComponents, setTitle, renderSearch } = useHeader();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [workerDetail, setWorkerDetail] = useState(null);
  const [newPayrollOpen, setNewPayrollOpen] = useState(false);
  const [newContractOpen, setNewContractOpen] = useState(false);

  const onAskContract = (workerData) => {
    handleContract(workerData);
    setNewContractOpen(true);
  };

  useEffect(() => {
    eventGA('candidate_search', headerSearch);
    setSearch((s) => ({ ...s, name: headerSearch }));
  }, [headerSearch]);

  useEffect(() => {
    setTitle('general:menu.myWorkersAndPayrolls');
    setComponents([renderSearch]);
  }, [setComponents, setTitle, renderSearch]);

  return (
    <>
      <Box position="relative" width="100%">
        <Box position="absolute" top="0" left="0" width="100%" pb={22}>
          <Box position="sticky" top={56} bgcolor="light.main" borderBottom="1px solid rgba(0,0,0,.15)">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px={2}>
              <Box ml="-8px">
                <IconButton onClick={() => setIsFilterModalOpen(true)}>
                  <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faFilter} />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography variant="body2" component="span" color="secondary">
                  {`${(workerList && workerList.length) || 0} ${t('myWorkers:workersAndPayrolls')}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" height={100} key="loader">
                  <CircularProgress />
                </Box>
              }>
              {isWorkerListLoading && <LinearProgress />}
              <Table aria-label="simple table">
                <TableBody>
                  {workerList &&
                    workerList.map((worker) => (
                      <WorkerMobileRow
                        key={worker.id}
                        workerData={worker}
                        onClick={() => {
                          setWorkerDetail(worker);
                        }}
                      />
                    ))}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={isFilterModalOpen}
        onClose={() => {
          setIsFilterModalOpen(false);
        }}>
        <MyWorkersFilterMobile
          costCenters={costCenters}
          search={search}
          onChange={(filter) => {
            setSearch({ ...search, ...filter });
            setIsFilterModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={!!workerDetail}
        onClose={() => {
          setWorkerDetail(null);
        }}>
        <WorkerDetailMobile workerData={workerDetail} onAskContract={onAskContract} />
      </Modal>
      <Modal
        isOpen={newPayrollOpen}
        disableScrollLock={false}
        onClose={() => {
          setNewPayrollOpen(false);
        }}>
        <NewPayroll shouldOpenContract={(val) => setNewContractOpen(val)} />
      </Modal>
      <Modal
        isOpen={newContractOpen}
        disableScrollLock={false}
        onClose={() => {
          setNewContractOpen(false);
        }}>
        <NewContractHandler defaultValues={newContractValues} actionType="default" />
      </Modal>
      <MobileFab color="primary" aria-label="add" onClick={() => setNewPayrollOpen(true)}>
        <Add />
      </MobileFab>
    </>
  );
};

MyWorkersMobile.propTypes = {
  workerList: array,
  isWorkerListLoading: bool.isRequired,
  costCenters: array.isRequired,
  handleContract: func.isRequired,
  search: object.isRequired,
  setSearch: func.isRequired,
  hasMore: bool.isRequired,
  newContractValues: object,
  loadMore: func.isRequired
};

MyWorkersMobile.defaultProps = {
  workerList: null,
  newContractValues: null
};

export default MyWorkersMobile;
