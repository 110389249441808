import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const GreenTextTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.green
  }
}))(Typography);

export default GreenTextTypography;
