import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { isNil, pathOr, path } from 'ramda';

import { ReactComponent as CloudCheck } from 'assets/cloud-check.svg';

import { renderOnCondition } from 'utils/component';

import styles from './TempWorkerRow.styles';

const Status = ({ classes, data }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);

  return (
    <>
      <Box display="flex">
        {renderOnCondition(
          !!pathOr(null, ['info', 'confirmedInDaco'], data),
          <Tooltip title={t('tempWorkers:tooltips.confirmedInDaco')}>
            <Box mx={1}>
              <FontAwesomeIcon icon={faCheckCircle} className={classes.iconColorSuccess} />
            </Box>
          </Tooltip>
        )}
        {renderOnCondition(
          !!pathOr(null, ['info', 'autoConfirmed'], data),
          <Tooltip title={t('tempWorkers:tooltips.autoConfirmed')}>
            <Box mx={1}>
              <CloudCheck />
            </Box>
          </Tooltip>
        )}
        {renderOnCondition(
          !!pathOr(null, ['info', 'editedInDaco'], data),
          <Tooltip title={t('tempWorkers:tooltips.editedInDaco')}>
            <Box mx={1}>
              <FontAwesomeIcon icon={faEdit} className={classes.iconColorSuccess} />
            </Box>
          </Tooltip>
        )}
        {renderOnCondition(
          !isNil(path(['info', 'impersonateValidator'], data) || path(['info', 'impersonateEditor'], data)),
          <Tooltip title={t('tempWorkers:tooltips.impersonateValidator')}>
            <Box mx={1}>
              <FontAwesomeIcon icon={faUserSecret} className={classes.iconColorSuccess} />
            </Box>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

Status.propTypes = {
  classes: object.isRequired,
  data: object.isRequired
};

export default withStyles(styles)(Status);
