import { api, secureCall } from 'utils/api';
import { cleanObj } from 'utils/object';
import i18n from 'translate/i18n';
import { toast } from 'react-toastify';

export const getList = secureCall(async (from, to) => api.get('contract', cleanObj({ from, to })));
export const getToConfirmList = secureCall(async (from, to) =>
  api.get('contract/to-confirm-list', cleanObj({ from, to }))
);
export const getCostCentersList = secureCall(async () => api.get('cost-center'));
export const getJobFunctions = secureCall(async () => api.get('job-function'));
export const searchWorkerList = secureCall(async (fullName) => api.get(`candidate?name=${fullName}`));

export const getNextContractDates = secureCall(async ({ contract_ids, ...params }) =>
  api.get(
    'candidate/get-previous-and-next-contract-dates',
    cleanObj({
      ...params,
      'contract_ids[]': contract_ids
    })
  )
);

export const getPreviousContractDate = secureCall(async ({ candidateId, date }) =>
  api.get(
    'candidate/get-previous-contract-date',
    cleanObj({
      id: candidateId,
      date
    })
  )
);

const confirm = async ({ contractIds, impersonateValidatorId }) =>
  api.post('contract/confirm-timesheet', {
    contract_ids: contractIds,
    impersonate_validator_id: impersonateValidatorId
  });

const confirmError = (keySuffix) => (error) => {
  const { errorKey } = error;
  if (errorKey) return toast.error(i18n.t(`errors:${errorKey}${keySuffix}`));
  return toast.error(i18n.t('errors:failedSave'));
};

export const confirmTimesheet = secureCall(confirm, confirmError(''));
export const confirmTimesheets = secureCall(confirm, confirmError('_plural'));
