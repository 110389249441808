import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { isNil, pathOr } from 'ramda';
import clsx from 'clsx';

import { ReactComponent as CloudCheck } from 'assets/cloud-check.svg';

import { renderOnCondition } from 'utils/component';

import styles from './TempWorkersListMobile.styles';

const Status = ({ classes, data }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);

  const statusesIcons = useMemo(
    () => ({
      confirmedInDaco: () => (
        <FontAwesomeIcon icon={faCheckCircle} className={clsx(classes.iconColorSuccess, classes.iconSize)} />
      ),
      autoConfirmed: () => <CloudCheck />,
      editedInDaco: () => (
        <FontAwesomeIcon icon={faEdit} className={clsx(classes.iconColorSuccess, classes.iconSizeEditPrestation)} />
      )
    }),
    [classes]
  );

  return (
    <Box mt={2}>
      {[
        { label: 'confirmedInDaco', impersonate: 'impersonateValidator' },
        { label: 'autoConfirmed', impersonate: null },
        { label: 'editedInDaco', impersonate: 'impersonateEditor' }
      ].map((status) => (
        <Box key={status.label}>
          {renderOnCondition(
            !!pathOr(null, ['info', status.label], data),
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>{statusesIcons[status.label]()}</Box>
              {renderOnCondition(
                !isNil(pathOr(null, ['info', status.impersonate], data)),
                <Box ml={2} mr={1}>
                  <FontAwesomeIcon icon={faUserSecret} className={clsx(classes.iconColorSuccess, classes.iconSize)} />
                </Box>
              )}
              <Box mx={1}>
                <Typography variant="body2" className={classes.textColorSuccess}>
                  {t(`tempWorkers:tooltips.${status.label}`)}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

Status.propTypes = {
  classes: object.isRequired,
  data: object.isRequired
};

export default withStyles(styles)(Status);
