import { mergeContracts } from 'utils/tempWorkers';
import { getNextWorkingDay } from 'utils/date';

export const getMergedContract = (parentContracts) =>
  parentContracts.length ? mergeContracts(parentContracts)[0] : null;

export const getContractType = (secondData) => {
  const hasContractType = !!secondData?.contractType?.length;
  return {
    isSeasonal: hasContractType && secondData.contractType.includes(56) ? 1 : 0,
    isFlexi: hasContractType && secondData.contractType.includes(30) ? 1 : 0
  };
};

export const nextWorkingDay = getNextWorkingDay();
