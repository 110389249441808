import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { array, bool, func } from 'prop-types';
import { pathOr } from 'ramda';

import { Modal } from 'components';

import DesktopView from './Desktop/DesktopView';
import MobileView from './Mobile/MobileView';
import RequestDocument from './RequestDocument';

const Documents = ({
  documents,
  costCenters,
  getDocumentsList,
  getCostCenters,
  isMobile,
  requestDocument,
  getDocument,
  isLoadingDocuments
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getCostCenters();
  }, [getCostCenters]);

  const handleRequest = () => setIsModalOpen(true);
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <RequestDocument costCenters={costCenters} requestDocument={requestDocument} />
      </Modal>
      {isMobile ? (
        <MobileView
          costCenters={costCenters}
          documents={documents}
          onRequest={handleRequest}
          getDocument={getDocument}
          isLoading={isLoadingDocuments}
          getDocumentsList={getDocumentsList}
        />
      ) : (
        <DesktopView
          costCenters={costCenters}
          documents={documents}
          onRequest={handleRequest}
          getDocument={getDocument}
          isLoading={isLoadingDocuments}
          getDocumentsList={getDocumentsList}
        />
      )}
    </>
  );
};

Documents.propTypes = {
  documents: array.isRequired,
  costCenters: array.isRequired,
  getCostCenters: func.isRequired,
  getDocumentsList: func.isRequired,
  requestDocument: func.isRequired,
  getDocument: func.isRequired,
  isMobile: bool.isRequired,
  isLoadingDocuments: bool.isRequired
};

const mapState = ({ tempWorkers, documents, user, loading }) => ({
  costCenters: tempWorkers.costCenters,
  documents: documents.list,
  isMobile: user.isOnMobile,
  isLoadingDocuments: pathOr(false, ['effects', 'documents', 'getDocumentsList'], loading)
});

const mapDispatch = ({
  tempWorkers: { getCostCenters },
  documents: { getDocumentsList, requestDocument, getDocument }
}) => ({
  getCostCenters,
  getDocumentsList,
  requestDocument,
  getDocument
});

export default connect(mapState, mapDispatch)(Documents);
