/* eslint-disable max-lines */
import React, { useState } from 'react';
import { Box, TableCell, TextField, Typography, InputAdornment } from '@material-ui/core';
import { equals, cond, either, always, groupBy, values, omit, isNil, prop } from 'ramda';
import { isValidDate } from 'ramda-adjunct';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes, { array, bool, func, object, number } from 'prop-types';

import { isSameDay } from 'date-fns';

import FrLocaleUtilsProvider from 'components/FrLocaleUtilsProvider';
import GreenCheckbox from 'components/GreenCheckbox';
import CustomTimePicker from 'components/CustomTimePicker';

import { Euro } from 'utils/number';
import { mondayWeek, convertMinutesToTime, minutesToPickerValue, toIsoDateString, areSameHours } from 'utils/date';
import { PerformanceScheduleRow } from '.';

const makeClassName = (classes, day, add = []) =>
  clsx(classes.dayCell, classes.borderLessCell, ...add, isSameDay(day, new Date()) ? classes.todayCell : '');

const getChangedBonuses = ({ premiumCode, smileCode, pdate, storedBonuses }, event) => {
  const bonusIdx = storedBonuses.findIndex((b) => b.premium_code === premiumCode && b.date === toIsoDateString(pdate));
  const newBonuses = [...storedBonuses];
  if (bonusIdx === -1) return newBonuses;
  if ([1, 3].includes(smileCode)) {
    newBonuses[bonusIdx].minutes = isValidDate(event) ? event.getHours() * 60 + event.getMinutes() : 0;
  } else if (smileCode === 2) {
    const { value } = event.target;
    const parsed = parseFloat(value.replace(',', '.'));
    newBonuses[bonusIdx].amount = parsed >= 0 ? parsed : 0;
  } else {
    const { checked } = event.target;
    newBonuses[bonusIdx].minutes = checked ? 1 : 0;
  }
  return newBonuses;
};

const TimeCell = ({
  premiumCode,
  day,
  editMode,
  isEditable,
  bonus,
  storedBonuses,
  onBonusesChange,
  classes,
  onClick
}) => {
  const [value, setValue] = useState(minutesToPickerValue(bonus.minutes));

  return (
    <TableCell align="right" className={makeClassName(classes, day)} onClick={onClick}>
      {!isNil(prop('date', bonus)) && (
        <>
          {!editMode || !isEditable ? (
            <Typography variant="body2" color="secondary">
              {convertMinutesToTime(bonus.minutes, 'h')}
            </Typography>
          ) : (
            <FrLocaleUtilsProvider>
              <CustomTimePicker
                id={`premiumDate-${premiumCode}-${toIsoDateString(day)}`}
                ampm={false}
                inputProps={{ tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1 }}
                format="HH'h'mm"
                mask="__h__"
                autoOk
                value={value}
                helperText={null}
                variant="inline"
                onChange={(event) => {
                  if (isValidDate(event) && !areSameHours(event, value)) {
                    // lorsque les premiums codes sont récupérés, les dupliquer pour tous les jours qui ont des heures et set amount et minutes à 0
                    setValue(event);
                    onBonusesChange(
                      getChangedBonuses(
                        { premiumCode, smileCode: bonus.smile_code_type, pdate: day, storedBonuses },
                        event
                      ),
                      day
                    );
                  }
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.bonusInputRoot, input: classes.bonusInput }
                }}
                keyboardIcon={false}
                InputAdornmentProps={{ className: classes.adornment }}
              />
            </FrLocaleUtilsProvider>
          )}
        </>
      )}
    </TableCell>
  );
};

TimeCell.propTypes = {
  premiumCode: number,
  day: PropTypes.instanceOf(Date),
  editMode: bool,
  isEditable: bool,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func,
  classes: object.isRequired,
  onClick: func
};

TimeCell.defaultProps = {
  premiumCode: null,
  day: null,
  editMode: false,
  isEditable: false,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {},
  onClick: () => null
};

const AmountCell = ({
  premiumCode,
  day,
  bonus,
  editMode,
  isEditable,
  storedBonuses,
  onBonusesChange,
  classes,
  onClick
}) => {
  const [value, setValue] = useState((bonus.amount || 0).toFixed(2).toString().replace('.', ','));
  const [formatError, setFormatError] = useState(false);
  return (
    <TableCell align="right" key={day} className={makeClassName(classes, day)} onClick={onClick}>
      {!isNil(prop('date', bonus)) && (
        <>
          {!editMode || !isEditable ? (
            <Typography variant="body2" color="secondary">
              {Euro.format(bonus.amount)}
            </Typography>
          ) : (
            // eslint-disable-line react/jsx-no-duplicate-props
            <TextField
              align="right"
              value={value}
              error={formatError}
              onChange={(event) => {
                const val = event.target.value;
                setFormatError(true);
                if (/^[0-9]+(,|.[0-9]{1,2})?$/.test(val)) {
                  setFormatError(false);
                  onBonusesChange(
                    getChangedBonuses(
                      { premiumCode, smileCode: bonus.smile_code_type, pdate: day, storedBonuses },
                      event
                    ),
                    day
                  );
                }
                if (!val || /^[0-9]+(,|.[0-9]{0,2})?$/.test(val)) {
                  setValue(val.replace('.', ','));
                }
              }}
              onBlur={() => {
                if (!value) setValue('0,00');
              }}
              inputProps={{
                tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                classes: {
                  root: classes.bonusInputRoot,
                  input: classes.bonusInput
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Box mt="2px">
                      <Typography variant="body2" color="secondary">
                        €
                      </Typography>
                    </Box>
                  </InputAdornment>
                )
              }}
            />
          )}
        </>
      )}
    </TableCell>
  );
};

AmountCell.propTypes = {
  premiumCode: number,
  day: PropTypes.instanceOf(Date),
  editMode: bool,
  isEditable: bool,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func,
  classes: object.isRequired,
  onClick: func
};

AmountCell.defaultProps = {
  premiumCode: null,
  day: null,
  editMode: false,
  isEditable: false,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {},
  onClick: () => null
};

const CheckBoxCell = ({
  day,
  bonus,
  editMode,
  isEditable,
  classes,
  premiumCode,
  storedBonuses,
  onBonusesChange,
  onClick
}) => {
  return (
    <TableCell
      align="right"
      key={day}
      className={makeClassName(classes, day, editMode && isEditable ? [classes.checkBoxCell] : [])}
      onClick={onClick}>
      {!isNil(prop('date', bonus)) && (
        <>
          {!editMode || !isEditable ? (
            <Typography variant="body2" color="secondary">
              {prop('minutes', bonus)}
            </Typography>
          ) : (
            <GreenCheckbox
              inputProps={{ tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1 }}
              id={`cb-${day}`}
              name={`checked${day}`}
              checked={!!prop('minutes', bonus)}
              onChange={(event) => {
                onBonusesChange(
                  getChangedBonuses(
                    { premiumCode, smileCode: bonus.smile_code_type, pdate: day, storedBonuses },
                    event
                  ),
                  day
                );
              }}
            />
          )}
        </>
      )}
    </TableCell>
  );
};

CheckBoxCell.propTypes = {
  premiumCode: number,
  day: PropTypes.instanceOf(Date),
  bonus: object,
  editMode: bool,
  isEditable: bool,
  classes: object.isRequired,
  storedBonuses: array,
  onBonusesChange: func,
  onClick: func
};

CheckBoxCell.defaultProps = {
  premiumCode: null,
  day: null,
  bonus: {},
  editMode: false,
  isEditable: false,
  storedBonuses: [],
  onBonusesChange: () => {},
  onClick: () => null
};

const getComponent = cond([
  [either(equals(1), equals(3)), always(TimeCell)],
  [equals(2), always(AmountCell)],
  [equals(4), always(CheckBoxCell)]
]);

const BonusesDisplay = ({ isHomePage, editMode, weekDays, storedBonuses, onBonusesChange, classes, onBonusClick }) => {
  const { t, i18n } = useTranslation();
  const rows = values(
    groupBy((bonus) => `${bonus[`description_${i18n.language}`]}-${bonus.premium_code}`, storedBonuses)
  );

  return (
    <>
      <PerformanceScheduleRow
        isHomePage={isHomePage}
        label={t('general:performed.bonus')}
        icon={faAward}
        editMode={editMode}
        classes={!rows.length ? omit(['borderLessCell'], classes) : classes}
        weekDays={weekDays}
        storedBonuses={storedBonuses}
        childrenProps={{
          align: 'right',
          className: rows.length ? clsx(classes.dayCell, classes.borderLessCell) : classes.dayCell
        }}
      />
      {rows.map((bonusesRow, index) => {
        const { premium_code, smile_code_type, source, is_active, ...props } = bonusesRow[0];
        return (
          <PerformanceScheduleRow
            isHomePage={isHomePage}
            key={premium_code}
            label={props[`description_${i18n.language}`]}
            editMode={editMode}
            isEditable={source === 1 && is_active === 1}
            premiumCode={premium_code}
            classes={rows.length === index + 1 ? omit(['borderLessCell'], classes) : classes}
            weekDays={weekDays}
            storedBonuses={bonusesRow}
            cellComponent={getComponent(smile_code_type)}
            childrenProps={{
              align: 'right',
              className: clsx(classes.dayCell, classes.borderLessCell),
              currency: smile_code_type === 2,
              premiumCode: premium_code,
              storedBonuses,
              onClick: onBonusClick,
              onBonusesChange
            }}
            {...(smile_code_type === 4 && {
              total: bonusesRow.reduce((acc, curr) => acc + curr.minutes, 0)
            })}
          />
        );
      })}
    </>
  );
};

BonusesDisplay.propTypes = {
  isHomePage: bool,
  premiums: array,
  editMode: bool,
  weekDays: array,
  bonuses: array,
  storedBonuses: array,
  onBonusesChange: func,
  classes: object.isRequired,
  onBonusClick: func
};

BonusesDisplay.defaultProps = {
  isHomePage: false,
  premiums: [],
  editMode: false,
  weekDays: [],
  bonuses: [],
  storedBonuses: [],
  onBonusesChange: () => {},
  onBonusClick: () => null
};

export default BonusesDisplay;
