import React, { useState } from 'react';
import { object, array, func, string } from 'prop-types';
import { Box, TextField, Popper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = makeStyles(() => ({
  root: {
    flex: 1,
    overflow: 'hidden'
  },
  inputRoot: {
    '&:before, &:after': {
      content: 'none'
    }
  },
  tag: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis'
  }
}));

const IconAutocomplete = ({ icon, value, getOptionLabel, noValueMessage, ...props }) => {
  const classes = styles();
  const theme = useTheme();
  const [focused, setFocused] = useState(false);

  return (
    <Box display="flex" flexDirection="row" position="relative">
      <Box mr={1}>
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: '20px', marginTop: 6 }}
          color={theme.palette.text.secondary}
          fixedWidth
        />
      </Box>
      <Autocomplete
        value={value}
        disableClearable={!value.length}
        classes={classes}
        PopperComponent={(p) => (
          <Popper {...p} disablePortal style={{ width: '100%', transform: 'translate3d(0,32px,0)' }} />
        )}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect
        renderInput={({ inputProps, ...params }) => {
          return (
            <TextField
              {...params}
              inputProps={{
                ...inputProps,
                value: value.length || focused ? inputProps.value : noValueMessage,
                onFocus: (e) => {
                  inputProps.onFocus(e);
                  setFocused(true);
                },
                onBlur: (e) => {
                  inputProps.onBlur(e);
                  setFocused(false);
                }
              }}
            />
          );
        }}
        {...props}
      />
    </Box>
  );
};

IconAutocomplete.propTypes = {
  icon: object.isRequired,
  value: array,
  getOptionLabel: func.isRequired,
  noValueMessage: string.isRequired
};

IconAutocomplete.defaultProps = {
  value: []
};

export default IconAutocomplete;
