import React from 'react';
import clsx from 'clsx';
import { bool, func, object, string } from 'prop-types';
import { Box, ButtonBase, TableCell, TableRow, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/core/styles';

const TitleBar = ({ classes, title, subtitle, isOpen, canCollapse, toggle }) =>
  canCollapse ? (
    <TableRow className={classes.titleRow}>
      <TableCell colSpan={100}>
        <ButtonBase className={classes.titleBar} onClick={toggle}>
          <Box p={0} height="100%" className={classes.titleBarContent}>
            <Typography variant="body2" className={classes.titleBarText}>
              {title}
            </Typography>
            <Box px={5}>
              <Typography variant="body2" className={classes.subtitle}>
                {subtitle}
              </Typography>
            </Box>

            {canCollapse && <Box ml="auto">{isOpen ? <ArrowDropUp /> : <ArrowDropDown />}</Box>}
          </Box>
        </ButtonBase>
      </TableCell>
    </TableRow>
  ) : (
    <Box p={2} className={clsx(classes.titleBarContent, classes.titleRow)}>
      <Typography variant="body2" className={classes.titleBarText}>
        {title}
      </Typography>
    </Box>
  );

TitleBar.propTypes = {
  classes: object.isRequired,
  title: string.isRequired,
  subtitle: string,
  isOpen: bool,
  canCollapse: bool,
  toggle: func
};

TitleBar.defaultProps = {
  isOpen: false,
  canCollapse: false,
  subtitle: null,
  toggle: () => null
};

const styles = createStyles((theme) => ({
  titleBar: {
    width: '100%'
  },
  titleBarContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'sticky',
    top: `calc(${theme.headerHeight}px + 40px)`,
    color: 'white',
    zIndex: 20,
    paddingLeft: theme.spacing(4)
  },
  titleRow: {
    backgroundColor: '#7d7d7d',
    '& th,td': {
      padding: 0,
      borderBottom: 0
    }
  }
}));

export default withStyles(styles)(TitleBar);
