import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    buttonGroup: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    desktopFooter: {
      backgroundColor: theme.palette.background.paper,
      position: 'fixed',
      padding: '2px 0',
      width: '117.6em'
    },
    comment: {
      whiteSpace: 'pre-line'
    },
    workerNav: {
      margin: '0 -12px'
    },
    workerNavText: {
      margin: '0 12px'
    },
    workerButton: {
      color: theme.palette.secondary.main,
      fontSize: 13,
      cursor: 'pointer',
      padding: 12,
      minWidth: 0,
      '&:hover': {
        background: 'none'
      }
    },
    weeksNav: {
      margin: '0 -4px'
    },
    weeksNavText: {
      margin: '0 12px 0 4px'
    },
    weeksButton: {
      margin: '0 4px'
    },
    weeksNavIconLeft: {
      marginRight: 12,
      fontSize: 11,
      marginTop: 1
    },
    weeksNavIconRight: {
      marginLeft: 12,
      fontSize: 11,
      marginTop: 1
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'none',
      fontSize: '1.4rem'
    },
    italic: {
      fontStyle: 'italic'
    },
    mobileContainer: {},
    mobileButtonBox: {
      flex: 1
    },
    fixedLineHeight: {
      lineHeight: 1
    },
    mobileHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      padding: '1rem',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 50
    },
    mobileFooter: {
      backgroundColor: theme.palette.background.paper,
      position: 'fixed',
      padding: theme.spacing(2),
      bottom: 0,
      left: 0,
      right: 0
    },
    box: {
      width: '100%',
      backgroundColor: theme.palette.light.main,
      borderTop: '1px solid #e5e5e5'
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      [theme.breakpoints.down('md')]: {
        display: 'block'
      }
    },
    paddinglessRightButton: {
      paddingRight: 0
    }
  })
);
