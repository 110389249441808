import { allPass, complement, is, isEmpty } from 'ramda';

import * as service from './service';

export const geo = {
  state: {
    geoData: null
  },
  reducers: {
    gotGeoData: (state, geoData) => ({ ...state, geoData })
  },
  effects: {
    async getGeoData() {
      const [frCountries, nlCountries, phoneCodes] = await Promise.all([
        service.getCountries('fr'),
        service.getCountries('nl'),
        service.getPhoneCodes()
      ]);

      if ([frCountries, nlCountries, phoneCodes].every(allPass([is(Object), complement(isEmpty)]))) {
        const geoData = Object.keys(frCountries).map((key) => ({
          id: key,
          name_fr: frCountries[key],
          name_nl: nlCountries[key],
          phone: phoneCodes[key]
        }));
        this.gotGeoData(geoData);
      }
    }
  }
};
