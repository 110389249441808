/* eslint-disable complexity */
import React, { useEffect } from 'react';
import Proptypes, { bool, func, node, string } from 'prop-types';
import { isNil } from 'ramda';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { eventGA } from 'utils/analytics';

const ConfirmDialog = ({
  alignTitle,
  isOpen,
  onClose,
  actions,
  children,
  title,
  showNextDayButton,
  showCloseButton,
  isOrdering
}) => {
  const { cancel, confirm, disabled, confirmAndCopyToNextDay } = actions || {};
  const { t } = useTranslation(['general']);

  useEffect(() => {
    if (isOpen) {
      eventGA('modal_opened', `ConfirmDialog-${title}`);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description">
      {showCloseButton && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <DialogTitle disableTypography id="confirm-dialog-title">
        <Typography align={alignTitle} variant="h6" component="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {!isNil(confirmAndCopyToNextDay) && showNextDayButton && (
          <Button type="button" onClick={confirmAndCopyToNextDay} color="primary" disabled={disabled || isOrdering}>
            {t('general:performance.copyToNextDay')}
          </Button>
        )}
        {!isNil(cancel) && (
          <Button type="button" onClick={cancel} color="primary" disabled={disabled || isOrdering}>
            {t('general:cancel')}
          </Button>
        )}
        <Button type="button" onClick={confirm} variant="contained" color="primary" disabled={disabled || isOrdering}>
          {t('general:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  alignTitle: Proptypes.oneOf(['left', 'center', 'right']),
  actions: Proptypes.shape({
    confirm: func
  }).isRequired,
  children: node,
  isOpen: bool.isRequired,
  onClose: func,
  title: string.isRequired,
  showNextDayButton: bool,
  showCloseButton: bool,
  isOrdering: bool
};

ConfirmDialog.defaultProps = {
  children: null,
  alignTitle: 'center',
  onClose: () => null,
  showNextDayButton: false,
  showCloseButton: false,
  isOrdering: false
};

export default ConfirmDialog;
