import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const GreenCheckbox = withStyles((theme) => ({
  root: {
    padding: 8,
    marginRight: -2,
    '&$checked': {
      color: theme.palette.green
    }
  },
  checked: {}
}))((props) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;
