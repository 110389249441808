import React, { useState } from 'react';
import { any, pathOr, propOr, and } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { FormControl, FormHelperText, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes, { array, bool, func, object, string } from 'prop-types';
import { addDays, isAfter, isBefore, isWeekend } from 'date-fns';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { mondayWeek } from 'utils/date';

import { FrLocaleUtilsProvider, CustomTimePicker } from 'components';
import { getTimeError, getTotal, hourRowsName } from '../ScheduleForm/common';
import scheduleFormStyle from '../ScheduleForm/scheduleFormStyle';

const ScheduleFormRow = ({
  label,
  name,
  classes,
  weekDays,
  onTimeChange,
  scheduleDays,
  date,
  dateLimit,
  isTotal,
  timeErrors,
  titleVariant,
  titleColor,
  titleComponent,
  children
}) => {
  const { t } = useTranslation(['general', 'validation', 'helpers']);
  const [focused, setFocused] = useState(false);
  const getHour = (day, hourName) =>
    propOr(
      null,
      hourName
    )(
      scheduleDays.find((sd) => {
        return sd.dayNumber === mondayWeek[day];
      }) || {}
    );

  const isTotalReadOnly = (day) =>
    any((hourName) => isNotNil(getHour(day, hourName)))([
      hourRowsName.startAm,
      hourRowsName.endAm,
      hourRowsName.startPm,
      hourRowsName.endPm
    ]);

  return (
    <TableRow key={name} className={classes.headRow}>
      <TableCell align="left" key={label} className={clsx(classes.borderLessCell, classes.firstScheduleCell)}>
        <Typography variant={titleVariant} color={titleColor} component={titleComponent}>{`- ${label}`}</Typography>
      </TableCell>
      {weekDays.map((day) => (
        <TableCell
          align="right"
          key={mondayWeek[day.getDay()]}
          className={clsx(classes.dayCell, classes.borderLessCell, classes.timeCell, {
            [classes.weekendCell]: isWeekend(day)
          })}>
          <FrLocaleUtilsProvider>
            <FormControl>
              <CustomTimePicker
                ampm={false}
                inputProps={{ className: classes.timeInput, tabIndex: mondayWeek[day.getDay()] + 1 }}
                InputProps={{ disableUnderline: true }} // eslint-disable-line react/jsx-no-duplicate-props
                format="HH:mm"
                mask="__:__"
                autoOk
                disabled={
                  (isAfter(day, new Date(dateLimit.toDateString())) && isBefore(day, new Date(date.toDateString()))) ||
                  !scheduleDays.map((sd) => sd.dayNumber).includes(mondayWeek[day.getDay()]) ||
                  and(isTotal, isTotalReadOnly(day.getDay()))
                }
                readOnly={isTotal && isTotalReadOnly(day.getDay())}
                variant="inline"
                value={
                  isTotal
                    ? getTotal(scheduleDays.find((sd) => sd.dayNumber === mondayWeek[day.getDay()]))
                    : getHour(day.getDay(), name)
                }
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                invalidDateMessage={!focused && t('validation:scheduleInvalid')}
                onChange={(value) => onTimeChange(mondayWeek[day.getDay()], value)}
                keyboardIcon={false}
                InputAdornmentProps={{ classes: { root: classes.keyboardIcon } }}
              />
              {getTimeError(mondayWeek[day.getDay()], name)(timeErrors) && (
                <FormHelperText error={!!getTimeError(mondayWeek[day.getDay()], name)(timeErrors)}>
                  {t(
                    `${pathOr(
                      'general:extend:wrongTimeValue',
                      ['message'],
                      getTimeError(mondayWeek[day.getDay()], name)(timeErrors)
                    )}`
                  )}
                </FormHelperText>
              )}
            </FormControl>
          </FrLocaleUtilsProvider>
        </TableCell>
      ))}
      {children || <TableCell align="right" className={classes.borderLessCell} />}
    </TableRow>
  );
};

ScheduleFormRow.propTypes = {
  name: string,
  label: string,
  weekDays: array.isRequired,
  scheduleDays: array.isRequired,
  isTotal: bool,
  timeErrors: array,
  onTimeChange: func,
  classes: object.isRequired,
  date: PropTypes.instanceOf(Date),
  dateLimit: PropTypes.instanceOf(Date),
  titleVariant: string,
  titleColor: string,
  titleComponent: string,
  children: object
};

ScheduleFormRow.defaultProps = {
  name: '',
  label: '',
  isTotal: false,
  timeErrors: [],
  onTimeChange: () => {},
  date: new Date(),
  dateLimit: addDays(new Date(), 1),
  titleVariant: 'body2',
  titleColor: 'secondary',
  titleComponent: null,
  children: null
};

export default withStyles(scheduleFormStyle)(ScheduleFormRow);
