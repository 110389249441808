import React, { Fragment, useMemo, useState } from 'react';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import { prop, pathOr } from 'ramda';
import { Box, LinearProgress, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { isSameDay } from 'date-fns';

import { getWeekDays } from 'utils/date';
import { eventGA } from 'utils/analytics';

import TitleBar from 'components/TempWorkersList/common/TitleBar';
import { costCenterString } from 'utils/tempWorkers';
import styles from './Planning.desktop.styles';
import PlanningHeader from './PlanningHeader';
import PlanningCandidateRow from './PlanningCandidateRow';
import PlanningFunctionRow from './PlanningFunctionRow';

const PlanningDesktop = ({
  classes,
  list,
  date,
  dayFilter,
  dayFilterChange,
  setDate,
  isLoading,
  sendAnalytics,
  viewByWorker,
  ...props
}) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);
  const [hiddenCenter, setHiddenCenters] = useState([]);
  const titleSection = useMemo(() => (viewByWorker ? 'prestations' : 'postes'), [viewByWorker]);

  const weekDays = getWeekDays(date);

  const buildSubtitle = (element) => {
    return `${element.length} ${
      element.length > 1 ? t(`general:planning.${titleSection}.plural`) : t(`general:planning.${titleSection}.singular`)
    }`;
  };

  return (
    <>
      <PlanningHeader date={date} setDate={setDate} viewByWorker={viewByWorker} {...props} />
      <Box mt={6} width="100%">
        <Table aria-label="simple table">
          <TableBody>
            <TableRow className={classes.headRow}>
              <TableCell component="th" className={classes.firstAndLastCell}>
                {t(`general:planning.${titleSection}.plural`).toUpperCase()}
              </TableCell>

              {viewByWorker && <TableCell align="left"> {t('general:status').toUpperCase()}</TableCell>}

              {(weekDays || []).map((day) => {
                const isDayFiltered = dayFilter.includes(day.getDay());
                return (
                  <TableCell
                    align="right"
                    key={day}
                    className={clsx(classes.dayCell, {
                      [classes.todayCell]: isSameDay(day, new Date()),
                      [classes.filteredDayCell]: dayFilter.includes(day.getDay())
                    })}
                    onClick={() => {
                      if (sendAnalytics) eventGA('home_day_filter', day.toISOString());
                      dayFilterChange((currentDays) =>
                        isDayFiltered ? currentDays.filter((d) => d !== day.getDay()) : [...currentDays, day.getDay()]
                      );
                    }}>
                    <Tooltip
                      title={t(`tempWorkers:help.${isDayFiltered ? 'removeDayFilter' : 'dayFilter'}`)}
                      classes={{ tooltip: classes.dayFilterTooltip }}>
                      <Box>
                        <FontAwesomeIcon icon={faFilter} className={clsx(classes.iconColor, classes.mediumIcon)} />
                        <Box display="inline" pl={3}>
                          <Typography variant="body2" component="span" color="secondary">
                            {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                          </Typography>
                        </Box>
                        <Typography variant="subtitle1" component="p" color="secondary">
                          {day.getDate()} {t(`helpers:dates.months.short.${day.getMonth()}`)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </TableCell>
                );
              })}
              <TableCell align="right" className={classes.dayCell}>
                <Typography variant="body2" color="secondary">
                  {t('general:total')}
                </Typography>
              </TableCell>
            </TableRow>

            {isLoading && (
              <TableRow className={clsx(classes.noBorderRow, classes.noPaddingRow)}>
                <TableCell colSpan={100} className={classes.firstAndLastCell}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {!!prop('length', list) &&
              (list || []).map((listElement) => {
                const dataInfos = pathOr({}, [0, 'costCenter'], listElement);
                const sectionTitle = `${costCenterString(dataInfos, dataInfos)}`;
                return (
                  <Fragment key={uuid()}>
                    <TitleBar
                      title={sectionTitle}
                      subtitle={buildSubtitle(listElement)}
                      isOpen={!hiddenCenter.includes(dataInfos.customer_commercial_name)}
                      canCollapse
                      toggle={() =>
                        setHiddenCenters((current) =>
                          current.includes(sectionTitle)
                            ? current.filter((c) => c !== sectionTitle)
                            : [...current, sectionTitle]
                        )
                      }
                    />
                    {(listElement || []).map((rowData, index) => {
                      return viewByWorker ? (
                        <PlanningCandidateRow
                          showDetails
                          key={uuid()}
                          data={rowData}
                          show={!hiddenCenter.includes(sectionTitle)}
                          weekDays={weekDays}
                          isLast={index === listElement.length - 1}
                        />
                      ) : (
                        <PlanningFunctionRow
                          showDetails
                          key={uuid()}
                          data={rowData}
                          show={!hiddenCenter.includes(sectionTitle)}
                          weekDays={weekDays}
                          isLast={index === listElement.length - 1}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

PlanningDesktop.propTypes = {
  dayFilter: array.isRequired,
  list: array.isRequired,
  date: PropTypes.instanceOf(Date),
  dayFilterChange: func.isRequired,
  setDate: func,
  classes: object.isRequired,
  isLoading: bool,
  sendAnalytics: bool,
  viewByWorker: bool.isRequired
};

PlanningDesktop.defaultProps = {
  date: new Date(),
  setDate: () => null,
  isLoading: false,
  sendAnalytics: false
};

export default withStyles(styles)(PlanningDesktop);
