/** @module api */
/** Api */
import { is, isEmpty, merge, mergeDeepLeft, pathOr } from 'ramda';
import HttpError from './utils/http-error';
import { BuildFormData, flattenObject } from './utils';

/**
 * @constant
 * @type {string}
 * @description Default header {'Content-type': 'application/json'}
 */
const DEFAULT_TYPE = 'application/json';
const DEFAULT_HEADER = {
  'Content-type': DEFAULT_TYPE
};

const makeStaticHeader = () => {
  let staticParameters = {};

  return (parameters) => {
    if (is(Object, parameters)) {
      staticParameters = merge(staticParameters, parameters);
      Object.keys(staticParameters).forEach((item) => {
        if (!staticParameters[item]) delete staticParameters[item];
      });
    }

    if (!isEmpty(staticParameters)) return staticParameters;

    return null;
  };
};

const makeFunction = (defaultFunction) => (fn) => fn || defaultFunction;

/**
 * @function
 * @param {Object} response
 * @param {Boolean} response.ok request status
 * @param {Boolean} response.status http status
 * @returns {Object} passed args object
 * @throws {Error} Message to defined
 */
const defaultHandleError = async (error = {}) => {
  let body;
  const code = error.status;
  const type = (error.headers && error.headers.get('Content-Type')) || DEFAULT_TYPE;
  try {
    body = await (type.startsWith(DEFAULT_TYPE) ? error.json() : error.text());
  } catch (error_) {
    body = error.statusText;
  }
  throw new HttpError(code, body);
};

const fomatXLSResponse = async (response, contentType) => {
  const contentDisposition = response.headers.get('content-disposition') || '';
  const fileName = contentDisposition.slice(contentDisposition.indexOf('=') + 1).slice(1, -1);
  return { fileName, file: await response.blob(), contentType };
};

// eslint-disable-next-line complexity
const defaultHandleResponse = (query) => (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  if ([204].includes(response.status)) return Promise.resolve({});
  const contentType = response.headers.get('Content-Type') || '';
  const currentPage = response.headers.get('x-pagination-current-page');
  if (query?.page > currentPage) {
    return [];
  }
  if (contentType.startsWith('text')) return response.text();
  if (contentType.includes('json')) return response.json();
  if (['application/', 'image/'].some((type) => contentType.startsWith(type)))
    return fomatXLSResponse(response, contentType);
  return Promise.resolve({});
};

const defaultBeforeFetch = () => {};

const checkUrl = (url) => {
  if (!url) throw new Error('Missing parameter: serverUrl');
};

const api = (baseUrl, queryPrefix) => {
  checkUrl(baseUrl);

  const errorFunc = defaultHandleError;
  const responseFunc = defaultHandleResponse;
  const beforeFetchFunc = defaultBeforeFetch;

  const headerStatic = makeStaticHeader();

  const makeHeader = () => {
    const initialHeader = DEFAULT_HEADER;
    return (parameters) => ({
      headers: { ...initialHeader, ...headerStatic(), ...parameters }
    });
  };

  const header = makeHeader();

  const handleError = makeFunction(errorFunc);
  const handleResponse = makeFunction(responseFunc);
  const beforeFetch = makeFunction(beforeFetchFunc);

  const request = async ({ url, params, headers, query }) => {
    if (!url) throw new Error('url must be defined');
    if (!is(String, url)) throw new TypeError('url must be a string');

    const requestHeader = header(headers);
    if (!is(Object, requestHeader)) throw new TypeError('requestHeader must be an object');
    if (!pathOr(false, ['headers', 'Content-type'], requestHeader)) {
      throw new Error('request must contains Content-type in headers object');
    }
    const actualUrl = new URL(baseUrl + url);
    if (query) {
      const flattenedQuery = flattenObject(query, queryPrefix);
      Object.keys(flattenedQuery).forEach((key) => {
        if (Array.isArray(flattenedQuery[key])) {
          flattenedQuery[key].map((value) => actualUrl.searchParams.append(key, value));
        } else actualUrl.searchParams.append(key, flattenedQuery[key]);
      });
    }
    await beforeFetch()();
    return fetch(actualUrl, mergeDeepLeft(params, requestHeader)).then(handleResponse()(query)).catch(handleError());
  };

  const makeRequest = (url, method, body, headers = {}) =>
    request({
      url,
      params: {
        body,
        method,
        headers
      }
    });

  const sendFile = (method) => (url, body) => {
    const formattedBody = BuildFormData(body);
    const parameters = {
      body: formattedBody,
      method,
      headers: headerStatic()
    };

    return fetch(baseUrl + url, parameters)
      .then(handleResponse())
      .catch(handleError());
  };

  /**
   * @description Get method
   * @function
   * @param {String} url
   * @returns {Promise} Return fetch results
   */
  const get = (url, query, headers) => {
    return request({ url, params: { method: 'GET' }, query, headers });
  };

  /**
   * @description Post method
   * @function
   * @param {String} url
   * @param {Object} body
   * @param {Object} headers
   * @param {string} headers.contentType application/json
   * @returns {Promise} Return fetch results
   */
  const post = (url, body = {}, headers = {}) => makeRequest(url, 'POST', JSON.stringify(body), headers);

  /**
   * @description Post File method
   * @function
   * @param {String} url
   * @param {Object} formData
   * @param {Object} headers
   * @param {string} headers.contentType multipart/form-data
   * @returns {Promise} Return fetch results
   */
  const postFile = (url, body) => sendFile('POST')(url, body);

  /**
   * @description Put method
   * @function
   * @param {String} url
   * @param {Object} body
   * @param {Object} headers
   * @param {string} headers.contentType application/json
   * @returns {Promise} Return fetch results
   */
  const put = (url, body = {}, headers = {}) => makeRequest(url, 'PUT', JSON.stringify(body), headers);

  /**
   * @description Put File method
   * @function
   * @param {String} url
   * @param {Object} formData
   * @param {Object} headers
   * @param {string} headers.contentType multipart/form-data
   * @returns {Promise} Return fetch results
   */
  const putFile = (url, body) => sendFile('PUT')(url, body);

  /**
   * @description Patch method
   * @function
   * @param {String} url
   * @param {Object} body
   * @param {Object} headers
   * @param {string} headers.contentType application/json
   * @returns {Promise} Return fetch results
   */
  const patch = (url, body = {}, headers = {}) => makeRequest(url, 'PATCH', JSON.stringify(body), headers);

  /**
   * @description Patch File method
   * @function
   * @param {String} url
   * @param {Object} formData
   * @param {Object} headers
   * @param {string} headers.contentType multipart/form-data
   * @returns {Promise} Return fetch results
   */
  const patchFile = (url, body) => sendFile('PATCH')(url, body);

  /**
   * @description Delete method
   * @function
   * @param {String} url
   * @param {Object} body
   * @param {Object} headers
   * @param {string} headers.contentType application/json
   * @returns {Promise} Return fetch results
   */
  const del = (url, body = {}, headers = {}) => makeRequest(url, 'DELETE', JSON.stringify(body), headers);

  /**
   * @description Head method
   * @function
   * @param {String} url
   * @returns {Promise} Return fetch results
   */
  const head = (url) => request({ url, params: { method: 'HEAD' } });

  /**
   * @description Options method
   * @function
   * @param {String} url
   * @returns {Promise} Return fetch results
   */
  const options = (url) => request({ url, params: { method: 'OPTIONS' } });

  return {
    get,
    post,
    postFile,
    put,
    putFile,
    patch,
    patchFile,
    del,
    head,
    options,
    getApiUrl: () => baseUrl,
    getHeader: () => header().headers,
    setHeader: headerStatic,
    setHandleErrorFunc: (fn) => handleError(fn),
    setHandleResponseFunc: (fn) => handleResponse(fn),
    setBeforeFetchFunc: (fn) => beforeFetch(fn)
  };
};

export { api, HttpError };
