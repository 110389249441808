import React, { useEffect, useState } from 'react';
import PropTypes, { array, bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Tooltip, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faList, faUserFriends, faDownload } from '@fortawesome/free-solid-svg-icons';
import { transparentize } from 'polished';
import { compose, isNil, pathOr } from 'ramda';

import SearchField from 'components/SearchField';
import WeekSelector from 'components/WeekSelector/WeekSelector';
import { useDebounce, useHeader, useList } from 'hooks';

import IconAutocomplete from 'components/IconAutocomplete';
import { costCenterString } from 'utils/tempWorkers';
import RequestMenu from 'components/TempWorkersList/common/RequestMenu';
import { renderOnCondition } from 'utils/component';
import { openFile } from 'utils/file';

const PlanningHeader = ({
  date,
  setDate,
  classes,
  viewByWorker,
  setViewByWorker,
  filters,
  setFilters,
  costCenters,
  getCostCenters,
  loadingCostCenters,
  exportPlanningXLS,
  exportParams
}) => {
  const { t } = useTranslation(['tempWorkers', 'helpers', 'general']);
  const { search, setSearch } = useHeader();
  const [searchTerm, setSearchTerm] = useState(search || '');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const handleChange = (val) => setSearchTerm(val);

  useEffect(() => {
    if (!isNil(debouncedSearchTerm)) {
      setSearch(debouncedSearchTerm.trim());
    }
  }, [debouncedSearchTerm, setSearch]);

  const exportToXLS = () => openFile(async () => exportPlanningXLS(exportParams));

  useList(costCenters, getCostCenters, loadingCostCenters);
  return (
    <>
      <Box display="flex" my={5}>
        <Typography component="h6" variant="h6">
          {t('general:menu.planning')}
        </Typography>
        <Box ml="auto">
          <RequestMenu />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box mr={4}>
          <WeekSelector dateChange={setDate} value={date} />
        </Box>
        <Box display="flex" alignItems="center" flex={1}>
          <Box mr={4} width="100%" minWidth={250}>
            <IconAutocomplete
              multiple
              icon={faIndustry}
              options={costCenters}
              getOptionLabel={costCenterString}
              value={filters?.cost_centers}
              noValueMessage={t('general:docs.allCostCenters')}
              onChange={(_, value) => {
                setFilters((f) => ({ ...f, cost_centers: value }));
              }}
            />
          </Box>
          <Box mr={4} minWidth={200}>
            <SearchField value={searchTerm} onChange={(e) => handleChange(e.target.value)} />
          </Box>
          <ButtonGroup color="secondary" size="small">
            <Tooltip title={t('general:planning.help.showByWorker')}>
              <Button
                onClick={() => {
                  setViewByWorker(true);
                }}
                className={viewByWorker ? classes.activeButton : ''}>
                <FontAwesomeIcon icon={faUserFriends} />
              </Button>
            </Tooltip>
            <Tooltip title={t('general:planning.help.showByFunction')}>
              <Button
                onClick={() => {
                  setViewByWorker(false);
                }}
                className={!viewByWorker ? classes.activeButton : ''}>
                <FontAwesomeIcon icon={faList} />
              </Button>
            </Tooltip>
          </ButtonGroup>
          {renderOnCondition(
            !!exportPlanningXLS,
            <Tooltip title={t('tempWorkers:help.exportXLSX')}>
              <Button color="secondary" className={classes.exportBtn} onClick={() => exportToXLS()}>
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  );
};

PlanningHeader.propTypes = {
  classes: object.isRequired,
  date: PropTypes.instanceOf(Date),
  setDate: func,
  viewByWorker: bool,
  setViewByWorker: func,
  filters: PropTypes.oneOfType([string, object]),
  setFilters: func,
  costCenters: array,
  getCostCenters: func.isRequired,
  loadingCostCenters: bool.isRequired,
  exportPlanningXLS: func,
  exportParams: object
};

PlanningHeader.defaultProps = {
  date: new Date(),
  setDate: () => null,
  viewByWorker: false,
  setViewByWorker: () => null,
  filters: {},
  setFilters: () => null,
  costCenters: [],
  exportPlanningXLS: () => null,
  exportParams: null
};

const styles = createStyles((theme) => ({
  activeButton: {
    backgroundColor: transparentize(0.8, theme.palette.primary.main)
  },
  exportBtn: {
    height: 32,
    minWidth: 32,
    marginLeft: 8,
    padding: 0
  }
}));

const mapState = ({ tempWorkers, loading }) => ({
  costCenters: tempWorkers.costCenters,
  loadingCostCenters: pathOr(false, ['effects', 'tempWorkers', 'costCenters'], loading)
});

const mapDispatch = ({ orders, tempWorkers }) => ({
  exportPlanningXLS: orders.exportPlanningXLS,
  getCostCenters: tempWorkers.getCostCenters
});

export default compose(withStyles(styles), connect(mapState, mapDispatch))(PlanningHeader);
