import React from 'react';
import { array, bool, object, node, string, func } from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import { Box, FormHelperText, FormControl, InputLabel, MenuItem, Select, FilledInput } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const autoCompleteStyle = (customList) =>
  makeStyles(() => {
    return {
      inputRoot: () => ({
        height: 'auto',
        ...(customList
          ? {
              '&[class*="MuiFilledInput-root"]': {
                padding: '9px 30px 10px 15px',
                width: 150
              }
            }
          : {
              '&.MuiFilledInput-adornedStart': {
                paddingTop: 24,
                paddingBottom: 6
              }
            })
      }),
      ...(customList && {
        paper: {
          width: '250px'
        },
        listbox: {
          maxWidth: '100%'
        },
        option: {
          padding: 0
        }
      })
    };
  });

export const BootstrapInput = withStyles(() => ({
  root: () => ({
    '& svg': {
      right: 9
    }
  })
}))(FilledInput);

export const inputStyle = makeStyles(() => ({
  root: (props) => ({
    ...props
  })
}));

const IconSelectable = ({
  controllerProps,
  dataTestId,
  error,
  helperText,
  icon,
  options,
  label,
  fillSpace,
  type,
  onInputChange,
  required,
  inputStyles,
  renderInput,
  customList,
  filterSearch,
  ...props
}) => {
  const getField = (classes, acClasses) => {
    let comp;
    switch (type.toLowerCase()) {
      case 'controlledselect':
        comp = (
          <FormControl fullWidth variant="filled" required={required} error={error}>
            <InputLabel id={`${label.replace(/\W/gi, '-')}-cSelect`}>{label}</InputLabel>
            <Controller
              as={
                <Select
                  classes={classes}
                  labelId={`${label.replace(/\W/gi, '-')}-cSelect`}
                  inputProps={{ 'data-testid': dataTestId, classes }}
                  input={<BootstrapInput />}
                  {...props}>
                  {options.map(({ name, value, key, selected }) => (
                    <MenuItem key={key} value={value} selected={selected || false}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              }
              {...controllerProps}
            />
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </FormControl>
        );
        break;
      case 'autocomplete':
        comp = (
          <FormControl fullWidth variant="filled" error={error} required={required}>
            <Controller
              {...props}
              as={
                <Autocomplete
                  id={label}
                  classes={acClasses}
                  options={options}
                  filterOptions={filterSearch}
                  renderInput={
                    renderInput ||
                    ((params) => {
                      return (
                        <TextField
                          label={label}
                          {...params}
                          onChange={onInputChange}
                          variant="filled"
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                        />
                      );
                    })
                  }
                />
              }
            />
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </FormControl>
        );
        break;
      default:
        comp = (
          <FormControl fullWidth variant="filled" error={error}>
            <InputLabel id={`${label.replace(/\W/gi, '-')}-select`}>{label}</InputLabel>
            <Select
              labelId={`${label.replace(/\W/gi, '-')}-select`}
              input={<BootstrapInput />}
              inputProps={{ classes }}
              {...props}>
              <MenuItem value="">
                <em> - </em>
              </MenuItem>
              {options.map(({ name, value, key, selected }) => (
                <MenuItem key={key} value={value} selected={selected}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={error}>{helperText}</FormHelperText>
          </FormControl>
        );
        break;
    }
    return comp;
  };
  const classes = inputStyle(inputStyles);
  const acClasses = autoCompleteStyle(customList)();
  return (
    <>
      <Box display="flex">
        {icon ? (
          <Box
            fontSize="20px"
            minWidth="40px"
            height="30px"
            position="relative"
            top="12px"
            display="flex"
            alignItems="center">
            {icon}
          </Box>
        ) : (
          <Box minWidth={fillSpace ? '40px' : '0'} />
        )}
        {getField(classes, acClasses)}
      </Box>
    </>
  );
};

IconSelectable.propTypes = {
  icon: node,
  fillSpace: bool,
  required: bool,
  type: string,
  label: string,
  dataTestId: string,
  controllerProps: object,
  error: bool,
  options: array,
  inputStyles: object,
  helperText: string,
  onInputChange: func,
  renderInput: func,
  customList: bool,
  filterSearch: func
};

IconSelectable.defaultProps = {
  icon: undefined,
  fillSpace: false,
  required: false,
  type: 'select',
  options: [],
  label: '',
  helperText: '',
  dataTestId: null,
  error: false,
  controllerProps: {},
  inputStyles: {},
  onInputChange: () => null,
  renderInput: null,
  customList: false,
  filterSearch: (options) => options
};

export default IconSelectable;
