import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { func, object } from 'prop-types';
import { Box, Button, Container, Divider, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { renderOnCondition } from 'utils/component';
import { getPreviousWorkDay } from 'utils/date';

import { PerformanceScheduleTablet } from 'components/PerformanceSchedule';
import { OrderName } from 'components';
import OrderWorkerCard from '../common/OrderWorkerCard';
import OrderInfo from '../common/OrderInfo';
import OrderActions from '../common/OrderActions';
import OrderPeriod from '../common/OrderPeriod';

const useStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));
const MobileDetailView = ({ history, location, setCandidate, cancelOrder, customer, getOrderById, order }) => {
  const { t } = useTranslation(['general']);
  const isFirstRender = useRef(true);
  const [showUpdateOrOrderAgain, setShowUpdateOrOrderAgain] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [actionType, setActionType] = useState('default');
  const orderId = useMemo(() => pathOr({}, ['state', 'orderId'], location), [location]);
  const fromPlanning = useMemo(() => pathOr({}, ['state', 'fromPlanning'], location), [location]);
  const classes = useStyles();

  const [currentOrder, setCurrentOrder] = useState();
  const [link, setLink] = useState();

  const canUpdate = useMemo(() => {
    if (!currentOrder) return false;
    if ([5, 7].includes(currentOrder.order_status_id)) return false;
    const now = new Date();
    const orderEditable = getPreviousWorkDay(currentOrder.start);
    orderEditable.setHours(17, 0, 0);
    return now < orderEditable;
  }, [currentOrder]);

  const handleUpdateOrOrderAgain = (action) => {
    const candidate = {
      ...currentOrder.firstCandidate,
      firstName: pathOr('', ['firstCandidate', 'first_name'], currentOrder),
      lastName: pathOr('', ['firstCandidate', 'last_name'], currentOrder),
      from: new Date(currentOrder.start),
      to: new Date(currentOrder.end),
      costCenter: currentOrder.costCenter,
      reference: currentOrder.invoice_reference,
      jobFunction: currentOrder.mainJobFunction,
      scheduleDays: currentOrder.scheduleDays,
      contractId: currentOrder.source_contract_id,
      description: currentOrder.description,
      vacancies: currentOrder.openings,
      order
    };
    setCandidate(candidate);
    setActionType(action);
    setShowUpdateOrOrderAgain(true);
  };

  useEffect(() => {
    if (!currentOrder) return;
    if (customer?.customer_id !== currentOrder?.customer?.id && isFirstRender.current) {
      history.replace('/orders');
    }
    isFirstRender.current = false;
  }, [customer, currentOrder, history]);

  useEffect(() => {
    if (!orderId) return;
    getOrderById(orderId);
  }, [orderId]);

  useEffect(() => {
    setCurrentOrder(order);
  }, [order]);

  useEffect(() => {
    setLink(!fromPlanning || isEmpty(fromPlanning) ? 'orders' : 'planning');
  }, [fromPlanning]);

  if (!currentOrder || isEmpty(currentOrder)) return null;

  return (
    <Box display="flex" flexDirection="column" width="100%" mt={4}>
      <Box flex="1" overflow="auto">
        <Container maxWidth="xl">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box
              className={classes.weekLink}
              onClick={() => history.push(`/${link}`)}
              display="flex"
              flexDirection="row"
              alignItems="center">
              <Box>
                <FontAwesomeIcon icon={faChevronLeft} className={clsx(classes.iconColor, classes.iconSize)} />
              </Box>
              <Box ml={5}>
                <Typography component="span" variant="body1" color="secondary">
                  {t(`general:${link}.title`)}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.buttonGroup}>
              {renderOnCondition(
                [2, 3].includes(currentOrder.order_type_id),
                <>
                  <IconButton disabled={!canUpdate} onClick={() => handleUpdateOrOrderAgain('update')}>
                    <FontAwesomeIcon icon={faEdit} />
                  </IconButton>
                  <IconButton disabled={!canUpdate} onClick={() => setShowConfirmCancel(true)}>
                    <FontAwesomeIcon icon={faBan} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" flex="1" mb={4} mt={9}>
            <Typography variant="h6" component="h6">
              <OrderName order={currentOrder} />
            </Typography>
            <OrderPeriod noIcon start={currentOrder.start} end={currentOrder.end} />
          </Box>
          <Divider />
          <Box mt={4} mb={6}>
            <OrderInfo order={currentOrder} />
          </Box>
          <Box mb={6}>
            <OrderWorkerCard order={currentOrder} isMobile />
          </Box>
          {renderOnCondition(
            [2, 3].includes(currentOrder.order_type_id),
            <>
              <Box mb={4}>
                <PerformanceScheduleTablet
                  date={new Date(currentOrder.start)}
                  contractHours={currentOrder.scheduleDays}
                  savedHours={currentOrder.scheduleDays}
                />
                <Divider />
              </Box>
              <Box display="flex" flexWrap="wrap" my={2} minHeight={100}>
                <Box width={[1, 1, 1 / 2]} mb={4}>
                  <Box mb={2}>
                    <Typography variant="body2">{t('general:comment')}</Typography>
                  </Box>
                  <Typography variant="body2" color="secondary">
                    {currentOrder.description}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Container>
      </Box>
      <Box display="flex" className={classes.box} width="100%" py={4}>
        <Container maxWidth="xl" className={classes.container}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => handleUpdateOrOrderAgain('orderAgain')}>
            {t('general:orders.orderAgain')}
          </Button>
        </Container>
      </Box>
      <OrderActions
        order={currentOrder}
        actionType={actionType}
        showUpdateOrOrderAgain={showUpdateOrOrderAgain}
        setShowUpdateOrOrderAgain={setShowUpdateOrOrderAgain}
        showConfirmCancel={showConfirmCancel}
        setShowConfirmCancel={setShowConfirmCancel}
        cancelOrder={cancelOrder}
        getOrderById={getOrderById}
      />
    </Box>
  );
};

MobileDetailView.propTypes = {
  history: object.isRequired,
  customer: object.isRequired,
  location: object.isRequired,
  setCandidate: func.isRequired,
  cancelOrder: func.isRequired,
  getOrderById: func.isRequired,
  order: object
};

MobileDetailView.defaultProps = {
  order: null
};

const mapState = ({ user: { currentCustomer } }) => ({
  customer: currentCustomer
});

const mapDispatch = ({ contracts: { setCandidate }, orders: { cancel, getOrderById } }) => ({
  setCandidate,
  cancelOrder: cancel,
  getOrderById
});

export default connect(mapState, mapDispatch)(withRouter(MobileDetailView));
