import React, { useRef, useCallback, useState, useEffect } from 'react';
import { array, func, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Box, CircularProgress, LinearProgress } from '@material-ui/core';
import { equals, isEmpty } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { OrderHeadCell } from 'components';
import { format } from 'date-fns';
import { localeDateFormat } from 'utils/date';
import InfiniteScroll from 'react-infinite-scroller';
import { usePrevious } from 'hooks';
import { costCenterString } from 'utils/tempWorkers';
import typeIcons from '../common/typeIcons';

const style = {
  clickableRow: {
    cursor: 'pointer'
  },
  hiddenRow: {
    visibility: 'collapse',
    '& th,td': {
      borderBottom: 0
    }
  },
  loadingRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0,
      padding: 0
    }
  }
};

const BodyCell = withStyles({
  root: {
    padding: '12px 8px',
    cursor: 'pointer'
  }
})((props) => <TableCell color="default" {...props} />);

const DocumentTable = ({ classes, data, getDocument, loadMore, isLoading, search, debouncedSearch }) => {
  const { t } = useTranslation(['general']);
  const currentPage = useRef(1);
  const activeLoadMore = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const isMounted = useRef(true);
  const [order, setOrder] = useState({
    order_by_field: null,
    order_by_direction: null
  });
  const prevOrder = usePrevious(order, true);

  const getNext = useCallback(
    async (criteria) => {
      const receivedNew = await loadMore(criteria);
      if (isMounted.current) {
        activeLoadMore.current = receivedNew;
        setHasMore(receivedNew);
      }
    },
    [loadMore, setHasMore, activeLoadMore]
  );

  useEffect(() => {
    if (debouncedSearch || !equals(order, prevOrder)) {
      activeLoadMore.current = false;
      currentPage.current = 1;
      setHasMore(true);
      getNext({ ...(debouncedSearch || search), ...order, page: 1, stack: false });
    }
  }, [debouncedSearch, search, getNext, order]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        if (activeLoadMore.current) {
          currentPage.current += 1;
          activeLoadMore.current = false;
          getNext({ ...search, ...order, page: currentPage.current, stack: true });
        }
      }}
      hasMore={hasMore}
      loader={
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" height={100} key="loader">
          <CircularProgress />
        </Box>
      }>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <OrderHeadCell order={order} field="title" onOrder={setOrder}>
              <Typography variant="body2" component="span" color="secondary">{`${data.length} ${t(
                'general:menu.documents'
              )}`}</Typography>
            </OrderHeadCell>
            <OrderHeadCell align="left" order={order} field="cost_center_name" onOrder={setOrder}>
              <Typography variant="body2" component="span" color="secondary">
                {t('general:costcenter')}
              </Typography>
            </OrderHeadCell>
            <OrderHeadCell align="left" order={order} field="created_at" onOrder={setOrder}>
              <Typography variant="body2" component="span" color="secondary">
                {t('general:dateAdded')}
              </Typography>
            </OrderHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && isEmpty(data) ? (
            <TableRow className={classes.loadingRow}>
              <TableCell colSpan={100} className={classes.loadingRow}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          ) : (
            data.map(({ document_type_id, title, costCenters, created_at, uuid }) => {
              return (
                <TableRow key={`${title}-${created_at}`} onClick={() => getDocument(uuid)}>
                  <BodyCell align="left">
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="center" mr={3}>
                        {React.cloneElement(
                          typeIcons[document_type_id - 1],
                          { title: t(`general:docs.category.${document_type_id}`) },
                          null
                        )}
                      </Box>
                      <Typography variant="body2">{title}</Typography>
                    </Box>
                  </BodyCell>
                  <BodyCell align="left">
                    <Typography variant="body2" color="secondary">
                      {costCenters.map((center) => costCenterString(center)).join(', ')}
                    </Typography>
                  </BodyCell>
                  <BodyCell align="left">
                    <Typography variant="body2" color="secondary">
                      {format(new Date(created_at.replace(' ', 'T')), localeDateFormat)}
                    </Typography>
                  </BodyCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
};

DocumentTable.propTypes = {
  classes: object.isRequired,
  data: array.isRequired,
  getDocument: func.isRequired,
  search: object,
  debouncedSearch: object,
  loadMore: func,
  isLoading: bool
};

DocumentTable.defaultProps = {
  loadMore: () => null,
  isLoading: false,
  search: null,
  debouncedSearch: null
};

export default withStyles(style)(DocumentTable);
