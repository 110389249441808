/* eslint-disable complexity, max-lines */

import {
  is,
  isEmpty,
  map,
  omit,
  path,
  pathOr,
  prop,
  propOr,
  sum,
  trim,
  uniq,
  uniqBy,
  isNil,
  pipe,
  values,
  reduce,
  flatten,
  groupWith,
  sort,
  filter
} from 'ramda';
import { endOfWeek, isSameWeek, startOfWeek } from 'date-fns';
import { fillPerformedHours, fillPremiumsForEmptyDays } from 'components/PerformanceSchedule/common';
import { days, formatScheduleListHours, getWeekNumber, makeFloat } from './date';

/* Format string of costCenter values
 * Takes costCenter object
 * returns formatted string
 * (See: https://adneom.atlassian.net/wiki/spaces/D2D/pages/1589477484/Format+des+textes)
 */
export const costCenterString = (costCenter, customer) => {
  const { description, branch } = costCenter || {};
  const commercialName = propOr(propOr(null, 'commercial_name', customer), 'customer_commercial_name', costCenter);
  const customerName = propOr(propOr(null, 'name', customer), 'customer_name', costCenter);
  let ccString = '';

  if (propOr('default', 'label', branch).toLowerCase() !== 'default') ccString += branch.label;
  else if (commercialName) ccString += commercialName;

  if (ccString && description) ccString += ` - ${description}`;
  else if (!ccString && customerName) ccString = customerName;

  return ccString;
};

const mergePremiums = (premiums) => {
  const grouped = pipe(
    filter((item) => item.premium_code === 1101),
    sort((a, b) => new Date(a.date) - new Date(b.date)),
    sort((a, b) => new Date(a.premium_code) - new Date(b.premium_code)),
    sort((a, b) => new Date(a.source) - new Date(b.source)),
    groupWith((a, b) => a.source === b.source && a.date === b.date && a.premium_code === b.premium_code),
    map((group) => ({ ...group[0], minutes: reduce((acc, val) => acc + val.minutes, 0, group) }))
  )(premiums);
  return [...filter((item) => item.premium_code !== 1101, premiums), ...grouped];
};

/** Convert dates to floating point number representatives
 * Takes schedule list object
 * returns same object but with floating point numbers.
 */
export const asFloatingHours = (scheduleList) =>
  (scheduleList || []).map((s) => ({ ...s, contractHour: makeFloat(s.contractHour) }));

/** Returns the right type depending on the candidate status id
 * Takes id type and `react-i18next` `useTranslation` instance
 * returns empty string by default
 */
export const getType = (contractType, t) => {
  let cType = '';
  if ([56, 57].includes(contractType)) {
    cType = t('general:extend.seasonal');
  } else if ([30, 31].includes(contractType)) {
    cType = t('general:extend.flexi');
  }
  return cType;
};

/** Returns the right status depending on the contract type id
 * Takes id type and `react-i18next` `useTranslation` instance
 * returns empty string by default
 */
export const getStatus = (statType, isStudent, t) => {
  let status = '';
  if ([9, 10].includes(statType)) {
    status = t('general:extend.studentWork');
  } else if ([5, 6].includes(statType)) {
    status = t('general:extend.student475');
  } else if (isStudent) {
    status = t('general:student');
  }
  return status;
};

const formatHours = (hour) => {
  if (is(String, hour)) return hour.endsWith(':00') ? hour.slice(0, -3) : hour;
  return null;
};

const getAllScheduleHours = (schedule) => [
  schedule.start_am && schedule.end_am ? `${formatHours(schedule.start_am)} - ${formatHours(schedule.end_am)}` : null,
  schedule.start_pm && schedule.end_pm ? `${formatHours(schedule.start_pm)} - ${formatHours(schedule.end_pm)}` : null
];

const hasTime = (schedule) => {
  if (!schedule) return false;
  if (
    Number(propOr(0, 'total_hours', schedule)) === 0 &&
    ['start_am', 'end_am', 'start_pm', 'end_pm'].every((attr) => !schedule[attr])
  ) {
    return false;
  }
  return true;
};

const formatScheduleDay = (schedule, contract) => {
  return hasTime(schedule)
    ? {
        ...schedule,
        id: contract.id,
        total_hours: Number(schedule.total_hours),
        start_am: schedule.start_am,
        end_am: schedule.end_am,
        start_pm: schedule.start_pm,
        end_pm: schedule.end_pm,
        allHours: getAllScheduleHours(schedule).filter((elmt) => elmt !== '00:00 - 00:00')
      }
    : null;
};

const mergeSchedules = (scheduleA, scheduleB) => {
  const newSchedule = {
    ...scheduleA,
    total_hours: propOr(0, 'total_hours', scheduleA) + propOr(0, 'total_hours', scheduleB),
    allHours: uniq(propOr([], 'allHours', scheduleA).concat(propOr([], 'allHours', scheduleB)))
  };
  const [startAm, endAm] = newSchedule.allHours[0]
    ? map((item) => `${trim(item)}:00`, newSchedule.allHours[0].split('-'))
    : [null, null];
  const [startPm, endPm] = newSchedule.allHours[1]
    ? map((item) => `${trim(item)}:00`, newSchedule.allHours[1].split('-'))
    : [null, null];
  return {
    ...newSchedule,
    start_am: newSchedule.allHours.length < 3 ? startAm : null,
    end_am: newSchedule.allHours.length < 3 ? endAm : null,
    start_pm: newSchedule.allHours.length < 3 ? startPm : null,
    end_pm: newSchedule.allHours.length < 3 ? endPm : null
  };
};

const fillHours = ({ existingIndex, mergedList, contract, day, type, contracts }) => {
  const existingHasHours = path([existingIndex, type, day, 'total_hours'], mergedList);
  const currentHasHours = path([type, day, 'total_hours'], contract);
  if (isNil(existingHasHours) && !isNil(currentHasHours)) {
    return { hours: formatScheduleDay(contract[type][day], contract), contracts: [...contracts, contract] };
  }
  if (!isNil(existingHasHours) && isNil(currentHasHours)) {
    return { hours: mergedList[existingIndex][type][day], contracts: [...contracts, mergedList[existingIndex]] };
  }
  if (!isNil(existingHasHours) && !isNil(currentHasHours)) {
    return {
      hours: mergeSchedules(
        mergedList[existingIndex][type][day],
        formatScheduleDay(contract[type][day], contract),
        contract.contractHasPremiumCodes
      ),
      contracts: [...contracts, mergedList[existingIndex], contract]
    };
  }
  return { hours: null, contracts };
};

export const mergeContracts = (contracts) => {
  const mergedList = [];
  const invoiceOject = (c) => ({ reference: c.invoice_reference, from: c.from, to: c.to });
  contracts.forEach((contract) => {
    const existingIndex = mergedList.findIndex(
      (existingContract) =>
        existingContract?.id?.startsWith(contract.id.substr(0, 13)) &&
        contract.costCenter?.id === existingContract.costCenter?.id &&
        isSameWeek(new Date(contract.from), new Date(existingContract.from), { weekStartsOn: 1 }) &&
        isSameWeek(new Date(contract.to), new Date(existingContract.to), { weekStartsOn: 1 })
    );
    if (existingIndex >= 0) {
      const schedule = {};
      const tsSchedule = {};
      let linkedContracts = [];
      days.forEach((day) => {
        const filledTs = fillHours({
          existingIndex,
          mergedList,
          contract,
          day,
          type: 'timesheetScheduleDays',
          contracts: linkedContracts
        });
        tsSchedule[day] = filledTs.hours;
        schedule[day] = fillHours({
          existingIndex,
          mergedList,
          contract,
          day,
          type: 'scheduleDays',
          contracts: linkedContracts
        }).hours;
        linkedContracts = filledTs.contracts;
      });
      mergedList[existingIndex].linkedContracts = uniqBy((c) => c.id, [
        ...(mergedList[existingIndex].linkedContracts || []),
        ...linkedContracts
      ]);
      mergedList[existingIndex].timesheetHistory = [
        ...mergedList[existingIndex].timesheetHistory,
        ...contract.timesheetHistory
      ];
      mergedList[existingIndex].contractHasPremiumCodes = mergePremiums([
        ...mergedList[existingIndex].contractHasPremiumCodes,
        ...contract.contractHasPremiumCodes
      ]);
      mergedList[existingIndex].timesheetScheduleDays = tsSchedule;
      mergedList[existingIndex].scheduleDays = schedule;
      mergedList[existingIndex].from =
        new Date(mergedList[existingIndex].from) < new Date(contract.from)
          ? mergedList[existingIndex].from
          : contract.from;
      mergedList[existingIndex].to =
        new Date(mergedList[existingIndex].to) < new Date(contract.to) ? contract.to : mergedList[existingIndex].to;
      if (contract.invoice_reference) {
        mergedList[existingIndex].invoice_reference.push(invoiceOject(contract));
      }
      if (!prop('length', mergedList[existingIndex].timesheet_comment)) {
        mergedList[existingIndex].comment = contract.timesheet_comment;
      }
    } else {
      mergedList.push({
        ...contract,
        linkedContracts: [contract],
        scheduleDays: map((sd) => formatScheduleDay(sd, contract), contract.scheduleDays || {}),
        timesheetScheduleDays: map((sd) => formatScheduleDay(sd, contract), contract.timesheetScheduleDays || {}),
        invoice_reference: contract.invoice_reference ? [invoiceOject(contract)] : []
      });
    }
  });
  return mergedList;
};

const formatContract = (contract) => {
  const costCenter = prop('costCenter', contract);
  const title = prop('title', contract);
  const uuid = path(['candidate', 'uuid'], contract);
  const contractHasPremiumCodes = pathOr([], ['contractHasPremiumCodes'], contract);
  const firstName = path(['candidate', 'first_name'], contract);
  const lastName = path(['candidate', 'last_name'], contract);
  const jobFunction = prop('jobFunction', contract);
  const from = prop('from', contract);
  const to = prop('to', contract);
  const scheduleDays = prop('scheduleDays', contract);
  const timesheetScheduleDays = prop('timesheetScheduleDays', contract);
  const ref = prop('invoice_reference', contract);
  const dacoEvaluationId = prop('daco_evaluation_id', contract);
  const contractType = contract.type_id;
  const contractStatus = contract.candidate_status;
  const candidateName = `${lastName}${!!firstName && !!lastName && ' '}${firstName}`;
  const weekHours = sum(days.map((day) => pathOr(0, ['scheduleDays', day, 'total_hours'], contract)));
  const editedInDaco = prop('timesheet_edited_daco', contract);
  const confirmedInDaco = prop('timesheet_confirmed_daco', contract);
  const autoConfirmed = prop('timesheet_automatically_confirmed_daco', contract);
  const impersonateValidator = prop('timesheet_impersonate_validator_id', contract);
  const impersonateEditor = prop('timesheet_impersonate_editor_id', contract);
  const contractGroupId = path(['contractGroup', 'id'], contract);
  const comment = prop('timesheet_comment', contract);
  const timesheetHistory = prop('timesheetHistory', contract);
  const linkedContracts = prop('linkedContracts', contract);
  const customer = prop('customer', contract);
  const filledPremiums = fillPremiumsForEmptyDays({
    changedHours: timesheetScheduleDays,
    contractHours: scheduleDays,
    allBonuses: contractHasPremiumCodes,
    date: new Date(from)
  });
  const newStoredHours = fillPerformedHours(new Date(from), timesheetScheduleDays, scheduleDays, filledPremiums);
  const timesheetWeekHours = pipe(
    values,
    reduce((acc, curr) => acc + curr?.performedMinutes || curr?.total_hours, 0)
  )(newStoredHours);

  return {
    name: candidateName,
    uuid,
    info: {
      costCenter,
      scheduleDays,
      timesheetScheduleDays: newStoredHours,
      timesheetWeekHours,
      timesheetHistory,
      contractGroupId,
      contractHasPremiumCodes,
      contractId: contract.id,
      reasonId: contract.reason_id,
      firstName,
      lastName,
      ref,
      jobFunction,
      contractStatus,
      contractType,
      from,
      to,
      editedInDaco,
      confirmedInDaco,
      autoConfirmed,
      impersonateValidator,
      impersonateEditor,
      dacoEvaluationId,
      comment,
      customer,
      linkedContracts
    },
    title,
    hours: {
      ...prop('scheduleDays', contract),
      total: {
        total_hours: weekHours
      }
    }
  };
};

export const groupContracts = (contracts = []) => {
  const mergedList = mergeContracts(contracts);
  return mergedList.map((item) =>
    formatContract({
      ...item,
      title: costCenterString(item?.costCenter, item?.customer)
    })
  );
};

export const groupContractsByCostCenter = (contracts = []) => {
  const mergedList = mergeContracts(contracts);

  const list = mergedList.reduce((acc, contract) => {
    const costCenter = prop('costCenter', contract);
    const customer = prop('customer', contract);
    const title = costCenterString(costCenter, customer);

    if (!acc[title]) {
      acc[title] = {
        title,
        contracts: []
      };
    }
    acc[title].contracts.push(formatContract({ ...contract, title }));

    return acc;
  }, {});
  const orderedList = Object.values(list).sort((a, b) => a.title.localeCompare(b.title));
  orderedList.forEach((center) => center.contracts.sort((a, b) => a.name.localeCompare(b.name)));

  return orderedList;
};

export const groupContractsByWeekAndCostCenter = (contracts = []) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(23);
  yesterday.setMinutes(59);
  yesterday.setSeconds(59);
  const mergedList = mergeContracts(contracts).filter((c) => new Date(c.to) <= yesterday);
  const list = mergedList.reduce((acc, contract) => {
    const costCenter = prop('costCenter', contract);
    const customer = prop('customer', contract);
    const title = costCenterString(costCenter, customer);
    const date = new Date(contract.from);
    const weekNumber = getWeekNumber(date);
    const weekKey = `${date.getFullYear()}-${weekNumber}`;
    const from = startOfWeek(date, { weekStartsOn: 1 });

    if (!acc[weekKey]) {
      acc[weekKey] = {
        weekKey,
        from,
        to: endOfWeek(date, { weekStartsOn: 1 }),
        weekNumber,
        year: from.getFullYear(),
        count: 0,
        costCenters: {}
      };
    }
    if (!acc[weekKey].costCenters[title]) {
      acc[weekKey].costCenters[title] = {
        key: `${weekKey}-${title}`,
        title,
        count: 0,
        contracts: []
      };
    }
    acc[weekKey].count += 1;
    acc[weekKey].costCenters[title].count += 1;
    acc[weekKey].costCenters[title].contracts.push(formatContract({ ...contract, title }));

    return acc;
  }, {});
  const orderedList = Object.values(list).sort((a, b) => a.from - b.from);
  return orderedList.map((week) => {
    const centers = Object.values(week.costCenters).sort((a, b) => a.title.localeCompare(b.title));
    centers.forEach((center) => center.contracts.sort((a, b) => a.name.localeCompare(b.name)));
    return {
      ...omit(['costCenters'], week),
      costCenters: centers
    };
  });
};

export const handleWorkersList = (list) => {
  return list.map((item) => ({
    id: item.id,
    gender: item.gender,
    firstName: item.first_name,
    lastName: item.last_name,
    isActive: !!item.is_activa,
    isStudent: !!item.is_student
  }));
};

/** Returns a filtered list based on search string
 * Takes List and String
 * returns List
 */
export const filterSearch = (workersList, search = '', dayFilter = []) => {
  const regexes = search
    .split(/[\s,+]+/g)
    .filter((s) => !!s)
    .map((s) => new RegExp(s.trim(), 'i'));

  return workersList.reduce((centers, center) => {
    const filteredMembers = center?.contracts?.filter(
      (member) =>
        regexes.map((regex) => regex.test(center.title + member.name)).every((r) => !!r) &&
        (isEmpty(dayFilter) ||
          dayFilter?.map((day) => path(['hours', days[day], 'total_hours'], member)).every((d) => !!d))
    );
    if (filteredMembers.length) return [...centers, { ...center, contracts: filteredMembers }];
    return centers;
  }, []);
};

export const getIdsFromCenters = (workersList) => {
  return pipe(
    map((center) => center.contracts),
    flatten,
    map((contract) => contract?.info?.linkedContracts),
    flatten,
    map((contract) => contract?.id)
  )(workersList);
};

export const filterValidationSearch = (weeksList, search = '') => {
  const filteredWeeksList = weeksList.map((week) => ({
    ...omit(['costCenters'], week),
    costCenters: filterSearch(week.costCenters, search).map((center) => ({ ...center, count: center.contracts.length }))
  }));
  return filteredWeeksList
    .filter((week) => !!week.costCenters.length)
    .map((week) => ({ ...week, count: week.costCenters.length }));
};

export const saveScheduleBetweenSteps = (data) => {
  const format = formatScheduleListHours(data.scheduleDays);
  return format.reduce((acc, curr) => {
    acc[curr.day] = curr;
    return acc;
  }, {});
};
