/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint max-lines: 0 */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, bool, func, object } from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Container,
  Divider,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { format } from 'date-fns';
import {
  convertTimeStringToHoursAndMinutes,
  localeDateFormat,
  getWeekNumber,
  getWeekDays,
  formatDate,
  mondayWeek,
  days
} from 'utils/date';
import clsx from 'clsx';
import {
  isNil,
  groupBy,
  values,
  clone,
  pipe,
  omit,
  map,
  prop,
  mapObjIndexed,
  pluck,
  pathOr,
  propOr,
  uniq,
  uniqBy,
  reverse,
  keys,
  filter,
  find,
  path,
  equals,
  findIndex,
  flatten
} from 'ramda';
import { cleanObj } from 'utils/object';

import {
  NewProlongation,
  Modal,
  ConfirmDialog,
  WorkerCard,
  StyledRating,
  GreenTextTypography,
  TimesheetHistory,
  BackButton
} from 'components';
import {
  PerformanceScheduleTablet,
  PerformanceScheduleTabletEdit,
  PerformanceScheduleDesktop
} from 'components/PerformanceSchedule';
import {
  getUnusedContractBonuses,
  fillBonusesForEmptyDays,
  fillPremiumsForEmptyDays,
  fillPerformedHours
} from 'components/PerformanceSchedule/common';

import { usePrevious } from 'hooks';
import { withStyles } from '@material-ui/core/styles';

import { eventGA } from 'utils/analytics';
import usePresentationStyle from './prestationStyle';

const customControlStyle = ({ palette }) => ({
  root: {
    color: palette.secondary.main
  },
  label: {
    fontSize: '1.4rem'
  }
});

const formatList = (lists) => ({
  newList: pipe(pluck('contracts'), flatten)(lists?.newList),
  rawList: lists?.rawList
});

const CustomFormControlLabel = withStyles(customControlStyle)((props) => <FormControlLabel {...props} />);

const setEndTime = (contractDate, currentDay) => {
  const { end_am: endAm, end_pm: endPm } = currentDay;

  if (!endAm && !endPm) {
    return contractDate.setHours(0, 0, 0, 0);
  }
  if (endPm) {
    const { hours: hoursAm } = convertTimeStringToHoursAndMinutes(endAm, ':');
    const { hours: hoursPm, minutes: minutesPm } = convertTimeStringToHoursAndMinutes(endPm, ':');

    if (hoursPm < hoursAm) contractDate.setDate(contractDate.getDate() + 1);
    return contractDate.setHours(hoursPm, minutesPm);
  }

  const { hours, minutes } = convertTimeStringToHoursAndMinutes(endAm, ':');
  return contractDate.setHours(hours, minutes);
};

const areButtonsDisabled = (editMode, data) => editMode || !!data.info.autoConfirmed || !!data.info.confirmedInDaco;
const areConfirmDisabled = (data, changedHours) => {
  const endOfContractDate = new Date(data.info.to);
  const workDaysLeft = filter(
    (item) => item?.date >= endOfContractDate.setHours(0, 0, 0, 0),
    values(data.info.timesheetScheduleDays)
  );
  if (workDaysLeft.length === 0) return false;

  const currentTime = new Date();
  const dayName = days[currentTime.getDay()];
  const currentDay = propOr(null, dayName, changedHours);
  if (currentDay) {
    setEndTime(endOfContractDate, currentDay);
  }
  return endOfContractDate.getTime() > currentTime.getTime();
};

const handleMissingHours = (missingHours, workHours) => {
  if (missingHours?.length === 0) return workHours;
  const cleanHours = workHours.filter(
    (item) =>
      [1001, 1101].includes(item.premium_code) && !missingHours.find((missingHour) => missingHour.date === item.date)
  );
  const newWorkHours = missingHours.map((missingHour) => {
    const filteredWorkHours = workHours.filter((item) => item.date === missingHour.date && item.premium_code === 1101);
    let remainingMinutes = missingHour.minutes;
    return filteredWorkHours.map((workHour) => {
      const { minutes } = workHour;
      let { minutes: effectiveMinutes } = workHour;
      let missingMinutes = 0;
      if (remainingMinutes > 0 && missingHour.is_absence_justification) {
        effectiveMinutes = minutes - remainingMinutes >= 0 ? minutes - remainingMinutes : 0;
        remainingMinutes -= minutes;
        missingMinutes = minutes - effectiveMinutes;
      }
      return flatten(
        filter(
          (item) =>
            item.premium_code === 1101 ||
            item.premium_code === 1001 ||
            item.minutes > 0 ||
            !item.is_absence_justification,
          [
            {
              ...workHour,
              minutes: effectiveMinutes
            },
            {
              ...missingHour,
              minutes: missingHour.is_absence_justification ? missingMinutes : missingHour.minutes,
              contract_id: workHour.contract_id
            }
          ]
        ),
        cleanHours
      );
    });
  });
  return flatten([...cleanHours, ...newWorkHours]);
};

const getFinalBonuses = (changedBonuses, validatedSources, linkedContracts) => {
  const reversedList = reverse(changedBonuses.filter((bonus) => (validatedSources ? bonus.source === 2 : true)));
  const filteredList = clone(reversedList).filter((bonus) => {
    return ![0, 2].includes(bonus.source) && (bonus.minutes || bonus.amount || bonus.premium_code === 1101);
  });
  const pipedInfos = pipe(uniqBy((item) => `${item.premium_code}-${item.date}-${item.contract_id}`))(filteredList);
  if (linkedContracts.length > 1) {
    const workHours = pipedInfos.filter((item) => item.premium_code === 1101);
    const extraHours = pipedInfos.filter((item) => item.premium_code === 1001);
    const missingHours = pipedInfos.filter(
      (item) => item.is_absence_justification && item.premium_code !== 1101 && item.premium_code !== 1001
    );
    const premiumHours = pipedInfos.filter(
      (item) => !item.is_absence_justification && item.premium_code !== 1101 && item.premium_code !== 1001
    );
    let newWorkHours = linkedContracts.map((contract) => {
      const { scheduleDays } = contract;
      const weekDays = getWeekDays(new Date(contract.from));
      const newBonuses = weekDays.map((weekDay, index) => {
        const currentDay = days[[1, 2, 3, 4, 5, 6, 0][index]];
        if (scheduleDays[currentDay]) {
          const workHour = workHours.find((item) => item.date === formatDate(weekDay, false));
          return {
            ...workHour,
            minutes: Number(scheduleDays[currentDay].total_hours),
            contract_id: contract.id
          };
        }
        return null;
      });
      return filter((item) => item?.premium_code, newBonuses);
    });
    newWorkHours = [
      ...pipe(
        flatten,
        filter((item) => item)
      )(newWorkHours),
      ...extraHours
    ];
    newWorkHours = handleMissingHours(missingHours, newWorkHours);
    return groupBy((bonus) => bonus.contract_id, [...newWorkHours, ...premiumHours]);
  }

  return groupBy((bonus) => bonus.contract_id, pipedInfos);
};

const getFinalHours = (changedHours, changedBonuses, contract) => {
  const weekDays = getWeekDays(new Date(contract.info.from));
  return pipe(
    cleanObj,
    omit(['total']),
    mapObjIndexed((val, key) => ({
      ...val,
      day: key,
      total_hours: !isNil(val.performedMinutes) ? val.performedMinutes : val.total_hours
    })),
    values,
    map((item) => {
      const isoDate = formatDate(weekDays[mondayWeek[days.findIndex((d) => item.day === d)]], false);
      const matchingBonus = pipe(
        filter((b) => b.premium_code === 1101 && b.date === isoDate),
        find((b) => b.minutes === item.total_hours)
      )(changedBonuses);
      return matchingBonus ? { ...item, id: matchingBonus.contract_id } : item;
    }),
    groupBy(prop('id'))
  )(changedHours);
};

const Prestation = ({
  editSchedule,
  confirmTimesheet,
  updateEvaluation,
  isTablet,
  location,
  history,
  setCandidate,
  appendableCodes,
  getAppendableCodes,
  getAppendablePremiums,
  user,
  customer,
  list,
  rawList,
  getList,
  getNextContractDates,
  nextContractDates,
  isListLoading,
  isLoading
}) => {
  const isFirstRender = useRef(true);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isPreconfirmed, setIsPreconfirmed] = useState(false);
  const { data, navigationBack } = location.state;
  const [comment, setComment] = useState(data.info.comment);
  const [savedHours, setSavedHours] = useState(data.info.timesheetScheduleDays);
  const [changedHours, setChangedHours] = useState(savedHours);
  const [storedHours, setStoredHours] = useState(savedHours);
  const [savedBonuses, setSavedBonuses] = useState(data.info.contractHasPremiumCodes);
  const prevData = usePrevious(data);
  const [changedBonuses, setChangedBonuses] = useState(savedBonuses);
  const [storedBonuses, setStoredBonuses] = useState(savedBonuses);
  const [rating, setRating] = useState(data.info.dacoEvaluationId);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldExtend, setShouldExtend] = useState(false);
  const classes = usePresentationStyle();
  let isResetting = false;
  const { t, i18n } = useTranslation(['general', 'helpers']);
  const { language } = i18n;
  const [homeContracts, setHomeContracts] = useState(flatten(pluck('contracts', list)));
  const [contractsList, setContractsList] = useState(rawList);
  const currentInList = useMemo(() => {
    const index = findIndex((item) => item.info.contractId === data.info.contractId, homeContracts);
    return index;
  }, [homeContracts, data]);

  const toggleEditMode = () => setEditMode(!editMode);
  const buttonsDisabled = areButtonsDisabled(editMode, data);
  const confirmDisabled = areConfirmDisabled(data, changedHours);

  const handleSave = async () => {
    setSavedBonuses(changedBonuses);
    setSavedHours(changedHours);
    setSaving(true);
    const validatedSources = !!changedBonuses.find((b) => b.source === 2);
    const finalBonuses = getFinalBonuses(changedBonuses, validatedSources, data.info.linkedContracts);
    const finalHours = getFinalHours(changedHours, changedBonuses, data);
    const contractIds = keys({ ...finalBonuses, ...finalHours });

    const awaited = await Promise.all(
      data.info.linkedContracts.map((contract) => {
        return new Promise((resolve) => {
          let premiumList;
          let timesheetScheduleDays;
          const defaultHours = finalHours[data.info.linkedContracts[0].id].map((item) => ({
            ...item,
            id: contract.id
          }));
          if (contractIds.find((id) => contract.id === id)) {
            premiumList = finalBonuses[contract.id];
            timesheetScheduleDays = finalHours[contract.id] || defaultHours;
          } else {
            const schedule = pipe(
              cleanObj,
              omit(['total']),
              mapObjIndexed((val, key) => ({ ...val, day: key, total_hours: 0 })),
              values
            )(contract.timesheetScheduleDays);
            premiumList = getUnusedContractBonuses(contract.from, schedule);
            timesheetScheduleDays = schedule;
          }
          premiumList = premiumList.reduce((acc, val) => {
            const exist = acc.find((item) => item.date === val.date && item.premium_code === val.premium_code);

            if (exist) return acc;
            return [...acc, val];
          }, []);
          editSchedule({
            contractId: contract.id,
            premiumList,
            timesheetScheduleDays,
            comment,
            impersonateValidatorId: path(['impersonator', 'id'], user)
          }).then((res) => resolve(res));
        });
      })
    );

    const newHistory = awaited.reduce(
      (acc, curr) => [...acc, ...pathOr([], ['contract', 'timesheetHistory'], curr)],
      []
    );

    if (rating) {
      await updateEvaluation({ candidateId: data.uuid, dacoEvaluationId: rating });
    }

    history.replace({
      pathname: '/prestation',
      state: {
        navigationBack: '',
        data: {
          ...location.state.data,
          info: {
            ...location.state.data.info,
            comment,
            timesheetHistory: newHistory,
            dacoEvaluationId: rating,
            timesheetScheduleDays: changedHours,
            contractHasPremiumCodes: changedBonuses
          }
        }
      }
    });
    setSaving(false);
    setEditMode(false);
  };

  const navigateContracts = (incrementor) => {
    history.push({
      pathname: '/prestation',
      state: {
        navigationBack: '',
        data: {
          ...homeContracts[currentInList + incrementor]
        }
      }
    });
  };

  const handleSaveAndConfirm = () => {
    setShouldSave(true);
    setIsPreconfirmed(true);
  };

  const handleCreateCancel = () => {
    setShouldSave(false);
    setIsOpen(false);
    setShouldExtend(false);
    setIsPreconfirmed(false);
  };

  const handleCreate = async () => {
    setSubmitting(true);
    if (shouldSave) await handleSave();

    const ids = await confirmTimesheet({
      contractIds: pluck('id', data.info.linkedContracts),
      impersonateValidatorId: path(['impersonator', 'id'], user)
    });
    if (
      ids?.success &&
      uniq([...pluck('id', data.info.linkedContracts), ...ids.success]).length === ids.success.length
    ) {
      const { contracts } = ids;
      const newHistory = contracts.reduce((acc, curr) => [...acc, ...pathOr([], ['timesheetHistory'], curr)], []);
      history.replace({
        pathname: '/prestation',
        state: {
          navigationBack: '',
          data: {
            ...location.state.data,
            info: {
              ...location.state.data.info,
              confirmedInDaco: 1,
              timesheetHistory: newHistory,
              timesheetScheduleDays: changedHours,
              contractHasPremiumCodes: changedBonuses
            }
          }
        }
      });
    }
    setSubmitting(false);
    setIsPreconfirmed(false);

    if (shouldExtend) {
      setCandidate(data.info);
      setShouldExtend(false);
      setIsOpen(true);
    }
  };

  const handleExtend = () => {
    setCandidate(data.info);
    setIsOpen(true);
  };

  const handleCancel = () => {
    setComment(data.info.comment);
    setRating(data.info.dacoEvaluationId);
    setChangedBonuses(clone(savedBonuses));
    setChangedHours(clone(savedHours));
    setEditMode(false);
  };

  const getNewWeek = async (date, contractId, incrementor) => {
    const dates = getWeekDays(new Date(date));
    const isNewWeek = !dates.map((d) => formatDate(d, false)).includes(data?.info?.from);
    const newList = isNewWeek
      ? formatList(await getList({ from: formatDate(dates[0], false), to: formatDate(dates[6], false) }, true))
      : contractsList;
    let selected;
    if (isNewWeek) {
      const filteredList = newList.rawList.filter((item) => item.uuid === data.uuid && item.info.from === date);
      selected = filteredList[incrementor > 0 ? 0 : filteredList.length - 1];
    } else {
      const filteredList = newList.filter((item) => item.uuid === data.uuid);
      selected = filteredList[filteredList.findIndex((item) => item.info.contractId === contractId) + incrementor];
    }
    if (selected) {
      history.push({
        pathname: '/prestation',
        state: {
          navigationBack: '',
          data: {
            ...selected
          }
        }
      });
    }
    if (isNewWeek) {
      setContractsList(newList.rawList);
      setHomeContracts(newList.newList);
    }
  };

  useEffect(() => {
    isResetting = true;
    setSaving(false);
    setSubmitting(false);
    setShouldSave(false);
    setEditMode(false);
    setIsPreconfirmed(false);
    setComment(data.info.comment);
    setSavedHours(data.info.timesheetScheduleDays);
    setChangedHours(data.info.timesheetScheduleDays);
    setStoredHours(data.info.timesheetScheduleDays);
    setSavedBonuses(data.info.contractHasPremiumCodes);
    setChangedBonuses(data.info.contractHasPremiumCodes);
    setStoredBonuses(data.info.contractHasPremiumCodes);
    setRating(data.info.dacoEvaluationId);
    setIsOpen(false);
  }, [data]);

  useEffect(() => {
    if (customer?.customer_id !== data?.info?.customer?.id && isFirstRender.current) {
      history.replace('/my-workers');
    }
    isFirstRender.current = false;
  }, [customer, data, history]);

  useEffect(() => {
    if (!rawList.length || (data?.info?.contractId === prevData?.info?.contractId && !equals(data, prevData))) {
      const dates = getWeekDays(new Date(data.info.from));
      const getNewList = async () => {
        const newList = formatList(
          await getList({ from: formatDate(dates[0], false), to: formatDate(dates[6], false) }, true)
        );
        setContractsList(newList?.rawList);
        setHomeContracts(newList?.newList);
      };
      getNewList();
    }
    if (data?.info?.contractId !== prevData?.info?.contractId) {
      getNextContractDates({
        uuid: data.uuid,
        contract_ids: data.info.linkedContracts.map((c) => c.id),
        from_date: data.info.from
      });
    }
  }, [data, rawList, getNextContractDates, getList]);

  useEffect(() => {
    if (!isResetting) {
      const date = new Date(data.info.from);
      const filledPremiums = fillPremiumsForEmptyDays({
        changedHours: storedHours,
        contractHours: data.hours,
        allBonuses: clone(storedBonuses),
        date
      });
      const newStoredHours = fillPerformedHours(date, storedHours, data.hours, filledPremiums);
      const filledBonuses = fillBonusesForEmptyDays({
        changedHours: newStoredHours,
        allBonuses: clone(storedBonuses),
        date
      });
      setChangedBonuses([...filledBonuses, ...filledPremiums]);
      setChangedHours(newStoredHours);
      if (!equals(prevData, data)) {
        setSavedHours(newStoredHours);
        setSavedBonuses([...filledBonuses, ...filledPremiums]);
      }
    }
  }, [storedBonuses, storedHours, data, prevData]);

  useEffect(() => {
    getAppendableCodes(data.info.contractGroupId);
  }, [getAppendableCodes, data]);

  useEffect(() => {
    getAppendablePremiums();
  }, []);

  const getMobileHeadButtons = () =>
    editMode && (
      <>
        <Typography
          className={classes.weekLink}
          component="span"
          variant="body1"
          color="secondary"
          onClick={handleCancel}>
          {t('general:cancel')}
        </Typography>
        <Typography component="span" variant="body1">
          {t('general:performance.editTitle')}
        </Typography>
      </>
    );

  const getButtons = () =>
    isTablet ? (
      getMobileHeadButtons()
    ) : (
      <Box className={classes.buttonGroup}>
        <Button
          onClick={() => {
            eventGA('prestation_edit');
            setEditMode(!editMode);
          }}
          disabled={buttonsDisabled}
          startIcon={<FontAwesomeIcon icon={faEdit} />}
          variant="outlined"
          color="primary">
          {t('general:edit')}
        </Button>
        <Button
          disabled={buttonsDisabled || confirmDisabled}
          onClick={() => {
            eventGA('prestation_confirm');
            setIsPreconfirmed(true);
          }}
          startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
          variant="outlined"
          color="primary">
          {t('general:confirm')}
        </Button>
        <Button
          disabled={editMode}
          variant="contained"
          color="primary"
          onClick={() => {
            eventGA('prestation_extend');
            handleExtend();
          }}>
          {t('general:extend.extend')}
        </Button>
      </Box>
    );

  const getMobileTablet = () =>
    editMode ? (
      <Box mx={[-4, -3]}>
        <PerformanceScheduleTabletEdit
          appendableCodes={appendableCodes}
          date={new Date(data.info.from)}
          contractHours={data.hours}
          bonuses={changedBonuses}
          onHoursChange={setStoredHours}
          onPremiumChange={() => null}
          onBonusesChange={setStoredBonuses}
          changedHours={changedHours}
          rating={rating}
          onRatingChange={setRating}
        />
      </Box>
    ) : (
      <PerformanceScheduleTablet
        date={new Date(data.info.from)}
        contractHours={data.hours}
        bonuses={savedBonuses}
        savedHours={savedHours}
      />
    );

  const workersNavigation = () =>
    (!editMode || !isTablet) && (
      <Box display="flex" alignItems="center" className={classes.workerNav}>
        <Typography component="span" variant="body1" color="secondary" className={classes.workerNavText}>
          {t('general:performance.title')}
        </Typography>
        <Button
          className={classes.workerButton}
          disabled={currentInList <= 0 || isListLoading}
          disableRipple
          onClick={() => {
            eventGA('prestation_previous');
            navigateContracts(-1);
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button
          className={classes.workerButton}
          disabled={currentInList >= contractsList.length - 1 || isListLoading}
          disableRipple
          onClick={() => {
            eventGA('prestation_next');
            navigateContracts(1);
          }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Box>
    );

  const weeksNavigation = () =>
    !isTablet && (
      <Box display="flex" alignItems="center">
        <Typography component="span" variant="body1" color="secondary" className={classes.workerNavText}>
          {t('general:workerTimesheets')}
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isListLoading || !nextContractDates?.previous_date}
          className={classes.weeksButton}
          onClick={() => {
            eventGA('prestation_previous_same_worker');
            getNewWeek(nextContractDates?.previous_date, data?.info?.contractId, -1);
          }}>
          <FontAwesomeIcon icon={faChevronLeft} className={classes.weeksNavIconLeft} />{' '}
          {nextContractDates?.previous_date
            ? format(new Date(nextContractDates?.previous_date), localeDateFormat)
            : t('performance.noPrestation')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isListLoading || !nextContractDates?.next_date}
          className={classes.weeksButton}
          onClick={() => {
            eventGA('prestation_next_same_worker');
            getNewWeek(nextContractDates?.next_date, data?.info?.contractId, 1);
          }}>
          {nextContractDates?.next_date
            ? format(new Date(nextContractDates?.next_date), localeDateFormat)
            : t('performance.noPrestation')}{' '}
          <FontAwesomeIcon icon={faChevronRight} className={classes.weeksNavIconRight} />
        </Button>
      </Box>
    );

  const getSchedule = () =>
    isTablet ? (
      getMobileTablet()
    ) : (
      <>
        <PerformanceScheduleDesktop
          appendableCodes={appendableCodes}
          setEditMode={setEditMode}
          date={new Date(data.info.from)}
          onHoursChange={setStoredHours}
          bonuses={changedBonuses}
          contractHours={data.hours}
          onBonusesChange={setStoredBonuses}
          changedHours={changedHours}
          savedHours={savedHours}
          editMode={editMode}
          editModeDisabled={buttonsDisabled}
        />
        <Divider />
      </>
    );

  const mobileButtons = () => (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      {editMode ? (
        <>
          <Button
            onClick={() => {
              eventGA('prestation_save');
              handleSave();
            }}
            disabled={saving}
            startIcon={<SaveIcon />}
            color="primary"
            className={classes.mobileButtonBox}>
            {t('general:save')}
          </Button>
          <Button
            variant="contained"
            disabled={saving || confirmDisabled}
            color="primary"
            className={classes.mobileButtonBox}
            onClick={() => {
              eventGA('prestation_save_and_confirm');
              handleSaveAndConfirm();
            }}>
            {t('general:saveAndConfirm')}
          </Button>
        </>
      ) : (
        <>
          <Box mr={2}>
            {!editMode && (
              <Box mr={-1}>
                <IconButton onClick={toggleEditMode} disabled={buttonsDisabled}>
                  <Box width={20} display="inline-flex" justifyContent="center" alignItems="center">
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                  </Box>
                </IconButton>
                <IconButton onClick={() => setIsPreconfirmed(true)} disabled={buttonsDisabled || confirmDisabled}>
                  <Box width={20} display="inline-flex" justifyContent="center" alignItems="center">
                    <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                  </Box>
                </IconButton>
              </Box>
            )}
          </Box>
          <Button onClick={handleExtend} variant="contained" color="primary" className={classes.mobileButtonBox}>
            {t('general:extend.extend')}
          </Button>
        </>
      )}
    </Box>
  );

  const desktopButtons = () =>
    editMode && (
      <>
        <Box>
          <Button
            onClick={() => {
              eventGA('prestation_cancel_edit');
              handleCancel();
            }}
            color="primary">
            {t('general:cancel')}
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              eventGA('prestation_save');
              handleSave();
            }}
            disabled={saving}
            startIcon={<SaveIcon />}
            color="primary">
            {t('general:save')}
          </Button>
          <Button
            variant="contained"
            disabled={saving || confirmDisabled}
            color="primary"
            onClick={() => {
              eventGA('prestation_save_and_confirm');
              handleSaveAndConfirm();
            }}>
            {t('general:saveAndConfirm')}
          </Button>
        </Box>
      </>
    );

  const previousButton = () => {
    let comp;
    if (!isTablet || (isTablet && !editMode)) {
      comp = (
        <BackButton
          onClick={() => history.push({ pathname: `/${navigationBack}` || '/', state: { date: data.info.from } })}>
          {navigationBack
            ? t('tempWorkers:prestations')
            : `${t('helpers:dates:week')} ${getWeekNumber(new Date(data.info.from))}`}
        </BackButton>
      );
    }
    return comp;
  };

  const getHeader = () => (
    <Box>
      <Box
        display="flex"
        minHeight={isTablet ? 52 : 0}
        alignItems="center"
        justifyContent="space-between"
        my={isTablet ? 0 : 4}>
        {previousButton()}
        {workersNavigation()}
        {weeksNavigation()}
        {isTablet && getButtons()}
      </Box>
      {isTablet && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={3}>
            <Typography variant="h6" component="h3" className={classes.fixedLineHeight}>
              {`${data.info.firstName} ${data.info.lastName}`}
            </Typography>
            <Box ml={4}>
              <StyledRating
                name="workerRating"
                size="small"
                value={rating}
                onChange={setRating}
                readOnly={!editMode || isTablet}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            {nextContractDates?.previous_date && (
              <Button
                disabled={isListLoading}
                className={classes.workerButton}
                disableRipple
                onClick={() => getNewWeek(nextContractDates?.previous_date, data?.info?.contractId, -1)}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            )}
            <Typography
              component="span"
              align="center"
              variant="body1"
              color="secondary"
              className={classes.weeksButton}>
              {`${format(new Date(data.info.from), 'dd/MM/yyyy')} - ${format(new Date(data.info.to), 'dd/MM/yyyy')}`}
            </Typography>
            {nextContractDates?.next_date && (
              <Button
                disabled={isListLoading}
                className={classes.workerButton}
                disableRipple
                onClick={() => getNewWeek(nextContractDates?.next_date, data?.info?.contractId, 1)}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );

  const footerButtons = () => (isTablet ? mobileButtons() : desktopButtons());

  const dialogActions = {
    cancel: () => handleCreateCancel(),
    confirm: () => handleCreate(),
    disabled: submitting
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
      <ConfirmDialog
        actions={dialogActions}
        isOpen={isPreconfirmed}
        title={t('general:performance.dialogTitle')}
        isOrdering={isLoading}>
        <Box mb={4}>
          <Typography component="p" variant="body1" color="secondary">
            {t('general:performance.confirmscreen')}
          </Typography>
        </Box>
        <Typography component="span" variant="body2" color="secondary" className={classes.italic}>
          {t('general:performance.confirmscreen_sub')}{' '}
          <a className={classes.link} href="mailto:customer@daoust.be">
            customer@daoust.be
          </a>
        </Typography>
        <Box mt={4}>
          <CustomFormControlLabel
            control={
              <Checkbox
                color="primary"
                id="shouldExtend"
                onChange={(_, val) => {
                  setShouldExtend(val);
                }}
              />
            }
            value={shouldExtend}
            label={t('general:contract.extendAfterConfirm')}
          />
        </Box>
      </ConfirmDialog>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}>
        <NewProlongation />
      </Modal>
      <Box flex="1" overflow="auto">
        <Container maxWidth="xl" display="flex" className={clsx({ [classes.mobileContainer]: isTablet })}>
          {getHeader()}
          <Box
            display="flex"
            flexWrap={['wrap-reverse', null, null, 'wrap']}
            justifyContent="space-between"
            mt={4}
            mb={6}>
            <WorkerCard
              dates={isTablet ? {} : { from: new Date(data.info.from), to: new Date(data.info.to) }}
              firstName={data.info.firstName}
              lastName={data.info.lastName}
              reference={data.info.ref}
              multipleRefs
              contractStatus={data.info.contractStatus}
              contractType={data.info.contractType}
              jobFunction={data.info.jobFunction ? data.info.jobFunction[`label_${language || 'fr'}`] : null}
              customer={data.title}
              showRating
              showWorkerInfos={!!data?.info?.firstName}
              rating={rating}
              onRatingChange={setRating}
              readOnly={!editMode}
              headless={isTablet}
            />
            <Box mb={4} display="flex" flexDirection="column" alignItems="flex-end">
              {!isTablet && getButtons()}
              <Box>
                {!!data.info.editedInDaco && (
                  <Box width={[1, 1, 1, 'auto']} pt={[0, 0, 0, 2]}>
                    <GreenTextTypography variant="body2">
                      <FontAwesomeIcon icon={faEdit} /> {t('tempWorkers:tooltips.editedInDaco')}
                    </GreenTextTypography>
                  </Box>
                )}
                {!!(data?.info?.impersonateValidator || data?.info?.impersonateEditor) && (
                  <Box width={[1, 1, 1, 'auto']} pt={[0, 0, 0, 2]}>
                    <GreenTextTypography variant="body2">
                      <FontAwesomeIcon icon={faUserSecret} /> {t('tempWorkers:tooltips.impersonateValidator')}
                    </GreenTextTypography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box mb={4}>{getSchedule()}</Box>
          <Box display="flex" flexWrap="wrap" my={2} minHeight={100}>
            <Box width={[1, 1, 1 / 2]} mb={4} pr={[0, 0, 2]}>
              {editMode ? (
                <TextField
                  variant="filled"
                  label={t('general:comment')}
                  fullWidth
                  id="comment"
                  value={comment || ''}
                  onChange={(e) => setComment(e.target.value)}
                  multiline
                  rows={4}
                />
              ) : (
                <>
                  <Box mb={2}>
                    <Typography variant="body2">{t('general:comment')}</Typography>
                  </Box>
                  <Typography variant="body2" color="secondary" className={classes.comment}>
                    {comment}
                  </Typography>
                </>
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} pl={[0, 0, 2]}>
              <Box mb={2}>
                <Typography variant="body2">{t('general:editHistory')}</Typography>
              </Box>
              <Typography component="div" variant="subtitle1" color="secondary">
                <TimesheetHistory history={data.info.timesheetHistory} />
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      {(editMode || isTablet) && (
        <Box display="flex" justifyContent="flex-end" className={classes.box} py={2}>
          <Container maxWidth="xl" className={classes.container}>
            {footerButtons()}
          </Container>
        </Box>
      )}
    </Box>
  );
};

Prestation.propTypes = {
  editSchedule: func.isRequired,
  confirmTimesheet: func.isRequired,
  customer: object.isRequired,
  updateEvaluation: func.isRequired,
  appendableCodes: array.isRequired,
  getAppendableCodes: func.isRequired,
  getAppendablePremiums: func.isRequired,
  location: object.isRequired,
  user: object.isRequired,
  isTablet: bool.isRequired,
  history: object.isRequired,
  setCandidate: func.isRequired,
  getNextContractDates: func.isRequired,
  nextContractDates: object,
  rawList: array.isRequired,
  list: array.isRequired,
  getList: func.isRequired,
  isListLoading: bool.isRequired,
  isLoading: bool.isRequired
};

Prestation.defaultProps = {
  nextContractDates: null
};

const mapState = ({
  user: { isOnTablet, current, currentCustomer },
  contracts: { appendableCodes },
  tempWorkers: { previousContractDate, nextContractDates, rawList, list },
  loading
}) => ({
  isTablet: isOnTablet,
  user: current,
  customer: currentCustomer,
  appendableCodes,
  previousContractDate,
  nextContractDates,
  rawList,
  list,
  isListLoading: pathOr(false, ['effects', 'tempWorkers', 'getList'], loading),
  isLoading:
    pathOr(false, ['effects', 'tempWorkers', 'confirmTimesheet'], loading) ||
    pathOr(false, ['effects', 'tempWorkers', 'getNextContractDates'], loading) ||
    pathOr(false, ['effects', 'contracts', 'updateEvaluation'], loading) ||
    pathOr(false, ['effects', 'contracts', 'setCandidate'], loading) ||
    pathOr(false, ['effects', 'contracts', 'editSchedule'], loading)
});

const mapDispatch = ({
  contracts: { editSchedule, setCandidate, updateEvaluation, getAppendableCodes, getAppendablePremiums },
  tempWorkers: { confirmTimesheet, getNextContractDates, getList }
}) => ({
  editSchedule,
  updateEvaluation,
  setCandidate,
  getAppendableCodes,
  getAppendablePremiums,
  confirmTimesheet,
  getNextContractDates,
  getList
});

export default pipe(connect(mapState, mapDispatch), withRouter)(Prestation);

/* eslint max-lines: 0 */
