import { createStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default createStyles((theme) => ({
  card: {
    maxWidth: '95%',
    marginLeft: '2.5%',
    marginBottom: '16px'
  },
  table: {
    width: '100%',
    textAlign: 'center',
    '& th, td': {
      width: 'calc(100% / 7)',
      padding: '8px 0',
      '& p': {
        fontSize: 12
      }
    }
  },
  cardAction: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.light.dark,
    justifyContent: 'flex-end'
  },
  cardActionButton: {
    borderColor: theme.palette.divider
  },
  header: {
    zIndex: 500,
    width: '100%',
    position: 'fixed',
    top: theme.headerHeight,
    backgroundColor: theme.palette.light.main
  },
  iconColor: {
    color: theme.palette.secondary.main,
    fontSize: '12px',
    marginRight: '4px'
  },
  iconColorSuccess: {
    color: theme.palette.green
  },
  textColorSuccess: {
    color: theme.palette.green
  },
  iconSize: {
    fontSize: 13
  },

  iconSizeEditPrestation: {
    fontSize: 11
  },
  weekBar: {
    backgroundColor: theme.palette.light.main,
    position: 'sticky',
    top: '56px',
    zIndex: 30
  },
  activeButton: {
    backgroundColor: transparentize(0.8, theme.palette.primary.main)
  }
}));
