import React from 'react';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';

const getOrderName = (order, lang) => {
  let name = '';
  if (order.order_type_id === 4) {
    name = path(['mainJobFunction', `label_${lang}`], order);
    if (order.openings > 1) name += ` (x ${order.openings})`;
  } else {
    const firstName = path(['firstCandidate', 'first_name'], order);
    const lastName = path(['firstCandidate', 'last_name'], order);
    const candidateName = `${lastName}${!!firstName && !!lastName && ' '}${firstName}`;
    name = candidateName;
  }
  return name;
};

const OrderName = ({ order }) => {
  const { i18n } = useTranslation();

  return <>{getOrderName(order, i18n.language)}</>;
};

OrderName.propTypes = {
  order: object.isRequired
};

export default OrderName;
