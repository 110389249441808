import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  LinearProgress
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { isEmpty, or } from 'ramda';
import { array, bool, func, object } from 'prop-types';

import { eventGA } from 'utils/analytics';

import SearchField from 'components/SearchField';
import Modal from 'components/Modal/Modal';
import NewPayroll from 'components/NewPayroll/NewPayroll';
import IconSelect from 'components/IconSelect';
import { faHistory, faIndustry, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroller';
import { makeStyles } from '@material-ui/core/styles';
import { OrderHeadCell } from 'components';
import IconAutocomplete from 'components/IconAutocomplete';
import { costCenterString } from 'utils/tempWorkers';
import NewContractHandler from 'components/NewContractHandler/NewContractHandler';
import WorkerDesktopRow from './WorkerRow.desktop';
import { sinceOptions, workerTypes } from '../common/constant';

const useStyles = makeStyles(() => ({
  loadingRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0,
      padding: 0
    }
  }
}));

const MyWorkersDesktop = ({
  workerList,
  isWorkerListLoading,
  costCenters,
  handleContract,
  search,
  setSearch,
  hasMore,
  newContractValues,
  loadMore,
  order,
  onOrder,
  getFirstPage
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [newPayrollOpen, setNewPayrollOpen] = useState(false);
  const [newContractOpen, setNewContractOpen] = useState(false);
  const [costCentersOpen, setCostCentersOpen] = useState(false);

  const onAskContract = (workerData) => {
    handleContract(workerData);
    setNewContractOpen(true);
  };

  const handleEventGACenter = (centers) => {
    const lastCenter = centers[centers.length - 1];
    eventGA('candidate_CC_filter', costCenterString(lastCenter));
  };

  return (
    <>
      <Box display="flex" my={5} justifyContent="space-between">
        <Typography component="h6" variant="h6">
          {t('myWorkers:myWorkersAndPayroll')}
        </Typography>
        <Box ml="auto">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setNewPayrollOpen(true);
            }}>
            {t('myWorkers:newPayroll')}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="flex-start" flex="1">
          <Box mr={4} display="flex" flexDirection="row" alignItems="center">
            <IconSelect
              icon={faHistory}
              value={search.since}
              onChange={(e) => {
                eventGA('candidate_date_range_filter', e.target.value);
                setSearch({ ...search, since: e.target.value });
              }}
              id="since"
              labelId="since"
              fullWidth>
              {sinceOptions.map(({ key, value, text }) => (
                <MenuItem key={key} value={value}>
                  {t(text)}
                </MenuItem>
              ))}
            </IconSelect>
          </Box>
          <Box mr={4}>
            <IconSelect
              icon={faUserAlt}
              value={search.workerType}
              onChange={(e) => {
                eventGA('candidate_type_filter', e.target.value);
                setSearch({ ...search, workerType: e.target.value });
              }}
              id="workerType"
              labelId="workerType"
              fullWidth>
              {workerTypes.map(({ key, value, text }) => (
                <MenuItem key={key} value={value}>
                  {t(text)}
                </MenuItem>
              ))}
            </IconSelect>
          </Box>
          <Box mr={4} flex="1" maxWidth={or(costCentersOpen, !!search.cost_centers.length) ? '100%' : 233}>
            <IconAutocomplete
              multiple
              icon={faIndustry}
              options={costCenters}
              onOpen={() => setCostCentersOpen(true)}
              onClose={() => setCostCentersOpen(false)}
              getOptionLabel={costCenterString}
              value={search.cost_centers}
              noValueMessage={t('general:docs.allCostCenters')}
              onChange={(_, value) => {
                handleEventGACenter(value);
                setSearch((f) => ({ ...f, cost_centers: value }));
              }}
            />
          </Box>
        </Box>
        <Box>
          <SearchField
            value={search.name || ''}
            onChange={(e) => {
              eventGA('candidate_search', e.target.value);
              setSearch({ ...search, name: e.target.value });
            }}
          />
        </Box>
      </Box>
      <Box mt={6} width="100%">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <Box width="100%" display="flex" justifyContent="center" my={4} key="loader">
              <CircularProgress />
            </Box>
          }>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <OrderHeadCell colSpan="2" order={order} field="candidate_name" onOrder={onOrder}>
                  <Typography variant="body2" component="span" color="secondary">
                    {`${(workerList && workerList.length) || 0} ${t('myWorkers:workersAndPayrolls')}`}
                  </Typography>
                </OrderHeadCell>
                <TableCell />
                <OrderHeadCell order={order} field="function_name" onOrder={onOrder}>
                  <Typography variant="body2" component="span" color="secondary">
                    {t('myWorkers:lastFunction')}
                  </Typography>
                </OrderHeadCell>
                <OrderHeadCell order={order} field="cost_center_name" onOrder={onOrder}>
                  <Typography variant="body2" component="span" color="secondary">
                    {t('myWorkers:lastCostCenter')}
                  </Typography>
                </OrderHeadCell>
                <OrderHeadCell order={order} field="to_date" onOrder={onOrder}>
                  <Typography variant="body2" component="span" color="secondary">
                    {t('myWorkers:lastContract')}
                  </Typography>
                </OrderHeadCell>
                <TableCell />
              </TableRow>
              {isWorkerListLoading && isEmpty(workerList) ? (
                <TableRow className={classes.loadingRow}>
                  <TableCell colSpan={100} className={classes.loadingRow}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {workerList &&
                    workerList.map((worker) => (
                      <WorkerDesktopRow key={worker.id} workerData={worker} onAskContract={onAskContract} />
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </Box>
      <Modal
        isOpen={newPayrollOpen}
        disableScrollLock={false}
        onClose={() => {
          setNewPayrollOpen(false);
        }}>
        <NewPayroll
          shouldOpenContract={(val) => {
            setNewContractOpen(val);
            if (!val) getFirstPage();
          }}
        />
      </Modal>
      <Modal
        isOpen={newContractOpen}
        disableScrollLock={false}
        onClose={() => {
          setNewContractOpen(false);
        }}>
        <NewContractHandler
          defaultValues={newContractValues}
          actionType="default"
          onClose={() => {
            getFirstPage();
          }}
        />
      </Modal>
    </>
  );
};

MyWorkersDesktop.propTypes = {
  workerList: array,
  isWorkerListLoading: bool.isRequired,
  costCenters: array.isRequired,
  handleContract: func.isRequired,
  search: object.isRequired,
  setSearch: func.isRequired,
  hasMore: bool.isRequired,
  newContractValues: object,
  loadMore: func.isRequired,
  order: object.isRequired,
  onOrder: func.isRequired,
  getFirstPage: func.isRequired
};

MyWorkersDesktop.defaultProps = {
  workerList: null,
  newContractValues: null
};

export default MyWorkersDesktop;
