import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

import NewContractPoolMobile from './NewContractPool.mobile';
import NewContractPoolDesktop from './NewContractPool.desktop';

const displayCondition = (el) => ({
  active: el.is_active,
  selected: !el.is_active,
  payroll: el.isPayroll === 'P',
  default: true
});

const NewContractPool = ({ isMobile, innerPool, setInnerPool, ...props }) => {
  const [viewType, setViewType] = useState('default');
  const nbrActive = useMemo(() => innerPool.filter((el) => el.is_active).length, [innerPool]);
  const nbrSelected = useMemo(() => innerPool.filter((el) => !el.is_active).length, [innerPool]);
  const nbrPayroll = useMemo(() => innerPool.filter((el) => el.isPayroll === 'P').length, [innerPool]);

  useEffect(() => {
    setInnerPool((list) => list.map((el) => ({ ...el, display: displayCondition(el)[viewType] })));
  }, [viewType]);

  return (
    <Box flex={1} overflow="hidden" display="flex">
      {isMobile ? (
        <NewContractPoolMobile
          innerPool={innerPool}
          viewType={viewType}
          setViewType={setViewType}
          nbrActive={nbrActive}
          nbrSelected={nbrSelected}
          setInnerPool={setInnerPool}
          nbrPayroll={nbrPayroll}
          {...props}
        />
      ) : (
        <NewContractPoolDesktop
          innerPool={innerPool}
          viewType={viewType}
          setViewType={setViewType}
          nbrActive={nbrActive}
          nbrSelected={nbrSelected}
          setInnerPool={setInnerPool}
          nbrPayroll={nbrPayroll}
          {...props}
        />
      )}
    </Box>
  );
};

NewContractPool.propTypes = {
  isMobile: bool.isRequired,
  isPoolLoading: bool.isRequired,
  innerPool: array.isRequired,
  setInnerPool: func.isRequired,
  preSelectedWorkers: array.isRequired
};

const mapState = ({ user: { isOnMobile }, loading }) => ({
  isMobile: isOnMobile,
  isPoolLoading: pathOr(true, ['effects', 'orders', 'getCandidatesPool'], loading)
});

export default connect(mapState, null)(NewContractPool);
