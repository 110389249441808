import React from 'react';
import { bool, object, func, string, oneOfType, number } from 'prop-types';
import { connect } from 'react-redux';
import { and, or } from 'ramda';
import { Route, Redirect } from 'react-router-dom';
import { useAllowedActions } from './useAllowedActions';
import { userRoles } from './userRoles';

const AuthRoute = ({
  Component,
  path,
  exact,
  requiredRoles,
  redirectUri,
  isLogged,
  publicRoute,
  gdprConsent,
  hasResetPassword
}) => {
  const hasRequiredRole = useAllowedActions(requiredRoles || userRoles.any, true);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        const {
          // eslint-disable-next-line react/prop-types
          location: { pathname: requestedPath }
        } = props;
        if (isLogged) {
          if (and(!gdprConsent, path !== '/gdpr')) return <Redirect to="/gdpr" />;
          if (and(!hasResetPassword, !['/gdpr', '/password/change'].includes(path)))
            return <Redirect to="/password/change" />;
          if (or(and(gdprConsent, path === '/gdpr'), and(hasResetPassword, path === '/password/change')))
            return <Redirect to={redirectUri} />;
          if (publicRoute) return <Redirect to={redirectUri} />;
        }
        if (or(and(isLogged, hasRequiredRole), publicRoute)) return <Component {...props} />;

        return <Redirect to={isLogged ? redirectUri : { pathname: '/login', state: { requestedPath } }} />;
      }}
    />
  );
};

AuthRoute.propTypes = {
  Component: oneOfType([object, func]).isRequired,
  path: string.isRequired,
  redirectUri: string,
  exact: bool,
  requiredRoles: oneOfType([object, string]),
  isLogged: bool.isRequired,
  publicRoute: bool,
  gdprConsent: string,
  hasResetPassword: number
};

AuthRoute.defaultProps = {
  exact: false,
  redirectUri: '/',
  requiredRoles: null,
  publicRoute: false,
  gdprConsent: null,
  hasResetPassword: 1
};

const mapState = ({ user: { isLogged, current } }) => ({
  isLogged,
  gdprConsent: current?.gdpr_consent,
  hasResetPassword: current?.has_reset_password || null
});

export default connect(mapState)(AuthRoute);
export * from './userRoles';
export * from './useAllowedActions';
