import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EmptyPrestation = () => {
  const { t } = useTranslation(['general']);
  const history = useHistory();
  const theme = useTheme();
  return (
    <Box m="20vh auto" display="flex" flexDirection="column" alignItems="center">
      <FontAwesomeIcon color={theme.palette.text.secondary} icon={faInbox} style={{ fontSize: 100 }} />
      <Box m={4}>
        <Typography variant="body1">{t('general:performance.noPrestations')}</Typography>
      </Box>
      <Box m={4}>
        <Button color="primary" variant="contained" onClick={() => history.push('/')}>
          {t('general:performance.gotocurrentweek')}
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyPrestation;
