import { any, isEmpty, path } from 'ramda';

import { days } from './date';

const buildSearchMatchingForWorker = ({ order, language }) => {
  return (
    order?.costCenter?.title +
    order?.candidate?.fullname +
    order.mainJobFunction[`label_${language || 'fr'}`] +
    order.costCenter.customer_commercial_name +
    order.costCenter.description
  );
};

const buildSearchMatchingForJobFunction = ({ order, language }) => {
  return (
    order?.costCenter?.title +
    order?.orderPlannings.map((pl) => pl?.candidate?.fullname) +
    order.mainJobFunction[`label_${language || 'fr'}`] +
    order.costCenter.customer_commercial_name +
    order.costCenter.description
  );
};

/** Returns a filtered list based on search string
 * Takes List and String
 * returns List
 */
export const filterPlanningSearch = ({ planningList, search = '', dayFilter = [], language, viewByWorker }) => {
  const regexes = search
    .split(/[\s,+]+/g)
    .filter((s) => !!s)
    .map((s) => new RegExp(s.trim(), 'i'));

  const matchingElements = (order) =>
    viewByWorker
      ? buildSearchMatchingForWorker({ order, language })
      : buildSearchMatchingForJobFunction({ order, language });

  const findMatchingDays = ({ order }) => {
    if (viewByWorker) {
      return dayFilter.map((day) => path(['scheduleDays', days[day], 'total_hours'], order)).every((d) => !!d);
    }
    const otherR = order?.orderPlannings.map((op) =>
      dayFilter.map((day) => path(['scheduleDays', days[day], 'total_hours'], op)).every((d) => !!d)
    );
    return any((e) => !!e, otherR);
  };

  return planningList.reduce((centers, center) => {
    const filteredMembers = center.filter(
      (order) =>
        regexes.map((regex) => regex.test(matchingElements(order))).every((r) => !!r) &&
        (isEmpty(dayFilter) || findMatchingDays({ order }))
    );

    if (filteredMembers.length) return [...centers, filteredMembers];
    return centers;
  }, []);
};
