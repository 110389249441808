import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { pathOr } from 'ramda';

import { FrLocaleUtilsProvider } from 'components';
import history from 'utils/history';
import { removeStorage } from 'utils/storage';

import { api, errorHandler } from 'utils/api';
import 'translate/i18n';
import theme from 'theme';
import App from 'App';
import store from 'store';

import * as serviceWorker from 'serviceWorker';

if (pathOr(0, ['navigation', 'type'], performance) !== 1) {
  removeStorage('impersonatedUser');
  removeStorage('impersonatorToken');
}

api.setHandleErrorFunc(errorHandler(store));
store.dispatch.user.initUser();

const Root = () => (
  <Provider store={store}>
    <FrLocaleUtilsProvider>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </FrLocaleUtilsProvider>
  </Provider>
);

ReactDOM.render(<Root />, document.querySelector('#root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
