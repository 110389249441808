import React, { Fragment, useState } from 'react';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { prop, pathOr } from 'ramda';
import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { isSameDay } from 'date-fns';

import { getWeekDays } from 'utils/date';
import { eventGA } from 'utils/analytics';

import TempWorkerRow from 'components/TempWorkerRow/TempWorkerRow';
import ListDesktopHeader from '../common/ListDesktopHeader';
import TitleBar from '../common/TitleBar';

import styles from './TempWorkersListDesktop.styles';

const TempWorkersListDesktop = ({
  classes,
  list,
  exportXLS,
  date,
  dayFilter,
  dayFilterChange,
  setDate,
  isLoading,
  onProlongationEvent,
  sendAnalytics
}) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);
  const [hiddenCenter, setHiddenCenters] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showBonuses, setShowBonuses] = useState(false);

  const weekDays = getWeekDays(date);

  return (
    <>
      <ListDesktopHeader
        exportXLS={exportXLS}
        date={date}
        setDate={setDate}
        detailsShown={showDetails}
        showDetails={setShowDetails}
        bonusesShown={showBonuses}
        showBonuses={setShowBonuses}
        sendAnalytics={sendAnalytics}
      />
      <Box mt={6} width="100%">
        <Table aria-label="simple table">
          <TableBody>
            <TableRow className={classes.headRow}>
              <TableCell component="th" scope="row" className={classes.firstAndLastCell}>
                <Typography variant="body2" color="secondary">
                  {`${list.reduce((acc, curr) => acc + pathOr(0, ['contracts', 'length'], curr), 0)} ${t(
                    'general:deliveries'
                  )}`}
                </Typography>
              </TableCell>
              <TableCell align="right" />
              {weekDays.map((day) => {
                const isDayFiltered = dayFilter.includes(day.getDay());
                return (
                  <TableCell
                    align="right"
                    key={day}
                    className={clsx(classes.dayCell, {
                      [classes.todayCell]: isSameDay(day, new Date()),
                      [classes.filteredDayCell]: dayFilter.includes(day.getDay())
                    })}
                    onClick={() => {
                      if (sendAnalytics) eventGA('home_day_filter', day.toISOString());
                      dayFilterChange((currentDays) =>
                        isDayFiltered ? currentDays.filter((d) => d !== day.getDay()) : [...currentDays, day.getDay()]
                      );
                    }}>
                    <Tooltip
                      title={t(`tempWorkers:help.${isDayFiltered ? 'removeDayFilter' : 'dayFilter'}`)}
                      classes={{ tooltip: classes.dayFilterTooltip }}>
                      <Box>
                        <FontAwesomeIcon icon={faFilter} className={clsx(classes.iconColor, classes.mediumIcon)} />
                        <Box display="inline" pl={3}>
                          <Typography variant="body2" component="span" color="secondary">
                            {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                          </Typography>
                        </Box>
                        <Typography variant="subtitle1" component="p" color="secondary">
                          {day.getDate()} {t(`helpers:dates.months.short.${day.getMonth()}`)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </TableCell>
                );
              })}
              <TableCell align="right" className={classes.dayCell}>
                <Typography variant="body2" color="secondary">
                  {t('general:total')}
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.lastCell} />
            </TableRow>
            {isLoading && (
              <TableRow className={clsx(classes.noBorderRow, classes.noPaddingRow)}>
                <TableCell colSpan={100} className={classes.firstAndLastCell}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {!!prop('length', list) &&
              list.map((center) => {
                return (
                  <Fragment key={center.title}>
                    <TitleBar
                      title={center.title}
                      isOpen={!hiddenCenter.includes(center.title)}
                      canCollapse
                      toggle={() =>
                        setHiddenCenters((current) =>
                          current.includes(center.title)
                            ? current.filter((c) => c !== center.title)
                            : [...current, center.title]
                        )
                      }
                    />
                    {center.contracts.map((row, index) => (
                      <TempWorkerRow
                        key={`${row.name.replace(' ', '')}-${row.id}-${row.info.contractId}`}
                        data={row}
                        show={!hiddenCenter.includes(center.title)}
                        showDetails={showDetails}
                        showBonuses={showBonuses}
                        endActions={[
                          () => (
                            <Tooltip title={t('general:extend.extend')}>
                              <IconButton
                                aria-label="prolong"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  eventGA('home_extension');
                                  onProlongationEvent({ ...row.info, id: row.id });
                                }}>
                                <FontAwesomeIcon
                                  icon={faCalendarPlus}
                                  className={clsx(classes.iconColor, classes.bigIcon)}
                                />
                              </IconButton>
                            </Tooltip>
                          )
                        ]}
                        weekDays={weekDays}
                        rowLabel={row.name}
                        isLast={index === center.contracts.length - 1}
                      />
                    ))}
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

TempWorkersListDesktop.propTypes = {
  dayFilter: array.isRequired,
  list: array.isRequired,
  exportXLS: func,
  date: PropTypes.instanceOf(Date),
  dayFilterChange: func.isRequired,
  setDate: func,
  classes: object.isRequired,
  isLoading: bool,
  onProlongationEvent: func,
  sendAnalytics: bool
};

TempWorkersListDesktop.defaultProps = {
  date: new Date(),
  exportXLS: null,
  setDate: () => null,
  onProlongationEvent: () => null,
  isLoading: false,
  sendAnalytics: false
};

export default withStyles(styles)(TempWorkersListDesktop);
