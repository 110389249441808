import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { faHistory, faIndustry } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import { useDebounce, useHeader } from 'hooks';
import { costCenterString } from 'utils/tempWorkers';
import { eventGA } from 'utils/analytics';
import { SearchField } from 'components';

import RequestMenu from 'components/TempWorkersList/common/RequestMenu';
import IconSelect from 'components/IconSelect';
import IconAutocomplete from 'components/IconAutocomplete';

import { withRouter } from 'react-router';
import OrderTable from './OrderTable';

const DesktopView = ({ orders, costCenters, isLoading, getOrders, search, setSearch }) => {
  const { setSearch: setHeaderSearch } = useHeader();
  const { t } = useTranslation(['general']);
  const debouncedSearch = useDebounce(search, 400);
  const [costCentersOpen, setCostCentersOpen] = useState(false);

  useEffect(() => {
    if (!isNil(debouncedSearch)) {
      setHeaderSearch(debouncedSearch.search.trim());
    }
  }, [debouncedSearch, setHeaderSearch]);

  const handleEventGACenter = (centers) => {
    const lastCenter = centers[centers.length - 1];
    eventGA('order_CC_filter', costCenterString(lastCenter));
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" my={5}>
        <Typography variant="h6" component="h2">
          {t('general:orders.title')}
        </Typography>
        <RequestMenu
          onClose={(res) => {
            if (res) {
              setSearch((s) => ({ ...s, force: (s.force || 0) + 1 }));
            }
          }}
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
        <Box display="flex" flexDirection="row" flex="1">
          <Box mr={4} flex="1" maxWidth={costCentersOpen || !!search.cost_centers.length ? '100%' : 233}>
            <IconAutocomplete
              multiple
              icon={faIndustry}
              options={costCenters}
              onOpen={() => setCostCentersOpen(true)}
              onClose={() => setCostCentersOpen(false)}
              getOptionLabel={costCenterString}
              value={search.cost_centers}
              noValueMessage={t('general:docs.allCostCenters')}
              onChange={(_, value) => {
                handleEventGACenter(value);
                setSearch((f) => ({ ...f, cost_centers: value }));
              }}
            />
          </Box>
          <Box mr={4}>
            <IconSelect
              icon={faHistory}
              defaultValue={search.months}
              onChange={(e) => {
                eventGA('order_date_range_filter', e.target.value);
                setSearch((f) => ({ ...f, months: e.target.value }));
              }}>
              <MenuItem value={3}>{t('general:orders.time.three')}</MenuItem>
              <MenuItem value={6}>{t('general:orders.time.six')}</MenuItem>
              <MenuItem value={12}>{t('general:orders.time.twelve')}</MenuItem>
              <MenuItem value="all">{t('general:orders.time.all')}</MenuItem>
            </IconSelect>
          </Box>
        </Box>
        <Box>
          <SearchField
            value={search.search}
            onChange={(e) => {
              e.persist();
              eventGA('order_search', e.target.value);
              setSearch((f) => ({ ...f, search: e.target.value }));
            }}
          />
        </Box>
      </Box>
      <OrderTable
        data={orders}
        loadMore={getOrders}
        search={search}
        isLoading={isLoading}
        debouncedSearch={debouncedSearch}
      />
    </Box>
  );
};

DesktopView.propTypes = {
  orders: array.isRequired,
  isLoading: bool.isRequired,
  costCenters: array.isRequired,
  getOrders: func.isRequired,
  search: object.isRequired,
  setSearch: func.isRequired
};

export default withRouter(DesktopView);
