/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { always, compose, cond, isNil, propEq, T, isEmpty } from 'ramda';
import { bool, object, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import { Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box, Button, Snackbar } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { useDebounce, useWindowDimensions, useGAPageView } from 'hooks';
import { initAnalytics } from 'utils/analytics';

import { show } from 'components/decorators';
import { CookieConsent, Header, Menu, Search } from 'components';
import {
  ConfirmPrestation,
  Documents,
  ForgotPassword,
  Help,
  Impersonate,
  HomePage,
  Gdpr,
  Login,
  News,
  NewsDetails,
  Orders,
  Prestation,
  ResetPassword,
  UpdatePassword,
  Settings,
  MyWorkers,
  Planning
} from 'pages';
import { getCookieConsentProperty } from 'utils/cookieConsent';

import { isNotNilOrEmpty } from 'ramda-adjunct';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AuthRoute, { useAllowedActions, userRoles } from './auth';
import { UserContext } from './user/userContext';
import { UserEntity } from './user/UserEntity';
import { HeaderContext } from './components/Header/headerContext';

const mobileBreakpoint = Number(process.env.REACT_APP_RESPONSIVE_MOBILE_BREAKPOINT);
const tabletBreakpoint = Number(process.env.REACT_APP_RESPONSIVE_TABLET_BREAKPOINT);
const hideMobileHeader = [/^\/prestation$/, /^\/orders\/[0-9]*/, /\/news\/details/];

const ToastCloseButton = ({ closeToast }) => (
  <Box display="flex" justifyContent="flex-end">
    <Button color="primary" size="small" onClick={closeToast}>
      OK
    </Button>
  </Box>
);

const redirection = cond([
  [propEq('validation', true), always('/')],
  [propEq('messaging', true), always('/news')],
  [propEq('documentAccess', true), always('/documents')],
  [T, always('/help')]
]);

// eslint-disable-next-line complexity
const App = ({
  classes,
  getToConfirmList,
  getGeoData,
  getUnreadCount,
  user,
  isLogged,
  logout,
  isMobile,
  isSnackOpen,
  snackMessage,
  setIsMobile,
  setIsTablet,
  isCurrentCustomerDefined,
  getFeatureList
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname: currentPath } = useLocation();
  const history = useHistory();
  const { width: windowWidth } = useWindowDimensions();
  const [components, setComponents] = useState([]);
  const [title, setTitle] = useState(null);
  const [search, setSearch] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCookieModal, setShowCookieModal] = useState(isEmpty(getCookieConsentProperty()));
  const debouncedSearchTerm = useDebounce(searchTerm);
  const theme = useTheme();
  const allowedActions = useAllowedActions([userRoles.validation, userRoles.messaging, userRoles.documentAccess]);
  const redirectUri = useMemo(() => (isCurrentCustomerDefined ? redirection(allowedActions) : '/'), [
    isCurrentCustomerDefined,
    allowedActions
  ]);

  const { t } = useTranslation(['general']);

  const showHeader =
    isLogged &&
    user?.gdpr_consent &&
    user?.has_reset_password > 0 &&
    (!isMobile || hideMobileHeader.map((path) => !!path.test(currentPath)).every((r) => !r));

  const renderSearch = useCallback(
    () => (
      <Search
        onSearch={setSearchTerm}
        value={searchTerm}
        isOpen={isSearchOpen}
        onToggle={setIsSearchOpen}
        key="search"
      />
    ),
    [isSearchOpen, searchTerm]
  );

  useEffect(() => {
    history.listen(() => {
      setSearch('');
      setSearchTerm('');
      setIsSearchOpen(false);
    });
  }, [history]);

  useEffect(() => {
    if (!isNil(debouncedSearchTerm)) {
      setSearch(debouncedSearchTerm.trim());
    }
  }, [debouncedSearchTerm, setSearch]);

  useEffect(() => {
    if (isLogged && user?.gdpr_consent) {
      getToConfirmList();
      getGeoData();
      getUnreadCount();
      getFeatureList();
    }
  }, [getToConfirmList, getGeoData, getUnreadCount, isLogged, user]);

  useEffect(() => {
    setIsMobile(windowWidth < mobileBreakpoint);
    setIsTablet(windowWidth < tabletBreakpoint);
  }, [windowWidth, setIsMobile, setIsTablet]);

  useEffect(() => {
    initAnalytics(getCookieConsentProperty('analytical'));
  }, [showCookieModal]);
  useGAPageView();
  return (
    <>
      <Helmet>
        <meta name="description" content={t('metadesc')} data-react-helmet="true" />
      </Helmet>
      <UserContext.Provider value={new UserEntity(user)}>
        <Box height="100%">
          <HeaderContext.Provider
            value={{
              search,
              setSearch: setSearchTerm,
              renderSearch,
              isSearchOpen,
              components,
              setComponents,
              title,
              setTitle
            }}>
            {showHeader && (
              <>
                <Header logout={logout} toggleMenu={setShowMenu} />
                {isMobile && <Menu logout={logout} show={showMenu} setShow={setShowMenu} />}
              </>
            )}
            <Box
              height={showHeader ? `calc(100% - ${theme.headerHeight}px)` : '100%'}
              pt={showHeader && isMobile ? `${theme.headerHeight}px` : ''}
              display="flex">
              <Switch>
                <AuthRoute path="/impersonate" redirectUri={redirectUri} Component={Impersonate} publicRoute />
                <AuthRoute path="/login" redirectUri={redirectUri} exact Component={Login} publicRoute />
                <AuthRoute path="/gdpr" redirectUri={redirectUri} exact Component={Gdpr} />
                <AuthRoute
                  path="/password/forgot"
                  redirectUri={redirectUri}
                  exact
                  Component={ForgotPassword}
                  publicRoute
                />
                <AuthRoute
                  path="/password/reset/:token"
                  redirectUri={redirectUri}
                  exact
                  Component={ResetPassword}
                  publicRoute
                />
                <AuthRoute path="/password/change" redirectUri={redirectUri} exact Component={UpdatePassword} />
                <AuthRoute
                  path="/"
                  redirectUri={redirectUri}
                  exact
                  Component={HomePage}
                  requiredRoles={userRoles.validation}
                />
                <AuthRoute
                  path="/planning"
                  redirectUri={redirectUri}
                  exact
                  Component={Planning}
                  requiredRoles={userRoles.validation}
                />
                <AuthRoute
                  path="/orders/:orderId?"
                  redirectUri={redirectUri}
                  exact
                  Component={Orders}
                  requiredRoles={userRoles.validation}
                />
                <AuthRoute
                  path="/confirm-prestation"
                  redirectUri={redirectUri}
                  exact
                  Component={ConfirmPrestation}
                  requiredRoles={userRoles.validation}
                />
                <AuthRoute
                  path="/prestation"
                  redirectUri={redirectUri}
                  exact
                  Component={Prestation}
                  requiredRoles={userRoles.validation}
                />
                <AuthRoute
                  path="/documents"
                  redirectUri={redirectUri}
                  exact
                  Component={Documents}
                  requiredRoles={userRoles.documentAccess}
                />
                <AuthRoute
                  path="/parameters"
                  redirectUri={redirectUri}
                  exact
                  Component={Settings}
                  requiredRoles={userRoles.any}
                />
                <AuthRoute
                  path="/my-workers"
                  redirectUri={redirectUri}
                  exact
                  Component={MyWorkers}
                  requiredRoles={userRoles.validation}
                />
                <AuthRoute
                  path="/news"
                  redirectUri={redirectUri}
                  exact
                  Component={News}
                  requiredRoles={userRoles.messaging}
                />
                <AuthRoute
                  path="/news/details"
                  redirectUri={redirectUri}
                  exact
                  Component={NewsDetails}
                  requiredRoles={userRoles.any}
                />
                <AuthRoute
                  path="/help"
                  redirectUri={redirectUri}
                  exact
                  Component={Help}
                  requiredRoles={userRoles.any}
                />
                <Redirect to={isLogged ? redirectUri : '/login'} />
              </Switch>
            </Box>
          </HeaderContext.Provider>
          <ToastContainer
            className={classes.toaster}
            transition={Slide}
            position="top-center"
            closeButton={ToastCloseButton}
            hideProgressBar
          />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={isSnackOpen}
            message={snackMessage}
          />
          <CookieConsent isOpen={showCookieModal} setIsOpen={setShowCookieModal} />
        </Box>
      </UserContext.Provider>
    </>
  );
};

App.propTypes = {
  classes: object.isRequired,
  getToConfirmList: func.isRequired,
  getGeoData: func.isRequired,
  getUnreadCount: func.isRequired,
  user: object,
  isLogged: bool.isRequired,
  logout: func.isRequired,
  isMobile: bool.isRequired,
  isSnackOpen: bool.isRequired,
  snackMessage: string,
  setIsMobile: func.isRequired,
  setIsTablet: func.isRequired,
  isCurrentCustomerDefined: bool,
  getFeatureList: func.isRequired
};

App.defaultProps = {
  user: null,
  snackMessage: null,
  isCurrentCustomerDefined: false
};

ToastCloseButton.propTypes = {
  closeToast: func.isRequired
};

const mapState = ({ events, user: { isInitialized, isLogged, current, isOnMobile, currentCustomer } }) => ({
  isInitialized,
  isLogged,
  user: current,
  isMobile: isOnMobile,
  isSnackOpen: events.snackBar.isOpen,
  snackMessage: events.snackBar.message,
  isCurrentCustomerDefined: isNotNilOrEmpty(currentCustomer)
});

const mapDispatch = ({
  tempWorkers: { getToConfirmList },
  features: { getFeatureList },
  geo: { getGeoData },
  news: { getUnreadCount },
  user: { logout, setIsMobile, setIsTablet }
}) => ({
  logout,
  setIsMobile,
  setIsTablet,
  getToConfirmList,
  getGeoData,
  getUnreadCount,
  getFeatureList
});

const styles = createStyles((theme) => ({
  toaster: {
    left: 0,
    top: 0,
    width: '100%',
    padding: 0,
    transform: 'none',

    '& div.Toastify__toast--default': {
      color: theme.palette.secondary.main
    },

    '& div.Toastify__toast--info, div.Toastify__toast--success, div.Toastify__toast--warning, div.Toastify__toast--error': {
      color: theme.palette.light.main,
      '& .MuiButton-label': {
        color: theme.palette.light.main
      }
    },
    '& div.Toastify__toast': {
      display: 'block',
      boxShadow: 'none',
      borderBottom: theme.borders[1]
    },

    '& div.Toastify__toast-body': {
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
      paddingLeft: theme.spacing(2)
    }
  }
}));

export default compose(
  withStyles(styles),
  connect(mapState, mapDispatch),
  show(({ isInitialized }) => isInitialized)
)(App);
