/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  LinearProgress,
  Checkbox,
  Container,
  Button,
  Chip,
  TableContainer,
  TableHead
} from '@material-ui/core';
import { array, bool, func, number, object, string } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import DaoustIcon from 'assets/icon-daoust.svg';
import PayrollIcon from 'assets/icon-payroll.svg';

import { Img } from 'components/Img/Img';
import OrderHeadCell from 'components/OrderHeadCell';
import { ascend, descend, prop, sortWith } from 'ramda';
import { formatStatus, formatType, handleSelectWorker } from './newContractPool.utils';

const useStyles = makeStyles(() => ({
  loadingRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0,
      padding: 0
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  root: {
    width: '100%'
  },
  containerTable: {
    maxHeight: 440
  },
  ghost: {
    display: 'none'
  },
  cell: {
    padding: '6px 24px 6px 8px;'
  }
}));

const NewContractPoolDesktop = ({
  innerPool,
  setInnerPool,
  isPoolLoading,
  preSelectedWorkers,
  setViewType,
  viewType,
  nbrActive,
  nbrSelected,
  nbrPayroll,
  data
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [order, setOrder] = useState({
    order_by_field: null,
    order_by_direction: null
  });

  const direction = (p) => (order.order_by_direction === 'ASC' ? ascend(p) : descend(p));

  const fullnameSort = sortWith([direction(prop('fullname')), descend(prop('added_to_pool_at'))]);
  const typeSort = sortWith([
    direction(prop('is_flexi')),
    direction(prop('is_student')),
    direction(prop('is_activa')),
    descend(prop('lastContract'))
  ]);
  const statusSort = sortWith([direction(prop('is_active')), descend(prop('last_contract_end_date'))]);
  const payrollSort = sortWith([direction(prop('isPayroll')), descend(prop('added_to_pool_at'))]);

  useEffect(() => {
    if (!innerPool.length || !order.order_by_field) return;

    const sorting = (list) => ({
      fullname: fullnameSort(list),
      type: typeSort(list),
      status: statusSort(list),
      payroll: payrollSort(list)
    });

    setInnerPool((list) => sorting(list)[order.order_by_field]);
  }, [order]);

  return (
    <>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="body1">{t('general:pool.availableWorkers.title')}</Typography>
        <Box my={2}>
          <Typography color="textSecondary" variant="body2">
            {t('general:pool.availableWorkers.subtitle')}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1} overflow="hidden" my={6}>
          <Box width={1 / 5} display="flex" flexDirection="column">
            <Button
              disableElevation
              flex={1}
              color={viewType === 'active' ? 'primary' : 'default'}
              variant={viewType === 'active' ? 'contained' : 'text'}
              onClick={() =>
                setViewType((v) => (['default', 'selected', 'payroll'].includes(v) ? 'active' : 'default'))
              }>
              {t('general:pool.active')} ({nbrActive})
            </Button>
            <Button
              disableElevation
              color={viewType === 'selected' ? 'primary' : 'default'}
              variant={viewType === 'selected' ? 'contained' : 'text'}
              flex={1}
              onClick={() =>
                setViewType((v) => (['default', 'active', 'payroll'].includes(v) ? 'selected' : 'default'))
              }>
              {t('general:pool.selected')} ({nbrSelected})
            </Button>
            <Button
              disableElevation
              color={viewType === 'payroll' ? 'primary' : 'default'}
              variant={viewType === 'payroll' ? 'contained' : 'text'}
              flex={1}
              onClick={() =>
                setViewType((v) => (['default', 'active', 'selected'].includes(v) ? 'payroll' : 'default'))
              }>
              {t('general:payroll.title')} ({nbrPayroll})
            </Button>
          </Box>
          <TableContainer className={classes.containerTable}>
            <Table aria-label="pool table" stickyHeader>
              <TableHead>
                <TableRow>
                  <OrderHeadCell />

                  <OrderHeadCell order={order} field="fullname" onOrder={setOrder}>
                    <Typography variant="body2" component="span" color="secondary">
                      {t('user:lastName')}
                    </Typography>
                  </OrderHeadCell>

                  <OrderHeadCell order={order} field="type" onOrder={setOrder}>
                    <Typography variant="body2" component="span" color="secondary">
                      {t('user:type')}
                    </Typography>
                  </OrderHeadCell>

                  <OrderHeadCell order={order} field="status" onOrder={setOrder}>
                    <Typography variant="body2" component="span" color="secondary">
                      {t('general:status')}
                    </Typography>
                  </OrderHeadCell>

                  <OrderHeadCell order={order} field="payroll" onOrder={setOrder} align="center">
                    <Typography variant="body2" component="span" color="secondary">
                      {t('general:payroll.title')}
                    </Typography>
                  </OrderHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isPoolLoading && (
                  <TableRow className={classes.loadingRow}>
                    <TableCell colSpan={100} className={classes.loadingRow}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}

                {innerPool &&
                  innerPool.map((worker) => (
                    <TableRow key={worker.uuid} className={worker.display ? '' : classes.ghost}>
                      <TableCell size="small">
                        <Checkbox
                          onChange={() => handleSelectWorker({ innerPool, setInnerPool, worker })}
                          color="primary"
                          checked={worker.preselected}
                        />
                      </TableCell>
                      <TableCell className={classes.cell} size="small">
                        <Typography variant="body2" component="span" color="secondary">
                          {worker.fullname}
                        </Typography>
                      </TableCell>
                      <TableCell size="small" className={classes.cell}>
                        {formatType({ t, worker })}
                      </TableCell>
                      <TableCell size="small" className={classes.cell}>
                        {formatStatus({ t, worker })}
                      </TableCell>
                      <TableCell size="small" className={classes.cell} align="center">
                        <Img src={worker.isPayroll === 'P' ? PayrollIcon : DaoustIcon} alt="" />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography variant="body1">
          {t('general:pool.selectedWorkers.title')} ({preSelectedWorkers.length} / {data?.vacancies})
        </Typography>

        <Box my={2}>
          {!preSelectedWorkers.length && (
            <Typography color="textSecondary" variant="body2">
              {t('general:pool.selectedWorkers.empty')}
            </Typography>
          )}
          <Box display="flex" flexDirection="row" flexWrap="wrap" gridGap="8px" minHeight="32px">
            {preSelectedWorkers.map((worker) => (
              <Chip
                key={worker.uuid}
                label={worker.fullname}
                onDelete={() => handleSelectWorker({ innerPool, setInnerPool, worker, isRemoved: true })}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </>
  );
};

NewContractPoolDesktop.propTypes = {
  innerPool: array,
  setInnerPool: func.isRequired,
  isPoolLoading: bool.isRequired,
  preSelectedWorkers: array.isRequired,
  viewType: string.isRequired,
  setViewType: func.isRequired,
  nbrActive: number.isRequired,
  nbrSelected: number.isRequired,
  nbrPayroll: number.isRequired,
  data: object
};

NewContractPoolDesktop.defaultProps = {
  innerPool: null,
  data: null
};

export default NewContractPoolDesktop;
