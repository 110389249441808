import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Box, Divider, Link, List, ListItem, ListItemText, Typography, SwipeableDrawer } from '@material-ui/core';
import { has } from 'ramda';

import { UserContext } from 'user/userContext';
import { Img, Translate } from 'components';
import CustomerDropdown from 'components/CustomerDropdown/CustomerDropdown';
import Logo from 'assets/daoust-connect.svg';
import { conditionsLinks, privacyLinks } from './common';
import { userRoles } from '../../auth/userRoles';
import NavItem from './NavItem';

const Menu = ({ classes, logout, show, setShow }) => {
  const { t, i18n } = useTranslation(['general', 'auth', 'translate']);
  const user = useContext(UserContext);

  const close = () => setShow(false);

  return (
    <SwipeableDrawer anchor="left" open={show} onClose={() => setShow(false)} onOpen={() => setShow(true)}>
      <div className={classes.menu}>
        <Box onClick={close} pl={4}>
          <Img src={Logo} alt="" width="200px" />
        </Box>
        <Box pt={4}>
          <Box pl={4}>
            <Typography component="h6" variant="h6">
              {user.getFullName()}
            </Typography>
            <CustomerDropdown />
          </Box>
          <Box mt={4} mb={1}>
            <Divider />
          </Box>
          <List>
            <NavItem requiredRoles={userRoles.validation}>
              <Link
                component={RouterLink}
                to="/"
                exact
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.home')} />
                </ListItem>
              </Link>
            </NavItem>
            <NavItem requiredRoles={userRoles.validation}>
              <Link
                component={RouterLink}
                to="/planning"
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.planning')} />
                </ListItem>
              </Link>
            </NavItem>
            <NavItem requiredRoles={userRoles.validation}>
              <Link
                component={RouterLink}
                to="/confirm-prestation"
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.TBCDeliveries')} />
                </ListItem>
              </Link>
            </NavItem>
            <NavItem requiredRoles={userRoles.validation}>
              <Link
                component={RouterLink}
                to="/orders"
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.orders')} />
                </ListItem>
              </Link>
            </NavItem>
            <NavItem requiredRoles={userRoles.validation}>
              <Link
                component={RouterLink}
                to="/my-workers"
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.myWorkersAndPayrolls')} />
                </ListItem>
              </Link>
            </NavItem>
            <NavItem requiredRoles={userRoles.documentAccess}>
              <Link
                component={RouterLink}
                to="/documents"
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.documents')} />
                </ListItem>
              </Link>
            </NavItem>
            <NavItem requiredRoles={userRoles.messaging}>
              <Link
                component={RouterLink}
                to="/news"
                textDecoration="none"
                onClick={close}
                className={classes.headerLink}>
                <ListItem button className={classes.headerLinkContainer}>
                  <ListItemText primary={t('general:menu.news')} />
                </ListItem>
              </Link>
            </NavItem>
            <Translate large />
            <Link
              component={RouterLink}
              to="/help"
              textDecoration="none"
              onClick={close}
              className={classes.headerLink}>
              <ListItem button className={classes.headerLinkContainer}>
                <ListItemText primary={t('general:menu.help')} />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to="/parameters"
              textDecoration="none"
              onClick={close}
              className={classes.headerLink}>
              <ListItem button className={classes.headerLinkContainer}>
                <ListItemText primary={t('general:menu.parameters')} />
              </ListItem>
            </Link>
            <Link
              href={has(i18n.language, conditionsLinks) ? conditionsLinks[i18n.language] : conditionsLinks.fr}
              textDecoration="none"
              target="_blank"
              className={classes.headerLink}>
              <ListItem button className={classes.headerLinkContainer}>
                <ListItemText primary={t('general:menu.conditions')} />
              </ListItem>
            </Link>
            <Link
              href={has(i18n.language, privacyLinks) ? privacyLinks[i18n.language] : privacyLinks.fr}
              textDecoration="none"
              target="_blank"
              className={classes.headerLink}>
              <ListItem button className={classes.headerLinkContainer}>
                <ListItemText primary={t('general:menu.privacy')} />
              </ListItem>
            </Link>
            <ListItem button onClick={logout} className={classes.headerLinkContainer}>
              <ListItemText primary={t('auth:logout')} />
            </ListItem>
          </List>
        </Box>
      </div>
    </SwipeableDrawer>
  );
};

Menu.propTypes = {
  classes: object.isRequired,
  show: bool.isRequired,
  setShow: func.isRequired,
  logout: func
};

Menu.defaultProps = {
  logout: () => null
};

const styles = createStyles((theme) => ({
  menu: {
    width: `${theme.menuWidth}px`,
    paddingTop: theme.spacing(3)
  },
  headerLinkContainer: {
    height: 48
  },
  headerLink: {
    color: theme.palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
    '&.active': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      textDecoration: 'none'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export default withStyles(styles)(Menu);
