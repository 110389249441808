/* eslint max-lines: 0 */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { func, object, array } from 'prop-types';
import { connect } from 'react-redux';
import { Box, InputLabel, FormControl, FormHelperText, MenuItem, Select, Typography, Link } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays, differenceInMinutes } from 'date-fns';
import { propOr, compose, prop, isNil, pipe, values, pick, map } from 'ramda';
import { isValidDate } from 'ramda-adjunct';
import { Controller, useForm } from 'react-hook-form';
import { convertMinutesToTime, valueToMinutes } from 'utils/date';
import { hasError, getError } from 'components/helpers';
import { withStyles } from '@material-ui/core/styles';

import templateNL from 'assets/TEMPLATE AFWIJKINGSREGISTER (IN)V2 Legal 25 04 2019.doc';
import templateFR from 'assets/TEMPLATE REGISTRE DE DEROGATION (IN) V2 - Legal 25 04 2019.doc';
import CustomTimePicker from 'components/CustomTimePicker';
import validations from './editDaySchedule.validation';
import scheduleFormStyle from '../../ScheduleForm/scheduleFormStyle';

const addDates = (start, end) => {
  if (end >= start) {
    return differenceInMinutes(end, start);
  }
  return Math.abs(differenceInMinutes(start, addDays(end, 1)));
};

const getTimeInMinutes = ({ startAm, endAm, startPm, endPm }) => {
  const resultAm = isValidDate(startAm) && isValidDate(endAm) ? addDates(startAm, endAm) : null;
  const resultPm = isValidDate(startPm) && isValidDate(endPm) ? addDates(startPm, endPm) : null;
  return resultAm + resultPm;
};

const formatForNightHours = (start, end) => {
  if (end < start) {
    return end + 1440 - start;
  }
  return end - start;
};

const areTotalHoursInvalid = (total, formValues) => {
  const minutes = pipe(
    pick(['startAm', 'endAm', 'startPm', 'endPm']),
    values,
    map((item) => (isValidDate(item) ? item.getHours() * 60 + item.getMinutes() : 0))
  )(formValues);
  const isOnePairFilled =
    (isValidDate(formValues.startAm) && isValidDate(formValues.endAm)) ||
    (isValidDate(formValues.startPm) && isValidDate(formValues.endPm));
  const isValid =
    valueToMinutes(total) === formatForNightHours(minutes[0], minutes[1]) + formatForNightHours(minutes[2], minutes[3]);
  return isValid || !isOnePairFilled ? false : 'invalidSchedule';
};

const getReasonCode = (reason, difference) => {
  if (difference > 0) {
    return 1001;
  }
  if (difference < 0 && prop('premium_code', reason) !== 1001) {
    return prop('premium_code', reason);
  }
  return null;
};

const EditDaySchedule = ({ onSubmit, data, appendablePremiums, formRef, classes }) => {
  const { dayName, date, start_am, start_pm, end_am, end_pm, total_hours, id, reason } = data;
  const { t, i18n } = useTranslation(['general', 'validation']);
  const [difference, setDifference] = useState(propOr(0, 'difference', data));
  const [fieldsAreDirty, setFieldsAreDirty] = useState(false);

  const makeDate = (ds) => {
    if (ds) {
      const [hrs, min] = ds.split(':');
      const newDate = new Date();
      newDate.setHours(hrs, min, 0, 0);
      return newDate;
    }
    return null;
  };
  const defaultValues = {
    startAm: makeDate(start_am),
    startPm: makeDate(start_pm),
    endAm: makeDate(end_am),
    endPm: makeDate(end_pm),
    isApproved: false,
    performedMinutes: makeDate(convertMinutesToTime(propOr(total_hours, 'performedMinutes', data)))
  };

  const { control, handleSubmit, errors, getValues, setValue, watch, register, triggerValidation } = useForm({
    mode: 'onBlur',
    defaultValues
  });

  const performedMinutesWatch = watch('performedMinutes');

  const setNewPerformedHours = useCallback((val) => setValue('performedMinutes', val), [setValue]);

  const areHoursInvalid = areTotalHoursInvalid(performedMinutesWatch, getValues());

  const performSubmit = (formData) => {
    onSubmit({
      ...formData,
      total_hours,
      difference,
      dayName,
      date,
      contractId: id,
      reason: appendablePremiums.find((pm) => pm.premium_code === formData.premiumCode)
    });
  };

  const validators = useMemo(() => validations(getValues, difference), [getValues, difference]);

  const hourValues = watch(['startAm', 'endAm', 'startPm', 'endPm']);

  const premiums = useMemo(
    () =>
      appendablePremiums.filter((item) =>
        difference > 0 ? item.premium_code === 1001 : item.premium_code !== 1001 && item.is_absence_justification === 1
      ),
    [appendablePremiums, difference]
  );

  useEffect(() => {
    register({ name: 'premiumCode' }, validators.reason);
  }, [register, validators]);

  const handlePerformedMinutesChange = (e) => {
    if (isNil(e[0]) || !isValidDate(e[0])) {
      triggerValidation('performedMinutes');
    } else {
      const newPerformedMinutes = valueToMinutes(e[0]);
      setDifference(newPerformedMinutes - total_hours);
    }
    return e[0];
  };

  const validateOnChange = (e, correspondingHour) => {
    setFieldsAreDirty(true);
    if (isNil(e[0]) && isNil(getValues(correspondingHour))) {
      setTimeout(() => triggerValidation(correspondingHour), 0);
    }
    return e[0];
  };

  useEffect(() => {
    if (fieldsAreDirty) {
      const newPerformedMinutes = getTimeInMinutes(hourValues);
      const performedDateValue = makeDate(convertMinutesToTime(newPerformedMinutes));
      setNewPerformedHours(performedDateValue);
      setDifference(newPerformedMinutes - total_hours);
      setFieldsAreDirty(false);
    }
  }, [fieldsAreDirty]);

  useEffect(() => {
    setValue('premiumCode', getReasonCode(reason, difference));
  }, [difference]);

  useEffect(() => {
    setDifference(valueToMinutes(performedMinutesWatch) - total_hours);
  }, [total_hours]);

  return (
    <>
      <Box mb={4}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1" color="textSecondary">
            {t('general:performed.hoursPerformed')}:
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={CustomTimePicker}
              className={classes.timeInputAlone}
              ampm={false}
              name="performedMinutes"
              format="HH:mm"
              mask="__:__"
              autoOk
              control={control}
              rules={validators.performedMinutes}
              variant="inline"
              // inputVariant="filled"
              value={watch('performedMinutes')}
              onChange={(e) => handlePerformedMinutesChange(e)}
              error={hasError('performedMinutes', errors)}
              helperText={t(getError(['performedMinutes', 'message'], errors))}
              fullWidth
              InputAdornmentProps={{ className: classes.adornment }}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
          <Typography variant="body1" color="textSecondary">
            {t('general:performed.contractHours')}:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {convertMinutesToTime(total_hours, 'h')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body1" color="textSecondary">
            {t('general:difference')}:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {difference < 0 ? '- ' : '+ '}
            {convertMinutesToTime(Math.abs(difference) || 0, 'h')}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body1" color="textSecondary">
        {t('general:performed.specifyScheduleTitle')}:
      </Typography>
      <Box mt={2}>
        <form onSubmit={handleSubmit(performSubmit)} ref={formRef} id="scheduleform">
          <Box display="flex" flexDirection="row" mb={5} justifyContent="space-between">
            <Box width={[1 / 2]} pr={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={CustomTimePicker}
                  ampm={false}
                  name="startAm"
                  label={t('general:begin')}
                  format="HH:mm"
                  mask="__:__"
                  autoOk
                  control={control}
                  rules={validators.startAm}
                  variant="inline"
                  inputVariant="filled"
                  value={watch('startAm')}
                  onChange={(e) => validateOnChange(e, 'startAm', 'endAm')}
                  error={hasError('startAm', errors)}
                  helperText={t(getError(['startAm', 'message'], errors))}
                  fullWidth
                  InputAdornmentProps={{ className: classes.adornment }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box width={[1 / 2]} pl={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={CustomTimePicker}
                  ampm={false}
                  name="endAm"
                  label={t('general:end')}
                  format="HH:mm"
                  mask="__:__"
                  autoOk
                  control={control}
                  rules={validators.endAm}
                  variant="inline"
                  inputVariant="filled"
                  value={watch('endAm')}
                  onChange={(e) => validateOnChange(e, 'endAm', 'startAm')}
                  error={hasError('endAm', errors)}
                  helperText={t(getError(['endAm', 'message'], errors))}
                  fullWidth
                  InputAdornmentProps={{ className: classes.adornment }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
          <Box display="flex" justifyContent="row" flexWrap="wrap" mb={5}>
            <Box width={[1 / 2]} pr={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={CustomTimePicker}
                  ampm={false}
                  name="startPm"
                  label={t('general:begin')}
                  format="HH:mm"
                  mask="__:__"
                  autoOk
                  control={control}
                  rules={validators.startPm}
                  variant="inline"
                  inputVariant="filled"
                  value={watch('startPm')}
                  onChange={(e) => validateOnChange(e, 'startPm', 'endPm')}
                  error={hasError('startPm', errors)}
                  helperText={t(getError(['startPm', 'message'], errors))}
                  fullWidth
                  InputAdornmentProps={{ className: classes.adornment }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box width={[1 / 2]} pl={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={CustomTimePicker}
                  ampm={false}
                  name="endPm"
                  label={t('general:end')}
                  format="HH:mm"
                  mask="__:__"
                  autoOk
                  control={control}
                  rules={validators.endPm}
                  variant="inline"
                  inputVariant="filled"
                  value={watch('endPm')}
                  onChange={(e) => validateOnChange(e, 'endPm', 'startPm')}
                  error={hasError('endPm', errors)}
                  helperText={t(getError(['endPm', 'message'], errors))}
                  fullWidth
                  InputAdornmentProps={{ className: classes.adornment }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            {!!areHoursInvalid && (
              <Box width="100%" pt={1} display="flex" justifyContent="center">
                <FormHelperText>{t(`validation:${areHoursInvalid}`)}</FormHelperText>
              </Box>
            )}
          </Box>
          <FormControl fullWidth variant="filled" disabled={difference === 0}>
            <InputLabel htmlFor="reason">{t('general:extend.reason')}</InputLabel>
            <Select
              variant="filled"
              id="reason"
              labelId="reason"
              fullWidth
              onChange={(e) => {
                setValue('premiumCode', e.target.value);
              }}
              onBlur={() => triggerValidation('premiumCode')}
              value={watch('premiumCode') || ''}>
              {premiums.map((premium) => (
                <MenuItem key={premium.premium_code} value={premium.premium_code}>
                  {premium[`description_${i18n.language}`]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={hasError('premiumCode', errors)}>
              {t(getError(['premiumCode', 'message'], errors))}
            </FormHelperText>
          </FormControl>
          <Box mt={5} mb={3}>
            <Typography variant="body2" color="textSecondary">
              {t('general:performed.confirmMessage1')}
              <Link component="a" href={i18n.language === 'fr' ? templateFR : templateNL} color="primary">
                {t('general:performed.derogationRegister')}
              </Link>
              {t('general:performed.confirmMessage2')}
            </Typography>
          </Box>
        </form>
      </Box>
    </>
  );
};

EditDaySchedule.propTypes = {
  onSubmit: func.isRequired,
  data: object.isRequired,
  appendablePremiums: array.isRequired,
  formRef: object,
  classes: object.isRequired
};

EditDaySchedule.defaultProps = {
  formRef: null
};

const mapState = ({ contracts: { appendablePremiums } }) => ({
  appendablePremiums
});

export default compose(connect(mapState, null), withStyles(scheduleFormStyle))(EditDaySchedule);
