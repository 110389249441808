import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';

import OrderStatus from './OrderStatus';

const orderTypesLabels = {
  2: 'general:contract.newContract',
  3: 'general:extend.newExtension',
  4: 'general:orders.newWorkerDemand'
};

const OrderInfo = ({ order }) => {
  const { t } = useTranslation(['general']);

  return (
    <>
      <OrderStatus
        statusId={order.order_status_id}
        openings={order.openings}
        acceptedCount={order.count_accepted_candidates}
        variant="subtitle2"
      />
      {orderTypesLabels[order.order_type_id] && (
        <Typography variant="subtitle2" color="secondary">
          {t(orderTypesLabels[order.order_type_id])}
        </Typography>
      )}
      <Typography variant="subtitle2" color="secondary">
        {t('general:orderDate', {
          date: format(new Date(order.created_at.replace(' ', 'T')), t('general:fullDateFormat'))
        })}
      </Typography>
      <Typography variant="subtitle2" color="secondary">
        {t('general:orders.number', { ref: order.ref })}
      </Typography>
    </>
  );
};

OrderInfo.propTypes = {
  order: object.isRequired
};

export default OrderInfo;
