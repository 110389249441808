class HttpError extends Error {
  constructor(code, ...parameters) {
    super(...parameters);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }
    this.code = code;
    this.errorKey = parameters && parameters[0]?.errorKey;
  }
}

export default HttpError;
