/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { any, propOr } from 'ramda';
import { formatScheduleListHours } from 'utils/date';
import { isValidDate } from 'ramda-adjunct';
import { eventGA } from 'utils/analytics';
import { renderOnCondition } from 'utils/component';
import { useTranslation } from 'react-i18next';

import { array, bool, func, number, object } from 'prop-types';
import { saveScheduleBetweenSteps } from 'utils/tempWorkers';
import { newContractStyles } from './NewContract.style';

const NewContractButtons = ({
  loadingParent,
  step,
  contractExists,
  mergedParentContract,
  hasScheduleTimeError,
  setShowModifyDialog,
  setIsPreconfirmed,
  setInvalidHours,
  data,
  isNewWorker,
  isEditing,
  setIsExtension,
  setContractExists,
  setStep,
  setSecondData,
  preSelectedWorkers,
  fetchPool,
  setDefaultSchedule,
  isOrdering,
  orderId,
  isLoading
}) => {
  const { t } = useTranslation(['general', 'pool']);
  const classes = newContractStyles();

  const [tooMuchPreSelected, setTooMuchPreSelected] = useState(false);

  const preSelectedWorkersNbr = useMemo(() => preSelectedWorkers.length, [preSelectedWorkers]);

  const checkValidHours = useCallback((values) => {
    const formattedValues = formatScheduleListHours(values);
    //  check if less than total_hours 50 * 60  = 3000 ?
    const totalWeekHours = formattedValues.reduce((acc, curr) => {
      const totalHours = curr.total_hours;
      return acc + totalHours;
    }, 0);
    // Check if any total_hours is over 11 * 60
    const anyOver11Hours = any((d) => d.total_hours > 11 * 60, formattedValues);
    const anyOver9Hours = any((d) => d.total_hours > 9 * 60 && d.total_hours <= 11 * 60, formattedValues);
    return {
      totalOver50: totalWeekHours > 50 * 60,
      totalOver40: totalWeekHours > 40 * 60 && totalWeekHours <= 50 * 60,
      anyOver11Hours,
      anyOver9Hours
    };
  }, []);

  const switchConfirmation = () => {
    const invalidHoursObj = checkValidHours(data.scheduleDays);
    setInvalidHours(invalidHoursObj);
    if (
      invalidHoursObj.totalOver50 ||
      invalidHoursObj.totalOver40 ||
      invalidHoursObj.anyOver11Hours ||
      invalidHoursObj.anyOver9Hours
    ) {
      // show message to say that it can't be sent to Matchbox, allow contract modification.
      return setShowModifyDialog(true);
    }
    return setIsPreconfirmed(true);
  };

  const goToPool = async () => {
    if (orderId) {
      return setStep(5);
    }
    setDefaultSchedule(saveScheduleBetweenSteps(data));
    const resultPool = await fetchPool();
    if (resultPool.length) {
      return setStep(4);
    }
    return setStep(5);
  };

  const skipPool = () => {
    setStep(5);
  };

  const addSelected = () => {
    if (preSelectedWorkers.length > data?.vacancies) {
      setTooMuchPreSelected(true);
      return;
    }
    setStep(5);
  };

  return (
    <Box display="flex" justifyContent="flex-end" className={classes.box} py={4}>
      <Dialog
        open={tooMuchPreSelected}
        onClose={() => setTooMuchPreSelected(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description">
        <DialogTitle disableTypography id="confirm-dialog-title">
          <Typography variant="h6" component="h2">
            {t('general:pool.selectedWorkers.title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="textSecondary" variant="body1">
              {t('general:pool.recap.tooMuchSelected.title')}
            </Typography>
            <Box mt={2}>
              <Typography color="textSecondary" variant="body1">
                {t('general:pool.recap.tooMuchSelected.subtitle')}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTooMuchPreSelected(false)} color="primary">
            {t('general:close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="xl" className={classes.container}>
        {renderOnCondition(
          step === 1,
          <Button
            className={classes.button}
            type="submit"
            form="newcontract"
            data-testid="firstButton"
            name="confirm"
            variant="contained"
            color="primary"
            size="medium"
            disabled={loadingParent}>
            {t('general:next')}
          </Button>
        )}
        {renderOnCondition(
          step === 2 && contractExists,
          <>
            <Button
              className={classes.button}
              onClick={(event) => {
                eventGA('existing_contract_new_order');
                event.preventDefault();
                setIsExtension(false);
                setContractExists(false);
              }}
              id="continueContract"
              name="continueContract"
              color="primary"
              type="button"
              size="medium">
              {t('general:contract.newDemand')}
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                eventGA('existing_contract_extension');
                setIsExtension(true);
                setStep(3);
                setContractExists(false);
                setSecondData({
                  status: parseInt(propOr(null, 'candidate_status', mergedParentContract), 10),
                  contractType: propOr(null, 'type_id', mergedParentContract),
                  reason: propOr(null, 'reason_id', mergedParentContract)
                });
              }}
              type="button"
              id="extendExisting"
              name="extendExisting"
              variant="contained"
              color="primary"
              size="medium">
              {t('general:contract.extendContract')}
            </Button>
          </>
        )}

        {renderOnCondition(
          step === 2 && !contractExists,
          <Button
            className={classes.button}
            id="next"
            name="confirm"
            form="secondcontract"
            type="submit"
            variant="contained"
            color="primary"
            size="medium">
            {t('general:next')}
          </Button>
        )}
        {renderOnCondition(
          step === 3,
          <Button
            className={classes.button}
            onClick={() => (isNewWorker ? goToPool() : switchConfirmation())}
            disabled={
              hasScheduleTimeError ||
              !!(data.scheduleDays || []).find((day) => !isValidDate(day.contractHour)) ||
              isLoading
            }
            name="confirm"
            variant="contained"
            color="primary"
            size="medium">
            {renderOnCondition(!isNewWorker, t(`general:contract.${isEditing ? 'editContract' : 'orderContract'}`))}
            {renderOnCondition(isNewWorker, t('general:next'))}
          </Button>
        )}
        {renderOnCondition(
          step === 4,
          <>
            <Button
              className={classes.button}
              onClick={() => skipPool()}
              name="byPass"
              size="medium"
              type="button"
              px={4}>
              {t('general:pool.bypass')}
            </Button>
            <Button
              className={classes.button}
              onClick={() => addSelected()}
              name="byPass"
              size="medium"
              disabled={!preSelectedWorkers.length}
              variant="contained"
              color="primary">
              {preSelectedWorkersNbr <= 1
                ? t('general:pool.add.single')
                : t('general:pool.add.multiple', {
                    nbr: preSelectedWorkers.length
                  })}
            </Button>
          </>
        )}
        {renderOnCondition(
          step === 5,

          <Button
            className={classes.button}
            onClick={() => switchConfirmation()}
            name="submitPool"
            size="medium"
            variant="contained"
            disabled={isOrdering}
            color="primary">
            {renderOnCondition(isNewWorker, t('general:order'))}
          </Button>
        )}
      </Container>
    </Box>
  );
};

NewContractButtons.propTypes = {
  loadingParent: bool.isRequired,
  step: number.isRequired,
  mergedParentContract: object,
  contractExists: bool.isRequired,
  hasScheduleTimeError: bool.isRequired,
  setShowModifyDialog: func.isRequired,
  setIsPreconfirmed: func.isRequired,
  setInvalidHours: func.isRequired,
  data: object.isRequired,
  isNewWorker: bool.isRequired,
  isEditing: bool.isRequired,
  setIsExtension: func.isRequired,
  setContractExists: func.isRequired,
  setStep: func.isRequired,
  setSecondData: func.isRequired,
  preSelectedWorkers: array.isRequired,
  fetchPool: func,
  setDefaultSchedule: func,
  isOrdering: bool.isRequired,
  isLoading: bool.isRequired,
  orderId: number
};

NewContractButtons.defaultProps = {
  mergedParentContract: null,
  fetchPool: () => null,
  setDefaultSchedule: () => null,
  orderId: null
};

export default NewContractButtons;
