/* istanbul ignore file */
export default {
  salaryAmount: {
    required: 'optionRequired',
    validate: {
      minAmount: (value) => {
        let toNum = value;
        if (typeof toNum === 'string') {
          toNum = /^[0-9,.]*$/.test(value) ? parseFloat(value.replace(',', '.')) : -1;
        }
        return (toNum >= 0 && toNum < 100000) || 'mustBetween';
      }
    }
  },
  reason: { required: 'optionRequired' }
};
