import { complement, compose, filter, isNil, join, map } from 'ramda';
import { api, secureCall } from 'utils/api';
import { cleanObj } from 'utils/object';

const buildWorkerListParams = ({ workerType, name, since, page }) =>
  compose(
    join('&'),
    map(({ key, value }) => `${key}=${value}`),
    filter(({ value }) => complement(isNil)(value))
  )([
    { key: 'worker_type', value: workerType || null },
    { key: 'full_name_and_function', value: name || null },
    { key: 'contract_since', value: since || null },
    { key: 'page', value: page || 1 },
    { key: 'expand', value: 'lastContract' }
  ]);

export const fetchWorkers = secureCall(
  async ({ order_by_field, order_by_direction, cost_centers, ...search }) => {
    const url = 'candidate/complete-index';
    const params = buildWorkerListParams(search);
    return api.get(
      `${url}?${params}`,
      cleanObj({
        ...(cost_centers.length && { 'cost_center_ids[]': cost_centers.map((c) => c.id) }),
        order_by_direction,
        order_by_field
      })
    );
  },
  { message: 'myWorkers:fetchWorkersFailure' }
);
