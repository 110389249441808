import React from 'react';
import { number } from 'prop-types';

const getPositions = (weekNumber) => (String(weekNumber).length === 1 ? 9.593 : 7.186);

const CalendarIcon = ({ weekNumber }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <path
        id="prefix__a"
        d="M19.34 9.479c.168 0 .304.135.304.303v10.54c0 .167-.136.303-.304.303H4.66c-.168 0-.304-.136-.304-.303V9.782c0-.168.136-.303.304-.303zM6.243 4.733v.732c0 .776.444 1.606 1.689 1.606 1.244 0 1.687-.83 1.687-1.606v-.732h4.782v.732c0 .776.443 1.606 1.687 1.606 1.245 0 1.687-.83 1.687-1.606v-.732h1.565c.168 0 .304.135.304.303v3.086c0 .168-.136.304-.304.304H4.66c-.168 0-.304-.136-.304-.304V5.036c0-.168.136-.303.304-.303h1.583zm1.689-1.358c.503 0 .707.181.707.626v1.464c0 .444-.204.625-.707.625-.504 0-.708-.18-.708-.625V4.001c0-.445.204-.626.708-.626zm8.156 0c.503 0 .707.181.707.626v1.464c0 .444-.204.625-.707.625-.504 0-.707-.18-.707-.625V4.001c0-.445.203-.626.707-.626z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <g fill="#000" fillOpacity=".6" mask="url(#prefix__b)">
        <path d="M0 0H24V24H0z" />
      </g>
      <text
        fill="#FFF"
        fontFamily="Roboto-Medium, Roboto"
        fontSize="8"
        fontWeight="400"
        letterSpacing=".267"
        mask="url(#prefix__b)">
        <tspan x={getPositions(weekNumber)} y="18">
          {weekNumber}
        </tspan>
      </text>
    </g>
  </svg>
);

CalendarIcon.propTypes = {
  weekNumber: number.isRequired
};

export default CalendarIcon;
