import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Card, CardContent, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { pathOr, prop, isEmpty, path } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'react-uuid';
import { faList, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import history from 'utils/history';

import { days, formatHour, getWeekDays } from 'utils/date';
import { useHeader } from 'hooks';

import Prestations from 'components/Prestations';
import WeekSelector from 'components/WeekSelector/WeekSelector';
import HourCell from 'components/TempWorkerRow/HourCell';

import TitleBar from 'components/TempWorkersList/common/TitleBar';
import RequestMenu from 'components/TempWorkersList/common/RequestMenu';
import { costCenterString } from 'utils/tempWorkers';
import { renderOnCondition } from 'utils/component';
import { capitalized } from 'utils/text';
import PlanningCandidateStatus from '../PlanningCandidateStatus';

import styles from './Planning.mobile.styles';

const getHours = (day, data) => {
  const hours = pathOr({}, ['scheduleDays', days[day?.getDay()]], data);
  return hours;
};

const scheduleLine = (time, startKey, endKey) => {
  if (!prop(startKey, time) && !prop(endKey, time)) return null;
  return (
    <Typography variant="caption" component="p" color="secondary">
      {`${formatHour(time[startKey])} - ${formatHour(time[endKey])}`}
    </Typography>
  );
};

const handleGoToOrderDetail = ({ e, data, day }) => {
  e.stopPropagation();
  const orderId = path(['mappingOrderIdByDay', day], data);
  if (!orderId) return;
  history.push({
    pathname: `/orders/${orderId}`,
    state: { orderId, fromPlanning: true }
  });
};

const PlanningMobileTableByFunction = ({ classes, weekDays, workerInfos }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);

  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          {weekDays.map((day) => (
            <td key={uuid()}>
              <Typography variant="body2">
                {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
              </Typography>
            </td>
          ))}
        </tr>
        <tr>
          {weekDays.map((day) => {
            const contractHours = pathOr({}, ['scheduleDays', days[day.getDay()]], workerInfos);
            return (
              <td key={uuid()}>
                <Box onClick={(e) => handleGoToOrderDetail({ e, day: days[day.getDay()], data: workerInfos })}>
                  <Box>
                    <HourCell hours={getHours(day, workerInfos)} isEdited={false} isPlanning />
                  </Box>
                  <Box>
                    {renderOnCondition(
                      !isEmpty(contractHours),
                      <Box>
                        {scheduleLine(contractHours, 'start_am', 'end_am')}
                        {scheduleLine(contractHours, 'start_pm', 'end_pm')}
                      </Box>
                    )}
                  </Box>
                </Box>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};
PlanningMobileTableByFunction.propTypes = {
  classes: object.isRequired,
  weekDays: array.isRequired,
  workerInfos: object.isRequired
};

const PlanningMobileTableByWorker = ({ classes, weekDays, data }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);

  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          {weekDays.map((day) => (
            <td key={uuid()}>
              <Typography variant="body2">
                {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
              </Typography>
            </td>
          ))}
        </tr>
        <tr>
          {weekDays.map((day) => {
            return (
              <td key={uuid()}>
                {data?.orderPlannings?.map((worker) => {
                  const contractHours = pathOr({}, ['scheduleDays', days[day.getDay()]], worker);
                  return (
                    <Box
                      key={uuid()}
                      onClick={(e) => handleGoToOrderDetail({ e, day: days[day.getDay()], data: worker })}>
                      {renderOnCondition(
                        !isEmpty(contractHours),
                        <>
                          <Box>{worker?.candidate?.fullname || capitalized(t('general:undefined'))}</Box>
                          <Box>
                            {scheduleLine(contractHours, 'start_am', 'end_am')}
                            {scheduleLine(contractHours, 'start_pm', 'end_pm')}
                          </Box>
                        </>
                      )}
                    </Box>
                  );
                })}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};
PlanningMobileTableByWorker.propTypes = {
  classes: object.isRequired,
  weekDays: array.isRequired,
  data: object.isRequired
};

const PlanningMobile = ({ classes, list, date, setDate, isLoading, setViewByWorker, viewByWorker }) => {
  const { t, language } = useTranslation(['tempWorkers', 'helpers']);
  const { setComponents, renderSearch, setTitle } = useHeader();
  const titleSection = useMemo(() => (viewByWorker ? 'prestations' : 'postes'), [viewByWorker]);

  const weekDays = getWeekDays(date);

  useEffect(() => {
    setTitle('general:menu.planning');
    setComponents([renderSearch, () => <Prestations key="prestations" />]);
  }, [renderSearch, setComponents, setTitle]);

  const buildSubtitle = (element) => {
    return `${element.length} ${
      element.length > 1 ? t(`general:planning.${titleSection}.plural`) : t(`general:planning.${titleSection}.singular`)
    }`;
  };

  return (
    <>
      <Box width="100%" position="relative">
        <Box
          display="flex"
          height="40px"
          className={classes.header}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <WeekSelector dateChange={setDate} isMobile />
          <Box pr={2}>
            <ButtonGroup color="secondary" size="small">
              <Tooltip title={t('general:planning.help.showByWorker')}>
                <Button
                  onClick={() => {
                    setViewByWorker(true);
                  }}
                  className={viewByWorker ? classes.activeButton : ''}>
                  <FontAwesomeIcon icon={faUserFriends} />
                </Button>
              </Tooltip>
              <Tooltip title={t('general:planning.help.showByFunction')}>
                <Button
                  onClick={() => {
                    setViewByWorker(false);
                  }}
                  className={!viewByWorker ? classes.activeButton : ''}>
                  <FontAwesomeIcon icon={faList} />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
        <Box pt="40px" pb={18}>
          {isLoading && <LinearProgress />}
          {!!prop('length', list) &&
            list.map((listElement) => {
              const dataInfos = pathOr({}, [0, 'costCenter'], listElement);
              const sectionTitle = costCenterString(dataInfos, dataInfos);

              return (
                <Box key={uuid()}>
                  <TitleBar title={sectionTitle} subtitle={buildSubtitle(listElement)} />
                  <Box pt={4} overflow="hidden">
                    {listElement?.map((workerInfos) => {
                      return (
                        <Card className={classes.card} variant="outlined" key={uuid()}>
                          <CardContent>
                            {viewByWorker && (
                              <>
                                <Typography variant="h6" component="h6">
                                  {workerInfos.candidate?.fullname || capitalized(t('general:undefined'))}
                                </Typography>
                                <PlanningCandidateStatus statusLabel={workerInfos.planningStatus} isColored />
                                <PlanningMobileTableByFunction
                                  classes={classes}
                                  weekDays={weekDays}
                                  workerInfos={workerInfos}
                                />
                              </>
                            )}
                            {!viewByWorker && (
                              <>
                                <Typography variant="h6" component="h6">
                                  {workerInfos.mainJobFunction
                                    ? workerInfos.mainJobFunction[`label_${language || 'fr'}`]
                                    : null}
                                </Typography>
                                <PlanningMobileTableByWorker classes={classes} weekDays={weekDays} data={workerInfos} />
                              </>
                            )}
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
      <RequestMenu isMobile />
    </>
  );
};

PlanningMobile.propTypes = {
  classes: object.isRequired,
  list: array,
  date: PropTypes.instanceOf(Date),
  setDate: func,
  isLoading: bool,
  viewByWorker: bool.isRequired,
  setViewByWorker: func.isRequired
};

PlanningMobile.defaultProps = {
  date: new Date(),
  setDate: () => null,
  isLoading: false,
  list: []
};

export default withStyles(styles)(withRouter(PlanningMobile));
