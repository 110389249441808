import { pathOr } from 'ramda';

export class UserEntity {
  constructor(user) {
    if (user) {
      Object.keys(user).forEach((key) => {
        this[key] = user[key];
      });
    }
  }

  getFullName() {
    if (this.first_name && this.last_name) {
      return `${this.first_name} ${this.last_name}`;
    }
    return '';
  }

  isImpersonated() {
    return !!this.impersonator;
  }

  getImpersonatorName() {
    return `${pathOr('', ['impersonator', 'first_name'], this)} ${pathOr('', ['impersonator', 'last_name'], this)}`;
  }
}
