import { toast } from 'react-toastify';
import { equals, prop, uniqBy, path } from 'ramda';

import i18n from 'translate/i18n';
import { getDate } from 'utils/date';
import store from '../store';

import * as service from './service';
import { handleOrdersByWorkerList, handleOrdersList } from './utils';

export const orders = {
  state: {
    list: [],
    search: null,
    pool: [],
    planningList: [],
    order: null
  },
  reducers: {
    gotList: (state, list, stack) => {
      return { ...state, list: uniqBy((elt) => elt.id, [...(stack ? state.list : []), ...list]) };
    },
    gotPlanningList: (state, list) => {
      return { ...state, planningList: list };
    },
    setSearch: (state, search) => ({
      ...state,
      search
    }),
    gotPool: (state, pool) => {
      return { ...state, pool };
    },
    gotOrder: (state, order) => ({
      ...state,
      order
    })
  },
  effects: (dispatch) => ({
    async getOrders({ stack, ...params }) {
      const { page, ...search } = params;
      this.setSearch(search);
      if (page === 1) this.gotList([]);
      const list = await service.getOrders({ ...params, date: getDate(params.months) });
      if (prop('length', list) && equals(search, path(['orders', 'search'], store.getState()))) {
        const newList = handleOrdersList(list);
        this.gotList(newList, stack);
        return newList.length === 50;
      }
      return false;
    },
    async createOrder(payload) {
      const result = await service.createOrder(payload);
      if (result) {
        dispatch.events.sendMessage(i18n.t('orders.newWorkerDemandSuccess'));
      } else {
        toast.error(i18n.t('errors:failedSave'));
      }
      return result;
    },
    async updateOrder(payload) {
      const result = await service.updateOrder(payload);
      if (result) {
        dispatch.events.sendMessage(i18n.t('modificationSuccess'));
      } else {
        toast.error(i18n.t('errors:failedSave'));
      }
      return result;
    },
    async cancel(id) {
      const success = await service.cancel(id);
      if (success) dispatch.events.sendMessage(i18n.t('general:orders.canceled'));
      return success;
    },
    async getCandidatesPool(payload) {
      const result = await service.getCandidatesPool(payload);
      this.gotPool(result || []);
      return result || [];
    },
    async getListByWorker({ ...params }) {
      const { page, ...search } = params;
      this.setSearch(search);
      if (page === 1) this.gotPlanningList([]);
      const result = await service.getListByWorker({ ...params, date: getDate(params?.months) });
      if (equals(search, path(['orders', 'search'], store.getState()))) {
        const newList = handleOrdersByWorkerList(result);
        this.gotPlanningList(newList);
        return !!result.length;
      }
      return false;
    },
    async getListByJobFunction({ stack, ...params }) {
      const { page, ...search } = params;
      this.setSearch(search);
      if (page === 1) this.gotPlanningList([]);
      const result = (await service.getListByJobFunction({ ...params, date: getDate(params?.months) })) || [];
      if (equals(search, path(['orders', 'search'], store.getState()))) {
        const newList = handleOrdersByWorkerList(result);
        this.gotPlanningList(newList, stack);
        return !!result.length;
      }
      return false;
    },
    async getOrderById(id) {
      const result = await service.getOrderById(id);
      if (result) this.gotOrder(result);
      return result;
    },
    exportPlanningXLS: service.exportPlanningXLS
  })
};
