import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import { Box, Container } from '@material-ui/core';
import { array, bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import { startOfWeek, endOfWeek } from 'date-fns';
import { useHeader } from 'hooks';

import { filterPlanningSearch } from 'utils/planning';
import { formatDate } from 'utils/date';
import history from 'utils/history';

import { useTranslation } from 'react-i18next';
import PlanningDesktop from './desktop/Planning.desktop';
import PlanningMobile from './mobile/Planning.mobile';

const Planning = ({ isMobile, planningList, location, listLoading, fetchListByWorker, fetchListByJobFunction }) => {
  const { search } = useHeader();
  const { language } = useTranslation(['tempWorkers', 'helpers']);

  const [localList, setLocalList] = useState(planningList || []);
  const [dayFilter, setDayFilter] = useState([]);
  const [filters, setFilters] = useState(search);
  const [viewByWorker, setViewByWorker] = useState(true);

  const [exportParams, setExportParams] = useState(null);

  const dates = useMemo(
    () => ({
      from: startOfWeek(new Date(path(['state', 'date'], location) || new Date()), { weekStartsOn: 1 }),
      to: endOfWeek(new Date(path(['state', 'date'], location) || new Date()), { weekStartsOn: 1 })
    }),
    [location]
  );

  useEffect(() => {
    const params = {
      start: formatDate(dates.from, false),
      end: formatDate(dates.to, false),
      date: formatDate(dates.from, false),
      page: 0,
      statuses: [],
      cost_center_ids: filters?.cost_centers?.map((c) => c?.id),
      search
    };
    setExportParams(params);
    if (viewByWorker) {
      fetchListByWorker(params);
    } else {
      fetchListByJobFunction(params);
    }
  }, [fetchListByWorker, dates, viewByWorker, search, filters]);

  useEffect(() => {
    if (!search && !dayFilter.length) setLocalList(planningList);
    if (search || dayFilter.length) {
      const filtered = filterPlanningSearch({ viewByWorker, planningList, search, dayFilter, language });
      setLocalList(filtered);
    }
  }, [planningList, search, dayFilter]);

  return (
    <>
      {isMobile ? (
        <Box width="100%">
          <PlanningMobile
            list={localList}
            date={dates.from}
            setDate={(date) => history.replace(location.pathname, { date: date.from })}
            isLoading={listLoading}
            filters={filters}
            setFilters={setFilters}
            viewByWorker={viewByWorker}
            setViewByWorker={setViewByWorker}
          />
        </Box>
      ) : (
        <Container maxWidth="xl">
          <Box>
            <PlanningDesktop
              list={localList}
              date={dates.from}
              setDate={(date) => history.replace(location.pathname, { date: date.from })}
              isLoading={listLoading}
              dayFilter={dayFilter}
              dayFilterChange={setDayFilter}
              sendAnalytics
              viewByWorker={viewByWorker}
              setViewByWorker={setViewByWorker}
              filters={filters}
              setFilters={setFilters}
              exportParams={exportParams}
            />
          </Box>
        </Container>
      )}
    </>
  );
};

Planning.propTypes = {
  planningList: array.isRequired,
  fetchListByWorker: func.isRequired,
  fetchListByJobFunction: func.isRequired,
  listLoading: bool.isRequired,
  isMobile: bool.isRequired,
  location: object.isRequired
};

const mapState = ({ orders: { planningList }, loading, user: { isOnMobile } }) => ({
  planningList,
  listLoading: pathOr(false, ['effects', 'orders', 'getListByWorker'], loading),
  isMobile: isOnMobile
});

const mapDispatch = ({ orders: { getListByWorker, getListByJobFunction } }) => ({
  fetchListByWorker: getListByWorker,
  fetchListByJobFunction: getListByJobFunction
});

export default connect(mapState, mapDispatch)(withRouter(Planning));
