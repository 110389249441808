import { compose, includes, values, any, reduce, flatten, pathOr } from 'ramda';
import { useSelector } from 'react-redux';

import { userRoles } from './userRoles';

const hasAnyAllowedAccess = compose(
  any((action) => !!action),
  values
);

const checkAccess = (actions) => any((permission) => includes(permission, actions));

export const useAllowedActions = (actions, anyPass = false) => {
  const permissions = useSelector(pathOr([], ['user', 'currentCustomer', 'permissions']));
  const askedActions = flatten([actions]);

  if (actions === userRoles.any) return true;

  const allowedActions = reduce(
    (acc, { name, value }) => ({
      ...acc,
      [name]: checkAccess(value)(permissions)
    }),
    {},
    askedActions
  );

  return anyPass ? hasAnyAllowedAccess(allowedActions) : allowedActions;
};
