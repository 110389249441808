/* eslint-disable complexity */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func, bool, string } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Typography
} from '@material-ui/core';
import { Error, Visibility, VisibilityOff } from '@material-ui/icons';
import { path } from 'ramda';
import { Link as RouterLink } from 'react-router-dom';

import { renderOnCondition } from 'utils/component';

import { getError } from 'components/helpers';

import validations from './User.form.validators';

const hasError = (fieldName, errors) => !!path([fieldName, 'message'], errors);
const getFieldType = (show) => (show ? 'text' : 'password');
const getButtonLabel = (type) => {
  switch (type) {
    case 'login':
      return 'auth:login';
    case 'updatePassword':
      return 'auth:changePassword';
    default:
      return 'general:confirm';
  }
};

const UserShortForm = ({ onSubmit, submitting, type }) => {
  const { t, i18n } = useTranslation(['general', 'auth', 'user']);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = async (data) => {
    if (type === 'forgotPassword') {
      const captcha = await executeRecaptcha('forgot_password');
      return onSubmit({ ...data, captcha });
    }
    return onSubmit(data);
  };

  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur'
  });

  const validators = validations({ passwordRequired: true, getValues, passwordValidation: type === 'resetPassword' });

  return (
    <form onSubmit={handleSubmit(submit)}>
      {renderOnCondition(
        ['forgotPassword', 'login'].includes(type),
        <FormControl fullWidth variant="filled" error={hasError('email', errors)}>
          <InputLabel htmlFor="emailInput">{t('user:email')}</InputLabel>
          <FilledInput
            inputRef={register(validators.email)}
            name="email"
            id="emailInput"
            type="text"
            fullWidth
            endAdornment={
              hasError('email', errors) ? (
                <InputAdornment position="end">
                  <IconButton aria-label={t('general:error')}>
                    <Error color="error" />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
          />
          <FormHelperText error>{t(getError(['email', 'message'], errors))}</FormHelperText>
        </FormControl>
      )}
      {renderOnCondition(
        ['resetPassword', 'updatePassword', 'login'].includes(type),
        <FormControl fullWidth variant="filled" margin="normal" error={hasError('password', errors)}>
          <InputLabel htmlFor="passInput">
            {t(`user:${type === 'updatePassword' ? 'newPassword' : 'password'}`)}
          </InputLabel>
          <FilledInput
            inputRef={register(validators.password)}
            name="password"
            id="passInput"
            type={getFieldType(showPassword)}
            fullWidth
            endAdornment={
              hasError('password', errors) ? (
                <InputAdornment position="end">
                  <IconButton aria-label={t('general:error')}>
                    <Error color="error" />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('helpers:passwordVisibility')}
                    onClick={() => setShowPassword((current) => !current)}
                    onMouseDown={(e) => e.preventDefault()}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          <FormHelperText error>{t(getError(['password', 'message'], errors))}</FormHelperText>
        </FormControl>
      )}
      {renderOnCondition(
        ['login'].includes(type),
        <Box>
          <Link component={RouterLink} to="/password/forgot" color="primary">
            <Typography variant="body2">{t('auth:forgotPassword')}</Typography>
          </Link>
        </Box>
      )}
      {renderOnCondition(
        ['resetPassword', 'updatePassword'].includes(type),
        <FormControl fullWidth variant="filled" margin="normal" error={hasError('passwordConfirmation', errors)}>
          <InputLabel htmlFor="passConfInput">{t('user:passwordConfirmation')}</InputLabel>
          <FilledInput
            inputRef={register(validators.passwordConfirmation)}
            name="passwordConfirmation"
            id="passConfInput"
            type={getFieldType(showPasswordConfirmation)}
            fullWidth
            endAdornment={
              hasError('password', errors) ? (
                <InputAdornment position="end">
                  <IconButton aria-label={t('general:error')}>
                    <Error color="error" />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('helpers:passwordVisibility')}
                    onClick={() => setShowPasswordConfirmation((current) => !current)}
                    onMouseDown={(e) => e.preventDefault()}>
                    {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          <FormHelperText error>{t(getError(['passwordConfirmation', 'message'], errors))}</FormHelperText>
        </FormControl>
      )}
      <Box mt={8}>
        <Button
          name="confirm"
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          disabled={submitting}
          fullWidth>
          {t(getButtonLabel(type))}
        </Button>
      </Box>
      {renderOnCondition(
        ['login'].includes(type),
        <Box mt={3}>
          <Typography component="span" variant="body2" color="secondary">
            {t('auth:noAccount1')}
          </Typography>
          &nbsp;&nbsp;
          <Link
            component="a"
            href={`https://www.daoust.be/${i18n.language}/daoust-connect`}
            color="primary"
            target="_blank"
            rel="noopener noreferrer">
            <Typography component="span" variant="body2">
              {t('auth:noAccount2')}
            </Typography>
          </Link>
        </Box>
      )}
    </form>
  );
};

UserShortForm.propTypes = {
  onSubmit: func.isRequired,
  submitting: bool,
  type: string.isRequired
};

UserShortForm.defaultProps = {
  submitting: false
};

export default UserShortForm;
