import NewContract from 'components/NewContract/NewContract';
import { bool, func, number, object, oneOf } from 'prop-types';
import { pathOr, prop } from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getContractType } from 'utils/contract';
import { formatScheduleListHours, getContractDate, toIsoDateString } from 'utils/date';

const NewDemandHandler = ({ createDemand, updateDemand, isEditing, orderId, actionType, ...props }) => {
  const [isPreconfirmed, setIsPreconfirmed] = useState(false);

  const handleNewWorkerContract = async ({
    data,
    secondData,
    mergedParentContract,
    isExtension,
    range,
    description,
    candidateIds,
    handleAfterCreateUpdateOrder,
    onClose
  }) => {
    let contract;
    const payload = {
      description,
      hourlyRate: pathOr(null, ['salaryAmount'], secondData),
      costCenterId: pathOr(null, ['costCenter', 'id'], data),
      jobFunctionId: data.jobFunction.id,
      start: toIsoDateString(getContractDate(range.from)),
      end: toIsoDateString(getContractDate(range.to)),
      reasonId: secondData.reason,
      reference: pathOr(null, ['reference'], data),
      isStudent: [5, 6].includes(parseInt(secondData.status, 10)) ? 1 : 0,
      isStudentContingent: [9, 10].includes(parseInt(secondData.status, 10)) ? 1 : 0,
      scheduleDays: formatScheduleListHours(data.scheduleDays),
      vacancies: pathOr(null, ['vacancies'], data),
      orderId,
      isEditing,
      candidateIds,
      ...getContractType(secondData)
    };
    if ((orderId && actionType === 'update') || (prop('id', mergedParentContract) && isExtension)) {
      contract = await updateDemand(payload);
    } else {
      contract = await createDemand(payload);
    }

    onClose();
    handleAfterCreateUpdateOrder(contract);
  };

  return (
    <NewContract
      isNewWorker
      isPreconfirmed={isPreconfirmed}
      setIsPreconfirmed={setIsPreconfirmed}
      handleNewWorkerContract={handleNewWorkerContract}
      isEditing={isEditing}
      orderId={orderId}
      actionType={actionType}
      {...props}
    />
  );
};

NewDemandHandler.propTypes = {
  history: object.isRequired,
  updateDemand: func.isRequired,
  createDemand: func.isRequired,
  orderId: number,
  isEditing: bool,
  actionType: oneOf(['create', 'update', 'orderAgain', 'default']).isRequired
};

NewDemandHandler.defaultProps = {
  isEditing: false,
  orderId: null
};

const mapDispatch = (dispatch) => ({
  createDemand: (data) => dispatch.orders.createOrder(data),
  updateDemand: (data) => dispatch.orders.updateOrder(data)
});

export default connect(null, mapDispatch)(withRouter(NewDemandHandler));
