import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { compose, propOr } from 'ramda';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Link
} from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { toggleAnalytics } from 'utils/analytics';
import { handleCookieChoice, getCookieConsentProperty, cookieLinks } from 'utils/cookieConsent';

import { Translate } from 'components';

import Logo from 'assets/daoust-connect.svg';
import Headline from 'assets/daoust-wttf.svg';
import { Img } from '../Img/Img';

const CookieConsent = ({ isOpen, setIsOpen, classes }) => {
  const { t, i18n } = useTranslation('cookieConsent');
  const [personalizeCookie, setPersonalizeCookie] = useState(false);
  const [consent, setConsent] = useState(getCookieConsentProperty());
  const [functionnal, setFunctionnal] = useState(false);
  const [analytical, setAnalytical] = useState(false);

  const handleModalClose = () => {
    if (getCookieConsentProperty('mandatory')) {
      setIsOpen(false);
      setPersonalizeCookie(false);
    }
  };

  const handleCookieSelection = useCallback(
    (val) => {
      const obj = {
        mandatory: true,
        functionnal: typeof val === 'boolean' ? val : functionnal,
        analytical: typeof val === 'boolean' ? val : analytical
      };
      handleCookieChoice(obj);
      toggleAnalytics(obj.analytical);
      setConsent(obj);
      handleModalClose();
    },
    [functionnal, analytical]
  );

  useEffect(() => {
    setFunctionnal(propOr(false, 'functionnal', consent));
    setAnalytical(propOr(false, 'analytical', consent));
  }, [consent]);

  const cookieActions = useMemo(
    () =>
      personalizeCookie ? (
        <Button color="primary" variant="contained" onClick={() => handleCookieSelection()}>
          {t('cookieConsent:accept')}
        </Button>
      ) : (
        <Box className={classes.actions}>
          <Button onClick={() => handleCookieSelection(false)} color="primary" variant="outlined">
            {t('cookieConsent:refuseAll')}
          </Button>
          <Button onClick={() => setPersonalizeCookie(true)} color="primary" variant="outlined">
            {t('cookieConsent:customize')}
          </Button>
          <Button onClick={() => handleCookieSelection(true)} color="primary" variant="contained">
            {t('cookieConsent:acceptAll')}
          </Button>
        </Box>
      ),
    [personalizeCookie, handleCookieSelection, i18n.language]
  );

  return (
    <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth="md">
      <Box mt={4} display="flex" alignItems="center" justifyContent="flex-end" px={4}>
        <Box>
          <Translate large isSelect />
        </Box>
      </Box>
      <DialogTitle disableTypography id="confirm-dialog-title">
        <Box display="flex" flexDirection="column" alignItems="center" mb={11}>
          <Box mb={6}>
            <Img src={Logo} alt="" />
          </Box>
          <Box>
            <Img src={Headline} alt="" />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" component="h2">
          {t(personalizeCookie ? 'cookieConsent:customizeCookies' : 'cookieConsent:title')}
        </Typography>
        <Box mt={4} mb={6}>
          {personalizeCookie ? (
            <Box display="flex" flexDirection="column">
              <FormControlLabel control={<Checkbox defaultChecked disabled />} label={t('cookieConsent:necessary')} />
              <FormControlLabel
                control={<Checkbox checked={functionnal} onChange={() => setFunctionnal((current) => !current)} />}
                label={t('cookieConsent:functionnal')}
              />
              <FormControlLabel
                control={<Checkbox checked={analytical} onChange={() => setAnalytical((current) => !current)} />}
                label={t('cookieConsent:analytical')}
              />
            </Box>
          ) : (
            <Box>
              <Typography component="p" align="justify" className={classes.text}>
                {t('cookieConsent:fullText')}
              </Typography>
              <Link
                component="a"
                target="_blank"
                href={i18n.language === 'fr' ? cookieLinks.fr : cookieLinks.nl}
                color="primary">
                {t('cookieConsent:knowMore')}
              </Link>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>{cookieActions}</DialogActions>
    </Dialog>
  );
};

CookieConsent.propTypes = {
  classes: object.isRequired,
  isOpen: bool,
  setIsOpen: func.isRequired
};
CookieConsent.defaultProps = {
  isOpen: false
};

const styles = createStyles(() => ({
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    flex: 1,
    justifyContent: 'space-around',
    '& > button': {
      minWidth: '150px',
      margin: '8px'
    }
  },
  text: {
    margin: '8px 0px 16px 0px'
  }
}));

export default compose(withStyles(styles))(CookieConsent);
