import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { complement, isNil, pathOr } from 'ramda';
import { format } from 'date-fns';
import { costCenterString } from 'utils/tempWorkers';

export const isNotEmpty = complement(isNil);
export const getRank = pathOr(null, ['lastContract', 'daco_evaluation_id']);

export const useLastContractEnd = (workerData) => {
  const lastContractEnd = pathOr(null, ['lastContract', 'to'], workerData);
  const contractEnd = useMemo(() => lastContractEnd && format(new Date(lastContractEnd), 'dd/MM/yyyy'), [
    lastContractEnd
  ]);

  return contractEnd;
};

export const useJobLabel = (workerData) => {
  const { i18n } = useTranslation();
  const jobLabel = useMemo(() => pathOr('', ['lastContract', 'jobFunction', `label_${i18n.language}`], workerData), [
    i18n,
    workerData
  ]);

  return jobLabel;
};

export const useCostCenter = (workerData) => {
  const formattedCostCenter = useMemo(() => {
    const params = workerData?.lastContract?.costCenter
      ? [workerData?.lastContract?.costCenter, workerData?.lastContract?.customer]
      : [workerData?.payrollCostCenter, workerData?.payrollCustomer];
    return costCenterString(params[0], params[1]);
  }, [workerData]);
  return formattedCostCenter;
};
