/* eslint-disable complexity */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Container, Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import { array, bool, func, object } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import WorkerCard from 'components/WorkerCard';
import { propOr } from 'ramda';
import Banner from 'components/Banner';
import { handleSelectWorker } from '../NewContractPool/newContractPool.utils';

const useStyles = makeStyles(() => ({
  loadingRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0,
      padding: 0
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}));

const NewContractRecapMobile = ({
  innerPool,
  setInnerPool,
  preSelectedWorkers,
  data,
  secondData,
  isMobile,
  setStep
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation(['general']);
  const { language } = i18n;

  const vacancies = useMemo(() => parseInt(data?.vacancies, 10), [data]);
  const nbrPreselectedWorkers = useMemo(() => preSelectedWorkers.length, [preSelectedWorkers]);
  const missingWorkers = useMemo(() => vacancies - nbrPreselectedWorkers, [vacancies, nbrPreselectedWorkers]);

  const bannerMessage = useMemo(() => {
    if (nbrPreselectedWorkers === 0) return t('general:pool.recap.noWorker', { nbrMissing: missingWorkers });
    if (!!nbrPreselectedWorkers && nbrPreselectedWorkers < vacancies)
      return t('general:pool.recap.moreOpeningsThanWorkers', {
        nbrSelected: nbrPreselectedWorkers,
        nbrMissing: missingWorkers
      });
    if (nbrPreselectedWorkers === vacancies)
      return t('general:pool.recap.openingsFilled', { nbr: nbrPreselectedWorkers });
    return '';
  }, [preSelectedWorkers, vacancies]);

  return (
    <>
      <Container maxWidth="xl" className={classes.container}>
        <Box mb={8}>
          <WorkerCard
            firstName={data.interimWorker?.firstName}
            lastName={data.interimWorker?.lastName}
            dates={{
              from: new Date(data.from),
              to: new Date(data.to)
            }}
            costCenter={data.costCenter}
            reference={data.reference}
            contractStatus={parseInt(propOr(null, 'status', secondData), 10)}
            contractType={propOr(null, 'contractType', secondData)}
            jobFunction={data.jobFunction ? data.jobFunction[`label_${language || 'fr'}`] : null}
            reasonId={secondData.reason}
            isMobile={isMobile}
            showWorkerInfos={false}
            vacancies={data?.vacancies}
          />
        </Box>

        {!!innerPool.length && (
          <>
            <Box mb={2}>
              <Typography variant="body1">
                {t('general:pool.selectedWorkers.title')} ({nbrPreselectedWorkers} / {data?.vacancies})
              </Typography>
              {nbrPreselectedWorkers === 0 && (
                <Typography variant="body2">{t('general:pool.selectedWorkers.empty')}</Typography>
              )}
            </Box>

            <Box mb={8}>
              <Box display="flex" flexDirection="row" flexWrap="wrap" gridGap="8px" minHeight="32px">
                {preSelectedWorkers.map((worker) => (
                  <Chip
                    key={worker.uuid}
                    label={worker.fullname}
                    onDelete={() => handleSelectWorker({ innerPool, setInnerPool, worker, isRemoved: true })}
                  />
                ))}
                {nbrPreselectedWorkers < vacancies && (
                  <Chip
                    color="secondary"
                    deleteIcon={<AddIcon />}
                    onDelete={() => setStep(4)}
                    label={t('general:pool.recap.add')}
                    icon={<PersonIcon />}
                  />
                )}
              </Box>
            </Box>
          </>
        )}

        <Box width="100%">
          <Banner type="info" message={bannerMessage} />
        </Box>
      </Container>
    </>
  );
};

NewContractRecapMobile.propTypes = {
  innerPool: array.isRequired,
  setInnerPool: func.isRequired,
  preSelectedWorkers: array.isRequired,
  data: object,
  secondData: object,
  isMobile: bool,
  setStep: func.isRequired
};

NewContractRecapMobile.defaultProps = {
  data: null,
  secondData: null,
  isMobile: false
};

export default NewContractRecapMobile;
