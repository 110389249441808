import React, { useCallback, useEffect, useRef } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { func, instanceOf } from 'prop-types';
import { isValidDate } from 'ramda-adjunct';
import { isNil } from 'ramda';

const formatValue = (value) => {
  if (value.length >= 4) return value;
  if (value.length === 1) return formatValue(`0${value}`);
  return `${value}${new Array(4 - value.length).fill('0').join('')}`;
};

const CustomTimePicker = ({ value, onChange, onBlur, inputProps, ...props }) => {
  const innerValue = useRef();
  const onInputChange = useCallback((e) => {
    let val = e?.target?.value;
    if (val) val = val.replace(':', '').replace('h', '').replaceAll('_', '');
    if (val.length) {
      val = formatValue(val);
      const hours = parseInt(val.slice(0, 2), 10);
      const minutes = parseInt(val.slice(2, 4), 10);
      const today = new Date();
      const time = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes, 0);
      if (parseInt(val.substring(0, 2), 10) <= 23 && parseInt(val.substring(2, 4), 10) <= 59) {
        innerValue.current = time;
        return;
      }
    }
    innerValue.current = null;
  });

  useEffect(() => {
    if (isValidDate(value) || isNil(value)) {
      innerValue.current = value;
    }
  }, [value]);

  return (
    <KeyboardTimePicker
      {...props}
      value={value}
      onChange={onChange}
      onBlur={(e) => {
        if (innerValue.current) {
          onChange(innerValue.current);
        }
        onBlur(e, innerValue.current);
      }}
      inputProps={{ ...inputProps, onChange: onInputChange }}
    />
  );
};

CustomTimePicker.propTypes = {
  onChange: func,
  onBlur: func,
  value: instanceOf(Date),
  inputProps: instanceOf(Object)
};

CustomTimePicker.defaultProps = {
  onChange: () => null,
  onBlur: () => null,
  value: null,
  inputProps: {}
};

export default CustomTimePicker;
