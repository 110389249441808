import React from 'react';
import { node } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';

export const styles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: 999
  }
}));

const MobileFab = ({ children, ...props }) => {
  const classes = styles();
  return (
    <Fab classes={classes} {...props}>
      {children}
    </Fab>
  );
};

MobileFab.propTypes = {
  children: node.isRequired
};

export default MobileFab;
