import React from 'react';
import { func, node } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  weekLink: {
    cursor: 'pointer'
  },
  iconColor: {
    color: theme.palette.secondary.main
  },
  iconSize: {
    fontSize: 13
  }
}));

const BackButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.weekLink} onClick={onClick} display="flex" flexDirection="row" alignItems="center">
      <Box>
        <FontAwesomeIcon icon={faChevronLeft} className={clsx(classes.iconColor, classes.iconSize)} />
      </Box>
      <Box ml={5}>
        <Typography component="span" variant="body1" color="secondary">
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

BackButton.propTypes = {
  onClick: func,
  children: node
};

BackButton.defaultProps = {
  onClick: () => null,
  children: null
};

export default BackButton;
