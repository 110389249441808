import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const inputStyle = makeStyles(() => ({
  root: {
    border: 'none',
    overflow: 'hidden',
    borderRadius: 0
  }
}));

const PassField = (props) => {
  const classes = inputStyle();
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Box my={1}>
      <TextField
        variant="filled"
        type={isVisible ? 'text' : 'password'}
        {...props}
        InputProps={{
          classes,
          disableUnderline: true,
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={() => setIsVisible((v) => !v)}>
                {isVisible ? <VisibilityIcon color="primary" /> : <VisibilityOffIcon color="primary" />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default PassField;
