import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object } from 'prop-types';
import { Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { propOr } from 'ramda';
import { BackButton } from 'components';

const News = ({ classes, isOnTablet, markAsRead, getUnreadCount }) => {
  const { t } = useTranslation(['general']);
  const history = useHistory();
  const { data: news } = propOr({}, 'state', useLocation());

  useEffect(() => {
    if (!news) {
      history.push('/news');
    }
    if (news && !news.is_read) {
      const read = async () => {
        await markAsRead(news.id);
        getUnreadCount();
        const { location } = history;
        const updatedNews = { ...news, is_read: true };
        history.replace(location.pathname, {
          ...location.state,
          data: updatedNews
        });
      };
      read();
    }
  }, [news]);

  return (
    <Container maxWidth="xl">
      {news && (
        <Box py={4}>
          <Box minHeight={isOnTablet ? 52 : 0} my={isOnTablet ? 0 : 4}>
            <BackButton onClick={() => history.push('/news')}>{t('general:news.title')}</BackButton>
          </Box>
          <Box width={[1, 1, 2 / 3, 1 / 2]} mt={5} mx="auto">
            <Typography variant="h6" component="h1">
              {news.title}
            </Typography>
            <Typography component="h2" variant="subtitle1" className={classes.title}>
              {t('general:news.published', {
                formattedDate: format(new Date(news.published_at.replace(' ', 'T')), t('general:fullDateFormat'))
              })}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: news.content }}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
};

News.propTypes = {
  isOnTablet: bool.isRequired,
  markAsRead: func.isRequired,
  getUnreadCount: func.isRequired,
  classes: object.isRequired
};

const mapState = ({ user: { isOnTablet } }) => ({
  isOnTablet
});

const mapDispatch = ({ news: { markAsRead, getUnreadCount } }) => ({
  markAsRead,
  getUnreadCount
});

const styles = createStyles((theme) => ({
  title: {
    marginBottom: 12
  },
  content: {
    color: theme.palette.secondary.main,
    '& img, video, picture, audio': {
      maxWidth: '100%'
    },
    '& p': {
      marginBottom: 20
    }
  }
}));

export default withStyles(styles)(connect(mapState, mapDispatch)(News));
