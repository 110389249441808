import React from 'react';
import { pathOr, propOr } from 'ramda';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid, Box, Container } from '@material-ui/core';

import Logo from 'assets/daoust-connect.svg';
import Headline from 'assets/daoust-wttf.svg';

import UserShortForm from 'user/User.short.form';
import { Footer, Img, Translate } from 'components';

const Login = ({ isLoading, login }) => {
  const { requestedPath: redirectPath } = propOr({}, 'state', useLocation());

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Container maxWidth="lg">
        <Box mt={4} display="flex" alignItems="center" justifyContent="flex-end">
          <Box>
            <Translate large isSelect />
          </Box>
        </Box>
      </Container>
      <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
        <Container maxWidth="sm">
          <Box display="flex" flexDirection="column" alignItems="center" mb={11}>
            <Box mb={6}>
              <Img src={Logo} alt="" />
            </Box>
            <Box>
              <Img src={Headline} alt="" />
            </Box>
          </Box>
          <UserShortForm onSubmit={(val) => login({ ...val, redirectPath })} submitting={isLoading} type="login" />
        </Container>
        <Footer />
      </Grid>
    </Box>
  );
};

Login.propTypes = {
  isLoading: bool.isRequired,
  login: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'login'], loading)
});

const mapDispatch = (dispatch) => ({
  login: dispatch.user.login
});

export default connect(mapState, mapDispatch)(Login);
