import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { node, number } from 'prop-types';

const TabPanel = ({ children, value, index, p, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}>
    {value === index && (
      <Box p={p}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: node.isRequired,
  value: number.isRequired,
  index: number.isRequired,
  p: number
};

TabPanel.defaultProps = {
  p: 3
};

export default TabPanel;
