import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export const styles = makeStyles(() => ({
  root: {
    '& div.MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      marginTop: 0
    },
    '& input': {
      padding: '10px 12px 10px'
    },
    '& ::before,::after': {
      borderBottom: 0
    },
    '&:hover ::before,::after': {
      borderBottom: 0
    }
  }
}));

const SearchField = (props) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <TextField
      classes={classes}
      variant="filled"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="secondary" />
          </InputAdornment>
        ),
        placeholder: t('general:search')
      }}
      {...props}
    />
  );
};

export default SearchField;
