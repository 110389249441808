import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormHelperText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { hasError, getError } from 'components/helpers';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { bool, func, string, node, object } from 'prop-types';
import validations from './changePassword.validation';
import PassField from './PassField';

const styles = createStyles(() => ({
  withError: {
    marginBottom: 8
  }
}));

const ToggleError = ({ error, children, toggleClass }) => (
  <FormHelperText error {...(error && { className: toggleClass })}>
    {children}
  </FormHelperText>
);

ToggleError.propTypes = {
  error: bool.isRequired,
  children: node,
  toggleClass: string
};

ToggleError.defaultProps = {
  children: null,
  toggleClass: null
};

const ChangePassword = ({ classes, onChange }) => {
  const { t } = useTranslation(['user']);
  const { handleSubmit, control, getValues, errors } = useForm({
    mode: 'onBlur'
  });
  const validators = useMemo(() => validations(getValues), [getValues]);

  return (
    <Box component="form" onSubmit={handleSubmit(onChange)} display="flex" flexDirection="column" mb={4}>
      <Controller
        as={PassField}
        control={control}
        name="password"
        label={t('user:currentPassword')}
        rules={validators.password}
        defaultValue=""
        autoComplete="current-password"
        fullWidth
      />
      <ToggleError error={hasError('password', errors)} toggleClass={classes.withError}>
        {t(getError(['password', 'message'], errors))}
      </ToggleError>
      <Controller
        as={PassField}
        control={control}
        name="newPassword"
        label={t('user:newPassword')}
        rules={validators.newPassword}
        autoComplete="new-password"
        defaultValue=""
        fullWidth
      />
      <ToggleError error={hasError('newPassword', errors)} toggleClass={classes.withError}>
        {t(getError(['newPassword', 'message'], errors))}
      </ToggleError>
      <Controller
        as={PassField}
        control={control}
        name="confirmPassword"
        label={t('user:passwordConfirmation')}
        rules={validators.confirmPassword}
        autoComplete="new-password"
        defaultValue=""
        fullWidth
      />
      <ToggleError error={hasError('confirmPassword', errors)} toggleClass={classes.withError}>
        {t(getError(['confirmPassword', 'message'], errors))}
      </ToggleError>
      <Box mt={2} width={[1 / 2]} display="flex" alignSelf="flex-end" flexDirection="column">
        <Button color="primary" variant="contained" fullWidth type="submit">
          {t('user:changePassword')}
        </Button>
      </Box>
    </Box>
  );
};

ChangePassword.propTypes = {
  classes: object.isRequired,
  onChange: func.isRequired
};

export default withStyles(styles)(ChangePassword);
