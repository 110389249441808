import React from 'react';
import { Box } from '@material-ui/core';
import { array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import NewContractRecapMobile from './NewContractRecap.mobile';
import NewContractRecapDesktop from './NewContractRecap.desktop';

const NewContractRecap = ({ isMobile, ...props }) => {
  return (
    <Box flex={1} overflow="hidden" display="flex">
      {isMobile ? (
        <NewContractRecapMobile isMobile={isMobile} {...props} />
      ) : (
        <NewContractRecapDesktop isMobile={isMobile} {...props} />
      )}
    </Box>
  );
};

NewContractRecap.propTypes = {
  isMobile: bool.isRequired,
  innerPool: array.isRequired,
  setInnerPool: func.isRequired,
  preSelectedWorkers: array.isRequired
};

const mapState = ({ user: { isOnMobile } }) => ({
  isMobile: isOnMobile
});

export default connect(mapState, null)(NewContractRecap);
