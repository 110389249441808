import React, { useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  FilledInput,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  RadioGroup
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EuroIcon from '@material-ui/icons/Euro';
import { array, func, object } from 'prop-types';
import { remove, or, pathOr, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { getStatus, getType, saveScheduleBetweenSteps } from 'utils/tempWorkers';
import { useForm, Controller } from 'react-hook-form';
import { hasError, getError } from 'components/helpers';
import LegalContractInfo from 'components/LegalContractInfo/LegalContractInfo';
import validators from './secondContractForm.validation';

const customControlStyle = ({ palette }) => ({
  root: {
    width: '100%',
    color: palette.secondary.main,
    borderBottom: `solid 1px ${palette.divider}`,
    padding: '5px 0',
    marginLeft: 0,
    marginRight: 0,
    justifyContent: 'space-between'
  }
});
const CustomFormControlLabel = withStyles(customControlStyle)((props) => (
  <FormControlLabel labelPlacement="start" {...props} />
));
const CustomFormLabel = withStyles({
  root: {
    marginBottom: ({ mb = 5 }) => mb
  }
})(FormLabel);
const CustomTypography = withStyles(({ palette }) => ({ root: { color: palette.secondary.dark } }))(Typography);
const CustomFormControl = withStyles({ root: { marginTop: '2rem' } })(FormControl);

const SecondContractForm = ({ data, setDefaultSchedule, onSubmit, defaultData, reasonsList, ...props }) => {
  const { t } = useTranslation(['general', 'tempWorkers']);

  const { control, register, handleSubmit, errors, watch, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      isPredefined: pathOr(false, ['isPredefined'], defaultData),
      salaryAmount: pathOr('', ['salaryAmount'], defaultData),
      reason: pathOr('', ['reason'], defaultData),
      status: pathOr('', ['status'], defaultData),
      contractType: or(prop('contractType', defaultData), [])
    }
  });

  const contractType = watch('contractType');
  const isPredefined = watch('isPredefined');
  const status = watch('status');

  useEffect(() => {
    register({ name: 'contractType' });
    register({ name: 'status' });
  }, [register]);

  useEffect(() => {
    if (!data.scheduleDays) return;
    setDefaultSchedule(saveScheduleBetweenSteps(data));
  }, [data.scheduleDays]);

  const handleTypeChange = (e, newType) => {
    const { checked } = e.target;
    let newTypes = [...contractType];
    if (checked) {
      if (!newTypes.includes(newType)) newTypes.push(newType);
    } else {
      const typeIdx = contractType.indexOf(newType);
      if (typeIdx >= 0) {
        newTypes = remove(typeIdx, 1, contractType);
      }
    }
    setValue('contractType', newTypes);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} id="secondcontract" {...props}>
      <Box display="flex" flexDirection="column">
        <CustomFormLabel component="legend" mb={15}>
          <CustomTypography variant="body2" align="left">
            {t('tempWorkers:reasonAndSalary')}
          </CustomTypography>
        </CustomFormLabel>
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="reason">{t('general:extend.reason')}</InputLabel>
          <Controller
            name="reason"
            control={control}
            rules={validators.reason}
            as={
              <Select variant="filled" id="reason" labelId="reason" fullWidth>
                {reasonsList?.map((reason) => (
                  <MenuItem key={reason.reason_id} value={reason.reason_id}>
                    {reason.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          <FormHelperText error={hasError('reason', errors)}>
            {t(getError(['reason', 'message'], errors))}
          </FormHelperText>
        </FormControl>
        <LegalContractInfo />
        <Box my={4}>
          <Controller
            name="isPredefined"
            control={control}
            onChange={([_event, el]) => {
              if (el) setValue('salaryAmount', null);
              return el;
            }}
            as={
              <CustomFormControlLabel
                control={<Checkbox color="primary" id="isPredefined" />}
                label={t('general:extend.predefinedSalary')}
              />
            }
          />
        </Box>
        {isPredefined && (
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="salary">{t('general:salaryAmountHourly')}</InputLabel>
            <Controller
              name="salaryAmount"
              control={control}
              rules={validators.salaryAmount}
              as={
                <FilledInput
                  id="salaryAmountHourly"
                  type="number"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <EuroIcon />
                    </InputAdornment>
                  }
                />
              }
            />
            <FormHelperText error={hasError('salaryAmount', errors)}>
              {t(getError(['salaryAmount', 'message'], errors), { min: 0, max: 99999 })}
            </FormHelperText>
          </FormControl>
        )}
        <CustomFormControl>
          <CustomFormLabel component="legend">
            <CustomTypography variant="body2" align="left">
              {t('general:status')}
            </CustomTypography>
          </CustomFormLabel>
          <RadioGroup aria-label="status" id="status" name="status">
            {[5, 9].map((stat) => (
              <CustomFormControlLabel
                key={stat}
                onChange={(e) => setValue('status', e.target.checked ? stat : null)}
                value={stat.toString()}
                id={`statusLabel-${stat}`}
                control={
                  <Checkbox
                    checked={status === stat}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    color="primary"
                  />
                }
                label={getStatus(stat, null, t)}
              />
            ))}
          </RadioGroup>
        </CustomFormControl>
        <CustomFormControl>
          <CustomFormLabel component="legend">
            <CustomTypography variant="body2" align="left">
              Type
            </CustomTypography>
          </CustomFormLabel>
          <CustomFormControlLabel
            id="flexiCheck"
            onChange={(e) => handleTypeChange(e, 30)}
            control={<Checkbox checked={contractType.includes(30)} color="primary" />}
            label={getType(30, t)}
          />
          <CustomFormControlLabel
            onChange={(e) => handleTypeChange(e, 56)}
            control={<Checkbox checked={contractType.includes(56)} color="primary" />}
            label={getType(56, t)}
          />
        </CustomFormControl>
      </Box>
    </Box>
  );
};

SecondContractForm.propTypes = {
  onSubmit: func.isRequired,
  defaultData: object,
  reasonsList: array.isRequired,
  data: object,
  setDefaultSchedule: func
};

SecondContractForm.defaultProps = {
  defaultData: {},
  data: {},
  setDefaultSchedule: () => null
};

export default SecondContractForm;
