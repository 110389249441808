import React, { useEffect, useState } from 'react';
import { string, func, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Checkbox, FormControlLabel, LinearProgress, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';

import styles from './NewPayrollStyle';

const NewPayrollUpload = ({ onSend, firstName, lastName, isSubmitting, classes }) => {
  const { t } = useTranslation(['general']);
  const uploadMessage = <em>{t('general:optional')}</em>;
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [identityFile, setIdentityFile] = useState(null);
  const [workPermitFile, setWorkPermitFile] = useState(null);
  const [driversLicense, setDriversLicense] = useState(null);
  const handleConfirmed = () => setIsConfirmed(!isConfirmed);
  const handleFile = (e, setState) => {
    const supportedType = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg', 'image/tiff', 'image/bmp'];
    const { files, value } = e.target;
    if (files.length > 0) {
      const [file] = files;
      if (file.size <= 20000000) {
        if (supportedType.includes(file.type)) {
          setState({ file, fakePath: value });
          e.target.value = null;
        } else {
          toast.error(t('validation:fileNotSupported'));
        }
      } else {
        toast.error(t('validation:fileTooBig'));
      }
    }
  };

  useEffect(() => {
    onSend({
      isConfirmed,
      files: identityFile || workPermitFile || driversLicense ? { identityFile, workPermitFile, driversLicense } : null
    });
  }, [isConfirmed, identityFile, workPermitFile, driversLicense, onSend]);

  return (
    <Box>
      {isSubmitting && <LinearProgress />}
      <Typography variant="h6" component="h2" gutterBottom className={classes.nameTitle}>
        {firstName} {lastName}
      </Typography>
      <Typography variant="body1">{t('general:attachments')}</Typography>
      <Box mt="2rem" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">{t('general:payroll.identityDocuments')}</Typography>
          <Typography color="textSecondary" variant="body2" className={classes.file}>
            {identityFile ? (
              <>
                {identityFile.file.name}
                <IconButton
                  disabled={isSubmitting}
                  size="small"
                  onClick={() => setIdentityFile(null)}
                  className={classes.icon}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              uploadMessage
            )}
          </Typography>
        </Box>
        <Box>
          <label htmlFor="identityFile">
            <input
              data-testid="inputIdentity"
              accept=".pdf, .jpg, .jpeg, .png, .bmp, .tif"
              onChange={(e) => handleFile(e, setIdentityFile)}
              className={classes.input}
              id="identityFile"
              type="file"
            />
            <Button
              name="identityFile"
              variant="contained"
              color="primary"
              size="small"
              component="span"
              disabled={isSubmitting}
              className={classes.uploadButton}>
              <FontAwesomeIcon fixedWidth icon={faFileUpload} color="white" />
            </Button>
          </label>
        </Box>
      </Box>
      <Box mt="2rem" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">{t('general:payroll.workPermit')}</Typography>
          <Typography color="textSecondary" variant="body2" className={classes.file}>
            {workPermitFile ? (
              <>
                {workPermitFile.file.name}
                <IconButton size="small" disabled={isSubmitting} onClick={() => setWorkPermitFile(null)}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              uploadMessage
            )}
          </Typography>
        </Box>
        <Box>
          <label htmlFor="workPermit">
            <input
              data-testid="inputWorkPermit"
              accept=".pdf, .jpg, .jpeg, .png, .bmp, .tif"
              onChange={(e) => handleFile(e, setWorkPermitFile)}
              className={classes.input}
              id="workPermit"
              type="file"
            />
            <Button
              name="workPermit"
              variant="contained"
              color="primary"
              component="span"
              disabled={isSubmitting}
              className={classes.uploadButton}>
              <FontAwesomeIcon fixedWidth icon={faFileUpload} color="white" />
            </Button>
          </label>
        </Box>
      </Box>
      <Box mt="2rem" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">{t('general:payroll.driversLicense')}</Typography>
          <Typography color="textSecondary" variant="body2" className={classes.file}>
            {driversLicense ? (
              <>
                {driversLicense.file.name}
                <IconButton disabled={isSubmitting} size="small" onClick={() => setDriversLicense(null)}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              uploadMessage
            )}
          </Typography>
        </Box>
        <Box>
          <label htmlFor="driversLicense">
            <input
              data-testid="inputDriversLicense"
              accept=".pdf, .jpg, .jpeg, .png, .bmp, .tif"
              onChange={(e) => handleFile(e, setDriversLicense)}
              className={classes.input}
              id="driversLicense"
              type="file"
            />
            <Button
              name="driversLicense"
              variant="contained"
              color="primary"
              component="span"
              disabled={isSubmitting}
              className={classes.uploadButton}>
              <FontAwesomeIcon fixedWidth icon={faFileUpload} color="white" />
            </Button>
          </label>
        </Box>
      </Box>
      <Box mt={8}>
        <FormControlLabel
          className={classes.form}
          control={
            <Box>
              <Checkbox
                disabled={isSubmitting}
                color="primary"
                className={classes.checkBox}
                checked={isConfirmed}
                onChange={handleConfirmed}
                name="confirmation"
              />
            </Box>
          }
          label={
            <Box>
              <Typography variant="body2" color="textSecondary">
                {t('general:payroll.confirmation')}
              </Typography>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

NewPayrollUpload.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  isSubmitting: bool.isRequired,
  onSend: func.isRequired,
  classes: object.isRequired
};

export default withStyles(styles)(NewPayrollUpload);
