/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
import { array, bool, func, object } from 'prop-types';
import { isEmpty, path, pathOr, prop, pipe, pluck, flatten, filter, keys, omit, mapObjIndexed, values } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import { useHeader } from 'hooks';
import { eventGA } from 'utils/analytics';
import { cleanObj } from 'utils/object';

import { ConfirmDialog, PrestationToValidateListMobile } from 'components';
import { filterValidationSearch } from 'utils/tempWorkers';
import SaveIcon from '@material-ui/icons/Save';
import PrestationToValidateListForm from 'components/TempWorkersList/desktop/PrestationToValidateListForm';
import EmptyPrestation from './EmptyPrestation';
import {
  getFinalBonuses,
  getFinalHours,
  fillPremiumsForEmptyDays,
  fillPerformedHours,
  getUnusedContractBonuses
} from './utils';

const useStyles = makeStyles((theme) => ({
  footerStyle: {
    borderTop: '1px solid #e5e5e5',
    position: 'fixed',
    bottom: 0,
    background: '#fff',
    display: 'flex',
    width: '100%',
    zIndex: 40,
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'none',
    fontSize: '1.4rem'
  }
}));

const ConfirmPrestation = ({
  confirmTimesheet,
  getToConfirmList,
  isMobile,
  listLoading,
  toConfirmList,
  toConfirmRaw,
  user,
  editSchedule,
  isLoading
}) => {
  const { t } = useTranslation(['general']);
  const { search } = useHeader();
  const [localList, setLocalList] = useState(toConfirmList);
  const [listRaw, setListRaw] = useState(toConfirmRaw);
  const [contractIds, setContractIds] = useState([]);
  const [isPreconfirmed, setIsPreconfirmed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modifiedList, setModifiedList] = useState([]);
  const classes = useStyles();

  const handleHoursModification = useCallback(
    (data) => {
      if (!prop('length', modifiedList)) return setModifiedList([data]);
      const newList = filter((item) => item.info.contractId !== data.info.contractId, modifiedList);
      return setModifiedList([...newList, data]);
    },
    [modifiedList]
  );

  const handleConfirmation = async () => {
    const ids = pipe(
      pluck('costCenters'),
      flatten,
      pluck('contracts'),
      flatten,
      filter((contract) => !!contractIds.find((id) => id === contract.info.contractId)),
      pluck('info'),
      flatten,
      pluck('linkedContracts'),
      flatten,
      pluck('id')
    )(localList);
    await confirmTimesheet({
      contractIds: ids,
      impersonateValidatorId: path(['impersonator', 'id'], user),
      count: ids.length
    });
    setIsPreconfirmed(false);
    if (isEditing) {
      setIsEditing(false);
      await getToConfirmList();
    }
    setContractIds([]);
  };

  const handleEditSchedule = async (contract, finalHours, finalBonuses, item, modifiedContractIds) => {
    let premiumList;
    let timesheetScheduleDays;
    const defaultHours = finalHours[item.info.linkedContracts[0].id].map((linkedContract) => ({
      ...linkedContract,
      id: contract.id
    }));
    if (modifiedContractIds.find((id) => contract.id === id)) {
      premiumList = finalBonuses[contract.id];
      timesheetScheduleDays = finalHours[contract.id] || defaultHours;
    } else {
      const schedule = pipe(
        cleanObj,
        omit(['total']),
        mapObjIndexed((val, key) => ({ ...val, day: key, total_hours: 0 })),
        values
      )(contract.timesheetScheduleDays);
      premiumList = getUnusedContractBonuses(contract.from, schedule);
      timesheetScheduleDays = schedule;
    }
    premiumList = premiumList.reduce((acc, val) => {
      const exist = acc.find((itm) => itm.date === val.date && itm.premium_code === val.premium_code);
      if (exist) return acc;
      return [...acc, val];
    }, []);
    return editSchedule({
      contractId: contract.id,
      premiumList,
      timesheetScheduleDays,
      impersonateValidatorId: path(['impersonator', 'id'], user)
    });
  };

  const handleSave = useCallback(async () => {
    const resolved = await Promise.all(
      modifiedList.map(async (item) => {
        const premiums = filter((val) => val.contract_id, item.premiums);
        const validatedSources = !!premiums.find((b) => b.source === 2);
        const finalBonuses = getFinalBonuses(premiums, validatedSources, item.info.linkedContracts);
        const finalHours = getFinalHours(item.hours, premiums, item.info);
        const modifiedContractIds = keys({ ...finalBonuses, ...finalHours });
        const promises = item.info.linkedContracts.map((contract) => {
          return handleEditSchedule(contract, finalHours, finalBonuses, item, modifiedContractIds);
        });
        return Promise.all(promises);
      })
    );
    setModifiedList([]);
    return resolved;
  }, [modifiedList]);

  const handleSaveAndConfirm = async () => {
    await handleSave();

    // if (shouldConfirm) {
    setIsPreconfirmed(true);
    // } else {
    // getToConfirmList();
    //   setIsEditing(false);
    // }
  };

  useEffect(() => {
    const getConfirmList = async () => {
      await getToConfirmList();
    };
    getConfirmList();
  }, [getToConfirmList]);

  const dialogActions = {
    cancel: async () => {
      await getToConfirmList();
      setIsEditing(false);
      setIsPreconfirmed(false);
    },
    confirm: () => handleConfirmation()
  };
  const confirmText = `${t('general:confirm')} ${contractIds.length} ${t(
    contractIds.length > 1 ? 'general:deliveries' : 'general:deliverie'
  )}`;

  const SaveAndConfirmText = `${t('general:saveAndConfirm')} ${contractIds.length} ${t(
    contractIds.length > 1 ? 'general:deliveries' : 'general:deliverie'
  )}`;

  const listComponent = () =>
    !isMobile ? (
      <Container maxWidth="xl">
        <PrestationToValidateListForm
          onSelectChange={setContractIds}
          list={localList}
          listRaw={listRaw}
          isEditing={!!isEditing}
          setIsEditing={setIsEditing}
          isLoading={listLoading}
          handleHoursModification={handleHoursModification}
          fillPremiumsForEmptyDays={fillPremiumsForEmptyDays}
          fillPerformedHours={fillPerformedHours}
        />
      </Container>
    ) : (
      <PrestationToValidateListMobile
        onSelectChange={setContractIds}
        list={localList}
        listRaw={listRaw}
        isLoading={listLoading}
      />
    );

  useEffect(() => {
    if (!search) setLocalList(toConfirmList);
    if (prop('length', toConfirmList) && search) {
      const filtered = filterValidationSearch(toConfirmList, search);
      setLocalList(filtered);
    }
    setListRaw(toConfirmRaw);
  }, [toConfirmList, toConfirmRaw, search]);

  return (
    <>
      {isEmpty(toConfirmList) && !listLoading ? (
        <EmptyPrestation />
      ) : (
        <>
          <Box display="flex" flexDirection="column" width="100%">
            <Box flex="1" overflow={isMobile ? 'inherit' : 'auto'} pb="65px">
              <Box>{listComponent()}</Box>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="flex-end" className={classes.footerStyle} py={4}>
              <Container maxWidth="xl" className={classes.container}>
                {isEditing ? (
                  <>
                    <Button
                      onClick={() => {
                        eventGA('prestation_to_confirm_cancel_edit');
                        setModifiedList([]);
                        setIsEditing(false);
                      }}
                      color="primary">
                      {t('general:cancel')}
                    </Button>
                    <Button
                      onClick={async () => {
                        eventGA('prestation_to_confirm_save');
                        await handleSave();
                      }}
                      startIcon={<SaveIcon />}
                      color="primary"
                      disabled={modifiedList.length === 0}>
                      {t('general:save')}
                    </Button>
                    <Button
                      onClick={async () => {
                        eventGA('prestation_to_confirm_cancel_save_and_confirm');
                        await handleSaveAndConfirm();
                      }}
                      variant="contained"
                      color="primary"
                      disabled={contractIds.length === 0}>
                      {SaveAndConfirmText}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => setIsPreconfirmed(true)}
                    color="primary"
                    disabled={contractIds.length === 0}
                    variant="contained"
                    fullWidth={isMobile}>
                    {confirmText}
                  </Button>
                )}
              </Container>
            </Box>
          </Box>
        </>
      )}
      <ConfirmDialog
        actions={dialogActions}
        isOpen={isPreconfirmed}
        title={confirmText}
        alignTitle="left"
        isOrdering={isLoading}>
        <Box mb={4}>
          <Typography variant="body1" color="secondary">
            {t('general:performance.confirmscreen', { count: contractIds.length })}
          </Typography>
        </Box>
        <Typography component="span" variant="body2" color="secondary" className={classes.italic}>
          {t('general:performance.confirmscreen_sub', { count: contractIds.length })}{' '}
          <a className={classes.link} href="mailto:customer@daoust.be">
            customer@daoust.be
          </a>
        </Typography>
      </ConfirmDialog>
    </>
  );
};

ConfirmPrestation.propTypes = {
  confirmTimesheet: func.isRequired,
  getToConfirmList: func.isRequired,
  toConfirmList: array.isRequired,
  toConfirmRaw: array.isRequired,
  listLoading: bool.isRequired,
  isMobile: bool.isRequired,
  user: object.isRequired,
  editSchedule: func.isRequired,
  isLoading: bool.isRequired
};

const mapState = ({ tempWorkers: { toConfirmList, toConfirmRaw }, loading, user: { isOnMobile, current } }) => ({
  user: current,
  toConfirmList,
  toConfirmRaw,
  listLoading: pathOr(false, ['effects', 'tempWorkers', 'getToConfirmList'], loading),
  isMobile: isOnMobile,
  isLoading:
    pathOr(false, ['effects', 'tempWorkers', 'confirmTimesheet'], loading) ||
    pathOr(false, ['effects', 'tempWorkers', 'getToConfirmList'], loading) ||
    pathOr(false, ['effects', 'contracts', 'editSchedule'], loading)
});

const mapDispatch = ({ tempWorkers: { confirmTimesheet, getToConfirmList }, contracts: { editSchedule } }) => ({
  confirmTimesheet,
  getToConfirmList,
  editSchedule
});

export default connect(mapState, mapDispatch)(ConfirmPrestation);
