import React from 'react';
import { object, func } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Img } from 'components';
import DaoustIcon from 'assets/icon-daoust.svg';
import PayrollIcon from 'assets/icon-payroll.svg';

const useStyles = makeStyles(() => ({
  borderBottomLess: {
    borderBottom: 'none',
    width: 60
  },
  noPaddingLeft: {
    paddingLeft: 0
  }
}));

const WorkerMobileRow = ({ workerData, onClick }) => {
  const classes = useStyles();

  return (
    <TableRow onClick={onClick}>
      <TableCell className={classes.borderBottomLess}>
        <Img src={workerData.isPayroll === 'P' ? PayrollIcon : DaoustIcon} alt="" />
      </TableCell>
      <TableCell className={classes.noPaddingLeft}>{workerData.fullname}</TableCell>
    </TableRow>
  );
};

WorkerMobileRow.propTypes = {
  workerData: object.isRequired,
  onClick: func.isRequired
};

export default WorkerMobileRow;
