/* istanbul ignore file */
import { isSameWeek } from 'date-fns';
import { isValidDate } from 'ramda-adjunct';

const getDate = (dateVal) => (Array.isArray(dateVal) ? dateVal[0] : dateVal);

export default (getValues, nextWorkingDay, customer) => {
  return {
    interimWorker: { required: 'optionRequired' },
    jobFunction: { required: 'optionRequired' },
    costCenter: { required: !!customer?.is_cost_center_mandatory && 'optionRequired' },
    from: {
      name: 'from',
      validate: {
        isDateValid: (value) => isValidDate(getDate(value)) || 'dateRequired',
        isWorkDay: (value) =>
          isSameWeek(getDate(value), nextWorkingDay, { weekStartsOn: 1 }) ||
          getDate(value) > nextWorkingDay ||
          'dateMustNotBeWorkday'
      }
    },
    to: {
      name: 'to',
      validate: {
        isDateValid: (value) => isValidDate(getDate(value)) || 'dateRequired',
        minDate: (value) => {
          return getDate(getValues('from')) <= getDate(value) || 'minDate';
        }
      }
    },
    vacancies: {
      required: 'optionRequired',
      validate: {
        minVacancies: (value) => {
          const toNum = /^\d+$/.test(value) ? parseInt(value, 10) : 0;
          return (toNum > 0 && toNum < 1000) || 'mustBetween';
        }
      }
    }
  };
};
