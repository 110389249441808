/* eslint-disable complexity */
import React from 'react';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { array, bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { pathOr, isEmpty, filter, compose, map, values, reduce, path, prop } from 'ramda';
import { isSameDay } from 'date-fns';
import uuid from 'react-uuid';

import { days, formatHour, hoursFromMinutes } from 'utils/date';

import { renderOnCondition } from 'utils/component';
import { capitalized } from 'utils/text';
import styles from './PlanningCandidateRow.styles';

const PlanningFunctionRow = ({ classes, show, history, data, weekDays, isLast }) => {
  const { t, i18n } = useTranslation(['tempWorkers']);
  const { language } = i18n;

  const getHours = (day, workerData) => {
    const hours = pathOr({}, ['scheduleDays', days[day?.getDay()]], workerData);
    return hoursFromMinutes(hours?.total_hours || 0);
  };

  const getTotalHours = (orderPlanning) => {
    if (!orderPlanning) return hoursFromMinutes(0);
    const totalDay = orderPlanning.reduce((total, order) => {
      const totalOrder = compose(
        reduce((x, y) => x + y, 0),
        map((el) => el.total_hours),
        filter((el) => !!el),
        values
      )(order.scheduleDays);
      // eslint-disable-next-line no-param-reassign
      total += totalOrder;
      return total;
    }, 0);
    return hoursFromMinutes(totalDay);
  };

  const scheduleLine = (time, startKey, endKey) => {
    if (!prop(startKey, time) && !prop(endKey, time)) return null;
    return (
      <Typography variant="caption" component="p" color="secondary">
        {`${formatHour(time[startKey])} - ${formatHour(time[endKey])}`}
      </Typography>
    );
  };

  const handleGoToOrderDetail = ({ e, day, worker }) => {
    e.stopPropagation();

    const orderId = path(['mappingOrderIdByDay', day], worker);
    if (!orderId) return;
    history.push({
      pathname: `/orders/${orderId}`,
      state: { orderId, fromPlanning: true }
    });
  };

  return (
    <>
      <TableRow
        key={uuid()}
        className={clsx(classes.tableRow, {
          [classes.noBorderRow]: isLast,
          [classes.hiddenRow]: !show
        })}>
        <TableCell component="th" scope="row" className={classes.firstAndLastCell}>
          <Typography variant="body2">
            {data.mainJobFunction ? data.mainJobFunction[`label_${language || 'fr'}`] : null}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {data.orderPlannings?.length || 0}{' '}
            {data.orderPlannings?.length > 1
              ? t('general:planning.tempWorker.plural')
              : t('general:planning.tempWorker.singular')}
          </Typography>
        </TableCell>

        {weekDays.map((day) => {
          return (
            <TableCell
              key={uuid()}
              align="right"
              className={clsx(classes.tableCell, {
                [classes.todayCell]: isSameDay(day, new Date())
              })}>
              {data?.orderPlannings?.map((worker, indexRow) => {
                const contractHours = pathOr({}, ['scheduleDays', days[day.getDay()]], worker);
                return (
                  <Box
                    key={uuid()}
                    className={clsx('', {
                      [classes.clickableRow]: !isEmpty(contractHours)
                    })}
                    onClick={(e) => handleGoToOrderDetail({ e, day: days[day.getDay()], worker })}>
                    {renderOnCondition(
                      !isEmpty(contractHours),
                      <>
                        <Box className={clsx('', { [classes.tableCellFunctionView]: indexRow !== 0 })}>
                          {worker?.candidate?.fullname || capitalized(t('general:undefined'))}
                        </Box>
                        <Box>{getHours(day, worker)}</Box>
                        <Box>
                          {scheduleLine(contractHours, 'start_am', 'end_am')}
                          {scheduleLine(contractHours, 'start_pm', 'end_pm')}
                        </Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </TableCell>
          );
        })}
        <TableCell align="center">
          <Typography variant="body2">{getTotalHours(data?.orderPlannings)}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

PlanningFunctionRow.propTypes = {
  classes: object.isRequired,
  history: object.isRequired,
  show: bool,
  data: object,
  weekDays: array,
  isLast: bool
};

PlanningFunctionRow.defaultProps = {
  show: false,
  data: {},
  weekDays: [],
  isLast: false
};

export default withStyles(styles)(withRouter(PlanningFunctionRow));
