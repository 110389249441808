/* eslint max-lines: 0 */
import React, { useCallback, useState } from 'react';
import { Box, makeStyles, Typography, TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBirthdayCake,
  faEnvelope,
  faIdCard,
  faIndustry,
  faHardHat,
  faLanguage,
  faUser,
  faPhone,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { func, object, array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Geonames from 'geonames.js';
import Flag from 'react-world-flags';
import { useForm } from 'react-hook-form';
import { isNil, pathOr, pick, prop, values } from 'ramda';

import { costCenterString } from 'utils/tempWorkers';
import countryList from 'utils/countryList';
import { localeDateFormat } from 'utils/date';

import { IconTextfield, IconSelectable, FrLocaleUtilsProvider } from 'components';
import { hasError, getError, getErrorOrOptional } from 'components/helpers';

import validations from './newPayroll.validation';

const orderedCountryList = countryList.sort((x, y) => {
  if (isNil(x.suggested)) return 0;
  if (isNil(y.suggested)) return -1;
  return x.suggested - y.suggested;
});

const defaultCountry = countryList.find((co) => co.name === 'Belgium');

const adornmentStyle = makeStyles(() => ({
  root: {
    margin: '0 !important'
  }
}));

const filterSearch = (options, { inputValue: search }) =>
  search
    ? options.filter((option) =>
        values(pick(['code', 'name', 'phone'], option)).some((attr) =>
          new RegExp(search.replace('+', '').trim(), 'i').test(attr)
        )
      )
    : options;

const NewPayrollForm = ({ defaultData, jobFunctions, costCenters, onSend, ...props }) => {
  const { t, i18n } = useTranslation(['general', 'user', 'validation']);
  const [asyncOptions, setAsyncOptions] = useState({ codes: [], cities: [] });
  const [noOptionsMessage, setNoOptionsMessage] = useState(t('general:multiSelectMessage'));
  const languageOptions = [
    { id: 34, code: 'fr', language: 'Français' },
    { id: 82, code: 'nl', language: 'Nederlands' },
    { id: 23, code: 'de', language: 'Deutch' }
  ].map((l) => ({ name: l.language, key: l.code, value: l.id }));
  const useIconsStyle = makeStyles((theme) => ({
    root: {
      color: theme.palette.secondary.main
    }
  }));
  const { errors, register, handleSubmit, control, getValues, setValue, watch, triggerValidation } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultData,
      birthDate: pathOr(null, ['birthDate'], defaultData),
      prefix: pathOr(defaultCountry, ['prefix'], defaultData),
      country: pathOr(defaultCountry, ['country'], defaultData)
    }
  });
  const { language } = i18n;
  const prefix = watch('prefix');
  const geonames = new Geonames({ username: 'daco', lan: language, encoding: 'JSON' });

  const handleChange = (selected, type) => {
    if (!selected[1]) {
      setValue(type, null);
      return null;
    }
    setValue(type, selected[1]);
    triggerValidation(type);
    return selected[1];
  };

  const formattedPhone = (val) =>
    `${val.substring(0, 4)} ${val.substring(4, 6)} ${val.substring(6, 8)} ${val.substring(8, 10)}`;

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const trimmedValue = value.trim().replace(/\s/g, '');
    setValue('phone', prefix.phone === '32' ? formattedPhone(trimmedValue).trim() : trimmedValue);
  };

  const loadOptions = async (inputValue, type) => {
    const data = await geonames.postalCodeSearch({
      [type === 'codes' ? 'postalcode' : 'placename_startsWith']: inputValue,
      countryBias: 'BE'
    });
    const codes = data.postalCodes ? data.postalCodes : [];
    const options = codes.map((o) => ({
      label: `${o[type === 'codes' ? 'postalCode' : 'placeName']} (${o.countryCode})`,
      value: o
    }));
    return options;
  };

  const onInputChange = useCallback(async (e, type) => {
    if (e) {
      const val = e.target.value;
      if (prop('length', val) >= 3) {
        const options = await loadOptions(val, type);
        setAsyncOptions((opts) => ({ ...opts, [type]: options }));
        setNoOptionsMessage(t('general:noOptions'));
      } else {
        setAsyncOptions((opts) => ({ ...opts, [type]: [] }));
        setNoOptionsMessage(t('general:multiSelectMessage'));
      }
    }
  }, []);

  const validators = validations({ control, getValues });
  const iconClasses = useIconsStyle();
  return (
    <Box display="flex" flexDirection="column" {...props}>
      <form id="newpayroll" onSubmit={handleSubmit(onSend)}>
        <Box mb={5}>
          <IconSelectable
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faIndustry} />}
            label={t('general:costcenter')}
            defaultValue={watch('costCenter') || null}
            id="costCenter"
            name="costCenter"
            dataTestId="costCenter"
            getOptionLabel={costCenterString}
            noOptionsText={t('general:noOptions')}
            type="autocomplete"
            filterOptions={(options, state) => {
              return options.filter((item) => item.description.toLowerCase().includes(state.inputValue.toLowerCase()));
            }}
            onChange={(e) => e[1]}
            error={hasError('costCenter', errors)}
            helperText={
              hasError('costCenter', errors) ? t(getError(['costCenter', 'message'], errors)) : t('general:optional')
            }
            control={control}
            rules={validators.costCenter}
            options={costCenters}
          />
        </Box>
        <Box mb={5}>
          <IconTextfield
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faUser} />}
            label={t('user:firstName')}
            id="firstName"
            name="firstName"
            inputProps={{ 'data-testid': 'firstName' }}
            helperText={t(getError(['firstName', 'message'], errors))}
            error={hasError('firstName', errors)}
            fullWidth
            inputRef={register(validators.firstName)}
          />
        </Box>
        <Box mb={5}>
          <IconTextfield
            id="lastName"
            name="lastName"
            inputProps={{ 'data-testid': 'lastName' }}
            label={t('user:lastName')}
            error={hasError('lastName', errors)}
            helperText={t(getError(['lastName', 'message'], errors))}
            fullWidth
            fillSpace
            inputRef={register(validators.lastName)}
          />
        </Box>
        <Box mb={5}>
          <FrLocaleUtilsProvider>
            <IconTextfield
              icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faBirthdayCake} />}
              label={t('general:birthDate')}
              id="birthDate"
              name="birthDate"
              inputProps={{ 'data-testid': 'birthDate' }}
              error={hasError('birthDate', errors)}
              helperText={t(getErrorOrOptional('birthDate', errors))}
              variant="inline"
              inputVariant="filled"
              format={localeDateFormat}
              invalidDateMessage={t('validation:dateInvalid')}
              value={watch('birthDate')}
              control={control}
              disableFuture
              fillSpace
              type="controlleddate"
              rules={validators.birthDate}
              autoOk
            />
          </FrLocaleUtilsProvider>
        </Box>
        <Box mb={5}>
          <IconTextfield
            id="socialSecurity"
            name="socialSecurity"
            inputProps={{ 'data-testid': 'socialSecurity' }}
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faIdCard} />}
            label={t('general:socialsecurityNumber')}
            helperText={
              hasError('socialSecurity', errors)
                ? t(getError(['socialSecurity', 'message'], errors))
                : t('general:optional')
            }
            error={hasError('socialSecurity', errors)}
            fullWidth
            fillSpace
            type="text"
            inputRef={register(validators.socialSecurity)}
          />
        </Box>
        <Box mb={5}>
          <IconSelectable
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faLanguage} />}
            label={t('general:administrativeLanguage')}
            id="administrativeLanguage"
            name="administrativeLanguage"
            dataTestId="administrativeLanguage"
            options={languageOptions}
            fullWidth
            type="controlledSelect"
            error={hasError('administrativeLanguage', errors)}
            helperText={t(getError(['administrativeLanguage', 'message'], errors))}
            controllerProps={validators.administrativeLanguage}
          />
        </Box>
        <Box mb={5}>
          <IconSelectable
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faHardHat} />}
            label={t('general:jobFunction', { count: 2 })}
            id="jobFunction"
            name="jobFunction"
            dataTestId="jobFunction"
            defaultValue={watch('jobFunction') || []}
            disableCloseOnSelect
            multiple
            getOptionLabel={(option) => option[`label_${language}`]}
            onChange={(val) => {
              return val[1];
            }}
            filterOptions={(options, state) => {
              return options.filter((item) =>
                item[`label_${language}`].toLowerCase().includes(state.inputValue.toLowerCase())
              );
            }}
            noOptionsText={t('general:noOptions')}
            type="autocomplete"
            error={hasError('jobFunction', errors)}
            helperText={
              hasError('jobFunctions', errors)
                ? t(getError(['jobFunctions', 'message'], errors))
                : t('general:optional')
            }
            control={control}
            rules={validators.jobFunction}
            options={jobFunctions}
          />
        </Box>
        <Box mb={5}>
          <IconTextfield
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faEnvelope} />}
            label={t('general:email')}
            id="email"
            name="email"
            inputProps={{ 'data-testid': 'email' }}
            fullWidth
            type="email"
            error={hasError('email', errors)}
            helperText={t(getErrorOrOptional('email', errors))}
            inputRef={register(validators.email)}
          />
        </Box>
        <Box mb={5} display="flex" flexDirection="row">
          <Box mr={2}>
            <IconSelectable
              icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faPhone} />}
              id="prefix"
              name="prefix"
              customList
              defaultValue={prefix}
              dataTestId="prefix"
              disableClearable
              getOptionSelected={(opt, val) => opt.phone === val.phone}
              filterSearch={filterSearch}
              renderOption={(px) => (
                <Box
                  display="flex"
                  flex="1"
                  maxWidth="100%"
                  padding="6px 16px"
                  borderBottom={px.suggested === 4 ? '1' : null}>
                  <Flag code={px.code} width="20" height="20" alt={px.name} />
                  <Box mx={2} flex="1" overflow="hidden">
                    <Typography noWrap variant="body2">
                      {px.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2">+{px.phone}</Typography>
                </Box>
              )}
              getOptionLabel={(opt) => `+${opt.phone}`}
              onChange={(val) => val[1]}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="filled"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" classes={adornmentStyle()}>
                          <Flag code={prefix.code} width="20" height="20" alt={prefix.name} />
                        </InputAdornment>
                      )
                    }}
                  />
                );
              }}
              type="autocomplete"
              error={hasError('prefix', errors)}
              helperText={t(getError(['prefix', 'message'], errors))}
              options={orderedCountryList}
              rules={validators.prefix}
              control={control}
              inputStyles={{
                padding: '18px',
                display: 'flex'
              }}
            />
          </Box>
          <Box flex="1">
            <IconTextfield
              label={t('general:phone')}
              id="phone"
              name="phone"
              inputProps={{ 'data-testid': 'phone' }}
              fullWidth
              error={hasError('phone', errors)}
              helperText={t(getError(['phone', 'message'], errors))}
              onChange={handlePhoneChange}
              inputRef={register(validators.phone)}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <IconTextfield
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faMapMarkerAlt} />}
            label={t('general:streetName')}
            id="streetName"
            name="streetName"
            data-testid="streetName"
            inputProps={{
              maxLength: 25,
              'data-testid': 'streetName'
            }}
            error={hasError('streetName', errors)}
            helperText={t(getErrorOrOptional('streetName', errors))}
            inputRef={register(validators.streetName)}
            fullWidth
          />
        </Box>
        <Box mb={5} display="flex" flexDirection="row" paddingLeft="40px">
          <Box mr={2} width={[1 / 2, 2 / 3]}>
            <IconTextfield
              label={t('general:number')}
              id="number"
              name="streetNumber"
              inputProps={{ 'data-testid': 'streetNumber' }}
              fullWidth
              type="number"
              helperText={t('general:optional')}
              inputRef={register(validators.streetNumber)}
            />
          </Box>
          <Box width={[1 / 2, 1 / 3]}>
            <IconTextfield
              label={t('general:box')}
              id="box"
              name="box"
              inputProps={{ 'data-testid': 'box' }}
              helperText={t('general:optional')}
              fullWidth
              inputRef={register(validators.box)}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <IconSelectable
            label={t('general:postalCode')}
            id="postalCode"
            name="postalCode"
            defaultValue={watch('postalCode') || null}
            onInputChange={(e) => onInputChange(e, 'codes')}
            renderOption={({ value: { postalCode, placeName, countryCode } }) =>
              `${postalCode} - ${placeName} (${countryCode})`
            }
            getOptionLabel={({ value: { postalCode, countryCode } }) => `${postalCode} (${countryCode})`}
            onClose={() => setAsyncOptions((opts) => ({ ...opts, codes: [] }))}
            noOptionsText={noOptionsMessage}
            type="autocomplete"
            onChange={(e) => handleChange(e, 'city')}
            error={hasError('postalCode', errors)}
            helperText={t(getError(['postalCode', 'message'], errors))}
            control={control}
            rules={validators.postalCode}
            options={asyncOptions.codes}
            fillSpace
          />
        </Box>
        <Box mb={5}>
          <IconSelectable
            label={t('general:city')}
            id="city"
            name="city"
            defaultValue={watch('city') || null}
            onInputChange={(e) => onInputChange(e, 'cities')}
            renderOption={({ value: { postalCode, placeName, countryCode } }) =>
              `${postalCode} - ${placeName} (${countryCode})`
            }
            getOptionLabel={({ value: { placeName, countryCode } }) => `${placeName} (${countryCode})`}
            onClose={() => setAsyncOptions((opts) => ({ ...opts, cities: [] }))}
            noOptionsText={noOptionsMessage}
            type="autocomplete"
            onChange={(e) => handleChange(e, 'postalCode')}
            error={hasError('city', errors)}
            helperText={t(getError(['city', 'message'], errors))}
            control={control}
            rules={validators.city}
            options={asyncOptions.cities}
            fillSpace
          />
        </Box>
        <Box mb={5}>
          <IconSelectable
            label={t('general:country')}
            id="country"
            name="country"
            getOptionLabel={(option) => option.name}
            noOptionsText={t('general:noOptions')}
            type="autocomplete"
            onChange={(e) => e[1]}
            error={hasError('country', errors)}
            helperText={t(getError(['country', 'message'], errors))}
            control={control}
            rules={validators.country}
            options={orderedCountryList}
            fillSpace
          />
        </Box>
        <Box mb={5}>
          <IconTextfield
            icon={<FontAwesomeIcon fixedWidth className={iconClasses.root} icon={faComment} />}
            label={t('general:comment')}
            id="comment"
            name="comment"
            data-testid="comment"
            fullWidth
            helperText={t('general:optional')}
            multiline
            rows={5}
            inputRef={register(validators.comment)}
          />
        </Box>
      </form>
    </Box>
  );
};

NewPayrollForm.propTypes = {
  onSend: func.isRequired,
  costCenters: array,
  defaultData: object,
  jobFunctions: array
};

NewPayrollForm.defaultProps = {
  defaultData: {},
  costCenters: [],
  jobFunctions: []
};

export default NewPayrollForm;
/* eslint max-lines: 0 */
