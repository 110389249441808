import { faClock, faUser, faSmile, faCalendarMinus, faCalendarTimes } from '@fortawesome/free-regular-svg-icons';
import { faAmbulance, faBan, faBed, faEuroSign, faStethoscope } from '@fortawesome/free-solid-svg-icons';

export const premiumList = {
  '1': 1023,
  '2': 1024,
  '3': 1027,
  '4': 1026,
  '5': 1028,
  '6': 1029,
  '7': 1025,
  '8': 1022,
  '9': 1021,
  '10': 1020
};

export const premiumIcons = {
  'fa-stethoscope': faStethoscope,
  'fa-ambulance': faAmbulance,
  'fa-calendar-times-o': faCalendarTimes,
  'fa-calendar-minus-o': faCalendarMinus,
  'fa-smile': faSmile,
  'fa-smile-o': faSmile,
  'fa-bed': faBed,
  'fa-eur': faEuroSign,
  'fa-ban': faBan,
  'fa-user': faUser,
  'fa-clock-o': faClock
};

export const bonuses = [
  {
    premium_code: 1001,
    smile_code_type: 3,
    description_fr: 'Heures supplémentaires',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1127,
    smile_code_type: 3,
    description_fr: 'JOUR FERIE F',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1139,
    smile_code_type: 1,
    description_fr: 'HEURES STAND-BY',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1140,
    smile_code_type: 1,
    description_fr: 'TEMPS DE DISPONIBLIT',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1146,
    smile_code_type: 1,
    description_fr: 'PRIME INSALUB./HEURE',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1149,
    smile_code_type: 1,
    description_fr: 'PRIME EQUIPE/HR',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1150,
    smile_code_type: 1,
    description_fr: 'PRIME DE FROID / H.',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1151,
    smile_code_type: 1,
    description_fr: 'PRIME PROD./HR',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1152,
    smile_code_type: 1,
    description_fr: 'PRIME/H. TRAV.LOURD',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1153,
    smile_code_type: 1,
    description_fr: 'PRIME/H TRAV.EXCEP.',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1159,
    smile_code_type: 1,
    description_fr: 'PR/HR NUIT',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1160,
    smile_code_type: 1,
    description_fr: 'SUPL.EQUIPE MATIN',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1161,
    smile_code_type: 1,
    description_fr: 'SUPL.EQUIPE APR-MID',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1162,
    smile_code_type: 1,
    description_fr: 'SUPL.EQUIPE NUIT',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1173,
    smile_code_type: 1,
    description_fr: 'PRIME PAUSE',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 1350,
    smile_code_type: 1,
    description_fr: 'INDEMN. RGPT/H',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 2128,
    smile_code_type: 4,
    description_fr: 'PRIME INSALUB./JOUR',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 2141,
    smile_code_type: 2,
    description_fr: 'Prime Masque',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 2312,
    smile_code_type: 4,
    description_fr: 'INDEMN. FRAIS REPAS',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 2350,
    smile_code_type: 4,
    description_fr: 'CHEQUE REPAS TRAV.',
    description_nl: null,
    is_active: true,
    is_positive: true
  },
  {
    premium_code: 2362,
    smile_code_type: 4,
    description_fr: 'INDEMN. JOURNALIERE',
    description_nl: null,
    is_active: true,
    is_positive: true
  }
];

export const getPremiumCode = (data) => {
  let code = 1101;
  if (data.difference < 0) {
    code = data.reason ? premiumList[`${data.reason}`] : 1101;
  } else if (data.difference > 0) {
    code = 1001;
  }
  return code;
};
