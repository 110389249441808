import React, { useState, useRef, useCallback, useEffect } from 'react';
import { array, func, bool, object } from 'prop-types';
import { Box, Divider, Typography, IconButton, LinearProgress, CircularProgress } from '@material-ui/core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { localeDateFormat } from 'utils/date';
import { costCenterString } from 'utils/tempWorkers';
import InfiniteScroll from 'react-infinite-scroller';
import FilterDocuments from './FilterDocuments';
import typeIcons from '../common/typeIcons';

const DocumentList = ({ data, costCenters, search, debouncedSearch, onSearch, getDocument, loadMore, isLoading }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentPage = useRef(1);
  const activeLoadMore = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const isMounted = useRef(true);

  const getNext = useCallback(
    async (criteria) => {
      const receivedNew = await loadMore(criteria);
      if (isMounted.current) {
        activeLoadMore.current = receivedNew;
        setHasMore(receivedNew);
      }
    },
    [loadMore, setHasMore, activeLoadMore]
  );

  useEffect(() => {
    if (debouncedSearch) {
      activeLoadMore.current = false;
      currentPage.current = 1;
      setHasMore(true);
      getNext({ ...debouncedSearch, page: 1, stack: false });
    }
  }, [debouncedSearch, getNext]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        if (activeLoadMore.current) {
          currentPage.current += 1;
          activeLoadMore.current = false;
          getNext({ ...search, page: currentPage.current, stack: true });
        }
      }}
      hasMore={hasMore}
      loader={
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" height={100} key="loader">
          <CircularProgress />
        </Box>
      }>
      <Box width="100%" position="sticky" top={56} bgcolor="light.main">
        <Box display="flex" justifyContent="space-between" alignItems="center" py={1} pl={1} pr={4}>
          <IconButton onClick={() => setIsModalOpen(true)}>
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faFilter} />
          </IconButton>
          <Typography variant="body2" color="secondary">{`${data.length} ${t('general:menu.documents')}`}</Typography>
        </Box>
        <Divider />
      </Box>
      {isLoading && <LinearProgress />}
      {data.map(({ document_type_id, title, costCenters: docCenters, created_at, uuid }) => (
        <Box
          display="flex"
          flexDirection="row"
          pt={3}
          pl={3}
          key={`${title}-${created_at}`}
          onClick={() => getDocument(uuid)}>
          <Box pr={3}>
            {React.cloneElement(
              typeIcons[document_type_id - 1],
              { title: t(`general:docs.category.${document_type_id}`) },
              null
            )}
          </Box>
          <Box flex="1">
            <Box pb={3} pr={3}>
              <Typography variant="body1">{title}</Typography>
              <Typography variant="body2" color="secondary">
                {docCenters.map((center) => costCenterString(center)).join(', ')}
              </Typography>
              <Typography variant="body2" color="secondary">
                {format(new Date(created_at.replace(' ', 'T')), localeDateFormat)}
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
      ))}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <FilterDocuments
          search={search}
          costCenters={costCenters}
          onFilter={(filters) => {
            setIsModalOpen(false);
            onSearch(filters);
          }}
        />
      </Modal>
    </InfiniteScroll>
  );
};

DocumentList.propTypes = {
  data: array.isRequired,
  costCenters: array.isRequired,
  search: object,
  debouncedSearch: object,
  onSearch: func.isRequired,
  getDocument: func.isRequired,
  loadMore: func,
  isLoading: bool
};

DocumentList.defaultProps = {
  search: null,
  debouncedSearch: null,
  loadMore: () => null,
  isLoading: false
};

export default DocumentList;
