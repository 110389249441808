import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const TextButton = withStyles({
  root: {
    textTransform: 'none'
  }
})((props) => <Button {...props} />);

export default TextButton;
