/* eslint max-lines: 0 */
import React, { useState } from 'react';
import { prop } from 'ramda';
import { isValidDate } from 'ramda-adjunct';
import { Box, TextField, Typography, InputAdornment } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import FrLocaleUtilsProvider from 'components/FrLocaleUtilsProvider';
import GreenCheckbox from 'components/GreenCheckbox';
import { minutesToPickerValue } from 'utils/date';
import { Euro } from '@material-ui/icons';
import PropTypes, { array, func, object, string, number, node } from 'prop-types';
import CustomTimePicker from 'components/CustomTimePicker';
import { getChangedBonuses } from './common';

export const PerformedComponent = ({ value, onChange, onValidDateChange, day, classes }) => {
  return (
    <FrLocaleUtilsProvider>
      <CustomTimePicker
        id={`performedHours-${day}`}
        ampm={false}
        format="HH'h'mm"
        mask="__h__"
        autoOk
        value={value}
        helperText={null}
        variant="inline"
        onChange={(event) => {
          onChange(event);
          if (isValidDate(event) && event !== value) onValidDateChange(event);
        }}
        onBlur={(_, val) => {
          if (!isValidDate(val)) {
            onChange('');
            onValidDateChange('');
          }
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: { root: classes.bonusInputRoot, input: classes.bonusInputTablet }
        }}
        keyboardIcon={false}
        InputAdornmentProps={{ classes: { root: classes.keyboardIcon } }}
      />
    </FrLocaleUtilsProvider>
  );
};

PerformedComponent.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: func,
  onValidDateChange: func,
  day: PropTypes.instanceOf(Date),
  classes: object.isRequired
};

PerformedComponent.defaultProps = {
  value: null,
  onChange: () => null,
  onValidDateChange: () => null,
  day: new Date()
};

export const TimeComponent = ({ premiumCode, date, bonus, storedBonuses, onBonusesChange, label, keyboardIcon }) => {
  const [value, setValue] = useState(minutesToPickerValue(bonus.minutes));
  return (
    <FrLocaleUtilsProvider>
      <KeyboardTimePicker
        id={`premiumDate-${premiumCode}-${date}`}
        ampm={false}
        format="HH'h'mm"
        mask="__h__"
        autoOk
        fullWidth
        value={value}
        helperText={null}
        inputVariant="filled"
        label={label}
        onChange={(event) => {
          // lorsque les premiums codes sont récupérés, les dupliquer pour tous les jours qui ont des heures et set amount et minutes à 0
          setValue(event);
          onBonusesChange(
            getChangedBonuses(
              { premiumCode, smileCode: bonus.smile_code_type, pdate: new Date(date), storedBonuses },
              event
            )
          );
        }}
        keyboardIcon={keyboardIcon}
      />
    </FrLocaleUtilsProvider>
  );
};

TimeComponent.propTypes = {
  premiumCode: number,
  date: string,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func,
  label: string,
  keyboardIcon: node
};

TimeComponent.defaultProps = {
  premiumCode: null,
  date: null,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {},
  label: null,
  keyboardIcon: null
};

export const AmountComponent = ({ premiumCode, date, bonus, storedBonuses, onBonusesChange, label }) => {
  const [value, setValue] = useState((bonus.amount || 0).toFixed(2).toString().replace('.', ','));
  const [formatError, setFormatError] = useState(false);
  return (
    // eslint-disable-line react/jsx-no-duplicate-props
    <TextField
      label={label}
      variant="filled"
      fullWidth
      error={formatError}
      value={value}
      onChange={(event) => {
        const val = event.target.value;
        setFormatError(true);
        if (/^[0-9]+(,[0-9]{2})?$/.test(val)) {
          setFormatError(false);
          onBonusesChange(
            getChangedBonuses(
              { premiumCode, smileCode: bonus.smile_code_type, pdate: new Date(date), storedBonuses },
              event
            )
          );
        }
        if (!val || /^[0-9]+(,[0-9]{0,2})?$/.test(val)) {
          setValue(val);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Euro />
          </InputAdornment>
        )
      }}
    />
  );
};

AmountComponent.propTypes = {
  premiumCode: number,
  date: string,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func,
  label: string
};

AmountComponent.defaultProps = {
  premiumCode: null,
  date: null,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {},
  label: null
};

export const CheckBoxComponent = ({ date, bonus, premiumCode, storedBonuses, onBonusesChange, label }) => {
  return (
    <Box
      component="label"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%">
      <Typography component="span" variant="body2" color="secondary">
        {label}
      </Typography>
      <Box mr={-2}>
        <GreenCheckbox
          id={`cb-${date}`}
          checked={!!prop('minutes', bonus)}
          onChange={(event) => {
            onBonusesChange(
              getChangedBonuses(
                { premiumCode, smileCode: bonus.smile_code_type, pdate: new Date(date), storedBonuses },
                event
              )
            );
          }}
          color="primary"
        />
      </Box>
    </Box>
  );
};

CheckBoxComponent.propTypes = {
  label: string,
  premiumCode: number,
  date: string,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func
};

CheckBoxComponent.defaultProps = {
  label: null,
  premiumCode: null,
  date: null,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {}
};
