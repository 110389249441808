import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pageViewGA } from 'utils/analytics';

const useGAPageView = () => {
  const location = useLocation();

  useEffect(() => {
    pageViewGA(location.pathname, location.pathname);
  }, [location]);
};

export default useGAPageView;
