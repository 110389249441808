import { api, secureCall } from 'utils/api';
import { formatDate } from 'utils/date';
import { cleanObj } from 'utils/object';

export const getDocumentsList = secureCall(
  async ({ title, cost_centers, date, document_type_id, page, order_by_field, order_by_direction }) =>
    api.get(
      'daco-document',
      cleanObj({
        ...(cost_centers.length && { 'cost_center_ids[]': cost_centers.map((c) => c.id) }),
        ...(parseInt(document_type_id, 10) !== 10 && {
          'document_type_ids[]': parseInt(document_type_id, 10) === 0 ? [1, 2] : [document_type_id]
        }),
        order_by_direction,
        order_by_field,
        date: formatDate(date, false),
        full_search: title,
        page
      })
    )
);
export const getDocument = secureCall(async (payload) => api.get('daco-document/download', payload));
export const getHistory = secureCall(async () => api.get('daco-document/request-history'));
export const requestDocument = secureCall(async (payload) => api.post('daco-document/request', payload));
