import React from 'react';
import { bool, node, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

const inputStyle = makeStyles((theme) => ({
  root: {
    border: 'none',
    overflow: 'hidden',
    borderRadius: 0,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.divider
    },
    '&$focused': {
      boxShadow: `${theme.palette.primary.main} 0 0 0 2px`
    }
  }
}));

const IconTextField = ({ error, helperText, icon, label, fillSpace, type, ...props }) => {
  const getField = (classes) => {
    let comp;
    switch (type.toLowerCase()) {
      case 'date':
        comp = (
          <FormControl fullWidth variant="filled">
            <KeyboardDatePicker label={label} InputProps={{ classes }} {...props} />
          </FormControl>
        );
        break;
      case 'controlleddate':
        /* Note:
         * datepicker requires extra attention for react-hook-form
         * https://spectrum.chat/react-hook-form/help/solved-material-ui-datepicker-keyboard-datepicker-and-react-hook-form-rhf-loading-date-data-from-api~93388d29-2f9a-4b80-98e5-a7cafbe55b28
         */
        comp = (
          <FormControl fullWidth variant="filled" error={error}>
            <Controller as={KeyboardDatePicker} label={label} InputProps={{ classes }} {...props} />
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </FormControl>
        );
        break;
      default:
        comp = (
          <TextField
            label={label}
            variant="filled"
            error={error}
            helperText={helperText}
            type={type}
            InputProps={{ classes }}
            {...props}
          />
        );
        break;
    }
    return comp;
  };
  const classes = inputStyle({ fillSpace });
  return (
    <Box display="flex">
      {icon ? (
        <Box
          fontSize="20px"
          minWidth="40px"
          height="30px"
          position="relative"
          top="12px"
          display="flex"
          alignItems="center">
          {icon}
        </Box>
      ) : (
        <Box minWidth={fillSpace ? '40px' : '0'} />
      )}
      {getField(classes)}
    </Box>
  );
};

IconTextField.propTypes = {
  icon: node,
  error: bool,
  fillSpace: bool,
  type: string,
  helperText: string,
  label: string
};

IconTextField.defaultProps = {
  icon: undefined,
  error: false,
  fillSpace: false,
  type: 'text',
  helperText: '',
  label: ''
};

export default IconTextField;
