import { makeStyles } from '@material-ui/core/styles';

export const newContractStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  cardContainer: {
    maxWidth: theme.breakpoints.values.md
  }
}));
