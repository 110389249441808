import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { func, object } from 'prop-types';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { getPreviousWorkDay } from 'utils/date';
import clsx from 'clsx';

import { renderOnCondition } from 'utils/component';

import { PerformanceScheduleDesktop } from 'components/PerformanceSchedule';
import OrderWorkerCard from '../common/OrderWorkerCard';
import OrderInfo from '../common/OrderInfo';
import OrderActions from '../common/OrderActions';

const useStyles = makeStyles((theme) => ({
  weekLink: {
    cursor: 'pointer'
  },
  iconColor: {
    color: theme.palette.secondary.dark
  },
  iconSize: {
    fontSize: 13
  }
}));

const DesktopDetailView = ({ history, location, setCandidate, cancelOrder, customer, getOrderById, order }) => {
  const { t } = useTranslation(['general']);
  const isFirstRender = useRef(true);
  const [showUpdateOrOrderAgain, setShowUpdateOrOrderAgain] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [actionType, setActionType] = useState('default');
  const classes = useStyles();
  const orderId = useMemo(() => pathOr({}, ['state', 'orderId'], location), [location]);
  const fromPlanning = useMemo(() => pathOr({}, ['state', 'fromPlanning'], location), [location]);

  const [currentOrder, setCurrentOrder] = useState();
  const [link, setLink] = useState();

  const canUpdate = useMemo(() => {
    if (!currentOrder) return false;
    if ([5, 7].includes(currentOrder.order_status_id)) return false;
    const now = new Date();
    const orderEditable = getPreviousWorkDay(currentOrder.start);
    orderEditable.setHours(17, 0, 0);
    return now < orderEditable;
  }, [currentOrder]);

  const handleUpdateOrOrderAgain = (action) => {
    const candidate = {
      ...currentOrder.firstCandidate,
      firstName: pathOr('', ['firstCandidate', 'first_name'], currentOrder),
      lastName: pathOr('', ['firstCandidate', 'last_name'], currentOrder),
      from: new Date(currentOrder.start),
      to: new Date(currentOrder.end),
      costCenter: currentOrder.costCenter,
      reference: currentOrder.invoice_reference,
      jobFunction: currentOrder.mainJobFunction,
      scheduleDays: currentOrder.scheduleDays,
      contractId: currentOrder.source_contract_id,
      description: currentOrder.description,
      vacancies: currentOrder.openings
    };
    setCandidate(candidate);
    setActionType(action);
    setShowUpdateOrOrderAgain(true);
  };

  useEffect(() => {
    if (!currentOrder) return;
    if (customer?.customer_id !== currentOrder?.customer?.id && isFirstRender.current) {
      history.replace('/orders');
    }
    isFirstRender.current = false;
  }, [customer, currentOrder, history]);

  useEffect(() => {
    setLink(!fromPlanning || isEmpty(fromPlanning) ? 'orders' : 'planning');
  }, [fromPlanning]);

  useEffect(() => {
    if (!orderId) return;
    getOrderById(orderId);
  }, [orderId]);

  useEffect(() => {
    setCurrentOrder(order);
  }, [order]);

  if (!currentOrder || isEmpty(currentOrder)) return null;

  return (
    <>
      <Container maxWidth="xl">
        <Box display="flex" justifyContent="space-between" mt={5}>
          <Box
            className={classes.weekLink}
            onClick={() => history.push(`/${link}`)}
            display="flex"
            flexDirection="row"
            alignItems="center">
            <Box>
              <FontAwesomeIcon icon={faChevronLeft} className={clsx(classes.iconColor, classes.iconSize)} />
            </Box>
            <Box ml={5}>
              <Typography component="span" variant="body1" color="secondary">
                {t(`general:${link}.title`)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.buttonGroup} display="flex" flexDirection="row">
            {renderOnCondition(
              [2, 3, 4].includes(currentOrder.order_type_id),
              <>
                <Box mr={2}>
                  <Button
                    disabled={!canUpdate}
                    onClick={() => handleUpdateOrOrderAgain('update')}
                    startIcon={<FontAwesomeIcon icon={faEdit} />}
                    variant="outlined"
                    color="primary">
                    {t('general:edit')}
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    disabled={!canUpdate}
                    onClick={() => setShowConfirmCancel(true)}
                    startIcon={<FontAwesomeIcon icon={faBan} />}
                    variant="outlined"
                    color="primary">
                    {t('general:cancel')}
                  </Button>
                </Box>
              </>
            )}
            <Button variant="contained" color="primary" onClick={() => handleUpdateOrOrderAgain('orderAgain')}>
              {t('general:orders.orderAgain')}
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap={['wrap-reverse', null, null, 'wrap']}
          justifyContent="space-between"
          mt={6}
          mb={6}>
          <OrderWorkerCard order={currentOrder} />
          <Box mr={16}>
            <OrderInfo order={currentOrder} />
          </Box>
        </Box>
        {renderOnCondition(
          [2, 3, 4].includes(currentOrder.order_type_id),
          <>
            <Box mb={4}>
              <PerformanceScheduleDesktop
                date={new Date(currentOrder.start)}
                contractHours={currentOrder.scheduleDays}
                changedHours={currentOrder.scheduleDays}
                savedHours={currentOrder.scheduleDays}
              />
              <Divider />
            </Box>
            <Box display="flex" flexWrap="wrap" my={2} minHeight={100}>
              <Box width={[1, 1, 1 / 2]} mb={4}>
                <Box mb={2}>
                  <Typography variant="body2">{t('general:comment')}</Typography>
                </Box>
                <Typography variant="body2" color="secondary">
                  {currentOrder.description}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Container>
      <OrderActions
        order={currentOrder}
        actionType={actionType}
        showUpdateOrOrderAgain={showUpdateOrOrderAgain}
        setShowUpdateOrOrderAgain={setShowUpdateOrOrderAgain}
        showConfirmCancel={showConfirmCancel}
        setShowConfirmCancel={setShowConfirmCancel}
        cancelOrder={cancelOrder}
        getOrderById={getOrderById}
      />
    </>
  );
};

DesktopDetailView.propTypes = {
  history: object.isRequired,
  customer: object.isRequired,
  location: object.isRequired,
  setCandidate: func.isRequired,
  cancelOrder: func.isRequired,
  getOrderById: func.isRequired,
  order: object
};

DesktopDetailView.defaultProps = {
  order: null
};

const mapState = ({ user: { currentCustomer }, orders: { order } }) => ({
  customer: currentCustomer,
  order
});

const mapDispatch = ({ contracts: { setCandidate }, orders: { cancel, getOrderById } }) => ({
  setCandidate,
  cancelOrder: cancel,
  getOrderById
});

export default connect(mapState, mapDispatch)(withRouter(DesktopDetailView));
