import React, { useMemo } from 'react';
import { bool, func, object, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { prop, sum } from 'ramda';

import { convertMinutesToTime } from 'utils/date';

import { Modal, NewProlongation, OrderName } from 'components';
import { useHistory, useLocation } from 'react-router';

import NewDemandHandler from 'components/NewDemandHandler/NewDemandHandler';
import NewContractHandler from 'components/NewContractHandler/NewContractHandler';
import OrderPeriod from './OrderPeriod';

const getDateOrNull = (date) => (date ? new Date(date) : null);
const getContractType = (order) => {
  let type = [];
  if (order.is_seasonal) type = [...type, 56];
  if (order.is_flexi) type = [...type, 30];
  return type;
};
const getContractStatus = (order) => {
  if (order.is_student) return 5;
  if (order.is_student_without_contingent) return 9;
  return null;
};

// eslint-disable-next-line complexity
const OrderActions = ({
  order,
  actionType,
  showUpdateOrOrderAgain,
  setShowUpdateOrOrderAgain,
  showConfirmCancel,
  setShowConfirmCancel,
  cancelOrder,
  getOrderById
}) => {
  const { t } = useTranslation(['general']);
  const history = useHistory();
  const location = useLocation();

  const totalHours = useMemo(
    () =>
      sum(
        Object.values(order.scheduleDays)
          .filter((day) => !!day)
          .map((day) => prop('total_hours', day))
      ),
    [order]
  );

  return (
    <>
      <Modal
        isOpen={showUpdateOrOrderAgain}
        onClose={(contract) => {
          if (contract?.order && showUpdateOrOrderAgain) {
            history.replace(location.pathname, { ...location.state, order: contract.order });
          }
          setShowUpdateOrOrderAgain(false);
        }}>
        {order.order_type_id === 2 && (
          <NewContractHandler
            isEditing={actionType === 'update'} // TODO: isEditing & mobile view
            actionType={actionType}
            defaultValues={{
              costCenter: order.costCenter,
              jobFunction: order.mainJobFunction,
              reference: actionType === 'orderAgain' ? null : order.invoice_reference,
              interimWorker: order.firstCandidate,
              from: actionType === 'orderAgain' ? null : getDateOrNull(order.start),
              to: actionType === 'orderAgain' ? null : getDateOrNull(order.end),
              reason: order.reason_id,
              salaryAmount: order.hourly_rate,
              isPredefined: !!order.hourly_rate,
              contractType: getContractType(order),
              status: getContractStatus(order),
              description: order.description,
              vacancies: order.openings
            }}
            defaultScheduleDays={order.scheduleDays}
            orderId={order.id}
          />
        )}
        {order.order_type_id === 3 && (
          <NewProlongation
            order={order}
            isEditing={actionType === 'update'}
            {...(actionType === 'update' && { orderId: order.id })}
          />
        )}
        {order.order_type_id === 4 && (
          <NewDemandHandler
            isEditing={actionType === 'update'}
            actionType={actionType}
            defaultValues={{
              costCenter: order.costCenter,
              jobFunction: order.mainJobFunction,
              reference: actionType === 'orderAgain' ? null : order.invoice_reference,
              interimWorker: order.firstCandidate,
              from: actionType === 'orderAgain' ? null : getDateOrNull(order.start),
              to: actionType === 'orderAgain' ? null : getDateOrNull(order.end),
              reason: order.reason_id,
              salaryAmount: order.hourly_rate,
              isPredefined: !!order.hourly_rate,
              contractType: getContractType(order),
              status: getContractStatus(order),
              description: order.description,
              vacancies: order.openings
            }}
            defaultScheduleDays={order.scheduleDays}
            orderId={order.id}
          />
        )}
      </Modal>
      <Dialog
        open={showConfirmCancel}
        onClose={() => setShowConfirmCancel(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description">
        <DialogTitle disableTypography id="confirm-dialog-title">
          <Typography variant="h6" component="h2">
            {t(`general:orders.cancel.${order.order_type_id}`)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="textSecondary" variant="body1">
              <OrderName order={order} />
            </Typography>
            <OrderPeriod noIcon start={order.start} end={order.end} />
            <Typography color="textSecondary" variant="body1">
              {convertMinutesToTime(totalHours, 'h')}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmCancel(false)} color="primary">
            {t('general:cancel')}
          </Button>
          <Button
            onClick={async () => {
              const result = await cancelOrder(order.id);
              await getOrderById(result.order.id);
              if (result?.order) {
                history.replace(location.pathname, { ...location.state, order: result.order });
              }
              setShowConfirmCancel(false);
            }}
            variant="contained"
            color="primary"
            autoFocus>
            {t('general:confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

OrderActions.propTypes = {
  order: object.isRequired,
  actionType: oneOf(['update', 'orderAgain', 'default']).isRequired,
  showUpdateOrOrderAgain: bool,
  setShowUpdateOrOrderAgain: func,
  showConfirmCancel: bool,
  setShowConfirmCancel: func,
  cancelOrder: func,
  getOrderById: func.isRequired
};

OrderActions.defaultProps = {
  showUpdateOrOrderAgain: false,
  setShowUpdateOrOrderAgain: () => null,
  showConfirmCancel: false,
  setShowConfirmCancel: () => null,
  cancelOrder: () => null
};

export default OrderActions;
