import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { func } from 'prop-types';

const Impersonate = ({ impersonate }) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const token = params.get('token');
  const impersonatorId = params.get('impersonator_id');

  useEffect(() => {
    impersonate({ email, token, impersonatorId });
  }, [email, impersonate, impersonatorId, token]);

  return null;
};

Impersonate.propTypes = {
  impersonate: func.isRequired
};

const mapDispatch = ({ user: { impersonate } }) => ({ impersonate });

export default connect(null, mapDispatch)(Impersonate);
