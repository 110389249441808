import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Button } from '@material-ui/core';

import { renderOnCondition } from 'utils/component';
import { makeStyles } from '@material-ui/core/styles';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import legalNL from 'assets/legal_Waarom uitzendwerk NL.pdf';
import legalFR from 'assets/legal_Daoust_Motif INT 2022_FR.pdf';

const useStyles = makeStyles((theme) => ({
  bold: {
    'font-weight': 800
  },
  txtLegal: {
    'font-style': 'italic',
    color: theme.palette.primary.main,
    'font-size': '1.2rem'
  },
  btnLegal: {
    'font-size': '1.2rem',
    top: '-1.4rem'
  },
  link: {
    'font-style': 'italic',
    color: theme.palette.primary.main,
    'font-size': '1.2rem'
  }
}));

const LegalContractInfo = () => {
  const classes = useStyles();

  const { i18n } = useTranslation(['general', 'validation']);
  const { language } = i18n;

  const [showFullLegalText, setShowFullLegalText] = useState(false);

  return (
    <>
      <Box mt={2}>
        {renderOnCondition(
          language === 'fr',
          <Box className={classes.txtLegal}>
            {showFullLegalText ? (
              <Typography color="primary" variant="body2" className={classes.txtLegal}>
                Merci de bien <span className={classes.bold}>communiquer spontanément </span> et vérifier
                continuellement <span className={classes.bold}>le motif de mise au travail</span> de nos intérimaires
                pour toute mission/prolongation d’intérim, en fonction de la réalité de votre entreprise et de la
                mission d‘intérim. <br />
                <br /> En cas de doute, vous trouverez à titre indicatif le résumé des motifs légaux possibles à choisir
                en fonction de votre situation, sur notre{' '}
                <a
                  className={classes.link}
                  href={legalFR}
                  download="Daoust_Motif INT 2022_FR.pdf"
                  target="_blank"
                  rel="noreferrer">
                  plaquette commerciale
                </a>{' '}
                que vous pouvez parcourir à l’aide le cas échéant de votre secrétariat social.
              </Typography>
            ) : (
              <Typography color="primary" variant="body2" className={classes.txtLegal}>
                Merci de bien <span className={classes.bold}>communiquer spontanément </span> et vérifier
                continuellement <span className={classes.bold}>le motif de mise au travail</span> de nos intérimaires...
              </Typography>
            )}
          </Box>
        )}

        {renderOnCondition(
          language === 'nl',
          <Box className={classes.txtLegal}>
            {showFullLegalText ? (
              <Typography color="primary" variant="body2" className={classes.txtLegal}>
                Bedankt <span className={classes.bold}> om spontaan te communiceren </span> en voortdurend{' '}
                <span className={classes.bold}> de reden na te gaan voor de tewerkstelling </span> van onze
                uitzendkrachten voor elke tijdelijke opdracht of verlenging, afhankelijk van de situatie van de
                onderneming en de tijdelijke opdracht. <br />
                <br /> In geval van twijfel vindt u een overzicht van de mogelijke wettelijke redenen waaruit u kan
                kiezen in onze{' '}
                <a
                  className={classes.link}
                  href={legalNL}
                  download="Waarom uitzendwerk NL.pdf"
                  target="_blank"
                  rel="noreferrer">
                  commerciële brochure
                </a>
                , die u indien nodig kunt raadplegen met de hulp van uw sociaal secretariaat.
              </Typography>
            ) : (
              <Typography color="primary" variant="body2" className={classes.txtLegal}>
                Bedankt <span className={classes.bold}> om spontaan te communiceren </span> en voortdurend{' '}
                <span className={classes.bold}> de reden na te gaan voor de tewerkstelling </span> van onze
                uitzendkrachten...
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row-reverse">
        <Button
          disableElevation
          variant="text"
          type="button"
          size="small"
          onClick={() => setShowFullLegalText(!showFullLegalText)}
          color="primary"
          className={classes.btnLegal}>
          {showFullLegalText ? (
            <FontAwesomeIcon fixedWidth icon={faMinus} />
          ) : (
            <FontAwesomeIcon fixedWidth icon={faPlus} />
          )}
        </Button>
      </Box>
    </>
  );
};

export default LegalContractInfo;
