import React, { useMemo, useState } from 'react';
import { compose, propOr, path } from 'ramda';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { bool, func, object, array } from 'prop-types';
import { Check, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Box, Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

const getName = (customer) => {
  if (path(['customer_commercial_name', 'length'], customer)) return customer.customer_commercial_name;
  return propOr('', 'customer_name', customer);
};

const CustomerDropdown = ({ classes, availableCustomers, currentCustomer, switchCurrentCustomer, isMobile }) => {
  const [open, setOpen] = useState(false);
  const hasMultipleCustomerContacts = useMemo(() => propOr(0, 'length', availableCustomers) > 1, [availableCustomers]);

  return (
    <>
      <Box mt={isMobile ? 0 : 2}>
        <ListItem
          button
          style={{ minHeight: 32 }}
          onClick={() => setOpen((current) => !current)}
          className={clsx({ [classes.noPadding]: isMobile })}>
          <ListItemText primary={getName(currentCustomer)} />
          {hasMultipleCustomerContacts && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      </Box>
      {hasMultipleCustomerContacts ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" className={classes.nested}>
            {(availableCustomers || []).map((customerContact) => (
              <ListItem
                button
                key={customerContact.id}
                onClick={() => {
                  switchCurrentCustomer(customerContact.id);
                  setOpen(false);
                }}
                className={clsx({ [classes.noPadding]: isMobile })}>
                <ListItemText primary={getName(customerContact)} />
                {customerContact.id === (currentCustomer || {}).id && <Check />}
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : (
        <></>
      )}
    </>
  );
};

CustomerDropdown.propTypes = {
  classes: object.isRequired,
  availableCustomers: array,
  currentCustomer: object,
  switchCurrentCustomer: func,
  isMobile: bool.isRequired
};

CustomerDropdown.defaultProps = {
  availableCustomers: null,
  currentCustomer: null,
  switchCurrentCustomer: () => {}
};

const mapState = ({ user: { availableCustomers, currentCustomer, isOnMobile } }) => ({
  availableCustomers,
  currentCustomer,
  isMobile: isOnMobile
});

const mapDispatch = ({ user: { switchCurrentCustomer } }) => ({
  switchCurrentCustomer
});

const styles = createStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  noPadding: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default compose(withStyles(styles), connect(mapState, mapDispatch))(CustomerDropdown);
