import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object, bool } from 'prop-types';
import { Box, Container, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { pathOr, prop } from 'ramda';

import { useHeader } from 'hooks';
import { CookieConsent } from 'components';
import ChangePassword from './ChangePassword';
import SwitchField from './SwitchField';
import TextButton from './TextButton';
import ConfirmDeletion from './ConfirmDeletion';

const Settings = ({
  user,
  update,
  updatePassword,
  getUserInfos,
  requestAccountDeletion,
  userInfos,
  isOnMobile,
  isLoading
}) => {
  const { t } = useTranslation(['general', 'user', 'cookieConsent']);
  const { setTitle, setComponents } = useHeader();
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const togglePassWordDisplay = () => setShowPasswordChange(!showPasswordChange);
  const handleContractChange = (val) => update({ confirmation_email: val ? 1 : 0 });
  const handleNewDocumentChange = (val) => update({ document_notification: val ? 1 : 0 });

  const changePassword = async (data) => {
    const res = await updatePassword(data);
    if (res) setShowPasswordChange(false);
  };

  const handleAccountDeletionRequest = async () => {
    const res = await requestAccountDeletion();
    if (res) await getUserInfos();
  };

  useEffect(() => {
    setTitle('general:menu.parameters');
    setComponents([]);
  }, [setTitle, setComponents]);

  useEffect(() => {
    if (!userInfos) {
      getUserInfos();
    }
  }, [userInfos]);

  return (
    <Container maxWidth="xl">
      {!isOnMobile && (
        <Box my={5}>
          <Typography variant="h6" component="h2">
            {t('general:menu.parameters')}
          </Typography>
        </Box>
      )}
      <Box width={[1, 1, 2 / 3, 1 / 2]} m="auto" display="flex" flexDirection="column" justifyContent="center">
        <Box my={4}>
          <Typography variant="body1" mb={6}>
            {t('general:connection')}
          </Typography>
          <Box py={1} alignItems="baseline" display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="secondary">
              {t('user:password')}
            </Typography>
            <TextButton color="primary" onClick={togglePassWordDisplay} disableFocusRipple>
              {showPasswordChange ? t('general:cancel') : t('general:edit')}
            </TextButton>
          </Box>
          {showPasswordChange && <ChangePassword onChange={changePassword} />}
          <Divider />
        </Box>
        <Box my={4}>
          <Typography variant="body1" mb={6}>
            {t('user:emailNotification')}
          </Typography>
          <SwitchField
            label={t('general:contractCreation')}
            checked={!!prop('confirmation_email', user)}
            onChange={handleContractChange}
          />
          <Divider />
          <SwitchField
            label={t('general:newDocument')}
            checked={!!prop('document_notification', user)}
            onChange={handleNewDocumentChange}
          />
          <Divider />
        </Box>
        <Box my={4}>
          <Typography variant="body1" mb={6}>
            {t('cookieConsent:privateLife')}
          </Typography>
          <Box py={1} alignItems="baseline" display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="secondary">
              {t('cookieConsent:cookiePreferences')}
            </Typography>
            <TextButton color="primary" onClick={() => setShowCookieConsent(true)} disableFocusRipple>
              {t('general:edit')}
            </TextButton>
          </Box>
          <CookieConsent isOpen={showCookieConsent} setIsOpen={() => setShowCookieConsent(false)} />
          <Divider />
        </Box>
        <Box my={4}>
          <Typography variant="body1" mb={6}>
            {t('user:deleteAccount.title')}
          </Typography>
          <Box py={1} alignItems="baseline" display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="secondary">
              {t('user:deleteAccount.action')}
            </Typography>
            <TextButton
              color="primary"
              onClick={() => setConfirmationDialogVisible(true)}
              disableFocusRipple
              disabled={isLoading || Boolean(userInfos?.user?.has_sent_delete_account_request)}>
              {userInfos?.user?.has_sent_delete_account_request
                ? t('user:deleteAccount.deletionRequested')
                : t('user:actions.delete')}
            </TextButton>
          </Box>
          <Divider />
        </Box>
      </Box>
      <ConfirmDeletion
        isOpen={confirmationDialogVisible}
        onClose={() => setConfirmationDialogVisible(false)}
        alignTitle="center"
        confirm={handleAccountDeletionRequest}
        showCloseButton
        disabled={isLoading}
        text={
          userInfos?.user?.has_sent_delete_account_request
            ? t('user:deleteAccount.deletionRequestedModal')
            : t('user:deleteAccount.deletionConfirmation')
        }
        title={t('user:deleteAccount.action')}
        hasRequestedDeletion={!!userInfos?.user?.has_sent_delete_account_request}
      />
    </Container>
  );
};

Settings.propTypes = {
  user: object.isRequired,
  update: func.isRequired,
  updatePassword: func.isRequired,
  isOnMobile: bool.isRequired,
  userInfos: object,
  getUserInfos: func.isRequired,
  requestAccountDeletion: func.isRequired,
  isLoading: bool.isRequired
};

Settings.defaultProps = {
  userInfos: null
};

const mapState = ({ user: { current: user, isOnMobile, userInfos }, loading }) => ({
  user,
  isOnMobile,
  userInfos,
  isLoading: pathOr(
    false,
    ['effects', 'user', 'getUserInfos'],
    loading || pathOr(false, ['effects', 'user', 'requestAccountDeletion'], loading)
  )
});
const mapDispatch = ({ user: { update, updatePassword, getUserInfos, requestAccountDeletion } }) => ({
  update,
  updatePassword,
  getUserInfos,
  requestAccountDeletion
});

export default connect(mapState, mapDispatch)(Settings);
