import React, { useEffect } from 'react';
import { Box, Button, Card, CardActions, CardContent, Container, Grid, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHeader } from 'hooks';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { eventGA } from 'utils/analytics';

const CardAction = withStyles(
  createStyles((theme) => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.light.dark,
      justifyContent: 'flex-end'
    }
  }))
)((props) => <CardActions {...props} />);

const CenteredButton = withStyles({
  root: {
    textAlign: 'center'
  }
})((props) => <Button {...props} />);

const Help = () => {
  const { t, i18n } = useTranslation('general');
  const { setTitle, setComponents } = useHeader();
  const permissions = useSelector(pathOr([], ['user', 'currentCustomer', 'permissions']));

  useEffect(() => {
    setTitle('general:menu.help');
    setComponents([]);
  }, [setTitle, setComponents]);

  return (
    <Container maxWidth="xl">
      <Box my={5}>
        <Typography component="h6" variant="h6">
          {t('general:help.title')}
        </Typography>
        {!permissions.length && <Typography color="error">{t('general:help.noPermissions')}</Typography>}
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body1" color="secondary">
                {t('general:help.card1Text')}
              </Typography>
              <Typography
                component="a"
                color="primary"
                href="mailto:customerservice@daoust.be"
                style={{ textDecoration: 'none' }}>
                customerservice@daoust.be
              </Typography>
            </CardContent>
            <CardAction>
              <CenteredButton
                href="mailto:customerservice@daoust.be"
                variant="outlined"
                color="primary"
                onClick={() => {
                  eventGA('support_email');
                }}>
                {t('general:help.card1Action')}
              </CenteredButton>
            </CardAction>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body1" color="secondary">
                {t('general:help.card2Text')}
              </Typography>
            </CardContent>
            <CardAction>
              <CenteredButton
                href={`https://www.daoust.be/${i18n.language}/jobcenter`}
                target="_blank"
                variant="outlined"
                color="primary"
                onClick={() => {
                  eventGA('support_directory');
                }}>
                {t('general:help.card2Action')}
              </CenteredButton>
            </CardAction>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body1" color="secondary">
                {t('general:help.card3Text')}
              </Typography>
            </CardContent>
            <CardAction>
              <CenteredButton
                href={`https://www.daoust.be/${i18n.language}/${i18n.language === 'fr' ? 'tuto-daco' : 'daco-tuto'}/`}
                target="_blank"
                variant="outlined"
                color="primary"
                onClick={() => {
                  eventGA('support_tutorial');
                }}>
                {t('general:help.card3Action')}
              </CenteredButton>
            </CardAction>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Help;
