/* eslint-disable complexity */
import React from 'react';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { array, bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { pathOr, isEmpty, filter, path, prop } from 'ramda';
import { isSameDay } from 'date-fns';
import uuid from 'react-uuid';

import DaoustIcon from 'assets/icon-daoust.svg';
import PayrollIcon from 'assets/icon-payroll.svg';

import { days, formatHour, hoursFromMinutes } from 'utils/date';

import { Img } from 'components';
import HourCell from 'components/TempWorkerRow/HourCell';

import { capitalized } from 'utils/text';
import styles from './PlanningCandidateRow.styles';
import PlanningCandidateStatus from '../PlanningCandidateStatus';

const PlanningCandidateRow = ({ classes, show, history, data, weekDays, isLast }) => {
  const { t, i18n } = useTranslation(['general', 'tempWorkers']);
  const { language } = i18n;

  const getHours = (day) => {
    const hours = pathOr({}, ['scheduleDays', days[day?.getDay()]], data);
    return hours;
  };

  const getTotalHours = () => {
    const weekDaysMap = filter(
      (item) => item,
      weekDays.map((day) => {
        const val = getHours(day);
        return isEmpty(val) ? null : val;
      })
    );
    const total = weekDaysMap.reduce((acc, curr) => {
      const minutes = curr.performedMinutes || curr.total_hours;
      return acc + minutes;
    }, 0);
    return hoursFromMinutes(total);
  };

  const scheduleLine = (time, startKey, endKey) => {
    if (!prop(startKey, time) && !prop(endKey, time)) return null;
    return (
      <Typography variant="caption" component="p" color="secondary">
        {`${formatHour(time[startKey])} - ${formatHour(time[endKey])}`}
      </Typography>
    );
  };

  const handleGoToOrderDetail = ({ e, day }) => {
    e.stopPropagation();
    const orderId = path(['mappingOrderIdByDay', day], data);
    if (!orderId) return;
    history.push({
      pathname: `/orders/${orderId}`,
      state: { orderId, fromPlanning: true }
    });
  };

  // classes.clickableRow,

  return (
    <>
      <TableRow
        key={uuid()}
        className={clsx(classes.tableRow, {
          [classes.noBorderRow]: isLast,
          [classes.hiddenRow]: !show
        })}>
        <TableCell component="th" scope="row" className={classes.firstAndLastCell}>
          <Typography variant="body1">{data?.candidate?.fullname || capitalized(t('general:undefined'))}</Typography>
          <Typography variant="body2">
            {data.mainJobFunction ? data.mainJobFunction[`label_${language || 'fr'}`] : null}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Img src={data?.candidate?.isPayroll === 'P' ? PayrollIcon : DaoustIcon} alt="" />
          </Typography>
        </TableCell>

        <TableCell align="right">
          <PlanningCandidateStatus statusLabel={data.planningStatus} isColored />
        </TableCell>
        {weekDays.map((day) => {
          const contractHours = pathOr({}, ['scheduleDays', days[day.getDay()]], data);
          return (
            <TableCell
              key={day.getDay()}
              align="right"
              className={clsx(classes.tableCell, {
                [classes.todayCell]: isSameDay(day, new Date()),
                [classes.clickableRow]: !isEmpty(getHours(day))
              })}
              onClick={(e) => handleGoToOrderDetail({ e, day: days[day.getDay()] })}>
              <Box>
                <HourCell hours={getHours(day)} isEdited={false} isPlanning />
              </Box>
              <Box>
                {scheduleLine(contractHours, 'start_am', 'end_am')}
                {scheduleLine(contractHours, 'start_pm', 'end_pm')}
              </Box>
            </TableCell>
          );
        })}
        <TableCell align="center">
          <Typography variant="body2">{getTotalHours()}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

PlanningCandidateRow.propTypes = {
  classes: object.isRequired,
  history: object.isRequired,
  show: bool,
  data: object,
  weekDays: array,
  isLast: bool
};

PlanningCandidateRow.defaultProps = {
  show: false,
  data: {},
  weekDays: [],
  isLast: false
};

export default withStyles(styles)(withRouter(PlanningCandidateRow));
