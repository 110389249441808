import { isNil, path } from 'ramda';

import { api, secureCall } from 'utils/api';
import { cleanObj } from 'utils/object';

export const getParent = secureCall(
  async ({ candidateId, customerId, jobFunctionId, costCenterId }) =>
    api.get(
      'contract/get-parent',
      cleanObj({
        candidate_id: candidateId,
        customer_id: customerId,
        job_function_id: jobFunctionId,
        cost_center_id: costCenterId
      })
    ),
  { message: { 400: 'errors:default' } }
);

export const addContract = secureCall(
  async (
    {
      hourlyRate,
      jobFunctionId,
      start,
      end,
      description,
      reasonId,
      reference,
      candidateId,
      costCenterId,
      isFlexi,
      isSeasonal,
      isStudent,
      isStudentContingent,
      scheduleDays
    },
    orderId
  ) =>
    api.post(
      `order/create-contract${!isNil(orderId) ? `?id=${orderId}` : ''}`,
      cleanObj({
        cost_center_id: costCenterId || null,
        job_function_id: jobFunctionId,
        hourly_rate: hourlyRate,
        start,
        end,
        description,
        reason_id: reasonId,
        invoice_reference: reference,
        is_flexi: isFlexi,
        is_student: isStudent,
        is_student_without_contingent: isStudentContingent,
        is_seasonal: isSeasonal,
        candidate_id: candidateId,
        scheduleDays
      })
    )
);

export const extendContract = secureCall(
  async (
    { jobFunctionId, start, end, scheduleDays, contractId, costCenterId, reference, description, reason_id },
    orderId
  ) =>
    api.post(
      `order/create-extension${!isNil(orderId) ? `?id=${orderId}` : ''}`,
      cleanObj({
        cost_center_id: costCenterId || null,
        job_function_id: jobFunctionId,
        description,
        start,
        end,
        invoice_reference: reference,
        source_contract_id: contractId,
        scheduleDays,
        reason_id
      })
    )
);

export const getAppendableCodes = secureCall(async (contractGroupId) =>
  api.get(
    'contract/appendable-premium-codes',
    cleanObj({
      contractGroupId
    })
  )
);

export const getAppendablePremiums = secureCall(async () => api.get('contract/phantom-premium-codes'));

export const editSchedule = secureCall(
  async ({ premiumList, timesheetScheduleDays, contractId, comment, impersonateValidatorId }) => {
    return api.post(
      'contract/update-timesheet',
      cleanObj({
        contract_id: contractId,
        timesheet_comment: comment,
        premiumList: premiumList.map(({ premium_code, date, minutes, amount }) => ({
          premium_code,
          date,
          minutes,
          amount
        })),
        timesheetScheduleDays: timesheetScheduleDays.map(
          ({ day, start_am, end_am, start_pm, end_pm, total_hours }) => ({
            day,
            start_am,
            end_am,
            start_pm,
            end_pm,
            total_hours
          })
        ),
        impersonate_editor_id: impersonateValidatorId
      })
    );
  }
);

export const updateEvaluation = secureCall(async ({ candidateId, dacoEvaluationId }) =>
  api.post(
    'candidate/update-evaluation',
    cleanObj({
      candidate_uuid: candidateId,
      daco_evaluation_id: dacoEvaluationId
    })
  )
);

export const doesPayrollExist = secureCall(async ({ firstName, lastName, postalCode }) => {
  return api.get(
    'payroll/candidate-exists',
    cleanObj({
      first_name: firstName,
      last_name: lastName,
      zip_code: path(['value', 'postalCode'], postalCode)
    })
  );
});

export const addPayroll = secureCall(
  async ({
    costCenterId,
    firstName,
    lastName,
    socialSecurity,
    administrativeLanguage,
    email,
    phoneIdentifier,
    phoneNumber,
    birthDate,
    jobFunctionIds,
    streetName,
    streetBox,
    zipCode,
    city,
    comment,
    streetNumber
  }) =>
    api.post('payroll/create', {
      cost_center_id: costCenterId || null,
      first_name: firstName,
      last_name: lastName,
      social_security_number: socialSecurity,
      administrative_language: administrativeLanguage,
      phone1_geobel_id: phoneIdentifier,
      phone1: phoneNumber,
      birthdate: birthDate,
      jobFunctions: jobFunctionIds,
      street: streetName,
      box: streetBox,
      zip_code: zipCode,
      city,
      comment: comment || '',
      email,
      number: String(streetNumber)
    })
);

export const payrollUpload = secureCall(async ({ payRollId, workPermit, identityDocument, drivingLicense }) =>
  api.postFile(`payroll/upload-files?payroll_id=${payRollId}`, {
    work_permit: workPermit,
    identity_document: identityDocument,
    driving_license: drivingLicense
  })
);

export const exportXLS = secureCall(async ({ idList }) =>
  api.post('contract/xls-export', {
    idList
  })
);

export const getReasons = secureCall(async (lang) => api.get(`contract/reasons?lang=${lang}`));
