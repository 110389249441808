import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { append, compose, filter, values, flatten, map, mapObjIndexed } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { func, number, oneOfType, string } from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import { useAllowedActions, userRoles } from 'auth';
import IconSelect from 'components/IconSelect';
import { documentTypes } from '../documentTypes';

const DocumentCategoryFilterOptions = ({ value, onChange }) => {
  const { t } = useTranslation();

  const allowedCategories = useAllowedActions([
    userRoles.documentCenterAccountExtracts,
    userRoles.documentCenterReport,
    userRoles.documentCenterDefault
  ]);

  const documentCategoriesFilterOptions = useMemo(
    () =>
      compose(
        append(
          <MenuItem key={9} value="10">
            {t('general:docs.category.9')}
          </MenuItem>
        ),
        filter(isNotNilOrEmpty),
        flatten,
        values,
        mapObjIndexed(
          (val, key) =>
            val &&
            map(
              (id) => (
                <MenuItem key={id} value={id.toString()}>
                  {t(`general:docs.category.${id}`)}
                </MenuItem>
              ),
              documentTypes[key]
            )
        )
      )(allowedCategories),
    [allowedCategories]
  );

  return (
    <IconSelect
      icon={faFile}
      value={value}
      fullWidth
      onChange={(e) => {
        onChange(e?.target?.value);
      }}>
      {documentCategoriesFilterOptions}
    </IconSelect>
  );
};

DocumentCategoryFilterOptions.propTypes = {
  value: oneOfType([string, number]),
  onChange: func
};

DocumentCategoryFilterOptions.defaultProps = {
  value: null,
  onChange: () => {}
};

export default DocumentCategoryFilterOptions;
