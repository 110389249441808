import { useCallback, useEffect, useRef } from 'react';
import { equals } from 'ramda';

import { usePrevious } from 'hooks';

// Appelle getList sous conditions
const useList = (list, getList, isLoading, params, callOnInit = true) => {
  const canCall = useRef(true);
  const previousParams = usePrevious(params, true);

  const get = useCallback(() => {
    if (!getList) return;
    getList(params);
    canCall.current = false;
  }, [params, getList]);

  useEffect(() => {
    if (((!list?.length && canCall.current) || !equals(previousParams, params)) && !isLoading) get();
  }, [list, isLoading, previousParams, params, get]);

  useEffect(() => {
    if (callOnInit && canCall.current) get();
  }, [get, callOnInit]);

  useEffect(() => {
    if (list?.length) canCall.current = true;
  }, [list]);
};

export default useList;
