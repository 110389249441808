/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonOutline as PersonIcon } from '@material-ui/icons';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import PropTypes, { array, bool, number, string } from 'prop-types';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

import { localeDateFormat } from 'utils/date';
import { renderOnCondition } from 'utils/component';

const useStyles = makeStyles((theme) => ({
  box: {
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  },
  cell: {
    color: theme.palette.secondary.main
  }
}));

const ConfirmScreen = ({
  description,
  hours,
  from,
  to,
  vacancies,
  jobFunction,
  name,
  plain,
  extension,
  firstName,
  lastName,
  totalHours,
  isError,
  isNewWorker,
  preSelectedWorkers
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  const extraContent = () => {
    let content = '';
    if (!plain) {
      content = (
        <>
          {hours && to && from && (
            <Typography color="textSecondary" variant="body1" align="center">
              {`${format(from, localeDateFormat)} - ${format(to, localeDateFormat)}`}
            </Typography>
          )}
          {extension && (
            <Typography id="totalHours" color="textSecondary" variant="body1" align="center">
              {totalHours}
            </Typography>
          )}
        </>
      );
    }
    return content;
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mb={4}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography color="textSecondary" id="infoText" variant="body1" align="left">
            {(extension || name) && !isNewWorker ? `${lastName} ${firstName}` : `${vacancies} x ${jobFunction}`}
          </Typography>
          {extraContent()}
        </Box>

        {renderOnCondition(
          preSelectedWorkers?.length > 0,
          <Box mt={4} alignItems="left" flex={1}>
            <Typography variant="body1" color="textSecondary">
              {t('general:pool.selectedWorkers.title')}
            </Typography>
            <List dense>
              {preSelectedWorkers.map((worker) => (
                <ListItem key={worker.uuid}>
                  <ListItemIcon>
                    <PersonIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={worker.fullname} className={classes.cell} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box display="flex" className={classes.box} mt={5}>
          <Box>{!preSelectedWorkers.length && <PersonIcon color="secondary" />}</Box>
          <Box ml={3}>
            <Typography color={isError ? 'primary' : 'textSecondary'} variant="body1">
              {description || t('general:orders.confirmscreen')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ConfirmScreen.propTypes = {
  firstName: string,
  lastName: string,
  jobFunction: string,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  vacancies: PropTypes.oneOfType([string, number]),
  hours: bool,
  plain: bool,
  name: bool,
  extension: bool,
  totalHours: string,
  description: string,
  isError: bool,
  isNewWorker: bool,
  preSelectedWorkers: array
};

ConfirmScreen.defaultProps = {
  name: false,
  plain: false,
  extension: false,
  hours: true,
  from: new Date(),
  to: new Date(),
  description: null,
  firstName: '',
  lastName: '',
  jobFunction: '',
  vacancies: '',
  totalHours: '',
  isError: false,
  isNewWorker: false,
  preSelectedWorkers: []
};

export default ConfirmScreen;
