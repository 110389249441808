/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes, { object, func, array } from 'prop-types';
import {
  Box,
  Checkbox,
  ExpansionPanelDetails,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { equals, find, prop, sortBy, isEmpty, pathOr, remove, any, propOr } from 'ramda';
import { isNotNil, isValidDate } from 'ramda-adjunct';
import { addDays, isAfter, isBefore } from 'date-fns';

import { usePrevious } from 'hooks';
import { days, formatTotalHours, getWeekDays, mondayWeek } from 'utils/date';
import { asFloatingHours } from 'utils/tempWorkers';

import { FrLocaleUtilsProvider } from 'components';
import { ExpansionPanel, ExpansionPanelSummary } from 'components/Expansion';

import scheduleFormStyle from './scheduleFormStyle';
import {
  defaultTimes,
  getTimeError,
  getTotal,
  hourRowsName,
  makeScheduleObject,
  applyScheduleChange,
  getCurrentDate,
  checkIfDayIsSame
} from './common';

const ScheduleFormTablet = ({
  classes,
  defaultSchedule,
  date,
  dateLimit,
  onScheduleChange,
  onTimeError,
  savedSchedule
}) => {
  const [calcTotal, setCalcTotal] = useState('00h00');
  const [expanded, setExpanded] = useState(true);
  const [focused, setFocused] = useState(false);
  const [scheduleDays, setScheduleDays] = useState(savedSchedule || []);
  const [timeErrors, setTimeErrors] = useState([]);
  const prevScheduleDays = usePrevious(scheduleDays, true);
  const { t } = useTranslation(['general', 'helpers']);
  const weekDays = getWeekDays(date);
  const inputClassName = clsx(classes.timeInput, classes.mobileInput);

  const handleTimeChange = (day, hourRowName) => (dateTime) => {
    const { schedule, newTimeErrors } = applyScheduleChange({
      day,
      hourRowName,
      scheduleDays,
      dateTime,
      date,
      timeErrors
    });
    const firstDay = schedule[0];
    if (schedule.length > 1 && day === firstDay.dayNumber) {
      if (isValidDate(firstDay[hourRowName])) {
        const newSchedule = schedule.map((item) => {
          if (item.dayNumber > firstDay.dayNumber) {
            const isDaySameAsFirstOne = checkIfDayIsSame(item, prevScheduleDays);
            return isDaySameAsFirstOne
              ? { ...firstDay, dayNumber: item.dayNumber, date: getCurrentDate(date, item.dayNumber) }
              : item;
          }
          return item;
        });
        setScheduleDays(newSchedule);
      }
    } else {
      setScheduleDays(schedule);
      if (isValidDate(dateTime) || !dateTime || timeErrors.length) setTimeErrors(newTimeErrors);
    }
  };

  const getHour = (day, hourName) =>
    propOr(
      null,
      hourName
    )(
      scheduleDays.find((sd) => {
        return sd.dayNumber === day;
      }) || {}
    );

  const isTotalReadOnly = (day) =>
    any((hourName) => isNotNil(getHour(mondayWeek[day], hourName)))([
      hourRowsName.startAm,
      hourRowsName.endAm,
      hourRowsName.startPm,
      hourRowsName.endPm
    ]);

  const daySchedule = (key) =>
    [
      {
        label: t('general:extend.startTimeAm'),
        name: hourRowsName.startAm
      },
      {
        label: t('general:extend.endTimeAm'),
        name: hourRowsName.endAm
      },
      {
        label: t('general:extend.startTimePm'),
        name: hourRowsName.startPm
      },
      {
        label: t('general:extend.endTimePm'),
        name: hourRowsName.endPm
      }
    ].map((sr) => (
      <Box className={classes.scheduleItem} key={sr.label}>
        <Box>
          <Typography variant="body2" color="secondary">{`${sr.label}`}</Typography>
        </Box>
        <Box>
          <FrLocaleUtilsProvider>
            <FormControl
              className={classes.scheduleField}
              style={{
                alignSelf: 'flex-end',
                maxWidth: '8rem',
                width: 'fit-content',
                alignItems: 'center'
              }}>
              <KeyboardTimePicker
                ampm={false}
                format="HH:mm"
                mask="__:__"
                disabled={
                  (isAfter(weekDays[key], new Date(dateLimit.toDateString())) &&
                    isBefore(weekDays[key], new Date(date.toDateString()))) ||
                  !scheduleDays.map((sd) => sd.dayNumber).includes(key)
                }
                inputProps={{ className: inputClassName }}
                autoOk
                value={getHour(key, sr.name)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                invalidDateMessage={!focused && t('validation:scheduleInvalid')}
                onChange={handleTimeChange(key, sr.name)}
                keyboardIcon={false}
                InputAdornmentProps={{ classes: { root: classes.keyboardIcon } }}
              />
              {getTimeError(key, sr.name)(timeErrors) && (
                <FormHelperText error={!!getTimeError(key, sr.name)(timeErrors)}>
                  {t(`${pathOr('general:extend:wrongTimeValue', ['message'], getTimeError(key, sr.name)(timeErrors))}`)}
                </FormHelperText>
              )}
            </FormControl>
          </FrLocaleUtilsProvider>
        </Box>
      </Box>
    ));

  const checkDefault = (dayHours, key) => {
    if (mondayWeek[date.getDay()] <= key && mondayWeek[dateLimit.getDay()] >= key && dayHours) {
      setScheduleDays((data) => [...data, makeScheduleObject(key, date, dayHours)]);
    }
  };

  useEffect(() => {
    if (!equals(scheduleDays, prevScheduleDays)) {
      onScheduleChange(scheduleDays);
      setCalcTotal(formatTotalHours(asFloatingHours(scheduleDays)));
    }
    if (isEmpty(scheduleDays)) onTimeError(true);
    else if (!isEmpty(scheduleDays) || timeErrors.length > 0) onTimeError(timeErrors.length > 0);
  }, [scheduleDays, timeErrors, prevScheduleDays, onScheduleChange, onTimeError]);

  useEffect(() => {
    weekDays.forEach((day) => {
      const dayHours = defaultSchedule[days[day.getDay()]];
      checkDefault(dayHours, mondayWeek[day.getDay()]);
    });
  }, [defaultSchedule]);

  const handleExpandChange = (key) => (_, newExpanded) => {
    setExpanded(newExpanded ? key : false);
  };

  const handleCheckBoxChange = (key) => (event) => {
    const dayHours = defaultSchedule[days[[1, 2, 3, 4, 5, 6, 0][key]]];
    const { checked } = event.target;
    const dayIdx = scheduleDays.findIndex((s) => s.dayNumber === key);
    if (checked) {
      if (dayIdx === -1) {
        const prevSchedule = find((item) => item.dayNumber === key && item.contractHour, prevScheduleDays);
        if (dayHours && !prevSchedule) {
          setScheduleDays((data) =>
            sortBy((item) => item.dayNumber, [...data, makeScheduleObject(key, date, dayHours)])
          );
        } else if (prevSchedule) {
          setScheduleDays((data) => [...data, { ...prevSchedule }]);
        } else {
          const defaultContractDay = prop('length', prevScheduleDays) ? prevScheduleDays[0] : scheduleDays[0];
          if (defaultContractDay) {
            setScheduleDays((data) =>
              sortBy((item) => item.dayNumber, [...data, { ...defaultContractDay, dayNumber: key }])
            );
          } else {
            setScheduleDays((data) => sortBy((item) => item.dayNumber, [...data, { ...defaultTimes, dayNumber: key }]));
          }
        }
      }
    } else {
      setTimeErrors(timeErrors.filter(({ dayNumber }) => dayNumber !== key));
      setScheduleDays(remove(dayIdx, 1, scheduleDays));
    }
  };

  const isTotalCalculable = (key) => getHour(key, 'startHour') && getHour(key, 'endHour');

  return (
    <Box>
      {weekDays.map((day, index) => {
        return (
          <ExpansionPanel
            aria-label="schedule accordion"
            key={day.getDay()}
            expanded={expanded === index}
            onChange={handleExpandChange(index)}
            square>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header">
              <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      color="primary"
                      checked={scheduleDays.findIndex((s) => s.dayNumber === index) >= 0}
                      disabled={
                        isBefore(day, new Date(date.toDateString())) || isAfter(day, new Date(dateLimit.toDateString()))
                      }
                      onChange={handleCheckBoxChange(index)}
                    />
                  }
                  label={t(`helpers:dates.days.${day.getDay()}`)}
                />
                <FrLocaleUtilsProvider>
                  <FormControl
                    onClick={(event) => event.stopPropagation()}
                    style={{
                      maxWidth: '8rem',
                      width: 'fit-content'
                    }}>
                    <KeyboardTimePicker
                      ampm={false}
                      format="HH:mm"
                      mask="__:__"
                      autoOk
                      inputProps={{ className: inputClassName }}
                      value={
                        !isTotalCalculable(index)
                          ? getHour(index, hourRowsName.contractHour)
                          : getTotal(scheduleDays.find((sd) => sd.dayNumber === index))
                      }
                      onChange={handleTimeChange(index, hourRowsName.contractHour)}
                      keyboardIcon={false}
                      InputAdornmentProps={{ classes: { root: classes.keyboardIcon } }}
                      disabled={
                        (isAfter(day, new Date(dateLimit.toDateString())) &&
                          isBefore(day, new Date(date.toDateString()))) ||
                        !scheduleDays.map((sd) => sd.dayNumber).includes(index) ||
                        isTotalReadOnly(day.getDay())
                      }
                      readOnly={isTotalReadOnly(day.getDay())}
                    />
                  </FormControl>
                </FrLocaleUtilsProvider>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box className={classes.scheduleBox}>{daySchedule(index)}</Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
      <Box display="flex" justifyContent="space-between" px={4}>
        <Typography variant="body1">Total</Typography>
        <Typography variant="body1">{calcTotal}</Typography>
      </Box>
    </Box>
  );
};

ScheduleFormTablet.propTypes = {
  classes: object.isRequired,
  defaultSchedule: object,
  date: PropTypes.instanceOf(Date),
  dateLimit: PropTypes.instanceOf(Date),
  onScheduleChange: func,
  onTimeError: func,
  savedSchedule: array
};

ScheduleFormTablet.defaultProps = {
  defaultSchedule: {},
  date: new Date(),
  dateLimit: addDays(new Date(), 1),
  onScheduleChange: () => null,
  onTimeError: () => null,
  savedSchedule: null
};

export default withStyles(scheduleFormStyle)(ScheduleFormTablet);
