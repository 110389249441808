import { toast } from 'react-toastify';
import { join, map, pipe, prop, is } from 'ramda';

import { api as apiInterface, HttpError } from 'utils/api-react';
import i18n from 'translate/i18n';
import history from 'utils/history';

const errorCodes = {
  404: (error) => [`errors:${prop('message', error)}`, 'errors:notFound'],
  401: (error) => [`errors:${prop('message', error)}`, 'errors:unauthorized'],
  400: (error) => (prop('message', error) ? `errors:${prop('message', error)}` : ''),
  500: (error) => (prop('message', error) ? `errors:${prop('message', error)}` : '')
};

/**
 * Custom error handler for project
 * @param  {Error} error            error received from API
 * @param  {any} [returnValue=null] value returned after function is executed
 * @return {any}
 */
export const handleError = (error, { returnValue = null, message } = {}) => {
  const { code } = error;
  let toastMessage;
  if (is(String, message)) toastMessage = message;
  else if (is(Object, message)) toastMessage = prop(code, message);
  if (!toastMessage)
    toastMessage = errorCodes[code] ? errorCodes[code](JSON.parse(JSON.stringify(error))) : 'errors:default';

  if (toastMessage) toast.error(i18n.t(toastMessage));
  return returnValue;
};

/**
 * Custom error handler for API module
 * @param  {Object} store reference to Redux store
 * @throws {HttpError}
 */
export const errorHandler = (store) => async (e) => {
  let body;
  const code = e.status;
  try {
    body = await e.json();
  } catch (err) {
    try {
      body = await e.text();
    } catch (er) {
      body = { message: e.statusText };
    }
  }
  if (code === 401) return store.dispatch.user.logout();
  if (code === 503) return history.push('/error/503');
  throw new HttpError(code, prop('message', body));
};

/**
 * Formats thr "order" param in search requests
 * @param  {string} defaultValue default order
 * @param  {Object} params       order received from Table component
 * @return {string}
 */
export const getOrderParams = (defaultValue, params) => {
  if (prop('length', params)) {
    return pipe(
      map((param) => `${param.desc ? '-' : ''}${param.id}`),
      join(',')
    )(params);
  }
  return defaultValue;
};

/**
 * Secures API calls
 * @param  {function} functionToTry function that makes the API call
 * @param  {any} catchReturn   return value of catch. If function, it is executed, if not, default error handler is called and returns that value
 * @return {any} if sucess, returns the result of functionToTry
 */
export const secureCall = (functionToTry, catchReturn) => async (...args) => {
  try {
    return await functionToTry(...args);
  } catch (e) {
    return is(Function, catchReturn) ? catchReturn(e, ...args) : handleError(e, catchReturn);
  }
};

export const api = apiInterface(process.env.REACT_APP_API_URL);
