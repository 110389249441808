/* eslint-disable complexity */
import React from 'react';
import { path, has, isNil, uniqBy, reverse } from 'ramda';
import { format } from 'date-fns';
import { days, minutesToPickerValue, convertMinutesToTime, toIsoDateString, valueToMinutes } from 'utils/date';
import { TableCell } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { isDate } from 'ramda-adjunct';

export const getAllHours = (data) => {
  const { startAm, startPm, endAm, endPm } = data;
  const hours = [];
  if (startAm || endAm) {
    hours.push(`${startAm ? format(startAm, 'HH:mm') : '00:00'} - ${endAm ? format(endAm, 'HH:mm') : '00:00'}`);
  }
  if (startPm || endPm) {
    hours.push(`${startPm ? format(startPm, 'HH:mm') : '00:00'} - ${endPm ? format(endPm, 'HH:mm') : '00:00'}`);
  }

  return hours;
};

export const getNewHoursAndPremiums = (data, storedHours, contractHours, premiums, isUpdatingPremium) => {
  const performedPremium = premiums.find((p) => p.date === toIsoDateString(data.date) && p.premium_code === 1101);
  const dayName = has('dayName', data) ? data.dayName : days[data.date.getDay()];
  const performedMinutes = isDate(data.performedMinutes)
    ? valueToMinutes(data.performedMinutes)
    : data.performedMinutes;
  return !isUpdatingPremium
    ? {
        hours: {
          ...storedHours,
          [dayName]: {
            start_am: data.startAm ? `${format(data.startAm, 'HH:mm')}:00` : null,
            start_pm: data.startPm ? `${format(data.startPm, 'HH:mm')}:00` : null,
            end_am: data.endAm ? `${format(data.endAm, 'HH:mm')}:00` : null,
            end_pm: data.endPm ? `${format(data.endPm, 'HH:mm')}:00` : null,
            total_hours: data.total_hours,
            performedMinutes,
            difference: data.difference || 0,
            date: data.date,
            reason: data.reason,
            id: data.contractId,
            allHours: getAllHours(data)
          }
        },
        premiums: [
          ...premiums.filter((p) => p.date !== toIsoDateString(data.date)),
          {
            ...performedPremium,
            minutes: data.difference > 0 ? contractHours[dayName].total_hours : performedMinutes
          },
          {
            ...data.reason,
            date: toIsoDateString(data.date),
            minutes: Math.abs(data.difference),
            amount: 0,
            contract_id: data.contractId
          }
        ]
      }
    : {
        hours: storedHours,
        premiums: [
          ...premiums.filter((p) => p.date !== toIsoDateString(data.date)),
          {
            ...performedPremium,
            minutes: data.difference > 0 ? contractHours[dayName].total_hours : performedMinutes
          },
          data.reason
            ? {
                ...data.reason,
                date: toIsoDateString(data.date),
                minutes: Math.abs(data.difference),
                amount: 0,
                contract_id: data.contractId
              }
            : null
        ].filter((item) => item)
      };
};

export const AlignMiddleTableCell = withStyles(() => ({
  root: {
    verticalAlign: 'middle'
  }
}))((props) => <TableCell {...props} />);

export const getInputsValues = (storedHours, bonuses, filteredBonuses) => {
  return days.map((day) => {
    if (storedHours[day]) {
      const validatedSources = !!filteredBonuses.find((b) => b.source === 2);
      if (validatedSources) {
        const dayWithRightSource = bonuses.find((b) => b.premium_code === 1101 && b.source === 2);
        const minutes = dayWithRightSource?.minutes || 0;
        return {
          pickerValue: minutesToPickerValue(parseInt(minutes, 10)),
          stringValue: convertMinutesToTime(minutes, 'h')
        };
      }
      const minutes = !isNil(storedHours[day].performedMinutes)
        ? storedHours[day].performedMinutes
        : storedHours[day].total_hours;
      return {
        pickerValue: minutesToPickerValue(parseInt(minutes, 10)),
        stringValue: convertMinutesToTime(minutes, 'h')
      };
    }
    return null;
  });
};

export const getDWHBonusMinutes = (bonus, changedHours) => {
  if (bonus?.source === 0) {
    if ([1, 3].includes(bonus?.smile_code_type)) {
      const bonusDayName = days[new Date(bonus.date).getDay()];
      return (
        path([bonusDayName, 'performedMinutes'], changedHours) || path([bonusDayName, 'total_hours'], changedHours) || 0
      );
    }
    if (bonus?.smile_code_type === 4) return bonus?.minutes || 1;
  }
  return bonus?.minutes;
};

export const filterBonuses = (allBonuses, changedHours) => {
  const validatedSources = !!allBonuses.find((b) => b.source === 2);
  return uniqBy(
    (item) => `${item.premium_code}-${item.date}`,
    reverse(
      allBonuses
        .filter((bonus) => (validatedSources ? bonus.source === 2 : true))
        .map((bonus) => {
          return {
            ...bonus,
            minutes: getDWHBonusMinutes(bonus, changedHours)
          };
        })
    )
  );
};

export const getHoursWithDate = (weekDays, contractHours) => {
  return weekDays
    .map((day) => {
      const dayName = days[day.getDay()];
      return { ...contractHours[dayName], date: toIsoDateString(day) };
    })
    .filter((hwd) => hwd.id);
};

export const getTotal = (type, storedHours) => {
  return days.reduce((acc, day) => {
    return acc + parseInt(path([day, type], storedHours) || 0, 10);
  }, 0);
};
