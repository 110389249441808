import React from 'react';
import { array } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { sum } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Badge } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => ({
  searchCancel: {
    marginLeft: 10,
    marginRight: 10,
    height: '100%'
  },
  input: {
    flex: 1
  },
  iconButton: {
    height: '100%'
  }
}));

const Prestations = ({ toConfirmList }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <IconButton onClick={() => history.push('/confirm-prestation')} className={classes.iconButton}>
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        badgeContent={sum(toConfirmList.map((week) => week.count))}
        color="primary">
        <FontAwesomeIcon icon={faInbox} />
      </Badge>
    </IconButton>
  );
};

Prestations.propTypes = {
  toConfirmList: array.isRequired
};

const mapState = ({ tempWorkers: { toConfirmList } }) => ({
  toConfirmList
});

export default connect(mapState)(Prestations);
