/* istanbul ignore file */
import { isValidDate } from 'ramda-adjunct';

const getDate = (dateVal) => (Array.isArray(dateVal) ? dateVal[0] : dateVal);

export default (getValues) => ({
  document_type_id: { required: 'documentTypeRequired' },
  reference: {
    validate: (val) => !!getValues('amount') || !!getValues('date') || !!val || 'refOrAmountOrDateRequired'
  },
  amount: {
    validate: {
      required: (val) => !!getValues('reference') || !!getValues('date') || !!val || 'refOrAmountOrDateRequired',
      minAmount: (value) => {
        const toNum = /^[0-9,.]*$/.test(value) ? parseFloat(value.replace(',', '.')) : -1;
        return !value || (toNum > -100000 && toNum < 100000) || 'mustBetween';
      }
    }
  },
  date: {
    validate: (val) => !!getValues('reference') || !!getValues('amount') || !!val || 'refOrAmountOrDateRequired'
  },
  from: {
    validate: {
      isDateValid: (value) => isValidDate(getDate(value)) || 'dateRequired'
    }
  },
  to: {
    name: 'to',
    validate: {
      isDateValid: (value) => isValidDate(getDate(value)) || 'dateRequired',
      minDate: (value) => {
        return getDate(getValues('from')) <= getDate(value) || 'minDate';
      }
    }
  }
});
