import React, { useRef, useState, useEffect, useCallback } from 'react';
import { array, bool, func, object } from 'prop-types';
import { Box, CircularProgress, LinearProgress, Typography, IconButton, Divider } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faFilter } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { transparentize } from 'polished';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { Modal, OrderName } from 'components';
import { costCenterString } from 'utils/tempWorkers';

import { localeDateFormat } from 'utils/date';

import history from 'utils/history';
import FilterOrders from './FilterOrders';

import OrderStatus from '../common/OrderStatus';
import OrderPeriod from '../common/OrderPeriod';

const Row = withStyles(
  createStyles((theme) => ({
    root: {
      borderBottom: `1px solid ${transparentize(0.5, theme.palette.secondary.main)}`
    }
  }))
)((props) => <Box {...props} />);

const OrderList = ({ data, isLoading, search, costCenters, debouncedSearch, onSearch, loadMore }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentPage = useRef(1);
  const activeLoadMore = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const isMounted = useRef(true);

  const getNext = useCallback(
    async (criteria) => {
      const receivedNew = await loadMore(criteria);
      if (isMounted.current) {
        activeLoadMore.current = receivedNew;
        setHasMore(receivedNew);
      }
    },
    [loadMore, setHasMore, activeLoadMore]
  );

  useEffect(() => {
    if (debouncedSearch) {
      activeLoadMore.current = false;
      currentPage.current = 1;
      setHasMore(true);
      getNext({ ...debouncedSearch, page: 1, stack: false });
    }
  }, [debouncedSearch, getNext]);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        if (activeLoadMore.current) {
          currentPage.current += 1;
          activeLoadMore.current = false;
          getNext({ ...search, page: currentPage.current, stack: true });
        }
      }}
      hasMore={hasMore}
      loader={
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" height={100} key="loader">
          <CircularProgress />
        </Box>
      }>
      <Box width="100%" position="sticky" top={56} bgcolor="light.main">
        <Box display="flex" justifyContent="space-between" alignItems="center" py={1} pl={1} pr={4}>
          <IconButton onClick={() => setIsModalOpen(true)}>
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faFilter} />
          </IconButton>
          <Typography variant="body2" color="secondary">{`${data.length} ${t('general:menu.orders')}`}</Typography>
        </Box>
        <Divider />
      </Box>
      {isLoading && <LinearProgress />}
      {data.map((order) => (
        <Row
          py={2}
          px={4}
          key={order.id}
          onClick={(e) => {
            e.stopPropagation();
            history.push({
              pathname: `/orders/${order.id}`,
              state: { orderId: order?.id }
            });
          }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="body2">
              <OrderName order={order} /> {order.order_type_id === 3 && <FontAwesomeIcon icon={faRedoAlt} />}
            </Typography>
            <Typography variant="body2" style={{ fontSize: 12 }}>
              {format(new Date(order.created_at.replace(' ', 'T')), localeDateFormat)}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body2" color="secondary">
              {costCenterString(order?.costCenter, order?.customer)}
            </Typography>
          </Box>
          <OrderStatus
            statusId={order.order_status_id}
            openings={order.openings}
            acceptedCount={order.count_accepted_candidates}
            isColored
          />
          <OrderPeriod ml="-3px" start={order.start} end={order.end} />
        </Row>
      ))}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <FilterOrders
          search={search}
          costCenters={costCenters}
          onFilter={(filters) => {
            setIsModalOpen(false);
            onSearch(filters);
          }}
        />
      </Modal>
    </InfiniteScroll>
  );
};

OrderList.propTypes = {
  data: array,
  costCenters: array.isRequired,
  loadMore: func,
  isLoading: bool.isRequired,
  search: object,
  debouncedSearch: object,
  onSearch: func.isRequired
};

OrderList.defaultProps = {
  data: [],
  loadMore: () => null,
  search: null,
  debouncedSearch: null
};

export default OrderList;
