import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  whatsNewModal: {
    '& ul': {
      marginTop: '16px',
      marginBottom: '16px',
      paddingLeft: '40px',
      listStyle: 'disc'
    }
  }
}));

const WhatsNew = ({ feature, markAsRead }) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  return (
    <Box my={6} px={6} className={classes.whatsNewModal}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Box flex="1" overflow="auto">
          <DialogTitle disableTypography id="confirm-dialog-title">
            <Typography align="center" variant="h6" component="h2" mb={6}>
              {t('general:whatsNew')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box my={8}>
              <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: feature.content }} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              form="scheduleform"
              name="save"
              onClick={() => {
                markAsRead(feature.id);
              }}
              variant="contained"
              color="primary">
              {t('general:markFeatureAsRead')}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Box>
  );
};

WhatsNew.propTypes = {
  feature: object,
  markAsRead: func.isRequired
};

WhatsNew.defaultProps = {
  feature: {}
};

export default WhatsNew;
