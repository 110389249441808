import React, { useMemo } from 'react';
import { isNil } from 'ramda';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, isSameDay } from 'date-fns';
import clsx from 'clsx';
import { array, bool, node, object, string, number, func, oneOfType } from 'prop-types';

const PerformanceScheduleRow = ({
  label,
  icon,
  editMode,
  weekDays,
  storedBonuses,
  premiumCode,
  isEditable,
  cellComponent,
  childrenProps,
  classes,
  total
}) => {
  const findBonus = (bonusesList, pCode, day) => {
    return bonusesList.find((cp) => {
      return cp.premium_code === pCode && cp.date === format(day, 'yyyy-MM-dd');
    });
  };
  const getBonusCodes = (pCode, day) => {
    return findBonus(storedBonuses, pCode, day);
  };

  const Component = cellComponent || TableCell;

  const totalBonus = useMemo(
    () =>
      storedBonuses
        .filter((bonus) => bonus.premium_code === premiumCode)
        .reduce(
          (tt, bonus) => {
            return {
              amount: !isNil(bonus.amount) ? tt.amount + bonus.amount : tt.amount,
              minutes: !isNil(bonus.minutes) ? tt.minutes + bonus.minutes : tt.minutes
            };
          },
          { amount: 0, minutes: 0 }
        ),
    [storedBonuses, premiumCode]
  );

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={clsx(classes.firstAndLastCell, classes.borderLessCell)}>
        <Box display="flex" flexDirection="row" color="text.secondary" alignItems="center">
          {icon ? (
            <Box pl={4}>
              <FontAwesomeIcon fixedWidth icon={icon} className={clsx(classes.iconColor)} />
            </Box>
          ) : (
            <Box width={5} pl={6} pr={2}>
              -
            </Box>
          )}
          <Box ml={1}>
            <Typography variant={icon ? 'body2' : 'subtitle1'} color="secondary">
              {label}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right" size="small" className={classes.borderLessCell} />
      {weekDays.map((day) => {
        return (
          <Component
            key={day}
            {...(cellComponent && {
              day,
              editMode,
              isEditable,
              bonus: getBonusCodes(premiumCode, day),
              classes
            })}
            {...childrenProps}
            className={clsx(childrenProps.className, isSameDay(day, new Date()) ? classes.todayCell : '')}
          />
        );
      })}
      {total ? (
        <TableCell align="right" className={clsx(classes.borderLessCell, classes.dayCell)}>
          <Typography color="secondary" variant="body2">
            {total}
          </Typography>
        </TableCell>
      ) : (
        <Component
          {...(cellComponent && {
            bonus: { ...totalBonus, date: '' },
            classes
          })}
          {...childrenProps}
        />
      )}
    </TableRow>
  );
};

PerformanceScheduleRow.propTypes = {
  label: string,
  icon: object,
  weekDays: array,
  storedBonuses: array,
  isEditable: bool,
  cellComponent: oneOfType([node, func, object]),
  childrenProps: object,
  classes: object.isRequired,
  bonuses: array,
  premiumCode: number,
  editMode: bool,
  total: oneOfType([string, number])
};

PerformanceScheduleRow.defaultProps = {
  label: '',
  icon: null,
  weekDays: [],
  storedBonuses: [],
  isEditable: false,
  cellComponent: null,
  childrenProps: {},
  bonuses: [],
  premiumCode: null,
  editMode: false,
  total: null
};

export default PerformanceScheduleRow;
