/* istanbul ignore file */
import { regex } from 'utils/validation';
import { isEmpty, isNil } from 'ramda';
import { isNotValidDate } from 'ramda-adjunct';
import { format } from 'date-fns';

const validateDate = (value) => {
  let isValid;
  if (!isNil(value) && !isEmpty(value)) {
    isValid = !isNotValidDate(value);
  } else {
    isValid = true;
  }
  return isValid;
};

const nissCheckDate = (trimmedValue, getValues) => {
  const bDay = getValues('birthDate');
  if (!bDay || isNotValidDate(bDay)) {
    return true;
  }
  const dateNISS = format(bDay, 'yyMMdd');
  return trimmedValue.startsWith(dateNISS);
};

const nissCheckSum = (niss) => {
  const identifierNumber = parseInt(String(niss).substring(0, 9), 10);
  const controlNumber = parseInt(String(niss).substring(9, 12), 10);
  const controlCalc = 97 - (identifierNumber % 97);
  return controlCalc === controlNumber;
};

export default ({ control, getValues }) => {
  return {
    firstName: {
      required: 'firstNameRequired',
      pattern: {
        value: regex.name,
        message: 'firstNameInvalid'
      }
    },
    lastName: {
      required: 'lastNameRequired',
      pattern: {
        value: regex.name,
        message: 'lastNameInvalid'
      }
    },
    costCenter: {
      name: 'costCenter',
      defaultValue: ''
    },
    birthDate: { required: false, validate: (value) => validateDate(value) || 'dateInvalid' },
    socialSecurity: {
      required: false,
      validate: (value) => {
        const ssValDigits = value ? value.replace('-', '').replace('.', '') : null;
        if (!isEmpty(ssValDigits) && !isNil(ssValDigits)) {
          if (ssValDigits.length !== 11) return 'socialSecurityInvalid';
          if (!nissCheckSum(ssValDigits)) return 'socialSecurityNumberError';
          if (!nissCheckDate(ssValDigits, getValues)) return 'socialSecurityNumberErrorDate';
        }
        return true;
      }
    },
    administrativeLanguage: {
      rules: {
        required: 'administrativeLanguageRequired'
      },
      name: 'administrativeLanguage',
      control,
      defaultValue: ''
    },
    jobFunction: {
      required: false,
      name: 'jobFunction',
      defaultValue: ''
    },
    email: {
      required: false,
      pattern: {
        value: regex.email,
        message: 'emailInvalid'
      }
    },
    prefix: {
      name: 'prefix',
      control,
      rules: { required: 'prefixRequired' },
      defaultValue: '32'
    },
    phone: { required: 'phoneRequired' },
    streetName: { required: false },
    streetNumber: { required: false },
    city: { required: 'cityRequired' },
    country: { required: 'countryRequired' },
    box: { required: false },
    comment: { required: false },
    postalCode: { required: 'postalCodeRequired', name: 'postalCode' }
  };
};
