/* eslint-disable max-lines */
import React, { useCallback, useMemo } from 'react';
import { func } from 'prop-types';
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  Button,
  FilledInput,
  InputAdornment
} from '@material-ui/core';
import { Close, PersonOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { inputStyle } from 'components/IconSelectable';
import { hasError, getError } from 'components/helpers';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { FrLocaleUtilsProvider } from 'components';
import { compose, values, filter, mapObjIndexed, flatten, map } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { localeDateFormat, toIsoDateString, formatMonthAndYear } from 'utils/date';
import EuroIcon from '@material-ui/icons/Euro';
import { useModal } from 'hooks';
import { useAllowedActions, userRoles } from 'auth';
import { documentTypes } from './documentTypes';
import validations from './requestDocument.validation';

const useStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  message: {
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  },
  clearable: {
    cursor: 'pointer'
  }
}));

const RequestDocument = ({ requestDocument }) => {
  const { t } = useTranslation(['general']);
  const { onClose } = useModal();
  const { handleSubmit, control, errors, getValues, setValue, triggerValidation, watch, formState } = useForm({
    mode: 'onBlur'
  });
  const onSubmit = useCallback(
    (data) => {
      requestDocument({
        ...data,
        amount: data.amount && data.amount.replace(',', '.'),
        date: data.date && toIsoDateString(data.date),
        from: data.from && toIsoDateString(data.from),
        to: data.from && toIsoDateString(data.to)
      }).then(() => onClose());
    },
    [requestDocument, onClose]
  );
  const allowedCategories = useAllowedActions([
    userRoles.documentCenterAccountExtracts,
    userRoles.documentCenterDefault
  ]);

  const documentCategoriesFilterOptions = useMemo(
    () =>
      compose(
        filter(isNotNilOrEmpty),
        flatten,
        values,
        mapObjIndexed(
          (val, key) =>
            val &&
            map(
              (id) =>
                [1, 2, 3, 4, 5].includes(id) && (
                  <MenuItem key={id} value={id}>
                    {t(`general:docs.category.${id}`)}
                  </MenuItem>
                ),
              documentTypes[key]
            )
        )
      )(allowedCategories),
    [allowedCategories]
  );

  const inputStyles = inputStyle();
  const classes = useStyles();
  const validators = validations(getValues);
  const documentType = watch('document_type_id');
  const date = watch('date');

  const validateMultiple = () => {
    triggerValidation('reference');
    triggerValidation('amount');
    triggerValidation('date');
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} display="flex" flexDirection="column" width="100%">
        <Box flex="1" overflow="auto">
          <Container maxWidth="md">
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box mb={6}>
                <Typography align="center" variant="h6" component="h2">
                  {t('general:docs.requestDocument')}
                </Typography>
              </Box>
              <Box mb={5}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="documentType">{t('general:docs.documentType')}</InputLabel>
                  <Controller
                    as={Select}
                    name="document_type_id"
                    control={control}
                    classes={inputStyles}
                    inputProps={{ ...inputStyles, ...{ 'data-testid': 'document_id' } }}
                    id="documentType"
                    variant="filled"
                    defaultValue=""
                    onBlur={() => {
                      triggerValidation('document_type_id');
                    }}
                    rules={validators.document_type_id}>
                    {documentCategoriesFilterOptions}
                  </Controller>
                  <FormHelperText error={hasError('document_type_id', errors)}>
                    {t(getError(['document_type_id', 'message'], errors))}
                  </FormHelperText>
                </FormControl>
              </Box>
              {[1, 2].includes(documentType) && (
                <>
                  <Box mb={5}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel htmlFor="reference">{t('general:docs.reference')}</InputLabel>
                      <Controller
                        as={FilledInput}
                        name="reference"
                        control={control}
                        classes={inputStyles}
                        inputProps={{ ...inputStyles, ...{ 'data-testid': 'reference' } }}
                        id="reference"
                        variant="filled"
                        onBlur={validateMultiple}
                        defaultValue=""
                        rules={validators.reference}
                      />
                      <FormHelperText error={hasError('reference', errors)}>
                        {t(getError(['reference', 'message'], errors))}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box mb={5}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel htmlFor="amount">{t('general:docs.amount')}</InputLabel>
                      <Controller
                        as={FilledInput}
                        name="amount"
                        control={control}
                        classes={inputStyles}
                        inputProps={{ ...inputStyles, ...{ 'data-testid': 'amount' } }}
                        id="amount"
                        variant="filled"
                        defaultValue=""
                        onBlur={validateMultiple}
                        rules={validators.amount}
                        endAdornment={
                          <InputAdornment position="end">
                            <EuroIcon color="secondary" />
                          </InputAdornment>
                        }
                      />
                      <FormHelperText error={hasError('amount', errors)}>
                        {t(getError(['amount', 'message'], errors), { min: -99999, max: 99999 })}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box mb={5}>
                    <FormControl fullWidth variant="filled">
                      <FrLocaleUtilsProvider>
                        <Controller
                          as={DatePicker}
                          name="date"
                          classes={inputStyles}
                          InputProps={{
                            endAdornment: date && (
                              <InputAdornment
                                position="end"
                                className={classes.clearable}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setValue('date', null);
                                }}>
                                <Close color="secondary" />
                              </InputAdornment>
                            )
                          }}
                          disableToolbar
                          variant="inline"
                          inputVariant="filled"
                          format={localeDateFormat}
                          label={t('general:docs.date')}
                          onClose={validateMultiple}
                          onBlur={() => null}
                          onChange={(val) => {
                            return val[0];
                          }}
                          fullWidth
                          autoOk
                          defaultValue={null}
                          rules={validators.date}
                          control={control}
                        />
                      </FrLocaleUtilsProvider>
                      <FormHelperText error={hasError('date', errors)}>
                        {t(getError(['date', 'message'], errors))}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </>
              )}
              {[3, 5].includes(documentType) && (
                <Box mx={-2} display="flex" flexDirection="row" justifyContent="space-between">
                  <Box px={2} width={[1, 1 / 2]}>
                    <FormControl fullWidth variant="filled">
                      <FrLocaleUtilsProvider>
                        <Controller
                          as={DatePicker}
                          name="from"
                          views={['year', 'month']}
                          labelFunc={formatMonthAndYear}
                          inputProps={{
                            'data-testid': 'from'
                          }}
                          defaultValue={null}
                          disableToolbar
                          variant="inline"
                          classes={inputStyles}
                          inputVariant="filled"
                          format={localeDateFormat}
                          margin="none"
                          label={t('general:orders.from')}
                          onChange={(val) => {
                            if (getValues('to')) triggerValidation('to');
                            return val[0];
                          }}
                          fullWidth
                          required
                          autoOk
                          rules={validators.from}
                          control={control}
                        />
                      </FrLocaleUtilsProvider>
                      <FormHelperText error={hasError('from', errors)}>
                        {t(getError(['from', 'message'], errors))}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box px={2} width={[1, 1 / 2]}>
                    <FormControl fullWidth variant="filled">
                      <FrLocaleUtilsProvider>
                        <Controller
                          as={DatePicker}
                          name="to"
                          views={['year', 'month']}
                          labelFunc={formatMonthAndYear}
                          inputProps={{
                            'data-testid': 'to'
                          }}
                          defaultValue={null}
                          disableToolbar
                          variant="inline"
                          classes={inputStyles}
                          inputVariant="filled"
                          format={localeDateFormat}
                          margin="none"
                          label={t('general:orders.to')}
                          onChange={(val) => {
                            if (getValues('from')) triggerValidation('from');
                            return val[0];
                          }}
                          fullWidth
                          required
                          autoOk
                          rules={validators.to}
                          control={control}
                        />
                      </FrLocaleUtilsProvider>
                      <FormHelperText error={hasError('to', errors)}>
                        {t(getError(['to', 'message'], errors))}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              )}
              {formState.isValid && (
                <Box display="flex" className={classes.message} mt={5}>
                  <Box>
                    <PersonOutline color="secondary" />
                  </Box>
                  <Box ml={3}>
                    <Typography color="textSecondary" variant="body2">
                      {t('general:docs.confirmation')}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
        <Box display="flex" justifyContent="flex-end" className={classes.box} py={4}>
          <Container maxWidth="xl" className={classes.container}>
            <Button id="submit" type="submit" variant="contained" color="primary" className={classes.button}>
              {t('general:confirm')}
            </Button>
          </Container>
        </Box>
      </Box>
    </>
  );
};

RequestDocument.propTypes = {
  requestDocument: func.isRequired
};

export default RequestDocument;
