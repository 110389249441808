import { cleanObj } from './object';

const gtagID = process.env.REACT_APP_GA_TRACKING_ID;

export const events = {
  home_new_contract_request: { title: 'Home_new_contract_request', page: 'Home' },
  home_new_order: { title: 'Home_new_order', page: 'Home' },
  home_new_payroll: { title: 'Home_new_payroll', page: 'Home' },
  home_extension: { title: 'Home_extension', page: 'Home' },
  home_hours_details: { title: 'Home_hours_details', page: 'Home' },
  home_premium_details: { title: 'Home_premium_details', page: 'Home' },
  home_search: { title: 'Home_search', page: 'Home' },
  home_day_filter: { title: 'Home_day_filter', page: 'Home' },
  home_date_picker: { title: 'Home_date_picker', page: 'Home' },
  home_current_week: { title: 'Home_current_week', page: 'Home' },
  existing_contract_new_order: { title: 'Existing_contract_new_order', page: 'Existing_contract' },
  existing_contract_extension: { title: 'Existing_contract_extension', page: 'Existing_contract' },
  payroll_form_save_payroll: { title: 'Payroll_form_save_payroll', page: 'Payroll' },
  payroll_form_save_payroll_and_order: { title: 'Payroll_form_save_payroll_and_order', page: 'Payroll' },
  order_CC_filter: { title: 'Order_CC_filter', page: 'Order' },
  order_date_range_filter: { title: 'Order_date_range_filter', page: 'Order' },
  order_search: { title: 'Order_search', page: 'Order' },
  candidate_CC_filter: { title: 'Candidate_CC_filter', page: 'Candidate' },
  candidate_date_range_filter: { title: 'Candidate_date_range_filter', page: 'Candidate' },
  candidate_type_filter: { title: 'Candidate_type_filter', page: 'Candidate' },
  candidate_search: { title: 'Candidate_search', page: 'Candidate' },
  document_CC_filter: { title: 'Document_CC_filter', page: 'Document' },
  document_category_filter: { title: 'Document_category_filter', page: 'Document' },
  document_history: { title: 'Document_history', page: 'Document' },
  document_search: { title: 'Document_search', page: 'Document' },
  support_email: { title: 'Support_email', page: 'Support' },
  support_directory: { title: 'Support_directory', page: 'Support' },
  support_tutorial: { title: 'Support_tutorial', page: 'Support' },
  prestation_edit: { title: 'Prestation_edit', page: 'Prestation' },
  prestation_confirm: { title: 'Prestation_confirm', page: 'Prestation' },
  prestation_extend: { title: 'Prestation_extend', page: 'Prestation' },
  prestation_previous: { title: 'Prestation_previous', page: 'Prestation' },
  prestation_next: { title: 'Prestation_next', page: 'Prestation' },
  prestation_previous_same_worker: { title: 'Prestation_previous_same_worker', page: 'Prestation' },
  prestation_next_same_worker: { title: 'Prestation_next_same_worker', page: 'Prestation' },
  prestation_hours_copy_next_day: { title: 'Prestation_hours_copy_next_day', page: 'Prestation' },
  prestation_hours_confirm: { title: 'Prestation_hours_confirm', page: 'Prestation' },
  prestation_add_premium_button: { title: 'Prestation_add_premium_button', page: 'Prestation' },
  prestation_add_premium_confirm: { title: 'Prestation_add_premium_confirm', page: 'Prestation' },
  prestation_add_premium_cancel: { title: 'Prestation_add_premium_cancel', page: 'Prestation' },
  prestation_cancel_edit: { title: 'Prestation_cancel_edit', page: 'Prestation' },
  prestation_save: { title: 'Prestation_save', page: 'Prestation' },
  prestation_save_and_confirm: { title: 'Prestation_save_and_confirm', page: 'Prestation' },
  prestation_to_confirm_edit: { title: 'Prestation_to_confirm_edit', page: 'Prestation to confirm' },
  prestation_to_confirm_hours_copy_next_day: {
    title: 'Prestation_to_confirm_hours_copy_next_day',
    page: 'Prestation to confirm'
  },
  prestation_to_confirm_hours_confirm: { title: 'Prestation_to_confirm_hours_confirm', page: 'Prestation to confirm' },
  prestation_to_confirm_add_premium_button: {
    title: 'Prestation_to_confirm_add_premium_button	',
    page: 'Prestation to confirm'
  },
  prestation_to_confirm_add_premium_confirm: {
    title: 'Prestation_to_confirm_add_premium_confirm',
    page: 'Prestation to confirm'
  },
  prestation_to_confirm_add_premium_cancel: {
    title: 'Prestation_to_confirm_add_premium_cancel',
    page: 'Prestation to confirm'
  },
  prestation_to_confirm_cancel_edit: { title: 'Prestation_to_confirm_cancel_edit', page: 'Prestation to confirm' },
  prestation_to_confirm_save: { title: 'Prestation_to_confirm_save', page: 'Prestation to confirm' },
  prestation_to_confirm_cancel_save_and_confirm: {
    title: 'Prestation_to_confirm_cancel_save_and_confirm',
    page: 'Prestation to confirm'
  },

  modal_opened: { title: 'Modal_opened' }
};

function gtag() {
  /* eslint-disable prefer-rest-params */
  return window.dataLayer && window.dataLayer.push(arguments);
}

export const pageViewGA = (title, location) => {
  return gtag('event', 'page_view', {
    page_title: title,
    page_path: location,
    page_location: location,
    send_to: gtagID
  });
};

export const eventGA = (key, value) => {
  if (key !== '' && !!events[key]) {
    return gtag('event', key, cleanObj({ ...events[key], value, send_to: gtagID }));
  }
  return null;
};

// * Needed if you want to disable info being sent to google analytics if not set, even if you remove the script, it will keep sending data.
export const toggleAnalytics = (isCookieAccepted) => {
  window[`ga-disable-${gtagID}`] = !isCookieAccepted;
};

export const initAnalytics = (isCookieAccepted) => {
  if (!gtagID) return null;
  toggleAnalytics(isCookieAccepted);
  const existingScript = document.getElementById('gtag');
  if (!existingScript) {
    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`;
    gtagScript.async = true;
    gtagScript.id = 'gtag';
    document.getElementsByTagName('head')[0].appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    return gtag('config', gtagID, { send_page_view: false });
  }
  return null;
};
