import * as service from './service';

export const features = {
  state: {
    list: [],
    feature: {}
  },
  reducers: {
    gotList: (state, list) => ({ ...state, list }),
    gotFeature: (state, feature) => ({ ...state, feature })
  },
  effects: {
    async getFeatureList() {
      const list = await service.getFeatureList();
      if (list) this.gotList(list);
    },
    async getLastOne() {
      const list = await service.getFeatureList();
      if (list) {
        const feature = list[0];
        this.gotFeature(feature);
      }
    },
    async markAsRead(id) {
      await service.markAsRead(id);
      return null;
    },
    async markAllAsRead() {
      await service.markAllAsRead();
      return null;
    }
  }
};
