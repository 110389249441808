import React, { useState, useEffect, useMemo } from 'react';
import PropTypes, { object, func, array } from 'prop-types';
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { addDays, isBefore, isWeekend } from 'date-fns';
import clsx from 'clsx';
import { usePrevious } from 'hooks';
import { equals, find, isEmpty, prop, remove, sortBy } from 'ramda';
import { asFloatingHours } from 'utils/tempWorkers';
import { days, formatTotalHours, getWeekDays, mondayWeek } from 'utils/date';
import { isValidDate } from 'ramda-adjunct';
import {
  defaultTimes,
  makeScheduleObject,
  applyScheduleChange,
  hourRowsName,
  getCurrentDate,
  checkIfDayIsSame
} from './common';
import scheduleFormStyle from './scheduleFormStyle';
import ScheduleFormRow from '../ScheduleFormRow/ScheduleFormRow';

const ScheduleFormDesktop = ({
  classes,
  defaultSchedule,
  date,
  dateLimit,
  onScheduleChange,
  onTimeError,
  savedSchedule
}) => {
  const [calcTotal, setCalcTotal] = useState('00h00');
  const [scheduleDays, setScheduleDays] = useState(savedSchedule || []);
  const [timeErrors, setTimeErrors] = useState([]);
  const prevScheduleDays = usePrevious(scheduleDays, true);
  const { t } = useTranslation(['general', 'validation', 'helpers']);
  const weekDays = getWeekDays(date);

  const handleTimeChange = (day, hourRowName, dateTime) => {
    const { schedule, newTimeErrors } = applyScheduleChange({
      day,
      hourRowName,
      scheduleDays,
      dateTime,
      date,
      timeErrors
    });
    const firstDay = schedule[0];
    if (schedule.length > 1 && day === firstDay.dayNumber) {
      const newSchedule = schedule.map((item) => {
        if (item.dayNumber > firstDay.dayNumber) {
          const isDaySameAsFirstOne = checkIfDayIsSame(item, prevScheduleDays);
          return isDaySameAsFirstOne
            ? { ...firstDay, dayNumber: item.dayNumber, date: getCurrentDate(date, item.dayNumber) }
            : item;
        }
        return item;
      });
      setScheduleDays(newSchedule);
    } else {
      setScheduleDays(schedule);
      if (isValidDate(dateTime) || !dateTime || timeErrors.length) setTimeErrors(newTimeErrors);
    }
  };

  const scheduleRows = [
    {
      label: t('general:extend.startTimeAm'),
      name: hourRowsName.startAm,
      key: 'start_am'
    },
    {
      label: t('general:extend.endTimeAm'),
      name: hourRowsName.endAm,
      key: 'end_am'
    },
    {
      label: t('general:extend.startTimePm'),
      name: hourRowsName.startPm,
      key: 'start_pm'
    },
    {
      label: t('general:extend.endTimePm'),
      name: hourRowsName.endPm,
      key: 'end_pm'
    }
  ].map((scheduleRowProps) => (
    <ScheduleFormRow
      {...scheduleRowProps}
      date={date}
      dateLimit={dateLimit}
      weekDays={weekDays}
      timeErrors={timeErrors}
      scheduleDays={scheduleDays}
      onTimeChange={(day, dateTime) => handleTimeChange(day, scheduleRowProps.name, dateTime)}
    />
  ));

  const handleCheckBoxChange = (key) => (event) => {
    const dayHours = defaultSchedule[days[[1, 2, 3, 4, 5, 6, 0][key]]];
    const { checked } = event.target;
    const dayIdx = scheduleDays.findIndex((s) => s.dayNumber === key);
    if (checked) {
      if (dayIdx === -1) {
        const prevSchedule = find((item) => item.dayNumber === key && item.contractHour, prevScheduleDays);
        if (dayHours && !prevSchedule) {
          setScheduleDays((data) =>
            sortBy((item) => item.dayNumber, [...data, makeScheduleObject(key, date, dayHours)])
          );
        } else if (prevSchedule) {
          setScheduleDays((data) => [...data, { ...prevSchedule }]);
        } else {
          const defaultContractDay = prop('length', prevScheduleDays) ? prevScheduleDays[0] : scheduleDays[0];
          if (defaultContractDay) {
            setScheduleDays((data) =>
              sortBy((item) => item.dayNumber, [...data, { ...defaultContractDay, dayNumber: key }])
            );
          } else {
            setScheduleDays((data) => sortBy((item) => item.dayNumber, [...data, { ...defaultTimes, dayNumber: key }]));
          }
        }
      }
    } else {
      setTimeErrors(timeErrors.filter(({ dayNumber }) => dayNumber !== key));
      setScheduleDays(remove(dayIdx, 1, scheduleDays));
    }
  };

  useEffect(() => {
    setCalcTotal(formatTotalHours(asFloatingHours(scheduleDays)));
    if (!equals(scheduleDays, prevScheduleDays)) {
      onScheduleChange(scheduleDays);
    }
    onTimeError(isEmpty(scheduleDays) || timeErrors.length > 0);
  }, [scheduleDays, timeErrors, prevScheduleDays, onScheduleChange, onTimeError]);

  const checkDefault = (dayHours, key) => {
    if (mondayWeek[date.getDay()] <= key && mondayWeek[dateLimit.getDay()] >= key && dayHours) {
      setScheduleDays((data) => [...data, makeScheduleObject(key, date, dayHours)]);
    }
  };

  useEffect(() => {
    weekDays.forEach((day) => {
      const dayHours = defaultSchedule[days[day.getDay()]];
      checkDefault(dayHours, mondayWeek[day.getDay()]);
    });
  }, [defaultSchedule]);

  const canEditDay = (day) => !isBefore(day, new Date());

  return (
    <Table aria-label="schedule table" size="small">
      <TableHead>
        <TableRow className={classes.headRow}>
          <TableCell />
          {useMemo(
            () =>
              weekDays.map((day) => (
                <TableCell
                  align="right"
                  key={day}
                  className={clsx(classes.dayCell, {
                    [classes.weekendCell]: isWeekend(day)
                  })}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <Checkbox
                        name={`checked${day.getDay()}`}
                        checked={scheduleDays.findIndex((s) => s.dayNumber === mondayWeek[day.getDay()]) >= 0}
                        color="primary"
                        onChange={handleCheckBoxChange(mondayWeek[day.getDay()])}
                        disabled={!canEditDay(day)}
                      />
                      <Typography variant="body2" color="secondary" align="right">
                        {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                      </Typography>
                    </Box>
                    <Typography variant="caption" component="p" color="secondary">
                      {day.getDate()} {t(`helpers:dates.months.short.${day.getMonth()}`)}
                    </Typography>
                  </Box>
                </TableCell>
              )),
            [scheduleDays]
          )}
          <TableCell align="right" className={classes.firstScheduleCell}>
            <Typography variant="body2" color="secondary">
              {t('general:total')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <ScheduleFormRow
          label={t('general:extend.totalHours')}
          name="contractHour"
          date={date}
          weekDays={weekDays}
          scheduleDays={scheduleDays}
          timeErrors={timeErrors}
          isTotal
          onTimeChange={(day, dateTime) => handleTimeChange(day, hourRowsName.contractHour, dateTime)}
          titleVariant="body1"
          titleComponent="span"
          titleColor="primary">
          <TableCell align="right" className={classes.borderLessCell}>
            {calcTotal}
          </TableCell>
        </ScheduleFormRow>
        {scheduleRows}
      </TableBody>
    </Table>
  );
};

ScheduleFormDesktop.propTypes = {
  classes: object.isRequired,
  defaultSchedule: object,
  date: PropTypes.instanceOf(Date),
  dateLimit: PropTypes.instanceOf(Date),
  onScheduleChange: func,
  onTimeError: func,
  savedSchedule: array,
  setDefaultSchedule: func
};

ScheduleFormDesktop.defaultProps = {
  defaultSchedule: {},
  date: new Date(),
  dateLimit: addDays(new Date(), 1),
  onScheduleChange: () => null,
  onTimeError: () => null,
  savedSchedule: null,
  setDefaultSchedule: () => null
};

export default withStyles(scheduleFormStyle)(ScheduleFormDesktop);
