import React from 'react';
import { Box, Divider, Grid, TextField } from '@material-ui/core';
import { ScheduleFormDesktop, ScheduleFormTablet } from 'components/ScheduleForm';
import { bool, func, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getContractDate } from 'utils/date';
import { nextWorkingDay } from 'utils/contract';

const NewContractSchedule = ({
  isTablet,
  setDescription,
  description,
  data,
  setHasScheduleTimeError,
  handleScheduleChange,
  ...props
}) => {
  const { t } = useTranslation(['general']);

  let from = getContractDate(data.from);
  from = from >= nextWorkingDay ? from : nextWorkingDay;

  const handleTimeErrorChange = (errorBool) => setHasScheduleTimeError(errorBool);

  return (
    <Grid display="flex">
      {isTablet ? (
        <Box mx={[-4, -3]}>
          <ScheduleFormTablet
            date={from}
            dateLimit={getContractDate(data.to)}
            onScheduleChange={handleScheduleChange}
            onTimeError={handleTimeErrorChange}
            {...props}
          />
        </Box>
      ) : (
        <ScheduleFormDesktop
          date={from}
          dateLimit={getContractDate(data.to)}
          onScheduleChange={handleScheduleChange}
          onTimeError={handleTimeErrorChange}
          {...props}
        />
      )}
      {!isTablet && <Divider />}
      <Box pt={4} width={isTablet ? '100%' : '50%'}>
        <TextField
          variant="filled"
          fullWidth
          value={description}
          multiline
          label={t('general:comment')}
          rows={3}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Box>
    </Grid>
  );
};

NewContractSchedule.propTypes = {
  isTablet: bool.isRequired,
  setDescription: func.isRequired,
  description: string,
  data: object.isRequired,
  setHasScheduleTimeError: func.isRequired,
  handleScheduleChange: func.isRequired
};

NewContractSchedule.defaultProps = {
  description: ''
};

export default NewContractSchedule;
