import { compose, curry, mapObjIndexed, prop, reduce } from 'ramda';

export const handleOrdersList = (orders) =>
  orders
    .filter((order) => [2, 3, 4].includes(order.order_type_id))
    .map((item) => ({
      ...item,
      scheduleDays: mapObjIndexed(
        (val) => (val ? { ...val, performedMinutes: val.total_hours } : null),
        item?.scheduleDays
      )
    }));

export const filterOrdersSearch = (orders, search) => {
  const regexes = search
    .split(/[\s,+]+/g)
    .filter((s) => !!s)
    .map((s) => new RegExp(s.trim(), 'i'));

  return orders.reduce((centers, center) => {
    const filteredMembers = center.orders.filter((member) =>
      regexes.map((regex) => regex.test(center.title + member.name)).every((r) => !!r)
    );
    if (filteredMembers.length) return [...centers, { ...center, orders: filteredMembers }];
    return centers;
  }, []);
};

const collectBy = curry(function collectBy(fn, list) {
  const group = reduce(
    (o, x) => {
      const tag = fn(x);
      if (o[tag] === undefined) {
        // eslint-disable-next-line no-param-reassign
        o[tag] = [];
      }
      o[tag].push(x);
      return o;
    },
    {},
    list
  );
  const newList = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const tag in group) {
    newList.push(group[tag]);
  }
  return newList;
});
export default collectBy;

export const handleOrdersByWorkerList = (orders) => {
  const list = compose(collectBy(prop('cost_center_id')))(orders);
  return list;
};
