import React from 'react';
import { is } from 'ramda';

export const show = (callback) => (Wrapped) => (props) => {
  const canShow = is(Function, callback) ? callback(props) : true;

  if (!canShow) return null;

  return <Wrapped {...props} />;
};
