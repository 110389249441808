import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { array, node, func } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { costCenterString } from 'utils/tempWorkers';
import { eventGA } from 'utils/analytics';

import { Modal } from 'components';

const mapState = ({ documents: { history } }) => ({
  history
});

const mapDispatch = ({ documents: { getHistory } }) => ({
  getHistory
});

const History = connect(
  mapState,
  mapDispatch
)(({ getHistory, history }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getHistory();
  }, [getHistory]);

  return (
    <Container maxWidth="md">
      <Box mx="auto" px={4}>
        <Box mb={6}>
          <Typography align="center" variant="h6" component="h2" mb={6}>
            {t('general:history')}
          </Typography>
        </Box>
        <ul>
          {history.map((item) => {
            const {
              id,
              document_type_id,
              customerContact: { first_name, last_name },
              costCenters,
              created_at
            } = item;
            return (
              <Box component="li" mb={3} key={id} display="flex">
                <Box pr={2}>
                  <Typography variant="body2" component="span">
                    {format(new Date(created_at.replace(' ', 'T')), t('general:fullDateFormat'))}
                  </Typography>
                </Box>
                <Box flex="1">
                  <Typography variant="body2" component="span" color="secondary">
                    {t('general:docs.hasDemanded1', {
                      first_name,
                      last_name,
                      document: t(`general:docs.category.${document_type_id}`)
                    })}
                    {!!costCenters.length &&
                      t('general:docs.hasDemanded2', { costCenters: costCenters.map(costCenterString).join(', ') })}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </ul>
      </Box>
    </Container>
  );
});

History.propTypes = {
  history: array,
  getHistory: func
};

History.defaultProps = {
  history: [],
  getHistory: () => null
};

const HistoryButton = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onClick: () => {
              eventGA('document_history');
              setOpenModal(true);
            }
          });
        }
        return child;
      })}
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <History />
      </Modal>
    </>
  );
};

HistoryButton.propTypes = {
  children: node.isRequired
};

export default HistoryButton;
