import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, InputBase } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
  searchCancel: {
    marginLeft: 10,
    marginRight: 10,
    height: '100%'
  },
  input: {
    flex: 1
  },
  iconButton: {
    height: '100%'
  }
}));

const Search = ({ onSearch, isMobile, isOpen, onToggle, value }) => {
  const classes = useStyles();

  const resetSearch = () => {
    onToggle(false);
    onSearch('');
  };

  useEffect(() => {
    if (!isMobile) onToggle(false);
    return () => onToggle(false);
  }, [isMobile, onToggle]);

  return (
    <>
      {isOpen ? (
        <>
          <IconButton className={classes.searchCancel} onClick={() => resetSearch()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
          <InputBase
            autoFocus
            className={classes.input}
            placeholder="Search"
            value={value}
            inputProps={{ 'aria-label': 'Search' }}
            onChange={(e) => onSearch(e.target.value)}
          />
        </>
      ) : (
        <IconButton className={classes.iconButton} onClick={() => onToggle(true)}>
          <FontAwesomeIcon icon={faSearch} />
        </IconButton>
      )}
    </>
  );
};

Search.propTypes = {
  onSearch: func.isRequired,
  isMobile: bool.isRequired,
  isOpen: bool.isRequired,
  onToggle: func.isRequired,
  value: string
};

Search.defaultProps = {
  value: ''
};

const mapState = ({ user: { isOnMobile: isMobile } }) => ({
  isMobile
});

export default connect(mapState)(Search);
