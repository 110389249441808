/* eslint max-lines: 0 */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  days,
  hoursFromMinutes,
  getWeekDays,
  toIsoDateString,
  valueToMinutes,
  getDayPickerValue,
  areSameHours,
  convertMinutesToTime
} from 'utils/date';
import ConfirmDialog from 'components/ConfirmDialog';
import { clone, prop } from 'ramda';
import { format } from 'date-fns';
import { isDate } from 'ramda-adjunct';
import { eventGA } from 'utils/analytics';
import {
  BonusSelector,
  getNewHoursAndPremiums,
  EditDaySchedule,
  filterBonuses,
  getTotal,
  getInputsValues,
  getHoursWithDate,
  getNewBonuses,
  getTotalFromPickers
} from './common';
import scheduleFormStyle from '../ScheduleForm/scheduleFormStyle';
import { BonusesDisplay } from './Bonuses';
import { PerformanceScheduleRow } from './PerformanceScheduleRow';
import { ReadOnlyTimeCell, ScheduleCell, PerformedCell } from './PerformanceScheduleCell';

const PerformanceScheduleDesktop = ({
  bonuses,
  changedHours,
  classes,
  date,
  contractHours,
  editMode,
  onHoursChange,
  onBonusesChange,
  appendableCodes,
  setEditMode,
  editModeDisabled
}) => {
  const [storedPremiums, setStoredPremiums] = useState([]);
  const [storedBonuses, setStoredBonuses] = useState([]);
  const [storedHours, setStoredHours] = useState({});
  const [inputsValues, setInputsValues] = useState([]);
  const [tempBonuses, setTempBonuses] = useState([]);
  const [dayData, setDayData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isBonusOpen, setIsBonusOpen] = useState(false);
  const shouldMoveToNextDay = useRef(false);
  const [showNextDayButton, setShowNextDayButton] = useState(false);
  const [nextDays, setNextDays] = useState([]);
  const { t } = useTranslation(['general', 'helpers']);
  const weekDays = getWeekDays(date);
  const formRef = useRef();

  const defaultTimes = {
    nightHours: new Date(0, 0, 0, 2, 0, 0),
    contractHour: new Date(0, 0, 0, 7, 36, 0)
  };

  const openModal = (dayHours) => {
    const dayIndex = dayHours.date.getDay();
    const daysLeft = weekDays.slice(dayIndex);
    const hoursWithDate = getHoursWithDate(daysLeft, contractHours);

    setNextDays(hoursWithDate);
    setShowNextDayButton(!!prop('length', hoursWithDate));
    setDayData(dayHours);
    setIsOpen(true);
  };

  const handleAddBonusClick = () => {
    eventGA('prestation_add_premium_button');
    setIsOpen(false);
    setIsBonusOpen(true);
  };

  useEffect(() => {
    const populatedBonuses = filterBonuses(bonuses, changedHours);

    const filteredPremiums = clone(populatedBonuses).filter((pc) => pc.is_phantom === 1 && pc.premium_code !== 2060);
    const filteredBonuses = clone(populatedBonuses).filter(
      (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
    );
    setStoredPremiums(filteredPremiums);
    setStoredBonuses(filteredBonuses);
    setStoredHours(changedHours);
    setInputsValues(getInputsValues(changedHours, bonuses, filteredBonuses));
  }, [bonuses, changedHours]);

  const handleEditSubmit = (data) => {
    if (data.premiumCode) {
      const newHoursAndPremiums = getNewHoursAndPremiums(data, storedHours, contractHours, storedPremiums);
      onBonusesChange([...storedBonuses, ...newHoursAndPremiums.premiums]);
      onHoursChange(newHoursAndPremiums.hours);
      setIsOpen(false);
    } else {
      const currentDate = new Date(data.date);
      const dayName = days[currentDate.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const defaultContractHours = prop(dayName, contractHours);
      const newHours = {
        ...storedHours,
        [dayName]: {
          ...defaultContractHours,
          date: data.date,
          performedMinutes: valueToMinutes(defaultContractHours.total_hours),
          difference: 0
        }
      };
      onHoursChange(newHours);
      onBonusesChange([
        ...storedBonuses,
        ...storedPremiums.filter((pm) => toIsoDateString(new Date(pm.date)) !== toIsoDateString(storedDayHours.date)),
        {
          ...storedPremiums.find(
            (pm) =>
              toIsoDateString(new Date(pm.date)) === toIsoDateString(storedDayHours.date) && pm.premium_code === 1101
          ),
          minutes: defaultContractHours.total_hours
        }
      ]);
      setIsOpen(false);
    }
    if (shouldMoveToNextDay.current) {
      const nextDay = nextDays[0];
      const nextDate = new Date(nextDay.date);
      const dayName = days[nextDate.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const defaultContractHours = prop(dayName, contractHours);
      const newItem = {
        ...storedDayHours,
        total_hours: defaultContractHours.total_hours,
        dayName,
        date: nextDate,
        reason: data.reason,
        premiumCode: data.premiumCode
      };
      openModal(newItem);
    }
  };

  const handleBonusSelectSubmit = (selected) => {
    const hoursWithDate = getHoursWithDate(weekDays, contractHours);
    const newBonuses = getNewBonuses(hoursWithDate, selected, storedBonuses, appendableCodes);
    onBonusesChange([...storedPremiums, ...newBonuses]);
    setIsBonusOpen(false);
  };

  const submitForm = async (applyToNextDay) => {
    shouldMoveToNextDay.current = applyToNextDay;
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
  };

  const inputCells = () => {
    return weekDays.map((day) => {
      const dayName = days[day.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const contractDayHours = prop(dayName, contractHours);

      return (
        <PerformedCell
          value={inputsValues[day.getDay()]?.pickerValue}
          stringValue={inputsValues[day.getDay()]?.stringValue}
          storedPremiums={storedPremiums}
          onClick={() => {
            if (!editModeDisabled) setEditMode(true);
          }}
          key={day}
          day={day}
          classes={classes}
          editMode={editMode}
          onChange={(e) => {
            setInputsValues((iv) =>
              Object.assign([], iv, {
                [day.getDay()]: {
                  pickerValue: e || new Date('invalid'),
                  stringValue: e ? convertMinutesToTime(e.getHours() * 60 + e.getMinutes(), 'h') : ''
                }
              })
            );
          }}
          onValidDateChange={(e) => {
            if (!isDate(e)) {
              return onHoursChange(clone(changedHours));
            }
            if (!areSameHours(e, getDayPickerValue(contractHours, day))) {
              return openModal({
                ...storedDayHours,
                total_hours: contractDayHours.total_hours,
                performedMinutes: valueToMinutes(e),
                difference: valueToMinutes(e) - parseInt(contractDayHours.total_hours, 10),
                dayName,
                date: day
              });
            }
            onHoursChange({
              ...storedHours,
              [dayName]: { ...contractDayHours, date: day, performedMinutes: valueToMinutes(e), difference: 0 }
            });
            return onBonusesChange([
              ...storedBonuses,
              ...storedPremiums.filter(
                (pm) => toIsoDateString(new Date(pm.date)) !== toIsoDateString(storedDayHours.date)
              ),
              {
                ...storedPremiums.find(
                  (pm) =>
                    toIsoDateString(new Date(pm.date)) === toIsoDateString(storedDayHours.date) &&
                    pm.premium_code === 1101
                ),
                minutes: contractDayHours.total_hours
              }
            ]);
          }}
        />
      );
    });
  };

  return (
    <>
      <ConfirmDialog
        title={t('general:performed.addBonus')}
        isMobile
        actions={{
          confirm: () => {
            eventGA('prestation_add_premium_confirm');
            handleBonusSelectSubmit(tempBonuses);
          },
          cancel: () => {
            eventGA('prestation_add_premium_cancel');
            setIsBonusOpen(false);
          }
        }}
        onClose={() => setIsBonusOpen(false)}
        isOpen={isBonusOpen}>
        <BonusSelector appendableCodes={appendableCodes} data={storedBonuses} onChange={setTempBonuses} />
      </ConfirmDialog>
      <ConfirmDialog
        isOpen={isOpen}
        title={
          dayData.date
            ? `${t(`helpers:dates.days.${dayData.date.getDay()}`)} ${format(dayData.date, 'dd/MM/yyyy')}`
            : ''
        }
        isMobile
        showNextDayButton={showNextDayButton}
        showCloseButton
        actions={{
          confirm: () => {
            eventGA('prestation_hours_confirm');
            submitForm(false);
          },
          confirmAndCopyToNextDay: () => {
            eventGA('prestation_hours_copy_next_day');
            submitForm(true);
          }
        }}
        onClose={() => {
          onHoursChange(clone(changedHours));
          setIsOpen(false);
        }}>
        <EditDaySchedule data={dayData} onSubmit={handleEditSubmit} formRef={formRef} />
      </ConfirmDialog>
      <Table aria-label="schedule table" size="small">
        <TableHead>
          <TableRow className={classes.headRow}>
            <TableCell />
            {weekDays.map((day) => {
              return (
                <TableCell align="right" key={day} className={clsx(classes.dayCell)}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                      <Typography variant="body2" color="secondary" align="right">
                        {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1" component="p" color="secondary">
                      {day.getDate()} {t(`helpers:dates.months.short.${day.getMonth()}`)}
                    </Typography>
                  </Box>
                </TableCell>
              );
            })}
            <TableCell align="right" className={classes.firstScheduleCell}>
              <Typography variant="body2" color="secondary">
                {t('general:total')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.headRow}>
            <TableCell className={clsx(classes.borderLessCell, classes.firstCell)}>
              <Typography variant="body2" component="span">
                {t('general:performed.hoursPerformed')}
              </Typography>
            </TableCell>
            {inputCells({}, true)}
            <TableCell align="right" className={classes.borderLessCell}>
              <Typography variant="body2">{hoursFromMinutes(getTotalFromPickers(inputsValues))}</Typography>
            </TableCell>
          </TableRow>
          {!!storedPremiums.find((item) => item.premium_code !== 1101) && (
            <>
              <PerformanceScheduleRow
                label={t('general:performed.contractHours')}
                editMode={editMode}
                classes={classes}
                weekDays={weekDays}
                isEditable
                premiumCode={1101}
                storedBonuses={storedBonuses}
                cellComponent={ReadOnlyTimeCell}
                childrenProps={{ mode: 'total_hours', contractHours, storedHours, classes }}
                total={hoursFromMinutes(getTotal('total_hours', contractHours))}
              />
              <PerformanceScheduleRow
                label={t('general:difference')}
                editMode={editMode}
                classes={classes}
                weekDays={weekDays}
                premiumCode={1001}
                storedBonuses={storedBonuses}
                cellComponent={ReadOnlyTimeCell}
                childrenProps={{ mode: 'difference', contractHours, storedHours, classes }}
                total={hoursFromMinutes(getTotal('difference', storedHours), true)}
              />
            </>
          )}
          <PerformanceScheduleRow
            label={t('general:performed.schedule')}
            icon={faClock}
            editMode={editMode}
            classes={classes}
            weekDays={weekDays}
            storedBonuses={storedBonuses}
            cellComponent={ScheduleCell}
            childrenProps={{
              changedHours,
              classes,
              contractHours,
              defaultTime: defaultTimes.contractHours
            }}
          />
          {!!storedBonuses.length && (
            <BonusesDisplay
              onBonusClick={() => {
                if (!editModeDisabled) setEditMode(true);
              }}
              editMode={editMode}
              contractHours={contractHours}
              weekDays={weekDays}
              storedBonuses={storedBonuses}
              onBonusesChange={(nb) => {
                onBonusesChange([...storedPremiums, ...nb]);
              }}
              classes={classes}
            />
          )}

          <TableRow>
            {editMode && (
              <Box component="td" data-testid="premiumButton" pl={4} p={2}>
                <Button
                  onClick={() => handleAddBonusClick()}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  color="primary"
                  fullWidth>
                  {t('general:performed.addBonus')}
                </Button>
              </Box>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

PerformanceScheduleDesktop.propTypes = {
  bonuses: array,
  classes: object.isRequired,
  contractHours: object.isRequired,
  changedHours: object,
  date: PropTypes.instanceOf(Date),
  editMode: bool,
  onHoursChange: func,
  onBonusesChange: func,
  appendableCodes: array,
  setEditMode: func,
  editModeDisabled: bool
};

PerformanceScheduleDesktop.defaultProps = {
  bonuses: [],
  changedHours: {},
  date: new Date(),
  editMode: false,
  onHoursChange: () => null,
  onBonusesChange: () => null,
  setEditMode: () => null,
  editModeDisabled: false,
  appendableCodes: []
};

export default withStyles(scheduleFormStyle)(PerformanceScheduleDesktop);
/* eslint max-lines: 0 */
