import { path, prop, omit } from 'ramda';

import history from 'utils/history';
import { api, secureCall } from 'utils/api';
import { getStorage, setStorage, removeStorage } from 'utils/storage';

import i18n from 'translate/i18n';

const handleToken = (token, save = false, key) => {
  api.setHeader({ Authorization: `Bearer ${token}` });
  return save ? setStorage(key || 'token', token) : true;
};

const handleLogin = ({ user, token, language } = {}) => {
  if (token && user) {
    const builtUser = {
      ...user,
      language
    };
    handleToken(token, true);
    setStorage('user', builtUser);
    i18n.changeLanguage(language);
    return builtUser;
  }
  return null;
};

const handleImpersonation = ({ user, impersonator, token }) => {
  if (token && user && impersonator) {
    const userData = { ...user, impersonator };
    handleToken(token, true, 'impersonatorToken');
    setStorage('impersonatedUser', userData);
    return userData;
  }
  return null;
};

export const initWithToken = async () => {
  const token = getStorage('token');
  const user = getStorage('user');
  const impersonatorToken = getStorage('impersonatorToken');
  const impersonatedUser = getStorage('impersonatedUser');
  if (impersonatedUser && impersonatorToken) {
    handleToken(impersonatorToken);
    return impersonatedUser;
  }
  if (token && user) {
    handleToken(token);
    return user;
  }
  return null;
};

export const login = secureCall(async (payload) => handleLogin((await api.post('daco-user/login', payload)) || {}), {
  message: { 401: 'errors:credentials', 403: 'errors:noLinkedClient' }
});

export const logout = () => {
  removeStorage('token');
  removeStorage('impersonatorToken');
  removeStorage('user');
  removeStorage('impersonatedUser');
  api.setHeader({ Authorization: '' });
};

const updateUser = (res) => {
  const impersonatorToken = getStorage('impersonatorToken');
  const impersonatedUser = getStorage('impersonatedUser');
  const newLang = path(['user', 'language'], res);
  if (newLang && i18n.language !== newLang) i18n.changeLanguage(newLang);
  if (impersonatedUser && impersonatorToken) {
    setStorage('impersonatedUser', {
      ...impersonatedUser,
      ...res?.user?.selectedCustomerContact,
      ...omit(['selectedCustomerContact'], res?.user)
    });
    return {
      ...prop('user', res),
      selectedCustomerContact: {
        ...res?.user?.selectedCustomerContact,
        ...impersonatedUser
      }
    };
  }
  setStorage('user', { ...res?.user?.selectedCustomerContact, ...omit(['selectedCustomerContact'], res?.user) });
  return prop('user', res);
};

export const acceptGdpr = secureCall(async () => {
  const res = await api.get('daco-user/gdpr-consent-accept');
  return updateUser(res);
});

export const update = secureCall(async (user) => {
  const res = await api.post('daco-user/update', user);
  return updateUser(res);
});

export const updatePassword = secureCall(
  async (user) => {
    const res = await api.post('daco-user/update-password', {
      old_password: user.password,
      new_password: user.newPassword
    });
    return res;
  },
  {
    message: {
      400: 'errors:passwordInvalid',
      401: 'errors:password'
    }
  }
);

export const handlePicture = secureCall(async (id, picture) => {
  const pictureUrl = await api.postFile(`users/${id}/picture`, { picture });
  return { pictureUrl };
});

export const forgotPassword = secureCall(async (data) => {
  await api.post('daco-user/request-reset-password', data);
  history.push('/');
  return true;
});

export const resetPassword = secureCall(
  async (payload) => {
    const data = await api.post('daco-user/reset-password', payload);
    return handleLogin({
      user: { ...data?.user?.selectedCustomerContact, ...omit(['selectedCustomerContact'], data?.user) },
      token: data.token,
      language: data?.user?.language
    });
  },
  { message: 'auth:resetPasswordFailure' }
);

export const getAvailableCustomers = secureCall(async () => api.get('daco-user/customer-contacts'), {
  message: 'auth:getAvailableCustomersFailure'
});

export const switchCustomerContact = secureCall(
  async (payload) => api.get(`daco-user/switch-customer-contact?customer_contact_id=${payload}`),
  { message: 'auth:setCurrentCustomerFailure' }
);

export const impersonate = secureCall(async (payload) =>
  handleImpersonation(await api.post('daco-user/impersonate', { ...payload, impersonator_id: payload.impersonatorId }))
);

export const getCustomers = secureCall(async () => api.get('daco-user/customer-contacts'));

export const getUserInfos = secureCall(async () => api.get('daco-user/view'));

export const requestAccountDeletion = secureCall(async () => api.get('daco-user/delete-account'));
