import React, { useRef, useState, useEffect, useCallback } from 'react';
import { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { isEmpty, equals } from 'ramda';
import { OrderHeadCell, OrderName } from 'components';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroller';
import { costCenterString } from 'utils/tempWorkers';

import { localeDateFormat } from 'utils/date';
import history from 'utils/history';

import { usePrevious } from 'hooks';
import OrderStatus from '../common/OrderStatus';
import OrderPeriod from '../common/OrderPeriod';

const style = {
  clickableRow: {
    cursor: 'pointer'
  },
  hiddenRow: {
    visibility: 'collapse',
    '& th,td': {
      borderBottom: 0
    }
  },
  loadingRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0,
      padding: 0
    }
  }
};

const BodyCell = withStyles({
  root: {
    padding: '12px 8px'
  }
})((props) => <TableCell color="default" {...props} />);

const OrderTable = ({ classes, data, loadMore, isLoading, search, debouncedSearch }) => {
  const { t } = useTranslation(['general']);
  const currentPage = useRef(1);
  const activeLoadMore = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const isMounted = useRef(true);
  const [order, setOrder] = useState({
    order_by_field: null,
    order_by_direction: null
  });
  const prevOrder = usePrevious(order, true);

  const getNext = useCallback(
    async (criteria) => {
      const receivedNew = await loadMore(criteria);
      if (isMounted.current) {
        activeLoadMore.current = receivedNew;
        setHasMore(receivedNew);
      }
    },
    [loadMore, setHasMore, activeLoadMore]
  );

  useEffect(() => {
    if (debouncedSearch || !equals(order, prevOrder)) {
      activeLoadMore.current = false;
      currentPage.current = 1;
      setHasMore(true);
      getNext({ ...(debouncedSearch || search), ...order, page: 1, stack: false });
    }
  }, [debouncedSearch, search, getNext, order]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  const populateTable = () =>
    data.map((dataOrder) => (
      <TableRow
        key={dataOrder.id}
        className={clsx(classes.clickableRow)}
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/orders/${dataOrder.id}`);
          history.push({
            pathname: `/orders/${dataOrder.id}`,
            state: { orderId: dataOrder.id }
          });
        }}>
        <BodyCell align="left">
          <Typography variant="body2">
            <OrderName order={dataOrder} /> {dataOrder.order_type_id === 3 && <FontAwesomeIcon icon={faRedoAlt} />}
          </Typography>
        </BodyCell>
        <BodyCell align="left">
          <OrderStatus
            statusId={dataOrder.order_status_id}
            openings={dataOrder.openings}
            acceptedCount={dataOrder.count_accepted_candidates}
            isColored
          />
        </BodyCell>
        <BodyCell align="left">
          <OrderPeriod start={dataOrder.start} end={dataOrder.end} />
        </BodyCell>
        <BodyCell align="left">
          <Typography variant="body2" color="secondary">
            {costCenterString(dataOrder?.costCenter, dataOrder?.customer)}
          </Typography>
        </BodyCell>
        <BodyCell align="left">
          <Typography variant="body2" color="secondary">
            {format(new Date(dataOrder?.created_at?.replace(' ', 'T')), localeDateFormat)}
          </Typography>
        </BodyCell>
      </TableRow>
    ));

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        if (activeLoadMore.current) {
          currentPage.current += 1;
          activeLoadMore.current = false;
          getNext({ ...search, ...order, page: currentPage.current, stack: true });
        }
      }}
      hasMore={hasMore}
      loader={
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" height={100} key="loader">
          <CircularProgress />
        </Box>
      }>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <OrderHeadCell order={order} field="candidate_name" onOrder={setOrder}>
              <Typography variant="body2" component="span" color="secondary">
                {`${data.length} ${t('general:menu.orders')}`}
              </Typography>
            </OrderHeadCell>
            <OrderHeadCell order={order} field="order_status" onOrder={setOrder} align="left">
              <Typography variant="body2" component="span" color="secondary">
                {t('general:status')}
              </Typography>
            </OrderHeadCell>
            <OrderHeadCell order={order} field="start_date" onOrder={setOrder} align="left">
              <Typography variant="body2" component="span" color="secondary">
                {t('general:period')}
              </Typography>
            </OrderHeadCell>
            <OrderHeadCell order={order} field="cost_center" onOrder={setOrder} align="left">
              <Typography variant="body2" component="span" color="secondary">
                {t('general:costcenter')}
              </Typography>
            </OrderHeadCell>
            <OrderHeadCell order={order} field="created_at_date" onOrder={setOrder} align="left">
              <Typography variant="body2" component="span" color="secondary">
                {t('general:orders.date')}
              </Typography>
            </OrderHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && isEmpty(data) ? (
            <TableRow className={classes.loadingRow}>
              <TableCell colSpan={100} className={classes.loadingRow}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          ) : (
            populateTable()
          )}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
};

OrderTable.propTypes = {
  classes: object.isRequired,
  data: array.isRequired,
  loadMore: func,
  isLoading: bool,
  search: object,
  debouncedSearch: object
};

OrderTable.defaultProps = {
  loadMore: () => null,
  isLoading: false,
  search: null,
  debouncedSearch: null
};

export default withStyles(style)(OrderTable);
