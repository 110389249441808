import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import { compose, prop, remove, sum } from 'ramda';
import { Box, LinearProgress, Table, TableBody, TableCell, Typography } from '@material-ui/core';

import CalendarIcon from 'assets/CalendarIcon';

import { getWeekDays, getWeekNumber } from 'utils/date';

import TempWorkerRow from 'components/TempWorkerRow/TempWorkerRow';
import TempWorkerRowForm from 'components/TempWorkerRow/TempWorkerRowForm';
import { usePrevious } from 'hooks';
import ListDesktopHeader from '../common/ListDesktopHeader';
import TitleBar from '../common/TitleBar';

import styles from './TempWorkersListDesktop.styles';

const PrestationToValidateListForm = ({
  classes,
  list,
  listRaw,
  isLoading,
  onSelectChange,
  isEditing,
  setIsEditing,
  getAppendableCodes,
  getAppendablePremiums,
  handleHoursModification,
  fillPremiumsForEmptyDays,
  fillPerformedHours,
  appendableCodes
}) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);
  const [allContractIds, setAllContractIds] = useState([]);
  const [hiddenCenter, setHiddenCenters] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showBonuses, setShowBonuses] = useState(false);
  const [contractIds, setContractIds] = useState([]);
  const prevListRaw = usePrevious(listRaw, false);

  const weekNamesCells = (date) =>
    getWeekDays(date).map((day) => {
      return (
        <TableCell align="right" key={day} className={clsx(classes.dayCell)}>
          <Box>
            <Box display="inline" pl={3}>
              <Typography variant="body2" component="span" color="secondary">
                {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
              </Typography>
            </Box>
            <Typography variant="caption" component="p" color="secondary">
              {day.getDate()} {t(`helpers:dates.months.short.${day.getMonth()}`)}
            </Typography>
          </Box>
        </TableCell>
      );
    });

  const handleSelectAll = (boolVal) => setContractIds(boolVal ? allContractIds : []);

  const handlePrestationCheck = (isChecked, data) => {
    const { contractId } = data.info;
    if (isChecked) {
      setContractIds([...contractIds, contractId]);
    } else {
      const contractIdx = contractIds.findIndex((cId) => cId === contractId);
      setContractIds(remove(contractIdx, 1, contractIds));
    }
  };

  useEffect(() => onSelectChange(contractIds), [contractIds, onSelectChange]);
  useEffect(() => {
    const allIds = list
      .map((week) => week.costCenters.map((center) => center.contracts.map((row) => row.info.contractId)).flat())
      .flat();
    setAllContractIds(allIds);
  }, [list, listRaw, prevListRaw]);

  useEffect(() => {
    getAppendablePremiums();
  }, []);

  const prestationsCount = useMemo(() => {
    if (prop('length', list)) {
      return sum(list.map((week) => sum(week.costCenters.map((center) => center.contracts.length))));
    }
    return 0;
  }, [list]);

  return (
    <>
      <ListDesktopHeader
        prestation
        allContractIds={allContractIds}
        contractIds={contractIds}
        detailsShown={showDetails}
        showDetails={setShowDetails}
        bonusesShown={showBonuses}
        showBonuses={setShowBonuses}
        onSelectAllChange={handleSelectAll}
        prestationsCount={prestationsCount}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      <Box mt={6} width="100%">
        {isLoading && <LinearProgress />}
        {!!prop('length', list) &&
          list.map((week) => (
            <Box mb={6} key={week.weekKey}>
              <Table aria-label="simple table">
                <TableBody>
                  <tr>
                    <TableCell align="left" className={clsx(classes.calendarCell)}>
                      <Box display="flex" flexDirection="row" whiteSpace="nowrap">
                        <Box pr={2}>
                          <CalendarIcon weekNumber={getWeekNumber(week.from)} />
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2" color="secondary">
                            {`${format(week.from, 'dd/MM')} -> ${format(week.to, 'dd/MM')}`}
                          </Typography>
                          <Typography variant="subtitle1" color="secondary">
                            {`${sum(week.costCenters.map((center) => center.contracts.length))} ${t(
                              'general:deliveries'
                            )}`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right" />
                    {weekNamesCells(week.from)}
                    <TableCell align="right" className={classes.dayCell}>
                      <Typography variant="body2" color="secondary">
                        {t('general:total')}
                      </Typography>
                    </TableCell>
                  </tr>
                  {week.costCenters.map((center) => (
                    <Fragment key={center.key}>
                      <TitleBar
                        title={center.title}
                        isOpen={!hiddenCenter.includes(center.key)}
                        canCollapse
                        toggle={() =>
                          setHiddenCenters((current) =>
                            current.includes(center.key)
                              ? current.filter((c) => c !== center.key)
                              : [...current, center.key]
                          )
                        }
                      />
                      {center.contracts.map((row) => {
                        return !isEditing ? (
                          <TempWorkerRow
                            key={`${row.name.replace(' ', '')}-${row.id}-${row.info.contractId}`}
                            data={row}
                            show={!hiddenCenter.includes(center.key)}
                            showDetails={showDetails}
                            showBonuses={showBonuses}
                            weekDays={getWeekDays(week.from)}
                            rowLabel={row.name}
                            onCheckChange={handlePrestationCheck}
                            checked={contractIds.includes(row.info.contractId)}
                          />
                        ) : (
                          <TempWorkerRowForm
                            key={`${row.name.replace(' ', '')}-${row.id}-${row.info.contractId}`}
                            data={row}
                            show={!hiddenCenter.includes(center.key)}
                            showDetails={showDetails}
                            showBonuses={showBonuses}
                            weekDays={getWeekDays(week.from)}
                            rowLabel={row.name}
                            onCheckChange={handlePrestationCheck}
                            checked={contractIds.includes(row.info.contractId)}
                            getAppendableCodes={getAppendableCodes}
                            handleHoursModification={handleHoursModification}
                            fillPremiumsForEmptyDays={fillPremiumsForEmptyDays}
                            fillPerformedHours={fillPerformedHours}
                            appendableCodes={appendableCodes}
                          />
                        );
                      })}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          ))}
      </Box>
    </>
  );
};

PrestationToValidateListForm.propTypes = {
  list: array.isRequired,
  listRaw: array.isRequired,
  classes: object.isRequired,
  isLoading: bool,
  onSelectChange: func.isRequired,
  isEditing: bool,
  setIsEditing: func,
  getAppendableCodes: func.isRequired,
  getAppendablePremiums: func.isRequired,
  handleHoursModification: func,
  fillPremiumsForEmptyDays: func,
  fillPerformedHours: func,
  appendableCodes: array
};

PrestationToValidateListForm.defaultProps = {
  isLoading: false,
  isEditing: false,
  setIsEditing: () => null,
  handleHoursModification: () => null,
  fillPremiumsForEmptyDays: () => null,
  fillPerformedHours: () => null,
  appendableCodes: []
};

const mapState = ({ contracts: { appendableCodes } }) => ({ appendableCodes });

const mapDispatch = ({ contracts: { getAppendableCodes, getAppendablePremiums } }) => ({
  getAppendableCodes,
  getAppendablePremiums
});

export default compose(withStyles(styles), connect(mapState, mapDispatch))(PrestationToValidateListForm);
