import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { Box, Button, Card, CardActions, CardContent, Checkbox, LinearProgress, Typography } from '@material-ui/core';
import { pathOr, prop, remove } from 'ramda';
import { format } from 'date-fns';
import uuid from 'react-uuid';

import CalendarIcon from 'assets/CalendarIcon';

import { days, getWeekDays, getWeekNumber } from 'utils/date';
import { useHeader, usePrevious } from 'hooks';

import HourCell from 'components/TempWorkerRow/HourCell';
import Status from './Status';
import TitleBar from '../common/TitleBar';

import styles from './TempWorkersListMobile.styles';

const PrestationToValidateListMobile = ({ classes, history, list, listRaw, date, isLoading, onSelectChange }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);
  const [allContractIds, setAllContractIds] = useState([]);
  const [contractIds, setContractIds] = useState([]);
  const { setComponents, renderSearch, setTitle } = useHeader();
  const prevListRaw = usePrevious(listRaw, false);

  const weekDays = getWeekDays(date);

  const handlePrestationCheck = (isChecked, data) => {
    const { contractId } = data.info;
    if (isChecked) {
      setContractIds([...contractIds, contractId]);
    } else {
      const contractIdx = contractIds.findIndex((cId) => cId === contractId);
      setContractIds(remove(contractIdx, 1, contractIds));
    }
  };

  const handleSelectAll = (boolVal) => setContractIds(boolVal ? allContractIds : []);

  useEffect(() => onSelectChange(contractIds), [contractIds]);

  useEffect(() => {
    setTitle('general:menu.TBCDeliveries');
    setComponents([
      renderSearch,
      () => (
        <Checkbox
          key="check"
          checked={contractIds.length === allContractIds.length && allContractIds.length > 0}
          indeterminate={contractIds.length > 0 && contractIds.length !== allContractIds.length}
          onChange={(e) => handleSelectAll(e.target.checked)}
          name="checkedB"
          color="primary"
        />
      )
    ]);
  }, [setComponents, renderSearch, contractIds, allContractIds]);

  useEffect(() => {
    const allIds = list
      .map((week) => week.costCenters.map((center) => center.contracts.map((row) => row.info.contractId)).flat())
      .flat();
    setAllContractIds(allIds);
  }, [list, listRaw, prevListRaw]);

  return (
    <>
      <Box width="100%" position="relative">
        {isLoading && <LinearProgress />}
        {!!prop('length', list) &&
          list.map((week) => (
            <Box key={uuid()}>
              <Box p={2} display="flex" justifyContent="space-between" className={classes.weekBar}>
                <Box pr={2} display="flex" alignItems="center">
                  <CalendarIcon weekNumber={getWeekNumber(week.from)} />
                  <Box ml={2}>
                    <Typography variant="body2" color="secondary">
                      {`${format(week.from, 'dd/MM')} -> ${format(week.to, 'dd/MM')}`}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="secondary">
                    {`${week.count} ${t('general:deliveries')}`}
                  </Typography>
                </Box>
              </Box>
              {week.costCenters.map((center) => (
                <Box key={uuid()}>
                  <TitleBar title={center.title} />
                  <Box pt={4}>
                    {center.contracts.map((row) => (
                      <Card className={classes.card} variant="outlined" key={uuid()}>
                        <CardContent
                          onClick={() =>
                            history.push({
                              pathname: '/prestation',
                              state: { data: row, navigationBack: 'confirm-prestation' }
                            })
                          }>
                          <Typography variant="h6" component="h6">
                            {row.name}
                          </Typography>
                          <Status data={row} />
                          <table className={classes.table}>
                            <tbody>
                              <tr>
                                {weekDays.map((day) => (
                                  <td key={uuid()}>
                                    <Typography variant="body2">
                                      {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                                    </Typography>
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                {weekDays.map((day) => (
                                  <td key={uuid()}>
                                    <HourCell hours={pathOr({}, ['hours', days[day.getDay()]], row)} />
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </CardContent>
                        <CardActions className={classes.cardAction}>
                          <Button
                            variant="outlined"
                            color={!contractIds.includes(row.info.contractId) ? 'default' : 'primary'}
                            startIcon={!contractIds.includes(row.info.contractId) ? <AddIcon /> : <CheckIcon />}
                            onClick={() => handlePrestationCheck(!contractIds.includes(row.info.contractId), row)}
                            className={classes.cardActionButton}>
                            {t(contractIds.includes(row.info.contractId) ? 'general:selected' : 'general:select')}
                          </Button>
                        </CardActions>
                      </Card>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
      </Box>
    </>
  );
};

PrestationToValidateListMobile.propTypes = {
  classes: object.isRequired,
  history: object.isRequired,
  list: array.isRequired,
  listRaw: array.isRequired,
  date: PropTypes.instanceOf(Date),
  isLoading: bool,
  onSelectChange: func.isRequired
};

PrestationToValidateListMobile.defaultProps = {
  date: new Date(),
  isLoading: false
};

export default withStyles(styles)(withRouter(PrestationToValidateListMobile));
