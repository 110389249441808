import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText, FilledInput, InputLabel, Select, MenuItem, Box } from '@material-ui/core';
import { addWeeks, differenceInWeeks, isSameWeek } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import { array, bool, func, object } from 'prop-types';
import { isNil, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { getNextWorkingDay } from 'utils/date';
import WeekSelector from 'components/WeekSelector/WeekSelector';
import LegalContractInfo from 'components/LegalContractInfo/LegalContractInfo';

const getDates = (source, diffStart, diffEnd, min) => {
  const from = addWeeks(new Date(source), differenceInWeeks(diffStart, new Date(diffEnd)) + 1);
  return from < min ? min : from;
};

const date = new Date();
const nextWorkingDay = getNextWorkingDay();

// `FormControl` wraps every field for the sake of design consistency.
const ExtendForm = ({ defaultData, onSend, keepData, reasonsList, setReason, setIsValid, isValid, reason }) => {
  // If start date is weekend go to next Monday (start: sunday).
  const [refInput, setReference] = useState(keepData ? propOr('', 'reference', defaultData) : '');
  const [errors, setErrors] = useState({ from: null, to: null });
  const [from, setFrom] = useState(
    keepData ? defaultData.from : getDates(defaultData.from, date, defaultData.from, nextWorkingDay)
  );
  const [to, setTo] = useState(
    keepData
      ? defaultData.to
      : addWeeks(new Date(defaultData.to), differenceInWeeks(date, new Date(defaultData.from)) + 1)
  );

  const { t } = useTranslation(['general', 'validation']);

  const { control, watch, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      reason: reason || ''
    }
  });

  const reasonInput = watch('reason');

  useEffect(() => {
    onSend({
      costCenter: defaultData.costCenter || {},
      from,
      to,
      reference: refInput,
      isValid: isNil(errors.from) && isNil(errors.to),
      reason_id: reasonInput?.reason_id
    });
  }, [onSend, from, to, refInput, errors, reasonInput]);

  const handleReferenceChange = (e) => {
    setReference(e.target.value);
  };

  const handleDateChange = (newDate, setter, name) => {
    if (name === 'from') {
      if (!isSameWeek(newDate, nextWorkingDay, { weekStartsOn: 1 }) && newDate < nextWorkingDay) {
        setErrors({ ...errors, from: { message: 'validation:dateMustNotBeWorkday' } });
      } else {
        setErrors({ from: null, to: null });
      }
    }
    setter(newDate);
  };

  useEffect(() => {
    if (!reasonInput) return;
    setReason(reasonInput);
    setIsValid(true);
  }, [reasonInput]);

  useEffect(() => {
    setValue('reason', defaultData?.reason || '');
  }, [defaultData?.reason, setValue]);

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="reference">{t('general:extend.reference')}</InputLabel>
          <FilledInput
            id="reference"
            value={refInput}
            onChange={handleReferenceChange}
            inputProps={{ maxLength: 25 }}
            fullWidth
          />
          <FormHelperText>{t('general:optional')}</FormHelperText>
        </FormControl>

        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="reason">{t('general:extend.reason')}</InputLabel>
          <Controller
            name="reason"
            control={control}
            rules={{ required: 'optionRequired' }}
            as={
              <Select variant="filled" id="reason" labelId="reason" fullWidth>
                {reasonsList?.map((r) => (
                  <MenuItem key={r.reason_id} value={r}>
                    {r.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          <FormHelperText error={!isValid}>{t('errors:reason')}</FormHelperText>
        </FormControl>

        <LegalContractInfo />

        <WeekSelector
          isBlock
          dateChange={(val) => {
            handleDateChange(val.from, setFrom, 'from');
            handleDateChange(val.to, setTo, 'to');
            return val;
          }}
          value={from}
        />
        <FormHelperText error={!!errors.from}>{errors.from?.message && t(errors.from.message)}</FormHelperText>
      </Box>
    </Box>
  );
};

ExtendForm.propTypes = {
  defaultData: object,
  onSend: func.isRequired,
  keepData: bool,
  reasonsList: array.isRequired,
  setReason: func.isRequired,
  setIsValid: func.isRequired,
  isValid: bool.isRequired,
  reason: object
};

ExtendForm.defaultProps = {
  defaultData: {},
  keepData: false,
  reason: null
};

export default ExtendForm;
