import {
  pathOr,
  clone,
  cond,
  both,
  compose,
  values,
  propSatisfies,
  complement,
  T,
  either,
  always,
  filter,
  find,
  propEq,
  anyPass,
  allPass,
  propOr,
  omit,
  equals
} from 'ramda';
import { isValidDate, isNilOrEmpty } from 'ramda-adjunct';
import { startOfWeek } from 'date-fns';
import { convertMinutesToTime } from 'utils/date';

export const getCurrentDate = (date, day) => {
  const dateCp = new Date(date);
  const weekStart = startOfWeek(dateCp, { weekStartsOn: 1 });
  const mondayDate = weekStart.getDate();
  const finalDate = new Date(weekStart);
  finalDate.setDate(mondayDate + day);
  return finalDate;
};

export const hourRowsName = {
  contractHour: 'contractHour',
  startAm: 'startAm',
  endAm: 'endAm',
  startPm: 'startPm',
  endPm: 'endPm'
};

export const defaultTimes = {
  [hourRowsName.startAm]: null,
  [hourRowsName.endAm]: null,
  [hourRowsName.startPm]: null,
  [hourRowsName.endPm]: null,
  [hourRowsName.contractHour]: null
};

const hourApiName = {
  [hourRowsName.startAm]: 'start_am',
  [hourRowsName.endAm]: 'end_am',
  [hourRowsName.startPm]: 'start_pm',
  [hourRowsName.endPm]: 'end_pm',
  [hourRowsName.contractHour]: 'total_hours'
};

const asDate = (timeStr, date) => (timeStr ? new Date(`${date.toDateString()} ${timeStr}`) : null);

export const makeScheduleObject = (key, date, dayHours) => ({
  startAm: asDate(pathOr(null, [hourApiName[hourRowsName.startAm]], dayHours), date),
  endAm: asDate(pathOr(null, [hourApiName[hourRowsName.endAm]], dayHours), date),
  startPm: asDate(pathOr(null, [hourApiName[hourRowsName.startPm]], dayHours), date),
  endPm: asDate(pathOr(null, [hourApiName[hourRowsName.endPm]], dayHours), date),
  contractHour: asDate(convertMinutesToTime(pathOr(null, [hourApiName[hourRowsName.contractHour]], dayHours)), date),
  dayNumber: key,
  date: getCurrentDate(date, key)
});

export const getTimeError = (key, field) => find((error) => error.dayNumber === key && error.field === field);

const isTimeUndefined = (time) => isNilOrEmpty(time) || !isValidDate(time);

const hasNoContractHour = propSatisfies(isTimeUndefined, hourRowsName.contractHour);
const hasNoStartAm = propSatisfies(isTimeUndefined, hourRowsName.startAm);
const hasNoEndAm = propSatisfies(isTimeUndefined, hourRowsName.endAm);
const hasNoStartPm = propSatisfies(isTimeUndefined, hourRowsName.startPm);
const hasNoEndPm = propSatisfies(isTimeUndefined, hourRowsName.endPm);

const fieldRequired = always('validation:fieldRequired');
const incorrectTotal = always('validation:incorrectTotal');
const alwaysNull = [T, always(null)];
const nullWhenContractHourDefined = [complement(hasNoContractHour), always(null)];

const validStartAm = (schedule) =>
  cond([nullWhenContractHourDefined, [both(hasNoContractHour, hasNoStartAm), fieldRequired], alwaysNull])(schedule);

const isEndAmRequired = both(hasNoEndAm, hasNoEndPm);

const validEndAm = (schedule) =>
  cond([nullWhenContractHourDefined, [both(hasNoContractHour, isEndAmRequired), fieldRequired], alwaysNull])(schedule);

const isStartPmRequired = both(hasNoStartPm, both(complement(hasNoEndAm), complement(hasNoEndPm)));

const validStartPm = (schedule) => {
  return cond([nullWhenContractHourDefined, [both(hasNoContractHour, isStartPmRequired), fieldRequired], alwaysNull])(
    schedule
  );
};

const isEndPmRequired = both(hasNoEndPm, complement(hasNoStartPm));

const validEndPm = (schedule) =>
  cond([nullWhenContractHourDefined, [both(hasNoContractHour, isEndPmRequired), fieldRequired], alwaysNull])(schedule);

const differenceToDate = (timeDifference) => {
  const HOUR_MS = 3600 * 1000;
  const MINUTE_MS = 60 * 1000;
  const totalHoursWithoutMinutes = Math.floor(timeDifference / HOUR_MS);
  const totalMinutes = Math.floor((timeDifference % HOUR_MS) / MINUTE_MS);
  const totalDifference = new Date();
  totalDifference.setHours(totalHoursWithoutMinutes);
  totalDifference.setMinutes(totalMinutes);

  return totalDifference;
};

const computeDifference = (endHourRowName, startHourRowName, params = { msDiff: false, addDay: false }) => (
  schedule
) => {
  if (!isValidDate(schedule[endHourRowName]) || !isValidDate(schedule[startHourRowName])) {
    return params.msDiff ? { timeDifference: 0, addedDay: false } : null;
  }
  let addedDay = false;
  const startDate = new Date();
  startDate.setHours(schedule[startHourRowName].getHours());
  startDate.setMinutes(schedule[startHourRowName].getMinutes());
  const endDate = new Date();
  endDate.setHours(schedule[endHourRowName].getHours());
  endDate.setMinutes(schedule[endHourRowName].getMinutes());
  if (params.addDay) {
    startDate.setDate(startDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 1);
  }
  if (endDate.getTime() < startDate.getTime()) {
    endDate.setDate(endDate.getDate() + 1);
    addedDay = true;
  }
  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
  return params.msDiff ? { timeDifference, addedDay } : differenceToDate(timeDifference);
};

const computeWholeDayTime = (schedule) => {
  const timeDifferenceMorning = computeDifference(hourRowsName.endAm, hourRowsName.startAm, {
    msDiff: true,
    addDay: false
  })(schedule);
  const timeDifferenceAfternoon = computeDifference(hourRowsName.endPm, hourRowsName.startPm, {
    msDiff: true,
    addDay: timeDifferenceMorning.addedDay
  })(schedule);
  const total =
    propOr(0, 'timeDifference', timeDifferenceMorning) + propOr(0, 'timeDifference', timeDifferenceAfternoon);
  if (Math.floor(total / 3600000) >= 24) return null;
  return differenceToDate(total);
};

const validContractHours = (schedule) => {
  if (!anyPass([hasNoStartAm, hasNoEndAm, hasNoStartPm, hasNoEndPm])(schedule)) {
    return computeWholeDayTime(schedule) ? null : incorrectTotal();
  }
  return null;
};

const isNotNil = complement(isNilOrEmpty);

const validDayHours = (dayNumber) =>
  compose(filter(propSatisfies(isNotNil, 'message')), values, (daySchedule) => ({
    startAm: { dayNumber, field: hourRowsName.startAm, message: validStartAm(daySchedule) },
    endAm: { dayNumber, field: hourRowsName.endAm, message: validEndAm(daySchedule) },
    startPm: { dayNumber, field: hourRowsName.startPm, message: validStartPm(daySchedule) },
    endPm: { dayNumber, field: hourRowsName.endPm, message: validEndPm(daySchedule) },
    contractHours: { dayNumber, field: hourRowsName.contractHour, message: validContractHours(daySchedule) }
  }));

export const getTotal = (scheduleDay = {}) =>
  cond([
    [
      both(complement(hasNoContractHour), allPass([hasNoStartAm, hasNoEndAm, hasNoStartPm, hasNoEndPm])),
      propOr(null, hourRowsName.contractHour)
    ],
    [
      hasNoStartPm,
      (schedule) =>
        cond([
          [hasNoEndPm, computeDifference(hourRowsName.endAm, hourRowsName.startAm)],
          [hasNoEndAm, computeDifference(hourRowsName.endPm, hourRowsName.startAm)],
          alwaysNull
        ])(schedule)
    ],
    [
      hasNoStartAm,
      (schedule) =>
        cond([
          [complement(either(hasNoEndPm, hasNoStartPm)), computeDifference(hourRowsName.endPm, hourRowsName.startPm)],
          alwaysNull
        ])(schedule)
    ],
    [complement(anyPass([hasNoStartAm, hasNoEndAm, hasNoStartPm, hasNoEndPm])), computeWholeDayTime],
    alwaysNull
  ])(scheduleDay);

export const applyScheduleChange = ({ day, hourRowName, scheduleDays, dateTime, date, timeErrors }) => {
  const dayScheduleIndex = scheduleDays.findIndex((s) => s.dayNumber === day);
  const newDayHours = {
    ...(scheduleDays[dayScheduleIndex] || {}),
    date: getCurrentDate(date, day),
    [hourRowName]: dateTime
  };
  const contractHour = hourRowName !== hourRowsName.contractHour ? getTotal(newDayHours) : dateTime;

  const schedule = clone(scheduleDays);
  schedule[dayScheduleIndex] = { ...newDayHours, contractHour };

  return {
    schedule,
    // Removing all errors for the current day then recheck errors
    newTimeErrors: [
      ...filter(complement(propEq('dayNumber', day)), timeErrors),
      ...validDayHours(day)(schedule[dayScheduleIndex])
    ]
  };
};

export const checkIfDayIsSame = (currentDay, schedule) => {
  const day = omit(
    ['dayNumber', 'date'],
    find((item) => item.dayNumber === currentDay.dayNumber, schedule)
  );
  const firstDay = omit(['dayNumber', 'date'], schedule[0]);
  return equals(firstDay, day);
};
