import React from 'react';
import { string, oneOf } from 'prop-types';
import { Box, colors, Typography } from '@material-ui/core';
import { Info, Error as Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const { yellow, blue } = colors;

const useStyles = makeStyles({
  root: {
    backgroundColor: (props) => props.bgColor,
    color: 'white',
    margin: '1em 0',
    padding: '1.5em'
  },
  typo: {
    marginLeft: '8px'
  }
});

const getBannerIcon = (type) => {
  const whiteColor = { color: 'white' };
  const infoColor = blue[700];
  const yellowColor = yellow[900];
  switch (type.toLowerCase()) {
    case 'info':
      return [infoColor, <Info style={whiteColor} />];
    case 'warning':
      return [yellowColor, <Warning style={whiteColor} />];
    default:
      return [infoColor, <Info style={whiteColor} />];
  }
};

const Banner = ({ message, type }) => {
  const [bgColor, Icon] = getBannerIcon(type);
  const classes = useStyles({ bgColor });
  return (
    <Box className={classes.root} borderRadius={4} display="flex" flexDirection="row" alignItems="center">
      {Icon}
      <Typography className={classes.typo} variant="body2">
        {message}
      </Typography>
    </Box>
  );
};

Banner.propTypes = {
  message: string.isRequired,
  type: oneOf(['info', 'warning', 'INFO', 'WARNING'])
};

Banner.defaultProps = {
  type: 'info'
};

export default Banner;
