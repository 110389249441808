import {
  isNil,
  groupBy,
  values,
  clone,
  pipe,
  omit,
  map,
  prop,
  mapObjIndexed,
  pathOr,
  uniqBy,
  reverse,
  filter,
  find,
  path,
  findIndex,
  flatten
} from 'ramda';

import { toIsoDateString, getWeekDays, formatDate, mondayWeek, days } from 'utils/date';

import { cleanObj } from 'utils/object';

const getDWHBonusMinutes = (bonus, changedHours) => {
  if (bonus?.source === 0) {
    if ([1, 3].includes(bonus?.smile_code_type)) {
      const bonusDayName = days[new Date(bonus.date).getDay()];
      return (
        path([bonusDayName, 'performedMinutes'], changedHours) || path([bonusDayName, 'total_hours'], changedHours) || 0
      );
    }
    if (bonus?.smile_code_type === 4) return bonus?.minutes || 1;
  }
  return bonus?.minutes;
};

const getPremiumMinutes = (premium) => {
  if (!premium || premium.premium_code === 1001) return 0;
  return -premium.minutes;
};

const getPerformedTime = (performedPremium, hours, foundPremium) => {
  if (!isNil(performedPremium?.minutes)) return performedPremium?.minutes;
  return parseInt(hours?.total_hours, 10) + getPremiumMinutes(foundPremium);
};

const handleMissingHours = (missingHours, workHours) => {
  if (missingHours?.length === 0) return workHours;
  const cleanHours = workHours.filter(
    (item) =>
      [1001, 1101].includes(item.premium_code) && !missingHours.find((missingHour) => missingHour.date === item.date)
  );

  const newWorkHours = missingHours.map((missingHour) => {
    const filteredWorkHours = workHours.filter((item) => item.date === missingHour.date && item.premium_code === 1101);
    let remainingMinutes = missingHour.minutes;
    return filteredWorkHours.map((workHour) => {
      const { minutes } = workHour;
      let { minutes: effectiveMinutes } = workHour;
      let missingMinutes = 0;
      if (remainingMinutes > 0) {
        effectiveMinutes = minutes - remainingMinutes >= 0 ? minutes - remainingMinutes : 0;
        remainingMinutes -= minutes;
        missingMinutes = minutes - effectiveMinutes;
      }
      return flatten(
        filter((item) => item.premium_code === 1101 || item.minutes > 0, [
          {
            ...workHour,
            minutes: effectiveMinutes
          },
          { ...missingHour, minutes: missingMinutes, contract_id: workHour.contract_id }
        ]),
        cleanHours
      );
    });
  });
  return flatten([...cleanHours, ...newWorkHours]);
};

export const getFinalBonuses = (changedBonuses, validatedSources, linkedContracts) => {
  const reversedList = reverse(changedBonuses.filter((bonus) => (validatedSources ? bonus.source === 2 : true)));
  const filteredList = clone(reversedList).filter((bonus) => {
    return ![0, 2].includes(bonus.source) && (bonus.minutes || bonus.amount || bonus.premium_code === 1101);
  });
  const pipedInfos = pipe(uniqBy((item) => `${item.premium_code}-${item.date}-${item.contract_id}`))(filteredList);
  if (linkedContracts.length > 1) {
    const workHours = pipedInfos.filter((item) => item.premium_code === 1101);
    const extraHours = pipedInfos.filter((item) => item.premium_code === 1001);
    const missingHours = pipedInfos.filter(
      (item) => item.is_absence_justification && item.premium_code !== 1101 && item.premium_code !== 1001
    );
    const premiumHours = pipedInfos.filter(
      (item) => !item.is_absence_justification && item.premium_code !== 1101 && item.premium_code !== 1001
    );
    let newWorkHours = linkedContracts.map((contract) => {
      const { scheduleDays } = contract;
      const weekDays = getWeekDays(new Date(contract.from));
      const newBonuses = weekDays.map((weekDay, index) => {
        const currentDay = days[[1, 2, 3, 4, 5, 6, 0][index]];
        if (scheduleDays[currentDay]) {
          const workHour = workHours.find((item) => item.date === formatDate(weekDay, false));
          return {
            ...workHour,
            minutes: Number(scheduleDays[currentDay].total_hours),
            contract_id: contract.id
          };
        }
        return null;
      });
      return filter((item) => item?.premium_code, newBonuses);
    });
    newWorkHours = [
      ...pipe(
        flatten,
        filter((item) => item)
      )(newWorkHours),
      ...extraHours
    ];
    newWorkHours = handleMissingHours(missingHours, newWorkHours);
    return groupBy((bonus) => bonus.contract_id, [...newWorkHours, ...premiumHours]);
  }

  return groupBy((bonus) => bonus.contract_id, pipedInfos);
};

export const getFinalHours = (changedHours, changedBonuses, contract) => {
  const weekDays = getWeekDays(new Date(contract.from));
  return pipe(
    cleanObj,
    omit(['total']),
    mapObjIndexed((val, key) => ({
      ...val,
      day: key,
      total_hours: !isNil(val.performedMinutes) ? val.performedMinutes : val.total_hours
    })),
    values,
    map((item) => {
      const isoDate = formatDate(weekDays[mondayWeek[days.findIndex((d) => item.day === d)]], false);
      const matchingBonus = pipe(
        filter((b) => b.premium_code === 1101 && b.date === isoDate),
        find((b) => b.minutes === item.total_hours)
      )(changedBonuses);
      return matchingBonus ? { ...item, id: matchingBonus.contract_id } : item;
    }),
    groupBy(prop('id'))
  )(changedHours);
};

export const getUnusedContractBonuses = (from, scheduleDays) => {
  const weekDays = getWeekDays(new Date(from));
  return scheduleDays.map((sd) => {
    const isoDate = formatDate(weekDays[mondayWeek[days.findIndex((d) => sd.day === d)]], false);
    return {
      premium_code: 1101,
      date: isoDate,
      is_phantom: 1,
      is_active: 1,
      minutes: 0,
      amount: 0
    };
  });
};

export const filterBonuses = (allBonuses, changedHours) => {
  const validatedSources = !!allBonuses.find((b) => b.source === 2);
  return uniqBy(
    (item) => `${item.premium_code}-${item.date}`,
    reverse(
      allBonuses
        .filter((bonus) => (validatedSources ? bonus.source === 2 : true))
        .map((bonus) => {
          return {
            ...bonus,
            minutes: getDWHBonusMinutes(bonus, changedHours)
          };
        })
    )
  );
};

export const fillPremiumsForEmptyDays = ({ changedHours, contractHours, allBonuses, date }) => {
  const weekDays = getWeekDays(date, 1);
  const populatedBonuses = filterBonuses(allBonuses, changedHours);
  const premiums = clone(populatedBonuses).filter((pc) => pc.is_phantom === 1 && pc.premium_code !== 2060);
  let formattedPremiums = [...premiums];
  mapObjIndexed((val, key) => {
    if (val && key !== 'total') {
      const isoDate = toIsoDateString(weekDays[mondayWeek[findIndex((d) => d === key, days)]]);
      const foundPremium = premiums.find((item) => item.premium_code !== 1101 && item.date === isoDate);
      const performedPremium = populatedBonuses.find((item) => item.premium_code === 1101 && item.date === isoDate);
      formattedPremiums = Object.assign([], formattedPremiums, {
        [formattedPremiums.length]: {
          contract_id: val.id,
          premium_code: 1101,
          is_phantom: 1,
          is_active: 1,
          date: isoDate,
          minutes: getPerformedTime(performedPremium, contractHours[key], foundPremium),
          amount: 0
        }
      });
    }
  }, changedHours);

  return formattedPremiums;
};

export const fillPerformedHours = (from, changedHours, contractHours, premiums) => {
  return Object.keys(changedHours).reduce((acc, key) => {
    let premium;
    let performedPremium;
    let date;
    let addedTime;
    const weekDays = getWeekDays(from, 1);
    if (key !== 'total') {
      date = weekDays[mondayWeek[findIndex((d) => d === key, days)]];
      const isoDate = toIsoDateString(date);
      premium = premiums.find((pm) => pm.date === isoDate && pm.premium_code !== 1101);
      performedPremium = premiums.find((pm) => pm.date === isoDate && pm.premium_code === 1101);
      addedTime = premium?.premium_code === 1001 ? premium.minutes : 0;
    }
    return {
      ...acc,
      ...(changedHours[key] && {
        [key]: {
          ...changedHours[key],
          performedMinutes:
            (!isNil(performedPremium?.minutes)
              ? performedPremium?.minutes
              : parseInt(pathOr(0, [key, 'total_hours'], contractHours), 10)) + addedTime,
          ...(date && {
            date
          }),
          ...(premium && {
            reason: premium
          }),
          ...(performedPremium && {
            difference:
              performedPremium.minutes + addedTime - parseInt(pathOr(0, [key, 'total_hours'], contractHours), 10)
          })
        }
      })
    };
  }, {});
};

export const getNewBonuses = (hoursWithDate, selected, storedBonuses, appendableCodes) => {
  return hoursWithDate
    .map((schedule) =>
      selected.map((s) => {
        const foundStoredBonus = storedBonuses.find(
          (bonus) => bonus.premium_code === s && bonus.date === schedule.date
        );
        return (
          foundStoredBonus || {
            ...appendableCodes.find((bonus) => bonus.premium_code === s),
            date: schedule.date,
            contract_id: schedule.id,
            source: 1,
            minutes: 0,
            amount: 0
          }
        );
      })
    )
    .flat();
};
