import React from 'react';
import { ReactComponent as FileAlt } from 'assets/file-alt.svg';
import { ReactComponent as FileHistory } from 'assets/file-history.svg';
import { ReactComponent as FileInvoiceDollar } from 'assets/file-invoice-dollar.svg';
import { ReactComponent as FileInvoiceScale } from 'assets/file-invoice-scale.svg';
import { ReactComponent as FileInvoice } from 'assets/file-invoice.svg';
import { ReactComponent as FileThList } from 'assets/file-th-list.svg';
import { ReactComponent as FileUser } from 'assets/file-user.svg';
import { ReactComponent as File } from 'assets/file.svg';

export default [
  <FileInvoiceDollar />,
  <FileInvoiceScale />,
  <FileUser />,
  <FileAlt />,
  <FileHistory />,
  <FileThList />,
  <FileInvoice />,
  <File />
];
