export const BuildFormData = (body) =>
  Object.keys(body).reduce((accumulator, value) => {
    accumulator.append(value, body[value]);
    return accumulator;
  }, new FormData());

export const flattenObject = (object, _prefix = '') => {
  const prefix = _prefix ? `${_prefix}.` : '';
  return Object.keys(object).reduce((result, key) => {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return { ...result, ...flattenObject(value, prefix + key) };
      }
      return { ...result, [prefix + key]: value };
    }
    return result;
  }, {});
};
