import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { pathOr } from 'ramda';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { array, bool, func, number, object } from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { useHeader } from 'hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { UserContext } from 'user/userContext';

import history from 'utils/history';

const News = ({ classes, news, getNews, isMobile, unreadCount, markAllAsRead, isMarkingAllAsRead, getUnreadCount }) => {
  const { t } = useTranslation(['general']);
  const user = useContext(UserContext);
  const currentPage = useRef(0);
  const shouldLoad = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const { setTitle, setComponents } = useHeader();

  const getNext = async (page) => {
    const receivedNew = await getNews(page);
    shouldLoad.current = receivedNew;
    setHasMore(receivedNew);
  };

  useEffect(() => {
    setTitle('general:menu.news');
    setComponents([]);
    currentPage.current = 1;
    shouldLoad.current = false;
    setHasMore(true);
    const getFirstPage = async () => {
      await getNext(1);
    };
    getFirstPage();
  }, [setTitle, setComponents, user.language]);

  const handleMarkAllAsReadClick = useCallback(async () => {
    await markAllAsRead();
    getUnreadCount();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box mt={5}>
        {!isMobile && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" component="h2">
              {t('general:news.title')}
            </Typography>
            {unreadCount > 0 && (
              <Button
                className={clsx(classes.button, classes.buttonMobile)}
                color="primary"
                onClick={handleMarkAllAsReadClick}>
                {isMarkingAllAsRead ? <CircularProgress /> : t('general:news.markAllAsRead')}
              </Button>
            )}
          </Box>
        )}
        <Container maxWidth="md" className={classes.noPaddingMobile}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              if (shouldLoad.current) {
                currentPage.current += 1;
                shouldLoad.current = false;
                getNext(currentPage.current);
              }
            }}
            hasMore={hasMore}
            loader={
              <Box width="100%" display="flex" justifyContent="center" alignItems="center" height={100} key="loader">
                <CircularProgress />
              </Box>
            }>
            {news.map((newsItem) => {
              return (
                <Card
                  className={classes.card}
                  variant="outlined"
                  key={newsItem.title}
                  onClick={() =>
                    history.push({
                      pathname: '/news/details',
                      state: { data: newsItem }
                    })
                  }>
                  <CardContent>
                    <Typography variant="h6" component="h6" className={classes.title}>
                      {newsItem.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.cardContent}
                      dangerouslySetInnerHTML={{ __html: newsItem.content.replace(/(<([^>]+)>)/gi, '') }}
                    />
                  </CardContent>
                  <CardActions className={classes.cardFooter}>
                    <Typography variant="subtitle1" component="span">
                      {t('general:news.published', {
                        formattedDate: format(
                          new Date(newsItem.published_at.replace(' ', 'T')),
                          t('general:fullDateFormat')
                        )
                      })}
                    </Typography>
                    {!newsItem.is_read && (
                      <Typography variant="subtitle1" component="span" className={classes.new}>
                        {t('general:news.new')}
                      </Typography>
                    )}
                  </CardActions>
                </Card>
              );
            })}
          </InfiniteScroll>
        </Container>
      </Box>
    </Container>
  );
};

News.propTypes = {
  classes: object.isRequired,
  news: array.isRequired,
  getNews: func.isRequired,
  isMobile: bool.isRequired,
  unreadCount: number.isRequired,
  markAllAsRead: func.isRequired,
  getUnreadCount: func.isRequired,
  isMarkingAllAsRead: bool.isRequired
};

const mapState = ({ user: { isOnMobile }, news: { list: news, unreadCount }, loading }) => ({
  news,
  unreadCount,
  isMobile: isOnMobile,
  isMarkingAllAsRead: pathOr(false, ['effects', 'news', 'markAllAsRead'], loading)
});

const mapDispatch = ({ news: { getList: getNews, markAllAsRead, getUnreadCount } }) => ({
  getNews,
  markAllAsRead,
  getUnreadCount
});

const styles = createStyles((theme) => ({
  title: {
    marginBottom: 12
  },
  card: {
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover h6': {
      color: theme.palette.primary.main
    }
  },
  new: {
    textTransform: 'uppercase'
  },
  cardContent: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    color: theme.palette.secondary.main
  },
  cardFooter: {
    padding: 16,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.light.dark,
    fontSize: '1.2rem',
    justifyContent: 'space-between'
  },
  noPaddingMobile: {
    overflow: 'hidden',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }
}));

export default withStyles(styles)(connect(mapState, mapDispatch)(News));
