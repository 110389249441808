import React, { useEffect } from 'react';
import { array, bool, func, object } from 'prop-types';
import { Box } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import RequestMenu from 'components/TempWorkersList/common/RequestMenu';
import { useHeader, useDebounce } from 'hooks';
import OrderList from './OrderList';

const styles = createStyles((theme) => ({
  header: {
    zIndex: 500,
    width: '100%',
    position: 'fixed',
    top: theme.headerHeight,
    backgroundColor: theme.palette.light.main
  }
}));

const MobileView = ({ orders, costCenters, getOrders, isLoading, search, setSearch }) => {
  const { setComponents, renderSearch, setTitle, search: headerSearch } = useHeader();
  const debouncedSearch = useDebounce(search, 400);

  useEffect(() => {
    setSearch((s) => ({ ...s, search: headerSearch }));
  }, [headerSearch]);

  useEffect(() => {
    setTitle('general:menu.orders');
    setComponents([renderSearch]);
  }, [setComponents, renderSearch, setTitle]);

  return (
    <Box position="relative" width="100%">
      <Box width="100%" position="absolute" top="0" left="0" pb={22}>
        <OrderList
          data={orders}
          loadMore={getOrders}
          search={search}
          isLoading={isLoading}
          debouncedSearch={debouncedSearch}
          onSearch={setSearch}
          costCenters={costCenters}
        />
        <RequestMenu
          isMobile
          onClose={(res) => {
            if (res) {
              setSearch((s) => ({ ...s, force: (s.force || 0) + 1 }));
            }
          }}
        />
      </Box>
    </Box>
  );
};

MobileView.propTypes = {
  orders: array.isRequired,
  isLoading: bool.isRequired,
  costCenters: array,
  getOrders: func.isRequired,
  search: object.isRequired,
  setSearch: func.isRequired
};

MobileView.defaultProps = {
  costCenters: []
};

export default withStyles(styles)(MobileView);
