import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Typography, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { faHistory, faIndustry, faUser } from '@fortawesome/free-solid-svg-icons';
import { array, func, object } from 'prop-types';

import { eventGA } from 'utils/analytics';

import IconSelect from 'components/IconSelect';
import IconAutocomplete from 'components/IconAutocomplete';
import { costCenterString } from 'utils/tempWorkers';
import { sinceOptions, workerTypes } from '../common/constant';

const useStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const MyWorkersFilterMobile = ({ search, onChange, costCenters }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [innerSearch, setInnerSearch] = useState(search);

  const handleEventGACenter = (centers) => {
    const lastCenter = centers[centers.length - 1];
    eventGA('candidate_CC_filter', costCenterString(lastCenter));
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box flex="1" overflow="auto">
        <Container maxWidth="md">
          <Box p={4} width={[1, 1, 2 / 3]} display="flex" flexDirection="column" alignItems="center" mx="auto">
            <Typography variant="h6" component="h2" textAlign="center">
              {t('general:filters')}
            </Typography>
            <Box width="100%" my={4}>
              <IconSelect
                icon={faUser}
                value={innerSearch.workerType}
                onChange={(e) => {
                  eventGA('candidate_type_filter', e.target.value);
                  setInnerSearch({ ...search, workerType: e.target.value });
                }}
                fullWidth>
                {workerTypes.map(({ key, text, value }) => (
                  <MenuItem key={key} value={value}>
                    {t(text)}
                  </MenuItem>
                ))}
              </IconSelect>
            </Box>
            <Box width="100%" mb={4}>
              <IconSelect
                icon={faHistory}
                value={innerSearch.since}
                onChange={(e) => {
                  eventGA('candidate_date_range_filter', e.target.value);
                  setInnerSearch({ ...search, since: e.target.value });
                }}
                fullWidth>
                {sinceOptions.map(({ key, text, value }) => (
                  <MenuItem key={key} value={value}>
                    {t(text)}
                  </MenuItem>
                ))}
              </IconSelect>
            </Box>
            <Box width="100%">
              <IconAutocomplete
                multiple
                icon={faIndustry}
                options={costCenters}
                getOptionLabel={costCenterString}
                value={innerSearch.cost_centers}
                noValueMessage={t('general:docs.allCostCenters')}
                onChange={(_, value) => {
                  handleEventGACenter(value);
                  setInnerSearch((f) => ({ ...f, cost_centers: value }));
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box display="flex" justifyContent="flex-end" className={classes.box} py={4}>
        <Container maxWidth="xl" className={classes.container}>
          <Button
            className={classes.button}
            width="100%"
            type="submit"
            name="confirm"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              onChange(innerSearch);
            }}>
            {t('general:filter')}
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

MyWorkersFilterMobile.propTypes = {
  costCenters: array.isRequired,
  onChange: func.isRequired,
  search: object.isRequired
};

export default MyWorkersFilterMobile;
