import React from 'react';
import { object } from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'dd/MM/yyyy', { locale: this.locale });
  }
}

const FrLocaleUtilsProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
    {children}
  </MuiPickersUtilsProvider>
);

FrLocaleUtilsProvider.propTypes = {
  children: object.isRequired
};

export default FrLocaleUtilsProvider;
