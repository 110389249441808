import React from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
import { bool, func, string } from 'prop-types';

const SwitchField = ({ label, onChange, checked }) => {
  return (
    <Box py={1} alignItems="baseline" display="flex" flexDirection="row" justifyContent="space-between">
      <Typography variant="body1" color="secondary">
        {label}
      </Typography>
      <Switch
        color="primary"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        name={label.replace(' ', '')}
      />
    </Box>
  );
};

SwitchField.propTypes = {
  label: string.isRequired,
  checked: bool.isRequired,
  onChange: func
};

SwitchField.defaultProps = { onChange: () => null };

export default SwitchField;
