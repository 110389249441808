/* eslint-disable complexity */
import React from 'react';
import { bool, object } from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Chip, Typography } from '@material-ui/core';
import { GreenTextTypography } from 'components';
import { path, isNil, includes } from 'ramda';

import { hoursFromMinutes } from 'utils/date';

const getFinalHours = (hours, contractHours) => {
  if (path(['allHours', 0], hours)) {
    return hours.allHours;
  }
  if (path(['allHours', 0], contractHours)) {
    return contractHours.allHours;
  }
  return [];
};

const HourCell = ({ hours, contractHours, classes, showDetails, isPlanning }) => {
  if (showDetails) {
    const finalHours = getFinalHours(hours, contractHours);
    return finalHours.map((hour, index) => {
      return !contractHours.allHours || (contractHours.allHours && includes(hour, contractHours.allHours)) ? (
        <Typography variant="caption" component="p" key={`${hours}-${index.toString()}`} className={classes.lightText}>
          {hour}
        </Typography>
      ) : (
        <GreenTextTypography variant="caption" component="p" key={`${hours}-${index.toString()}`}>
          {hour}
        </GreenTextTypography>
      );
    });
  }
  if ((!isNil(hours?.total_hours) && !Object.prototype.hasOwnProperty.call(hours, 'confirmed')) || hours?.confirmed) {
    return (
      <Typography
        variant="body2"
        component="span"
        className={hours?.difference !== 0 && !isPlanning ? classes.edited : ''}>
        {hoursFromMinutes(hours?.performedMinutes || hours?.total_hours)}
      </Typography>
    );
  }
  return (
    <Chip
      label={
        <Typography variant="body2">
          {!isNil(hours?.total_hours) && hoursFromMinutes(hours?.performedMinutes || hours?.total_hours)}
        </Typography>
      }
      color="primary"
      className={classes.squareChip}
      size="small"
    />
  );
};

HourCell.propTypes = {
  hours: object,
  classes: object.isRequired,
  showDetails: bool,
  contractHours: object,
  isPlanning: bool
};

HourCell.defaultProps = {
  hours: {},
  contractHours: {},
  showDetails: false,
  isPlanning: false
};

const styles = createStyles((theme) => ({
  squareChip: {
    borderRadius: theme.borderWidths[2],
    height: 'auto',
    '& span': {
      paddingLeft: `${theme.spacing()}px`,
      paddingRight: `${theme.spacing()}px`
    }
  },
  lightText: {
    fontSize: 10,
    color: theme.palette.secondary.main
  },
  edited: {
    backgroundColor: theme.palette.green,
    color: 'white',
    borderRadius: 3,
    padding: '0 3px'
  }
}));

export default withStyles(styles)(HourCell);
