import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { InsertInvitation } from '@material-ui/icons';
import { format } from 'date-fns';

import { localeDateFormat } from 'utils/date';

const isValid = (date) => !!date && date !== '0000-00-00';

const OrderPeriod = ({ start, end, noIcon, ml, ...childProps }) => {
  const { t } = useTranslation(['general']);
  if (!start && !end) return null;
  return (
    <Box display="flex" flexDirection="row" alignItems="center" ml={ml}>
      {!noIcon && (
        <Box mr={2} mt={1}>
          <InsertInvitation fontSize="small" color="secondary" />
        </Box>
      )}
      <Box>
        <Typography variant="body2" color="secondary" {...childProps}>
          {isValid(start) ? format(new Date(start), localeDateFormat) : t('general:undefined')} -{' '}
          {isValid(end) ? format(new Date(end), localeDateFormat) : t('general:undefined')}
        </Typography>
      </Box>
    </Box>
  );
};

OrderPeriod.propTypes = {
  start: string,
  end: string,
  noIcon: bool,
  ml: string
};

OrderPeriod.defaultProps = {
  start: null,
  end: null,
  noIcon: false,
  ml: '0'
};

export default OrderPeriod;
