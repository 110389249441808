/* eslint-disable max-lines */
import React, { Fragment, useMemo } from 'react';
import PropTypes, { array, object } from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { startOfWeek, isSameDay } from 'date-fns';
import clsx from 'clsx';
import { prop, uniqBy, clone, values } from 'ramda';
import { convertMinutesToTime, days, toIsoDateString, hoursFromMinutes, formatHour } from 'utils/date';
import { Euro } from 'utils/number';
import { GreenTextTypography } from 'components';
import { filterBonuses } from './common';
import scheduleFormStyle from '../ScheduleForm/scheduleFormStyle';

const PerformanceScheduleTablet = ({ classes, date, contractHours, savedHours, bonuses }) => {
  const storedPremiums = useMemo(() => {
    return clone(filterBonuses(bonuses, savedHours)).filter((pc) => pc.is_phantom === 1 && pc.premium_code !== 2060);
  }, [savedHours, bonuses]);
  const storedHours = useMemo(() => savedHours, [savedHours]);
  const storedBonuses = useMemo(() => {
    return clone(filterBonuses(bonuses, savedHours)).filter(
      (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
    );
  }, [savedHours, bonuses]);
  const { t, i18n } = useTranslation(['general', 'helpers']);
  const lastMonday = startOfWeek(date, { weekStartsOn: 1 });
  const weekDays = [1, 2, 3, 4, 5, 6, 0].map((day) => {
    const diffFromMonday = (day - 1 + 7) % 7; // Monday as the first day of the week and avoid negative numbers
    const _date = new Date(lastMonday);
    return new Date(_date.setDate(_date.getDate() + diffFromMonday));
  });

  const getValidatedSourceMinutes = (dayHours) => {
    const bonus = bonuses.find(
      (b) => b.premium_code === 1101 && isSameDay(new Date(b.date), dayHours.date) && b.source === 2
    );
    return bonus?.minutes || 0;
  };

  const getTotalMinutes = () => {
    const result = weekDays.reduce((acc, curr) => {
      const dayHours = storedHours[days[curr.getDay()]] || null;
      if (dayHours) {
        const populatedBonuses = filterBonuses(bonuses, savedHours);
        const filteredBonuses = clone(populatedBonuses).filter(
          (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
        );
        const validatedSources = !!filteredBonuses.find((b) => b.source === 2);
        if (validatedSources) {
          return acc + getValidatedSourceMinutes(dayHours);
        }
        const minutes = dayHours.performedMinutes || dayHours.total_hours;
        return acc + minutes;
      }
      return acc;
    }, 0);
    return result;
  };

  const inputCells = () => {
    return weekDays.map((day) => {
      const dayHours = storedHours[days[day.getDay()]] || null;
      const populatedBonuses = filterBonuses(bonuses, savedHours);
      const filteredBonuses = clone(populatedBonuses).filter(
        (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
      );
      const validatedSources = !!filteredBonuses.find((b) => b.source === 2);
      return (
        <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
          {dayHours && (
            <>
              {storedPremiums.find((sp) => sp.date === toIsoDateString(day) && sp.premium_code !== 1101) ? (
                <GreenTextTypography variant="subtitle1">
                  {hoursFromMinutes(validatedSources ? getValidatedSourceMinutes(dayHours) : dayHours.performedMinutes)}
                </GreenTextTypography>
              ) : (
                <Typography variant="subtitle1">
                  {hoursFromMinutes(
                    validatedSources
                      ? getValidatedSourceMinutes(dayHours)
                      : dayHours.performedMinutes || dayHours.total_hours
                  )}
                </Typography>
              )}
            </>
          )}
        </TableCell>
      );
    });
  };

  const scheduleLine = (storedTime, contractTime, key) => {
    const stored = storedTime || {};
    const contract = contractTime || {};
    const content = formatHour(stored[key]);
    return (
      <>
        {stored[key] !== contract[key] ? (
          <GreenTextTypography variant="caption">{content}</GreenTextTypography>
        ) : (
          <Typography variant="caption" color="secondary">
            {content}
          </Typography>
        )}
      </>
    );
  };

  const scheduleCells = () => {
    const populateCell = (storedTime, contractTime, startKey, endKey) =>
      storedTime[startKey] && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="subtitle1" color="secondary" align="right">
            {scheduleLine(storedTime, contractTime, startKey)}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            |
          </Typography>
          <Typography variant="subtitle1" color="secondary" align="right">
            {scheduleLine(storedTime, contractTime, endKey)}
          </Typography>
        </Box>
      );
    return (
      <>
        <TableRow className={classes.headRow}>{inputCells({}, true)}</TableRow>
        {values(savedHours).find((item) => prop('start_am', item)) && (
          <TableRow className={classes.headRow}>
            {weekDays.map((day) => {
              const savedDayHours = savedHours[days[day.getDay()]];
              const contractDayHours = contractHours[days[day.getDay()]];
              return (
                <TableCell align="right" key={day} className={clsx(classes.mobileDayCell)}>
                  {savedDayHours && populateCell(savedDayHours, contractDayHours, 'start_am', 'end_am')}
                </TableCell>
              );
            })}
          </TableRow>
        )}
        {values(savedHours).find((item) => prop('start_pm', item)) && (
          <TableRow className={classes.headRow}>
            {weekDays.map((day) => {
              const savedDayHours = savedHours[days[day.getDay()]];
              const contractDayHours = contractHours[days[day.getDay()]];
              return (
                <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
                  {savedDayHours && populateCell(savedDayHours, contractDayHours, 'start_pm', 'end_pm')}
                </TableCell>
              );
            })}
          </TableRow>
        )}
      </>
    );
  };

  const getBonusCodes = (premiumCode, day) =>
    storedBonuses.find((cp) => cp.premium_code === premiumCode && cp.date === toIsoDateString(day));

  const timeCells = (premiumCode) => {
    return weekDays.map((day) => {
      const bonus = getBonusCodes(premiumCode, day);
      return (
        <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
          {bonus && <Typography variant="caption">{convertMinutesToTime(bonus.minutes, 'h')}</Typography>}
        </TableCell>
      );
    });
  };

  const moneyCells = (premiumCode) => {
    return weekDays.map((day) => {
      const bonus = getBonusCodes(premiumCode, day);
      return (
        <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
          {bonus && <Typography variant="caption">{Euro.format(bonus.amount)}</Typography>}
        </TableCell>
      );
    });
  };

  const booleanCells = (premiumCode) => {
    return weekDays.map((day) => {
      const bonus = getBonusCodes(premiumCode, day);
      return (
        <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
          {bonus && <Typography variant="caption">{bonus.minutes}</Typography>}
        </TableCell>
      );
    });
  };

  const getIterFunction = (smileCode) => {
    let iterFunc;
    if (smileCode === 1 || smileCode === 3) {
      iterFunc = timeCells;
    } else if (smileCode === 2) {
      iterFunc = moneyCells;
    } else {
      iterFunc = booleanCells;
    }
    return iterFunc;
  };

  const bonusTables = uniqBy(prop('premium_code'), storedBonuses)
    .map((cp) => {
      return {
        label: cp[`description_${i18n.language}`],
        iterFunction: getIterFunction(cp.smile_code_type),
        premiumCode: cp.premium_code
      };
    })
    .map((sr) => (
      <Fragment key={sr.premiumCode}>
        <Box mt={4} pl={4} display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary">
            {`- ${sr.label}`}
          </Typography>
        </Box>
        <Box>
          <Table aria-label="schedule table" size="small">
            <TableHead>
              <TableRow className={classes.headRow}>
                {weekDays.map((day) => {
                  return (
                    <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
                      <Typography variant="caption" align="center">
                        {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.headRow}>{sr.iterFunction(sr.premiumCode)}</TableRow>
            </TableBody>
          </Table>
        </Box>
      </Fragment>
    ));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
          <Box pr={2}>
            <FontAwesomeIcon icon={faClock} className={clsx(classes.iconSize, classes.iconColor)} />
          </Box>
          <Typography variant="body2">{t('general:performed.hoursPerformed')}</Typography>
        </Box>
        {storedPremiums.find((item) => item.premium_code !== 1101) ? (
          <GreenTextTypography variant="body2">{hoursFromMinutes(getTotalMinutes())}</GreenTextTypography>
        ) : (
          <Typography variant="body2">{hoursFromMinutes(getTotalMinutes() || getTotalMinutes())}</Typography>
        )}
      </Box>
      <Table style={{ width: '100%' }} aria-label="schedule table" size="small">
        <TableHead>
          <TableRow className={classes.headRow}>
            {weekDays.map((day) => {
              return (
                <TableCell align="center" key={day} className={clsx(classes.mobileDayCell)}>
                  <Typography variant="subtitle1">
                    {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{scheduleCells()}</TableBody>
      </Table>
      {!!prop('length', bonuses) && (
        <>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mt={5}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box pr={2}>
                <FontAwesomeIcon icon={faAward} className={clsx(classes.iconSize, classes.iconColor)} />
              </Box>
              <Typography variant="body1">{t('general:performed.bonus')}</Typography>
            </Box>
          </Box>
          {bonusTables}
        </>
      )}
    </>
  );
};

PerformanceScheduleTablet.propTypes = {
  classes: object.isRequired,
  date: PropTypes.instanceOf(Date),
  savedHours: object.isRequired,
  contractHours: object.isRequired,
  bonuses: array
};

PerformanceScheduleTablet.defaultProps = {
  bonuses: [],
  date: new Date()
};

export default withStyles(scheduleFormStyle)(PerformanceScheduleTablet);
