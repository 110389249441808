import { withStyles } from '@material-ui/core/styles';
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary as MuiExpansionPanelSummary
} from '@material-ui/core';

export const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-of-type) div:first-of-type': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: '0 16px',
    minHeight: 60,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&$expanded': {
      minHeight: 60
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiExpansionPanelSummary);
