import React from 'react';
import { bool, func, string } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ConfirmDeletion = ({
  isOpen,
  onClose,
  showCloseButton,
  title,
  alignTitle,
  confirm,
  disabled,
  text,
  hasRequestedDeletion
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      disableBackdropClick={disabled}
      disableEscapeKeyDown={disabled}
      aria-labelledby="confirm-deletion-title"
      aria-describedby="confirm-deletion-description">
      {showCloseButton && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose} disabled={disabled}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <DialogTitle disableTypography id="confirm-deletion-title">
        <Typography align={alignTitle} variant="h6" component="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="justify" variant="body1">
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box mt="2">
          {hasRequestedDeletion && (
            <Button type="button" onClick={onClose} variant="contained" color="primary" disabled={disabled}>
              {t('general:close')}
            </Button>
          )}
          {!hasRequestedDeletion && (
            <Button type="button" onClick={confirm} variant="contained" color="primary" disabled={disabled}>
              {t('general:confirm')}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDeletion.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  showCloseButton: bool.isRequired,
  title: string.isRequired,
  alignTitle: string.isRequired,
  confirm: func.isRequired,
  disabled: bool.isRequired,
  text: string.isRequired,
  hasRequestedDeletion: bool.isRequired
};

export default ConfirmDeletion;
