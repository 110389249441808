import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  spacing: 4,
  fontWeights: [400, 500],
  typography: {
    fontFamily: 'Roboto',
    htmlFontSize: 10,
    button: {
      letterSpacing: '1.25px'
    },
    h6: {
      letterSpacing: '0.25px',
      color: 'rgba(0,0,0,.87)'
    },
    caption: {
      fontSize: '1rem'
    },
    subtitle1: {
      fontSize: '1.2rem'
    }
  },
  lineHeight: 1.5,
  transition: '.2s linear',
  radii: [0, 2],
  elementSizes: [24, 36],
  headerHeight: 56,
  menuWidth: 264,
  palette: {
    primary: {
      main: '#db2032',
      light: 'rgba(219,32,50,.5)',
      lightest: 'rgba(219,32,50,.2)'
    },
    error: {
      main: '#ed8c01'
    },
    secondary: {
      main: 'rgba(0,0,0,.6)',
      dark: 'rgba(0,0,0,.87)',
      light: 'rgba(38,38,38,0.6)',
      lightest: 'rgba(0,0,0,.15)'
    },
    light: {
      main: '#fff',
      dark: '#fafafa'
    },
    green: '#07c900'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 396,
      md: 650,
      lg: 1024,
      xl: 1200
    }
  },
  borderWidths: [0, 2, 3],
  borders: ['none', '1px solid rgba(0,0,0,.1)'],
  shadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  // Overrides
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: '16px',
        paddingRight: '16px'
      }
    },
    MuiIconButton: {
      root: {
        fontSize: 20
      },
      label: {
        position: 'relative'
      }
    },
    MuiBadge: {
      badge: {
        fontSize: 10
      }
    },
    MuiMenu: {
      paper: {
        width: 'auto',
        height: 'auto',
        marginTop: 12,
        padding: 8
      }
    },
    MuiButton: {
      root: {
        padding: '4px 16px'
      },
      outlinedSizeSmall: {
        fontSize: '2rem'
      },
      outlinedSecondary: {
        border: '1px solid rgba(0, 0, 0, 0.2)'
      },
      text: {
        padding: 'inehrit'
      }
    },
    MuiTableCell: {
      root: {
        paddingRight: 10,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
      }
    },
    MuiFilledInput: {
      adornedStart: {
        height: 32
      }
    },
    MuiTabs: {
      root: {
        minWidth: '33.33%'
      },
      flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1
      }
    },
    MuiToggleButton: {
      root: {
        textTransform: 'inherit',
        fontWeight: 400,
        fontSize: '1.4rem',
        '&.Mui-selected': {
          backgroundColor: 'rgba(219,32,50,.12)',
          color: '#db2032'
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingLeft: 28,
        paddingRight: 28
      }
    }
  }
});
