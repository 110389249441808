import React from 'react';
import { string } from 'prop-types';

export const Img = ({ src, alt, className, ...props }) => {
  return <img alt={alt} className={className} src={src || ''} {...props} />;
};

Img.propTypes = {
  src: string,
  alt: string,
  className: string
};
Img.defaultProps = {
  src: null,
  alt: null,
  className: null
};

export default Img;
