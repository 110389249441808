/* eslint max-lines: 0 */
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidDate } from 'ramda-adjunct';
import { Box, TableCell, TextField, Typography, InputAdornment, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { pathOr, isNil, prop } from 'ramda';
import PropTypes, { array, bool, func, object, string, number, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { isSameDay } from 'date-fns';

import { premiumIcons } from 'utils/premium';
import { Euro } from 'utils/number';
import { mondayWeek, convertMinutesToTime, days, formatHour, minutesToPickerValue, toIsoDateString } from 'utils/date';

import { GreenTextTypography } from 'components';
import FrLocaleUtilsProvider from 'components/FrLocaleUtilsProvider';
import GreenCheckbox from 'components/GreenCheckbox';
import HourCell from 'components/TempWorkerRow/HourCell';
import CustomTimePicker from 'components/CustomTimePicker';
import { getChangedBonuses } from './common';

const makeClassName = (classes, day, add = []) =>
  clsx(classes.dayCell, classes.borderLessCell, ...add, isSameDay(day, new Date()) ? classes.todayCell : '');

export const ScheduleCell = ({ day, changedHours, classes, contractHours }) => {
  if (!day) return <TableCell align="right" key={day} className={makeClassName(classes, day)} />;

  const dayName = days[day.getDay()];
  const dayHours = contractHours[days[day.getDay()]] || null;
  const changedDayHours = changedHours[dayName] || null;

  const hasDifferentTime = () => {
    const differentTime = changedDayHours;
    return (
      dayHours.start_am !== pathOr(null, ['start_am'], differentTime) ||
      dayHours.end_am !== pathOr(null, ['end_am'], differentTime) ||
      dayHours.start_pm !== pathOr(null, ['start_pm'], differentTime) ||
      dayHours.end_pm !== pathOr(null, ['end_pm'], differentTime)
    );
  };

  const scheduleLine = (time, storedTime, startKey, endKey) => {
    if (!storedTime || (!storedTime[startKey] && !storedTime[endKey])) return null;
    return storedTime &&
      (time[startKey] !== pathOr(null, [startKey], storedTime) ||
        time[endKey] !== pathOr(null, [endKey], storedTime)) ? (
      <GreenTextTypography variant="caption" component="p">{`${formatHour(storedTime[startKey])} - ${formatHour(
        storedTime[endKey]
      )}`}</GreenTextTypography>
    ) : (
      <Typography variant="caption" component="p" color="secondary">
        {`${formatHour(time[startKey])} - ${formatHour(time[endKey])}`}
      </Typography>
    );
  };

  return (
    <TableCell align="right" key={day} className={makeClassName(classes, day)}>
      {dayHours && (hasDifferentTime() || !pathOr(null, ['allHours', 'length'], dayHours)) ? (
        <Box display="flex" flexDirection="column">
          {scheduleLine(dayHours, changedDayHours, 'start_am', 'end_am')}
          {scheduleLine(dayHours, changedDayHours, 'start_pm', 'end_pm')}
        </Box>
      ) : (
        <HourCell hours={dayHours} showDetails />
      )}
    </TableCell>
  );
};

ScheduleCell.propTypes = {
  day: PropTypes.instanceOf(Date),
  contractHours: object,
  changedHours: object,
  classes: object.isRequired
};

ScheduleCell.defaultProps = {
  day: null,
  contractHours: {},
  changedHours: {}
};

const editedStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green,
    color: 'white',
    borderRadius: 3,
    padding: '0 3px',
    marginRight: -3
  }
}));

export const PerformedCell = forwardRef(
  ({ value, stringValue, onChange, onValidDateChange, day, editMode, storedPremiums, classes, ...props }, ref) => {
    const formatted = stringValue || '00h00';
    const typoClass = editedStyles();

    return (
      <TableCell align="right" className={makeClassName(classes, day)} {...props}>
        {!isNil(value) && (
          <>
            {!editMode ? (
              <Typography
                variant="body2"
                component="span"
                {...(day &&
                  storedPremiums.find((sp) => sp.date === toIsoDateString(day) && sp.premium_code !== 1101) && {
                    className: typoClass.root
                  })}>
                {formatted}
              </Typography>
            ) : (
              <FrLocaleUtilsProvider>
                <CustomTimePicker
                  inputRef={ref}
                  inputProps={{ tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1, inputRef: ref, ref }}
                  id={`performedHours-${day}`}
                  ampm={false}
                  format="HH'h'mm"
                  mask="__h__"
                  autoOk
                  value={value}
                  helperText={null}
                  variant="inline"
                  onChange={(event) => {
                    onChange(event);
                    if (isValidDate(event) && event !== value) onValidDateChange(event);
                  }}
                  onBlur={(_, val) => {
                    if (!isValidDate(val)) {
                      onChange('');
                      onValidDateChange('');
                    }
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    disableUnderline: true,
                    classes: { root: classes.bonusInputRoot, input: classes.bonusInput }
                  }}
                  keyboardIcon={false}
                  InputAdornmentProps={{ classes: { root: classes.keyboardIcon } }}
                />
              </FrLocaleUtilsProvider>
            )}
          </>
        )}
      </TableCell>
    );
  }
);

PerformedCell.propTypes = {
  value: oneOfType([PropTypes.instanceOf(Date), string]),
  stringValue: string,
  onChange: func,
  onValidDateChange: func,
  day: PropTypes.instanceOf(Date),
  editMode: bool,
  classes: object.isRequired,
  storedPremiums: array.isRequired
};

PerformedCell.defaultProps = {
  value: null,
  stringValue: null,
  onChange: () => null,
  onValidDateChange: () => null,
  day: null,
  editMode: false
};

export const TimeCell = ({
  premiumCode,
  day,
  editMode,
  isEditable,
  bonus,
  storedBonuses,
  onBonusesChange,
  classes,
  onClick
}) => {
  const [value, setValue] = useState(minutesToPickerValue(bonus.minutes));

  return (
    <TableCell align="right" className={makeClassName(classes, day)} onClick={onClick}>
      {!isNil(prop('date', bonus)) && (
        <>
          {!editMode || !isEditable ? (
            <Typography variant="body2" color="secondary">
              {convertMinutesToTime(bonus.minutes, 'h')}
            </Typography>
          ) : (
            <FrLocaleUtilsProvider>
              <CustomTimePicker
                id={`premiumDate-${premiumCode}-${toIsoDateString(day)}`}
                ampm={false}
                inputProps={{ tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1 }}
                format="HH'h'mm"
                mask="__h__"
                autoOk
                value={value}
                helperText={null}
                variant="inline"
                onChange={(event) => {
                  // lorsque les premiums codes sont récupérés, les dupliquer pour tous les jours qui ont des heures et set amount et minutes à 0
                  setValue(event);
                  onBonusesChange(
                    getChangedBonuses(
                      { premiumCode, smileCode: bonus.smile_code_type, pdate: day, storedBonuses },
                      event
                    )
                  );
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.bonusInputRoot, input: classes.bonusInput }
                }}
                keyboardIcon={false}
                InputAdornmentProps={{ classes: { root: classes.keyboardIcon } }}
              />
            </FrLocaleUtilsProvider>
          )}
        </>
      )}
    </TableCell>
  );
};

TimeCell.propTypes = {
  premiumCode: number,
  day: PropTypes.instanceOf(Date),
  editMode: bool,
  isEditable: bool,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func,
  classes: object.isRequired,
  onClick: func
};

TimeCell.defaultProps = {
  premiumCode: null,
  day: null,
  editMode: false,
  isEditable: false,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {},
  onClick: () => null
};

const FAIconWithRef = React.forwardRef((props, ref) => {
  return <FontAwesomeIcon {...props} forwardedRef={ref} />;
});

// Mode -> Difference Or contract hours
export const ReadOnlyTimeCell = ({ day, mode, contractHours, storedHours, classes }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  if (!day) return <TableCell align="right" key={day} className={makeClassName(classes, day)} />;

  const dayName = days[day.getDay()];
  const dayHours = mode === 'difference' ? storedHours[dayName] : contractHours[dayName];

  const iconText = () => (
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      {pathOr(0, ['reason', 'icon', 'length'], dayHours) > 1 && (
        <Box pr={1}>
          <Tooltip title={dayHours.reason[`description_${i18n.language}`]}>
            <FAIconWithRef color={theme.palette.green} icon={premiumIcons[dayHours.reason.icon]} fixedWidth />
          </Tooltip>
        </Box>
      )}
      <Typography variant="body2" color="secondary">
        {dayHours[mode] < 0 ? '-' : '+'}
        {convertMinutesToTime(Math.abs(dayHours[mode]), 'h')}
      </Typography>
    </Box>
  );

  return (
    <TableCell align="right" key={day} className={makeClassName(classes, day)}>
      {!!pathOr(false, [mode], dayHours) &&
        (mode === 'difference' ? (
          iconText()
        ) : (
          <Typography variant="body2" color="secondary">
            {convertMinutesToTime(dayHours[mode], 'h')}
          </Typography>
        ))}
    </TableCell>
  );
};

ReadOnlyTimeCell.propTypes = {
  day: PropTypes.instanceOf(Date),
  contractHours: object,
  mode: string,
  storedHours: object,
  classes: object.isRequired
};

ReadOnlyTimeCell.defaultProps = {
  day: null,
  contractHours: {},
  mode: 'difference',
  storedHours: {}
};

export const AmountCell = ({
  premiumCode,
  day,
  bonus,
  editMode,
  isEditable,
  storedBonuses,
  onBonusesChange,
  classes,
  onClick
}) => {
  const [value, setValue] = useState((bonus.amount || 0).toFixed(2).toString().replace('.', ','));
  const [formatError, setFormatError] = useState(false);
  return (
    <TableCell align="right" key={day} className={makeClassName(classes, day)} onClick={onClick}>
      {!isNil(prop('date', bonus)) && (
        <>
          {!editMode || !isEditable ? (
            <Typography variant="body2" color="secondary">
              {Euro.format(bonus.amount)}
            </Typography>
          ) : (
            // eslint-disable-line react/jsx-no-duplicate-props
            <TextField
              align="right"
              value={value}
              error={formatError}
              onChange={(event) => {
                const val = event.target.value;
                setFormatError(true);
                if (/^[0-9]+(,|.[0-9]{1,2})?$/.test(val)) {
                  setFormatError(false);
                  onBonusesChange(
                    getChangedBonuses(
                      { premiumCode, smileCode: bonus.smile_code_type, pdate: day, storedBonuses },
                      event
                    )
                  );
                }
                if (!val || /^[0-9]+(,|.[0-9]{0,2})?$/.test(val)) {
                  setValue(val.replace('.', ','));
                }
              }}
              onBlur={() => {
                if (!value) {
                  setValue('0,00');
                  setFormatError(false);
                }
              }}
              inputProps={{
                tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                classes: {
                  root: classes.bonusInputRoot,
                  input: classes.bonusInput
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Box mt="2px">
                      <Typography variant="body2" color="secondary">
                        €
                      </Typography>
                    </Box>
                  </InputAdornment>
                )
              }}
            />
          )}
        </>
      )}
    </TableCell>
  );
};

AmountCell.propTypes = {
  premiumCode: number,
  day: PropTypes.instanceOf(Date),
  editMode: bool,
  isEditable: bool,
  bonus: object,
  storedBonuses: array,
  onBonusesChange: func,
  classes: object.isRequired,
  onClick: func
};

AmountCell.defaultProps = {
  premiumCode: null,
  day: null,
  editMode: false,
  isEditable: false,
  bonus: {},
  storedBonuses: [],
  onBonusesChange: () => {},
  onClick: () => null
};

export const CheckBoxCell = ({
  day,
  bonus,
  editMode,
  isEditable,
  classes,
  premiumCode,
  storedBonuses,
  onBonusesChange,
  onClick
}) => {
  return (
    <TableCell
      align="right"
      key={day}
      className={makeClassName(classes, day, editMode && isEditable ? [classes.checkBoxCell] : [])}
      onClick={onClick}>
      {!isNil(prop('date', bonus)) && (
        <>
          {!editMode || !isEditable ? (
            <Typography variant="body2" color="secondary">
              {prop('minutes', bonus)}
            </Typography>
          ) : (
            <GreenCheckbox
              inputProps={{ tabIndex: day ? mondayWeek[day.getDay()] + 1 : -1 }}
              id={`cb-${day}`}
              name={`checked${day}`}
              checked={!!prop('minutes', bonus)}
              onChange={(event) => {
                onBonusesChange(
                  getChangedBonuses({ premiumCode, smileCode: bonus.smile_code_type, pdate: day, storedBonuses }, event)
                );
              }}
            />
          )}
        </>
      )}
    </TableCell>
  );
};

CheckBoxCell.propTypes = {
  premiumCode: number,
  day: PropTypes.instanceOf(Date),
  bonus: object,
  editMode: bool,
  isEditable: bool,
  classes: object.isRequired,
  storedBonuses: array,
  onBonusesChange: func,
  onClick: func
};

CheckBoxCell.defaultProps = {
  premiumCode: null,
  day: null,
  bonus: {},
  editMode: false,
  isEditable: false,
  storedBonuses: [],
  onBonusesChange: () => {},
  onClick: () => null
};
