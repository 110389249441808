import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import WorkerCard from 'components/WorkerCard';

const ContractExistsCard = (props) => {
  const { t } = useTranslation(['general']);
  return (
    <Box display="flex" flexDirection="column">
      <Box py={2}>
        <Typography color="textSecondary" variant="body2">
          {t('general:alreadyOccupiedFunction')}
        </Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <WorkerCard {...props} />
      </Box>
      <Divider />
      <Box py={2}>
        <Typography color="textSecondary" variant="body2">
          {t('general:alreadyOccupiedInfo')}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContractExistsCard;
