/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { func, bool, array, object } from 'prop-types';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Box, IconButton, Button, Container, Typography } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/Save';
import { connect } from 'react-redux';
import { pathOr, compose } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Banner, ConfirmDialog, ConfirmScreen } from 'components';
import { zonedTimeToUtc } from 'date-fns-tz';
import clsx from 'clsx';
import { useModal } from 'hooks';
import { isWorkDay } from 'utils/date';
import { withStyles } from '@material-ui/core/styles';
import { eventGA } from 'utils/analytics';
import NewPayrollForm from './NewPayrollForm';
import NewPayrollUpload from './NewPayrollUpload';
import styles from './NewPayrollStyle';

const NewPayroll = ({
  addPayroll,
  costCenters,
  jobFunctions,
  getCostCenters,
  getJobFunctions,
  isSubmitting,
  shouldOpenContract,
  doesPayrollExist,
  classes,
  isLoading
}) => {
  const { onClose, setLeftBtn } = useModal();
  const tzDate = zonedTimeToUtc(new Date(), { timeZone: 'Europe/Berlin' });
  const { t } = useTranslation(['general']);
  const [data, setData] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [isNext, setIsNext] = useState(false);
  const [isComfirmable, setIsComfirmable] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const handleCreate = async () => {
    setIsComfirmable(false);
    const payroll = await addPayroll({
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: data.birthDate || '',
        socialSecurity: data.socialSecurity,
        administrativeLanguage: parseInt(data.administrativeLanguage, 10),
        jobFunctionIds: data.jobFunction && data.jobFunction.map((jf) => jf.id || jf.value.id),
        costCenterId: data.costCenter ? data.costCenter.id : null,
        phoneNumber: data.phone,
        phoneIdentifier: data.prefix.phone,
        streetName: data.streetName,
        streetBox: data.box,
        zipCode: data.postalCode.value.postalCode,
        city: data.postalCode.value.placeName,
        comment: data.comment,
        email: data.email,
        streetNumber: data.streetNumber
      },
      uploadData: uploadData.files
        ? {
            workPermit: uploadData.files.workPermitFile?.file,
            identityDocument: uploadData.files.identityFile?.file,
            drivingLicense: uploadData.files.driversLicense?.file
          }
        : null
    });
    if (payroll) {
      onClose();
      shouldOpenContract(isRedirect);
    }
  };

  useEffect(() => {
    getCostCenters();
    getJobFunctions();
  }, [getCostCenters, getJobFunctions]);

  useEffect(() => {
    setLeftBtn(() => () => (
      <>
        {isNext && (
          <IconButton disabled={isSubmitting} onClick={() => setIsNext(false)}>
            <NavigateBeforeIcon />
          </IconButton>
        )}
      </>
    ));
  }, [setLeftBtn, isNext, isSubmitting]);

  const isUploadButtonDisabled = uploadData ? !uploadData.isConfirmed : true;

  const dialogActions = {
    cancel: () => {
      setIsComfirmable(false);
      setIsRedirect(false);
    },
    confirm: () => {
      handleCreate();
    }
  };

  const getTitle = () => (
    <Box mb={1}>
      <Box mb={10}>
        <Typography align="center" variant="h6" component="h2">
          {t('general:payroll.newPayroll')}
        </Typography>
      </Box>
      {!isWorkDay(tzDate) ? (
        <Box mb={6} margin="0 auto">
          <Banner type="warning" message={t('general:extend.openingHoursWarning')} />
        </Box>
      ) : null}
      {!isNext && (
        <>
          <Box mb={5}>
            <Typography component="p">{t('general:payroll.description')}</Typography>
          </Box>
          <Box mb={5}>
            <Typography component="p">{t('general:payroll.subDescription')}</Typography>
          </Box>
        </>
      )}
    </Box>
  );

  const lastButtons = () => (
    <>
      <Box>
        <Button
          className={clsx(classes.button, classes.buttonMobile)}
          color="primary"
          onClick={() => {
            eventGA('payroll_form_save_payroll');
            setIsComfirmable(true);
          }}
          disabled={isUploadButtonDisabled || isSubmitting}
          startIcon={<SaveOutlinedIcon />}>
          {t('general:save')}
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            eventGA('payroll_form_save_payroll_and_order');
            setIsComfirmable(true);
            setIsRedirect(true);
          }}
          disabled={isUploadButtonDisabled || isSubmitting}
          name="confirm"
          type="submit"
          variant="contained"
          color="primary"
          size="medium">
          {t('general:payroll.saveUnderContract')}
        </Button>
      </Box>
    </>
  );

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
      <ConfirmDialog
        actions={dialogActions}
        isOpen={isComfirmable}
        alignTitle="left"
        isOrdering={isLoading}
        title={t('general:payroll.newPayroll')}>
        <ConfirmScreen
          name
          plain
          firstName={pathOr('', ['firstName'], data)}
          lastName={pathOr('', ['lastName'], data)}
          description={t('general:contract.confirmscreen')}
        />
      </ConfirmDialog>
      <Box flex="1" overflow="auto">
        <Container maxWidth="md">
          <Box margin="auto">
            <Box>{getTitle()}</Box>
            {isNext ? (
              <NewPayrollUpload
                firstName={data.firstName}
                lastName={data.lastName}
                onSend={setUploadData}
                isSubmitting={isSubmitting}
              />
            ) : (
              <NewPayrollForm
                defaultData={data || {}}
                jobFunctions={jobFunctions}
                costCenters={costCenters}
                onSend={async (d) => {
                  const payrollExists = await doesPayrollExist(d);
                  if (!payrollExists) {
                    setData(d);
                    setIsNext(true);
                  }
                }}
              />
            )}
          </Box>
        </Container>
      </Box>
      <Box display="flex" justifyContent="flex-end" className={classes.footerStyle} py={4}>
        <Container maxWidth="xl" className={classes.container}>
          {!isNext ? (
            <Button
              name="confirm"
              form="newpayroll"
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}>
              {t('general:next')}
            </Button>
          ) : (
            lastButtons()
          )}
        </Container>
      </Box>
    </Box>
  );
};

NewPayroll.propTypes = {
  isSubmitting: bool.isRequired,
  shouldOpenContract: func.isRequired,
  addPayroll: func.isRequired,
  jobFunctions: array.isRequired,
  getCostCenters: func.isRequired,
  getJobFunctions: func.isRequired,
  costCenters: array.isRequired,
  classes: object.isRequired,
  doesPayrollExist: func.isRequired,
  isLoading: bool.isRequired
};

const mapState = ({ contracts, loading, tempWorkers }) => ({
  isSubmitting: pathOr(false, ['effects', 'contracts', 'postPayroll'], loading),
  costCenters: tempWorkers.costCenters,
  jobFunctions: tempWorkers.jobFunctions,
  savedCandidate: contracts.savedCandidate,
  isLoading:
    pathOr(false, ['effects', 'contracts', 'postPayroll'], loading) ||
    pathOr(false, ['effects', 'tempWorkers', 'getCostCenters'], loading) ||
    pathOr(false, ['effects', 'tempWorkers', 'getJobFunctions'], loading) ||
    pathOr(false, ['effects', 'contracts', 'doesPayrollExist'], loading)
});

const mapDispatch = ({ contracts, tempWorkers }) => ({
  addPayroll: contracts.postPayroll,
  getCostCenters: tempWorkers.getCostCenters,
  getJobFunctions: tempWorkers.getJobFunctions,
  doesPayrollExist: contracts.doesPayrollExist
});

export default compose(withStyles(styles), connect(mapState, mapDispatch))(NewPayroll);
