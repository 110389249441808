/* istanbul ignore file */
import { isValidDate } from 'ramda-adjunct';
import { isNil } from 'ramda';

const getOptional = (getValues, startend, ampm) => {
  const type = ['Am', 'Pm'].find((item) => item !== ampm);
  return (getValues(`start${type}`) && getValues(`end${type}`) && !getValues(`${startend}${ampm}`)) || 'optionRequired';
};

export default (getValues, difference) => {
  return {
    startAm: {
      validate: {
        isOptional: (value) => (!value ? getOptional(getValues, 'end', 'Am', 'startAm') : true),
        isValid: (value) => (!value ? true : isValidDate(value) || 'hourInvalid')
      }
    },
    endAm: {
      validate: {
        isOptional: (value) => (!value ? getOptional(getValues, 'start', 'Am', 'endAm') : true),
        isValid: (value) => (!value ? true : isValidDate(value) || 'hourInvalid')
      }
    },
    startPm: {
      validate: {
        isOptional: (value) => (!value ? getOptional(getValues, 'end', 'Pm', 'startPm') : true),
        isValid: (value) => (!value ? true : isValidDate(value) || 'hourInvalid')
      }
    },
    endPm: {
      validate: {
        isOptional: (value) => (!value ? getOptional(getValues, 'start', 'Pm', 'endPm') : true),
        isValid: (value) => (!value ? true : isValidDate(value) || 'hourInvalid')
      }
    },
    reason: { required: !isNil(difference) && difference < 0 ? 'optionRequired' : false },
    isApproved: { required: 'optionRequired' }
  };
};
