/* eslint max-lines: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { object, func, array, number } from 'prop-types';
import {
  Box,
  Button,
  ExpansionPanelDetails,
  Container,
  FormControl,
  Tabs,
  Tab,
  Typography,
  useTheme
} from '@material-ui/core';
import { AccessTime, Add, ExpandMore } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faAward, faStar } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { isSameDay, format } from 'date-fns';
import { prop, clone } from 'ramda';
import { Euro } from 'utils/number';

import { Modal, StyledRating, TabPanel, ConfirmDialog, GreenTextTypography } from 'components';
import { ExpansionPanel, ExpansionPanelSummary } from 'components/Expansion';
import {
  convertMinutesToTime,
  days,
  formatHour,
  toIsoDateString,
  areSameHours,
  getDayPickerValue,
  valueToMinutes,
  getWeekDays
} from 'utils/date';

import { isDate } from 'ramda-adjunct';
import { renderOnCondition } from 'utils/component';
import { eventGA } from 'utils/analytics';
import scheduleFormStyle from '../ScheduleForm/scheduleFormStyle';
import {
  BonusSelector,
  EditDaySchedule,
  filterBonuses,
  getInputsValues,
  getNewHoursAndPremiums,
  getHoursWithDate,
  getNewBonuses
} from './common';
import { TimeComponent, AmountComponent, CheckBoxComponent, PerformedComponent } from './PerformanceScheduleComponent';

const PerformanceScheduleTabletEdit = ({
  contractHours,
  classes,
  bonuses,
  date,
  onHoursChange,
  onBonusesChange,
  changedHours,
  rating,
  onRatingChange,
  appendableCodes
}) => {
  const theme = useTheme();
  const [storedPremiums, setStoredPremiums] = useState([]);
  const [storedBonuses, setStoredBonuses] = useState([]);
  const [storedHours, setStoredHours] = useState({});
  const [inputsValues, setInputsValues] = useState([]);
  const [dayData, setDayData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isBonusOpen, setIsBonusOpen] = useState(false);
  const [ratingHoverValue, setHover] = useState(rating || -1);
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const [tempBonuses, setTempBonuses] = useState([]);
  const [nextDays, setNextDays] = useState([]);
  const shouldMoveToNextDay = useRef(false);
  const [showNextDayButton, setShowNextDayButton] = useState(false);
  const formRef = useRef();

  const { t, i18n } = useTranslation(['general', 'helpers']);
  const weekDays = getWeekDays(date);

  const handleExpandChange = (key) => (_, newExpanded) => {
    setExpanded(newExpanded ? key : false);
  };

  const openModal = (dayHours) => {
    const dayIndex = dayHours.date.getDay();
    const daysLeft = weekDays.slice(dayIndex);
    const hoursWithDate = getHoursWithDate(daysLeft, contractHours);

    setNextDays(hoursWithDate);
    setShowNextDayButton(!!prop('length', hoursWithDate));
    setDayData(dayHours);
    setIsOpen(true);
  };

  const handleAddBonusClick = () => {
    setIsOpen(false);
    setIsBonusOpen(true);
  };

  const submitForm = async (applyToNextDay) => {
    shouldMoveToNextDay.current = applyToNextDay;
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
  };

  useEffect(() => {
    const populatedBonuses = filterBonuses(bonuses, changedHours);
    const filteredPremiums = clone(populatedBonuses).filter((pc) => pc.is_phantom === 1 && pc.premium_code !== 2060);
    const filteredBonuses = clone(populatedBonuses).filter(
      (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
    );
    setStoredPremiums(filteredPremiums);
    setStoredBonuses(filteredBonuses);
    setStoredHours(changedHours);
    setInputsValues(getInputsValues(changedHours, bonuses, filteredBonuses));
  }, [bonuses, changedHours]);

  const scheduleLine = (storedTime, contractTime, startKey, endKey) => {
    const stored = storedTime || {};
    const contract = contractTime || {};
    const content = `${formatHour(stored[startKey])} - ${formatHour(stored[endKey])}`;
    return (
      <>
        {stored[startKey] !== contract[startKey] || stored[endKey] !== contract[endKey] ? (
          <GreenTextTypography component="span">{content}</GreenTextTypography>
        ) : (
          <Typography component="span" color="secondary">
            {content}
          </Typography>
        )}
      </>
    );
  };

  const populateCell = (storedTime, contractTime) => (
    <Box display="flex" flexDirection="column">
      <Box className={classes.scheduleItemInput}>
        <Typography component="span" color="secondary">
          {t('general:performed.contractHours')}
        </Typography>
        <Typography component="span" color="secondary">
          {convertMinutesToTime(contractTime.total_hours)}
        </Typography>
      </Box>
      {!!storedTime.difference && (
        <Box className={classes.scheduleItemInput}>
          <Typography component="span" color="secondary">
            {t('general:difference')}
          </Typography>
          <Typography component="span" color="secondary">
            {storedTime.difference < 0 ? '-' : '+'}
            {convertMinutesToTime(Math.abs(storedTime.difference), 'h')}
          </Typography>
        </Box>
      )}
      {storedTime.reason && (
        <Box className={classes.scheduleItemInput}>
          <Typography component="span" color="secondary">
            {t('general:extend.reason')}
          </Typography>
          <Typography component="span" color="secondary">
            {storedTime.reason[`description_${i18n.language}`]}
          </Typography>
        </Box>
      )}
      <Box className={classes.scheduleItemInput}>
        <Typography component="span" color="secondary">
          {t('general:performed.schedule')}
        </Typography>
        {scheduleLine(storedTime, contractTime, 'start_am', 'end_am')}
      </Box>
      <Box className={classes.scheduleItemInput} justifyContent="flex-end">
        <Box />
        {scheduleLine(storedTime, contractTime, 'start_pm', 'end_pm')}
      </Box>
    </Box>
  );

  const getSchedule = () => (
    <Box>
      {weekDays.map((day) => {
        const dayName = prop(day.getDay(), days);
        const storedDayHours = prop(dayName, storedHours);
        const contractDayHours = prop(dayName, contractHours);
        return (
          <ExpansionPanel
            aria-label="schedule accordion"
            key={day.getDay()}
            expanded={expanded === day.getDay()}
            onChange={storedDayHours && handleExpandChange(day.getDay())}
            square>
            <ExpansionPanelSummary
              expandIcon={storedDayHours && <ExpandMore />}
              classes={{ expandIcon: classes.expandIcon }}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header">
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Typography component="span" variant="body1">
                  {t(`helpers:dates.days.${day.getDay()}`)}
                </Typography>
                {storedDayHours && (
                  <Box display="flex" alignItems="center">
                    {!!storedDayHours.difference && <FontAwesomeIcon icon={faEdit} color={theme.palette.green} />}
                    <Box ml={2}>
                      <FormControl
                        onFocus={(event) => event.stopPropagation()}
                        onClick={(event) => event.stopPropagation()}>
                        <PerformedComponent
                          value={inputsValues[day.getDay()]?.pickerValue}
                          key={day}
                          day={day}
                          classes={classes}
                          onChange={(e) => {
                            setInputsValues((iv) =>
                              Object.assign([], iv, {
                                [day.getDay()]: {
                                  pickerValue: e || new Date('invalid'),
                                  stringValue: e ? convertMinutesToTime(e.getHours() * 60 + e.getMinutes(), 'h') : ''
                                }
                              })
                            );
                          }}
                          onValidDateChange={(e) => {
                            if (!isDate(e)) {
                              return onHoursChange(clone(changedHours));
                            }
                            if (!areSameHours(e, getDayPickerValue(contractHours, day))) {
                              return openModal({
                                ...storedDayHours,
                                total_hours: contractDayHours.total_hours,
                                performedMinutes: valueToMinutes(e),
                                difference: valueToMinutes(e) - parseInt(contractDayHours.total_hours, 10),
                                dayName,
                                date: day
                              });
                            }
                            onHoursChange({
                              ...storedHours,
                              [dayName]: {
                                ...contractDayHours,
                                date: day,
                                performedMinutes: valueToMinutes(e),
                                difference: 0
                              }
                            });
                            return onBonusesChange([
                              ...storedBonuses,
                              ...storedPremiums.filter(
                                (pm) => toIsoDateString(new Date(pm.date)) !== toIsoDateString(storedDayHours.date)
                              ),
                              {
                                ...storedPremiums.find(
                                  (pm) =>
                                    toIsoDateString(new Date(pm.date)) === toIsoDateString(storedDayHours.date) &&
                                    pm.premium_code === 1101
                                ),
                                minutes: contractDayHours.total_hours
                              }
                            ]);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
              <Box className={classes.scheduleBox}>
                {storedDayHours && populateCell(storedDayHours, contractDayHours)}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Box>
  );

  const getPremiumComponent = (bonus) => {
    const { is_active, premium_code, smile_code_type, minutes, amount, date: day } = bonus;
    const premiumLabel = bonus[`description_${i18n.language}`];
    const inactiveOrComp = (val, Comp) =>
      is_active ? (
        <Box key={premium_code} className={classes.scheduleItemInput}>
          {Comp}
        </Box>
      ) : (
        <>
          <Box key={premium_code} className={classes.scheduleItemInput}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Typography component="span" color="secondary">
                {premiumLabel}
              </Typography>
              <Typography component="span" color="secondary">
                {val}
              </Typography>
            </Box>
          </Box>
        </>
      );
    switch (smile_code_type) {
      case 1:
      case 3:
        return inactiveOrComp(
          convertMinutesToTime(minutes || 0, 'h'),
          <TimeComponent
            label={premiumLabel}
            premiumCode={premium_code}
            date={day}
            bonus={bonus}
            storedBonuses={storedBonuses}
            onBonusesChange={onBonusesChange}
            keyboardIcon={<AccessTime />}
          />
        );
      case 2:
        return inactiveOrComp(
          Euro.format(amount || 0),
          <AmountComponent
            label={premiumLabel}
            premiumCode={premium_code}
            date={day}
            bonus={bonus}
            storedBonuses={storedBonuses}
            onBonusesChange={onBonusesChange}
          />
        );
      default:
        return inactiveOrComp(
          minutes || 0,
          <CheckBoxComponent
            label={premiumLabel}
            premiumCode={premium_code}
            date={day}
            bonus={bonus}
            storedBonuses={storedBonuses}
            onBonusesChange={onBonusesChange}
          />
        );
    }
  };

  const populateBonus = (day) =>
    storedBonuses.map((premium) => isSameDay(day, new Date(premium.date)) && getPremiumComponent(premium));

  const getBonus = () => (
    <Box>
      {weekDays.map((day) => {
        const dayName = days[day.getDay()];
        const dayHours = contractHours[dayName];
        const dayBonus = storedBonuses.filter((b) => isSameDay(day, new Date(b.date)));
        return (
          <ExpansionPanel
            aria-label="schedule accordion"
            key={day.getDay()}
            expanded={expanded === day.getDay()}
            onChange={dayHours && handleExpandChange(day.getDay())}
            square>
            <ExpansionPanelSummary
              expandIcon={dayHours && <ExpandMore />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography component="span" variant="body1">
                  {t(`helpers:dates.days.${day.getDay()}`)}
                </Typography>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
              <Box display="flex" flexDirection="column" width="100%">
                <Box>{populateBonus(day)}</Box>
                <Box>
                  {dayHours && (
                    <Button
                      onClick={() => handleAddBonusClick(day, dayBonus)}
                      startIcon={<Add />}
                      variant="outlined"
                      color="primary"
                      fullWidth>
                      {t('general:performed.addBonus')}
                    </Button>
                  )}
                </Box>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Box>
  );

  const getEvaluation = () => (
    <Box display="flex" flexDirection="column" px={[4, 3]} pt={3}>
      <Typography component="span" variant="body1" color="secondary">
        {t('general:performance.ratingHelp')}
      </Typography>
      <Box pt={2} display="flex" flexDirection="column" justifyContent="center">
        <Box m="0 auto">
          <StyledRating
            name="workerRating"
            size="large"
            value={rating}
            onChange={(_event, newValue) => {
              onRatingChange(newValue);
            }}
            onChangeActive={(_event, newHover) => {
              setHover(newHover);
            }}
          />
        </Box>
        {(rating !== null || ratingHoverValue > -1) && (
          <Typography component="span" align="center" color="primary" variant="body1">
            {t(`helpers:ratings.${ratingHoverValue !== -1 ? ratingHoverValue : rating}`)}
          </Typography>
        )}
      </Box>
    </Box>
  );

  const handleEditSubmit = (data) => {
    if (data.premiumCode) {
      const newHoursAndPremiums = getNewHoursAndPremiums(data, storedHours, contractHours, storedPremiums);
      onBonusesChange([...storedBonuses, ...newHoursAndPremiums.premiums]);
      onHoursChange(newHoursAndPremiums.hours);
      setIsOpen(false);
    } else {
      const currentDate = new Date(data.date);
      const dayName = days[currentDate.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const defaultContractHours = prop(dayName, contractHours);
      const newHours = {
        ...storedHours,
        [dayName]: {
          ...defaultContractHours,
          date: data.date,
          performedMinutes: valueToMinutes(defaultContractHours.total_hours),
          difference: 0
        }
      };
      onHoursChange(newHours);
      onBonusesChange([
        ...storedBonuses,
        ...storedPremiums.filter((pm) => toIsoDateString(new Date(pm.date)) !== toIsoDateString(storedDayHours.date)),
        {
          ...storedPremiums.find(
            (pm) =>
              toIsoDateString(new Date(pm.date)) === toIsoDateString(storedDayHours.date) && pm.premium_code === 1101
          ),
          minutes: defaultContractHours.total_hours
        }
      ]);
      setIsOpen(false);
    }
    if (shouldMoveToNextDay.current) {
      const nextDay = nextDays[0];
      const nextDate = new Date(nextDay.date);
      const dayName = days[nextDate.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const defaultContractHours = prop(dayName, contractHours);
      const newItem = {
        ...storedDayHours,
        total_hours: defaultContractHours.total_hours,
        dayName,
        date: nextDate,
        reason: data.reason,
        premiumCode: data.premiumCode
      };
      openModal(newItem);
    }
  };

  const handleBonusSelectSubmit = (selected) => {
    const hoursWithDate = getHoursWithDate(weekDays, contractHours);
    const newBonuses = getNewBonuses(hoursWithDate, selected, storedBonuses, appendableCodes);
    onBonusesChange([...storedPremiums, ...newBonuses]);
    setIsBonusOpen(false);
  };

  const tabLabel = (icon, label) => (
    <Box display="flex" flexDirection="column" alignItems="center">
      {icon}
      {label}
    </Box>
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onHoursChange(clone(changedHours));
          setIsOpen(false);
        }}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box flex="1" overflow="auto">
            <Container maxWidth="md">
              <Box mb={6}>
                <Typography align="center" variant="h6" component="h2" mb={6}>
                  {dayData.date
                    ? `${t(`helpers:dates.days.${dayData.date.getDay()}`)} ${format(dayData.date, 'dd/MM/yyyy')}`
                    : ''}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box width={[1, 1, 1 / 2]}>
                  <EditDaySchedule data={dayData} onSubmit={handleEditSubmit} formRef={formRef} />
                </Box>
              </Box>
            </Container>
          </Box>
          <Box display="flex" justifyContent="flex-end" className={classes.box} py={4}>
            <Container maxWidth="xl" className={classes.container}>
              {renderOnCondition(
                showNextDayButton,
                <Button
                  color="primary"
                  onClick={() => {
                    eventGA('prestation_hours_copy_next_day');
                    submitForm(true);
                  }}>
                  {t('general:performance.copyToNextDay')}
                </Button>
              )}
              <Button
                className={classes.button}
                form="scheduleform"
                name="save"
                onClick={() => {
                  submitForm(false);
                }}
                variant="contained"
                color="primary">
                {t('general:save')}
              </Button>
            </Container>
          </Box>
        </Box>
      </Modal>
      <ConfirmDialog
        title={t('general:performed.addBonus')}
        isMobile
        actions={{
          confirm: () => handleBonusSelectSubmit(tempBonuses),
          cancel: () => setIsBonusOpen(false)
        }}
        isOpen={isBonusOpen}>
        <BonusSelector appendableCodes={appendableCodes} data={storedBonuses} onChange={setTempBonuses} />
      </ConfirmDialog>
      <Box pt={2}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_e, val) => setTabValue(val)}
          aria-label="disabled tabs example">
          <Tab label={tabLabel(<FontAwesomeIcon variant="fullWidth" icon={faClock} />, t('general:hours'))} />
          <Tab label={tabLabel(<FontAwesomeIcon variant="fullWidth" icon={faAward} />, t('general:bonus'))} />
          <Tab label={tabLabel(<FontAwesomeIcon variant="fullWidth" icon={faStar} />, t('general:evaluation'))} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} p={0}>
        {getSchedule()}
      </TabPanel>
      <TabPanel value={tabValue} index={1} p={0}>
        {getBonus()}
      </TabPanel>
      <TabPanel value={tabValue} index={2} p={0}>
        {getEvaluation()}
      </TabPanel>
    </>
  );
};

PerformanceScheduleTabletEdit.propTypes = {
  contractHours: object.isRequired,
  classes: object.isRequired,
  changedHours: object,
  bonuses: array.isRequired,
  date: PropTypes.instanceOf(Date),
  onHoursChange: func,
  onBonusesChange: func,
  rating: number,
  onRatingChange: func,
  appendableCodes: array.isRequired
};

PerformanceScheduleTabletEdit.defaultProps = {
  date: new Date(),
  changedHours: {},
  rating: null,
  onRatingChange: () => null,
  onHoursChange: () => null,
  onBonusesChange: () => null
};

export default withStyles(scheduleFormStyle)(PerformanceScheduleTabletEdit);
/* eslint max-lines: 0 */
