import { regex } from 'utils/validation';

export default (getValues) => ({
  password: {
    required: 'passwordRequired'
  },
  newPassword: {
    required: 'passwordRequired',
    pattern: {
      value: regex.password,
      message: 'passwordInvalid'
    }
  },
  confirmPassword: {
    required: 'passwordConfirmationRequired',
    validate: (value) => value === getValues().newPassword || 'passwordShouldMatch'
  }
});
