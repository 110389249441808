import React, { useState, useEffect } from 'react';
import { Box, Backdrop, Container, Paper, Fade, IconButton, Modal as MaterialModal } from '@material-ui/core';
import { connect } from 'react-redux';
import { has, is, pathOr } from 'ramda';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { bool, func, node } from 'prop-types';

import { eventGA } from 'utils/analytics';

import { ModalContext } from './modalContext';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    overflow: 'auto',
    flex: 1,
    height: '100%'
  },
  paper: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0
  }
}));

const Modal = ({ disableScrollLock, onClose, isOpen, children, isMobile }) => {
  const classes = useStyles();
  const [leftBtn, setLeftBtn] = useState(() => () => null);

  useEffect(() => {
    if (!isOpen) {
      setLeftBtn(() => () => null);
    }
    if (isOpen) {
      const child = is(Array, children) ? children.find((item) => has('type', item)) : children;
      const name = pathOr(child.type.displayName, ['type', 'WrappedComponent', 'name'], child);
      eventGA('modal_opened', name);
    }
  }, [isOpen]);

  return (
    <MaterialModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableScrollLock={disableScrollLock}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={isOpen}>
        <ModalContext.Provider
          value={{
            onClose,
            setLeftBtn
          }}>
          <Container maxWidth="xl">
            <Paper className={classes.paper}>
              <Box pt={isMobile ? 0 : '10px'}>
                <Container maxWidth="xl">
                  <Box display="flex" justifyContent="space-between">
                    <Box ml={[-4, -3]}>{leftBtn()}</Box>
                    <Box mr={[-4, -3]}>
                      <IconButton onClick={onClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Container>
              </Box>
              <Box className={classes.root}>{children}</Box>
            </Paper>
          </Container>
        </ModalContext.Provider>
      </Fade>
    </MaterialModal>
  );
};

const mapState = ({ user: { isOnMobile } }) => ({
  isMobile: isOnMobile
});

Modal.propTypes = {
  disableScrollLock: bool,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
  isMobile: bool.isRequired
};

Modal.defaultProps = {
  disableScrollLock: true
};

export default connect(mapState, null)(Modal);
