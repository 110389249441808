import { createStyles } from '@material-ui/core/styles';

export default createStyles((theme) => ({
  noPaddingCell: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  headRow: {
    verticalAlign: 'baseline'
  },
  borderLessCell: {
    borderBottom: 'none'
  },
  dayCell: {
    width: 85,
    maxWidth: 85,
    padding: '6px 8px 6px 8px',
    '& .fa-filter': {
      display: 'none'
    },
    '&:hover .fa-filter': {
      display: 'inline-block'
    }
  },
  mobileDayCell: {
    padding: '4px',
    width: '14.28%',
    '& .fa-filter': {
      display: 'none'
    },
    '&:hover .fa-filter': {
      display: 'inline-block'
    },
    '&:last-child': {
      padding: 4
    }
  },
  weekendCell: {
    backgroundColor: theme.palette.divider,
    '&:last-child': {
      paddingRight: 24
    },
    'tr:last-of-type &': {
      paddingBottom: 12
    }
  },
  filteredDayCell: {
    '& .fa-filter': {
      display: 'inline'
    }
  },
  firstCell: {
    whiteSpace: 'nowrap'
  },
  firstScheduleCell: {
    whiteSpace: 'nowrap',
    paddingLeft: '2rem'
  },
  lastCell: {
    width: 55
  },
  noBorderRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0
    }
  },
  noPaddingRow: {
    '& th,td': {
      paddingTop: 0
    }
  },
  iconColor: {
    color: theme.palette.secondary.main
  },
  expansionPanelDetails: {
    padding: '16px 27px 16px 16px',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  buttonInput: {
    boxShadow: 'none',
    textTransform: 'none',
    borderBottomRightRadius: 'unset',
    borderBottomLeftRadius: 'unset',
    borderBottom: '2px solid transparent',
    paddingTop: 6,
    '&:hover': {
      boxShadow: 'none',
      borderBottomRightRadius: 'unset',
      borderBottomLeftRadius: 'unset',
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  timeCell: {
    paddingLeft: 20
  },
  timeInput: {
    overflow: 'hidden',
    borderRadius: 4,
    textAlign: 'right',
    fontWeight: 400,
    width: '100%',
    letterSpacing: '1.25px',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.action.hover,
    paddingRight: 6,
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border-radius']),
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&:focused': {
      backgroundColor: '#fff',
      borderColor: theme.palette.action.hover
    },
    '&:disabled': {
      backgroundColor: theme.palette.divider,
      borderColor: theme.palette.divider
    }
  },
  checkBoxCell: {
    padding: 0
  },
  bonusInputRoot: {
    backgroundColor: theme.palette.action.hover,
    padding: '0',
    borderRadius: 4,
    maxWidth: 80,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    '&.Mui-error': {
      backgroundColor: theme.palette.error.main
    }
  },
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  bonusInputTablet: {
    padding: '8px 9px',
    textAlign: 'right',
    fontSize: theme.typography.body1.fontSize
  },
  bonusInput: {
    padding: '6px 9px',
    textAlign: 'right',
    fontSize: theme.typography.body2.fontSize
  },
  mobileInput: {
    alignSelf: 'flex-end',
    maxWidth: '73px',
    width: 'fit-content',
    paddingRight: 8
  },
  keyboardIcon: {
    display: 'none',
    width: 0
  },
  scheduleBox: {
    width: '100%',
    flexDirection: 'column'
  },
  scheduleTitle: {
    paddingTop: '0.223em'
  },
  scheduleItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 24,
    '&:before': {
      content: "''",
      borderRight: `1px solid ${theme.palette.divider}`,
      width: 0,
      position: 'absolute',
      top: -4,
      right: -1,
      bottom: 4
    },
    '&:last-child:before': {
      bottom: 'auto',
      height: 'calc(((1.1876em + 29px)/ 2) + 4px)'
    },
    '&::after': {
      content: "''",
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: 10,
      height: 0,
      position: 'absolute',
      top: 'calc((1.1876em + 29px)/ 2)',
      right: 0
    }
  },
  scheduleItemInput: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 36,
    marginTop: -12,
    '&:before': {
      content: "''",
      borderRight: `1px solid ${theme.palette.divider}`,
      width: 0,
      position: 'absolute',
      height: 'calc(100% - 12px)',
      top: 12,
      right: -1
    },
    '&:last-child:before': {
      height: 'calc(50% - 12px)'
    },
    '&:first-child:before': {
      top: -4,
      height: 'calc(100% + 4px)'
    },
    '&:only-child:before': {
      top: -4,
      height: 'calc(50% + 4px)'
    },
    '&::after': {
      content: "''",
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: 10,
      height: 0,
      position: 'absolute',
      top: '50%',
      right: 0
    }
  },
  iconSize: {
    fontSize: 16,
    width: 16
  },
  flexCheckbox: {
    alignItems: 'center',
    marginLeft: 0
  },
  adornment: {
    display: 'none'
  },
  timeInputAlone: {
    maxWidth: '33%',
    paddingTop: '3px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    '& input': {
      textAlign: 'right',
      paddingRight: '6px'
    }
  }
}));
