/* eslint max-lines: 0, complexity: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Container, IconButton, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { connect } from 'react-redux';
import { equals, pathOr, prop, propOr } from 'ramda';
import { array, bool, func, number, object, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { zonedTimeToUtc } from 'date-fns-tz';
import { withRouter } from 'react-router';

import { Banner, ConfirmDialog, ConfirmScreen, HoursDialog, WorkerCard } from 'components';
import { formatTotalHours, isWorkDay, getRange, toIsoDateString, getWeekDays, formatPoolDates } from 'utils/date';

import { useModal } from 'hooks';
import { asFloatingHours } from 'utils/tempWorkers';
import { getMergedContract } from 'utils/contract';
import { renderOnCondition } from 'utils/component';
import NewContractForm from './NewContractForm';
import SecondContractForm from './SecondContractForm';
import ContractExistsCard from './ContractExistsCard';
import NewContractButtons from './NewContractButtons';
import NewContractSchedule from './NewContractSchedule';
import NewContractPool from './NewContractPool/NewContractPool';
import NewContractRecap from './NewContractRecap/NewContractRecap';

const NewContract = ({
  history,
  savedCandidate,
  parentContract,
  getParentContract,
  isMobile,
  isTablet,
  isEditing,
  filteredWorkers,
  searchWorkerList,
  getCostCenters,
  getJobFunctions,
  loadingWorkerList,
  costCenters,
  jobFunctions,
  customer,
  loadingParent,
  defaultValues,
  defaultScheduleDays,
  isNewWorker,
  isPreconfirmed,
  setIsPreconfirmed,
  handleExistingWorkerContract,
  handleNewWorkerContract,
  getReasons,
  reasonsList,
  getCandidatesPool,
  pool,
  isOrdering,
  orderId,
  isLoadingPool,
  actionType // create, update, orderAgain
}) => {
  const { t, i18n } = useTranslation(['general']);
  const { onClose, setLeftBtn } = useModal();
  const { language } = i18n;
  const [data, setData] = useState(defaultValues);
  const [secondData, setSecondData] = useState(defaultValues);
  const [description, setDescription] = useState(defaultValues?.description);
  const [isExtension, setIsExtension] = useState(false);
  const [contractExists, setContractExists] = useState(false);
  const [step, setStep] = useState(1);
  const [defaultSchedule, setDefaultSchedule] = useState(defaultScheduleDays || {});
  const [hasScheduleTimeError, setHasScheduleTimeError] = useState(false);
  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [invalidHours, setInvalidHours] = useState({});
  const [innerPool, setInnerPool] = useState(pool);
  const tzDate = zonedTimeToUtc(new Date(), { timeZone: 'Europe/Berlin' });
  const mergedParentContract = getMergedContract(parentContract);
  const onFirstStepSubmit = (newData) => {
    if (mergedParentContract) {
      setContractExists(true);
      setDefaultSchedule(mergedParentContract.scheduleDays);
    } else {
      setContractExists(false);
    }
    setData({
      ...data,
      ...newData
    });
    setStep(2);
  };
  const range = useMemo(() => getRange(data), [data]);
  const onSecondStepSubmit = (newData) => {
    setSecondData({ ...secondData, ...newData, salaryAmount: newData.salaryAmount || null });
    setIsExtension(false);
    setStep(3);
  };
  const preSelectedWorkers = useMemo(() => innerPool.filter((el) => el.preselected), [innerPool]);
  const [poolParams, setPoolParams] = useState({});

  const handleAfterCreateUpdateOrder = () => {
    setIsPreconfirmed(false);
    setIsExtension(false);
    setContractExists(!!prop('id', mergedParentContract));
    history.replace({
      pathname: '/orders'
    });
    history.go(0);
  };

  const handleContract = () => {
    const contractParams = {
      data,
      secondData,
      mergedParentContract,
      isExtension,
      range,
      description,
      candidateIds: preSelectedWorkers.map((w) => w.id),
      onClose,
      handleAfterCreateUpdateOrder
    };
    if (isNewWorker) return handleNewWorkerContract(contractParams);

    return handleExistingWorkerContract(contractParams);
  };

  useEffect(() => {
    getCostCenters();
    getJobFunctions();
    getReasons(language);
  }, [getCostCenters, getJobFunctions]);

  useEffect(() => {
    setLeftBtn(() => () =>
      step > 1 ? (
        <IconButton
          onClick={() => {
            if (step === 3) {
              setIsExtension(false);
              setContractExists(!!prop('id', mergedParentContract));
              return setStep(2);
            }
            if (step === 5 && !innerPool.length) {
              return setStep(3);
            }
            if (step >= 2) {
              setIsExtension(false);
              return setStep((s) => s - 1);
            }
            return 1;
          }}>
          <NavigateBeforeIcon />
        </IconButton>
      ) : null
    );
  }, [step, setLeftBtn]);

  const handleScheduleChange = (scheduleDays) => {
    setData({
      ...data,
      scheduleDays
    });
  };

  const getBanner = () =>
    !isWorkDay(tzDate) ? (
      <Container maxWidth="md">
        <Box width="100%" margin="auto">
          <Banner type="warning" message={t('general:extend.openingHoursWarning')} />
        </Box>
      </Container>
    ) : null;

  const displayProp = isTablet || step === 3 ? 'block' : 'flex';
  const dataIfFinal = (value) => (step === 3 ? value : null);

  const dialogActions = {
    cancel: () => setIsPreconfirmed(false),
    confirm: () => handleContract()
  };

  const fetchPool = async () => {
    if (!data?.scheduleDays) return null;

    const params = {
      jobFunctionId: data?.jobFunction?.id,
      isStudent: [5, 6].includes(parseInt(secondData?.status, 10)) ? 1 : 0,
      isFlexi: secondData?.contractType?.includes(30) ? 1 : 0,
      dates: formatPoolDates(getWeekDays(range?.from), data?.scheduleDays || []).map((d) => toIsoDateString(d))
    };
    if (equals(params, poolParams)) return innerPool;

    setPoolParams(params);
    return getCandidatesPool(params);
  };

  useEffect(() => {
    setInnerPool(pool.map((el) => ({ ...el, preselected: false, display: true })));
  }, [pool]);

  const getTitle = () => {
    // Contrat avec interimaire defini
    if (!isNewWorker) {
      if (actionType === 'create') return t('general:contract.newContract');
      if (actionType === 'update') return t('general:contract.updateContract');
      if (actionType === 'orderAgain') return t('general:orders.orderAgain');
      if (actionType === 'default') return t('general:contract.newContract');
    }
    // Demande sans interimaire defini
    if (actionType === 'create') return t('general:orders.newOrder');
    if (actionType === 'update') return t('general:orders.updateOrder');
    if (actionType === 'orderAgain') return t('general:orders.orderAgain');
    if (actionType === 'default') return t('general:orders.newOrder');

    return '';
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
      <ConfirmDialog actions={dialogActions} isOpen={isPreconfirmed} isOrdering={isOrdering} title={getTitle()}>
        <ConfirmScreen
          extension
          firstName={pathOr('', ['interimWorker', 'firstName'], data)}
          lastName={pathOr('', ['interimWorker', 'lastName'], data)}
          from={range.from}
          to={range.to}
          totalHours={formatTotalHours(asFloatingHours(data.scheduleDays))}
          description={t('general:contract.confirmscreen')}
          isNewWorker={isNewWorker}
          vacancies={pathOr('', ['vacancies'], data)}
          jobFunction={data.jobFunction ? data.jobFunction[`label_${language || 'fr'}`] : null}
          preSelectedWorkers={preSelectedWorkers}
          isOrdering={isOrdering}
        />
      </ConfirmDialog>
      <HoursDialog
        isOpen={showModifyDialog}
        isOverMaxHours={invalidHours.anyOver11Hours || invalidHours.totalOver50}
        data={data}
        range={range}
        onCancel={setShowModifyDialog}
        onConfirm={handleContract}
      />
      <Box flex="1" overflow="auto" display="flex" flexDirection="column">
        <Box mb={6}>
          <Typography align="center" variant="h6" component="h2" mb={6}>
            {renderOnCondition(step < 4, <>{getTitle()}</>)}
            {renderOnCondition(step === 4, t('general:pool.title'))}
            {renderOnCondition(step === 5, t('general:pool.recap.title'))}
          </Typography>
        </Box>
        {getBanner()}

        {renderOnCondition(
          step === 1,
          <Container maxWidth="md">
            <NewContractForm
              candidate={savedCandidate}
              searchCandidates={searchWorkerList}
              isLoadingCandidates={loadingWorkerList}
              costCenters={costCenters}
              customer={customer}
              jobFunctions={jobFunctions}
              workers={filteredWorkers}
              onSubmit={onFirstStepSubmit}
              defaultValues={data}
              getParentContract={getParentContract}
              isNewWorker={isNewWorker}
            />
          </Container>
        )}
        {renderOnCondition(
          !contractExists && step >= 2 && step < 4,
          <Container maxWidth={step === 2 ? 'md' : 'xl'}>
            <Box display={displayProp} flexDirection="row" justifyContent="space-between" margin="auto">
              <WorkerCard
                mb={4}
                firstName={data.interimWorker?.firstName}
                lastName={data.interimWorker?.lastName}
                dates={{
                  from: new Date(data.from),
                  to: new Date(data.to)
                }}
                costCenter={data.costCenter}
                reference={data.reference}
                contractStatus={dataIfFinal(parseInt(propOr(null, 'status', secondData), 10))}
                contractType={dataIfFinal(propOr(null, 'contractType', secondData))}
                jobFunction={data.jobFunction ? data.jobFunction[`label_${language || 'fr'}`] : null}
                reasonId={dataIfFinal(secondData.reason)}
                isMobile={isMobile}
                showWorkerInfos={!isNewWorker}
                vacancies={data?.vacancies}
              />
              {step === 2 && (
                <SecondContractForm
                  defaultData={secondData}
                  data={data}
                  onSubmit={onSecondStepSubmit}
                  width={isTablet ? 1 : 1 / 2}
                  mb={3}
                  reasonsList={reasonsList}
                  setDefaultSchedule={setDefaultSchedule}
                />
              )}
              {step === 3 && (
                <NewContractSchedule
                  isTablet={isTablet}
                  setDescription={setDescription}
                  description={description}
                  data={data}
                  setHasScheduleTimeError={setHasScheduleTimeError}
                  handleScheduleChange={handleScheduleChange}
                  defaultSchedule={defaultSchedule}
                  setDefaultSchedule={setDefaultSchedule}
                />
              )}
            </Box>
          </Container>
        )}

        {renderOnCondition(
          step === 4,
          <NewContractPool
            innerPool={innerPool}
            setInnerPool={setInnerPool}
            preSelectedWorkers={preSelectedWorkers}
            isMobile={isMobile}
            data={data}
            setStep={setStep}
          />
        )}

        {renderOnCondition(
          step === 5,
          <NewContractRecap
            innerPool={innerPool}
            setInnerPool={setInnerPool}
            preSelectedWorkers={preSelectedWorkers}
            data={data}
            secondData={secondData}
            setStep={setStep}
          />
        )}

        {renderOnCondition(
          contractExists && step >= 2,
          <Container maxWidth="md">
            <ContractExistsCard
              costCenter={data.costCenter}
              firstName={data.interimWorker?.firstName}
              lastName={data.interimWorker?.lastName}
              contractStatus={parseInt(propOr(null, 'candidate_status', mergedParentContract), 10)}
              contractType={propOr(null, 'type_id', mergedParentContract)}
              lastDate={propOr(null, 'to', mergedParentContract)}
              jobFunction={data.jobFunction ? data.jobFunction[`label_${language || 'fr'}`] : null}
              reasonId={propOr(null, 'reason_id', mergedParentContract)}
              isMobile={isMobile}
              showWorkerInfos={!isNewWorker}
            />
          </Container>
        )}
      </Box>
      <NewContractButtons
        loadingParent={loadingParent}
        step={step}
        contractExists={contractExists}
        mergedParentContract={mergedParentContract}
        hasScheduleTimeError={hasScheduleTimeError}
        setShowModifyDialog={setShowModifyDialog}
        setIsPreconfirmed={setIsPreconfirmed}
        setInvalidHours={setInvalidHours}
        data={data}
        isNewWorker={isNewWorker}
        isEditing={isEditing}
        setIsExtension={setIsExtension}
        setContractExists={setContractExists}
        setStep={setStep}
        setSecondData={setSecondData}
        preSelectedWorkers={preSelectedWorkers}
        fetchPool={fetchPool}
        setDefaultSchedule={setDefaultSchedule}
        isOrdering={isOrdering}
        orderId={orderId}
        isLoading={isLoadingPool}
      />
    </Box>
  );
};

NewContract.propTypes = {
  savedCandidate: object,
  isEditing: bool,
  customer: object.isRequired,
  jobFunctions: array.isRequired,
  costCenters: array.isRequired,
  filteredWorkers: array.isRequired,
  getCostCenters: func.isRequired,
  getReasons: func.isRequired,
  parentContract: array.isRequired,
  reasonsList: array.isRequired,
  searchWorkerList: func.isRequired,
  getJobFunctions: func.isRequired,
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
  getParentContract: func.isRequired,
  loadingParent: bool.isRequired,
  loadingWorkerList: bool.isRequired,
  defaultValues: object,
  defaultScheduleDays: object,
  isNewWorker: bool.isRequired,
  isPreconfirmed: bool.isRequired,
  setIsPreconfirmed: func.isRequired,
  handleExistingWorkerContract: func,
  handleNewWorkerContract: func,
  getCandidatesPool: func.isRequired,
  pool: array.isRequired,
  isOrdering: bool.isRequired,
  orderId: number,
  history: object.isRequired,
  isLoadingPool: bool.isRequired,
  actionType: oneOf(['create', 'update', 'orderAgain', 'default']).isRequired
};

NewContract.defaultProps = {
  savedCandidate: null,
  isEditing: false,
  defaultValues: {},
  defaultScheduleDays: {},
  handleExistingWorkerContract: () => null,
  handleNewWorkerContract: () => null,
  orderId: null
};

const mapState = ({ user, tempWorkers, contracts, orders: { pool }, loading }) => ({
  customer: user.currentCustomer,
  isMobile: user.isOnMobile,
  isTablet: user.isOnTablet,
  filteredWorkers: tempWorkers.filteredWorkers,
  costCenters: tempWorkers.costCenters,
  jobFunctions: tempWorkers.jobFunctions,
  parentContracts: contracts.parentContracts,
  savedCandidate: contracts.savedCandidate,
  parentContract: contracts.parentContract,
  reasonsList: contracts.reasonsList,
  pool,
  loadingParent: pathOr(false, ['effects', 'contracts', 'getParent'], loading),
  loadingWorkerList: pathOr(false, ['effects', 'tempWorkers', 'searchWorkerList'], loading),
  isLoadingPool: pathOr(false, ['effects', 'orders', 'getCandidatesPool'], loading),
  isOrdering:
    pathOr(false, ['effects', 'orders', 'createOrder'], loading) ||
    pathOr(false, ['effects', 'orders', 'updateOrder'], loading) ||
    pathOr(false, ['effects', 'contracts', 'extendContract'], loading) ||
    pathOr(false, ['effects', 'contracts', 'postContract'], loading)
});

const mapDispatch = (dispatch) => ({
  searchWorkerList: (fullName) => dispatch.tempWorkers.searchWorkerList(fullName),
  getCostCenters: () => dispatch.tempWorkers.getCostCenters(),
  getJobFunctions: () => dispatch.tempWorkers.getJobFunctions(),
  getReasons: (data) => dispatch.contracts.getReasons(data),
  getParentContract: (data) => dispatch.contracts.getParent(data),
  getCandidatesPool: (data) => dispatch.orders.getCandidatesPool(data),
  getOrderById: (data) => dispatch.orders.getOrderById(data)
});

export default connect(mapState, mapDispatch)(withRouter(NewContract));
/* eslint max-lines: 0 */
