import { propOr } from 'ramda';
// eslint-disable-next-line import/no-cycle

import { getStorage, setStorage } from './storage';

/**
 * this is the structure of the object that you should always send
 * Mamdatory will always be true
 * functionnal is for everything else
 * analytical is for google analytics
 * {
        mandatory: true,
        functionnal: true/false
        analytical: true/false
      }
 */

let cookieConsentObject = getStorage('cookieConsent') || {};

export const getCookieConsentProperty = (key) => {
  if (!key || key === '') {
    return cookieConsentObject;
  }
  return propOr(false, key, cookieConsentObject);
};

export const handleCookieChoice = (val) => {
  if (typeof val === 'object') {
    cookieConsentObject = val;
  }
  setStorage('cookieConsent', cookieConsentObject);
};

export const cookieLinks = {
  fr: 'https://www.daoust.be/fr/daoust-connect-cookies/',
  nl: 'https://www.daoust.be/nl/daoust-connect-cookies/'
};
