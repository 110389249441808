import React, { useCallback, useEffect, useRef, useState } from 'react';
import { array, bool, func } from 'prop-types';
import { Container } from '@material-ui/core';

import { connect } from 'react-redux';
import { equals, isNil, path, pathOr } from 'ramda';
import { useDebounce, useHeader, usePrevious } from 'hooks';
import MyWorkersMobile from './mobile/MyWorkers.mobile';
import MyWorkersDesktop from './desktop/MyWorkers.desktop';

const MyWorkers = ({
  isMobile,
  workerList,
  fetchWorkerList,
  setCandidate,
  isWorkerListLoading,
  getCostCenters,
  costCenters
}) => {
  const { search: headerSearch, setSearch: setHeaderSearch } = useHeader();
  const [search, setSearch] = useState({ workerType: 0, name: headerSearch, since: 3, cost_centers: [] });
  const debouncedSearch = useDebounce(search, 400);
  const activeLoadMore = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const [newContractValues, setNewContractValues] = useState(null);
  const currentPage = useRef(1);
  const [order, setOrder] = useState({
    order_by_field: null,
    order_by_direction: null
  });
  const prevOrder = usePrevious(order, true);
  const isMounted = useRef(true);

  const getNext = useCallback(
    async (criteria) => {
      const receivedNew = await fetchWorkerList(criteria);
      if (isMounted.current) {
        activeLoadMore.current = true;
        setHasMore(receivedNew);
      }
    },
    [fetchWorkerList, setHasMore, activeLoadMore]
  );

  const handleContract = (workerData) => {
    const worker = { ...workerData, firstName: workerData.first_name, lastName: workerData.last_name };
    setCandidate(worker);
    setNewContractValues({
      costCenter: path(['lastContract', 'costCenter'], workerData),
      jobFunction: path(['lastContract', 'jobFunction'], workerData)
    });
  };

  const loadMore = useCallback(() => {
    if (activeLoadMore.current) {
      currentPage.current += 1;
      activeLoadMore.current = false;
      getNext({ ...search, ...(!isMobile && order), page: currentPage.current, stack: true });
    }
  }, [order, getNext, search]);

  const getFirstPage = useCallback(() => {
    activeLoadMore.current = false;
    currentPage.current = 1;
    setHasMore(true);
    getNext({ ...(debouncedSearch || search), ...(!isMobile && order), page: 1, stack: false });
  }, [debouncedSearch, getNext, search, order]);

  useEffect(() => {
    if (!isNil(debouncedSearch?.name) && !isMobile) {
      setHeaderSearch(debouncedSearch.name.trim());
    }
  }, [debouncedSearch, setSearch, setHeaderSearch, isMobile]);

  useEffect(() => {
    getCostCenters();
  }, [getCostCenters]);

  useEffect(() => {
    if (debouncedSearch || !equals(order, prevOrder)) {
      getFirstPage();
    }
  }, [debouncedSearch, getFirstPage]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  const commonProps = {
    workerList,
    isWorkerListLoading,
    costCenters,
    handleContract,
    search,
    setSearch,
    hasMore,
    newContractValues,
    loadMore
  };

  return isMobile ? (
    <MyWorkersMobile {...commonProps} />
  ) : (
    <Container maxWidth="xl">
      <MyWorkersDesktop {...commonProps} order={order} onOrder={setOrder} getFirstPage={getFirstPage} />
    </Container>
  );
};

const mapState = ({ user: { isOnMobile }, workers: { workers }, tempWorkers, loading }) => ({
  isMobile: isOnMobile,
  workerList: workers,
  costCenters: tempWorkers.costCenters,
  isWorkerListLoading: pathOr(true, ['effects', 'workers', 'fetchWorkers'], loading)
});

const mapDispatch = ({ workers: { fetchWorkers }, contracts: { setCandidate }, tempWorkers: { getCostCenters } }) => ({
  fetchWorkerList: fetchWorkers,
  getCostCenters,
  setCandidate
});

MyWorkers.propTypes = {
  isMobile: bool.isRequired,
  workerList: array,
  fetchWorkerList: func,
  setCandidate: func,
  isWorkerListLoading: bool,
  getCostCenters: func.isRequired,
  costCenters: array.isRequired
};

MyWorkers.defaultProps = {
  workerList: null,
  fetchWorkerList: () => {},
  setCandidate: () => {},
  isWorkerListLoading: false
};

export default connect(mapState, mapDispatch)(MyWorkers);
