import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { Grid, Typography, Box, Container } from '@material-ui/core';

import Logo from 'assets/daoust-connect.svg';
import Headline from 'assets/daoust-wttf.svg';
import { Footer, Img, Translate } from 'components';

import UserShortForm from 'user/User.short.form';

const ResetPassword = ({ isLoading, resetPassword }) => {
  const { t } = useTranslation('auth');
  const { token } = useParams();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Container maxWidth="lg">
        <Box mt={4} display="flex" alignItems="center" justifyContent="flex-end">
          <Box>
            <Translate large isSelect />
          </Box>
        </Box>
      </Container>
      <Grid container direction="column" justify="center" alignItems="center">
        <Container maxWidth="sm">
          <Box display="flex" flexDirection="column" alignItems="center" mb={11}>
            <Box mb={6}>
              <Img src={Logo} alt="" />
            </Box>
            <Box>
              <Img src={Headline} alt="" />
            </Box>
          </Box>
          <Box mb={9} display="flex" justifyContent="center">
            <Typography component="h5" variant="h5">
              {t('auth:resetPassword')}
            </Typography>
          </Box>
          <UserShortForm
            onSubmit={(data) => resetPassword({ ...data, token: decodeURIComponent(token) })}
            submitting={isLoading}
            type="resetPassword"
          />
        </Container>
        <Footer />
      </Grid>
    </Box>
  );
};

ResetPassword.propTypes = {
  isLoading: bool.isRequired,
  resetPassword: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'resetPassword'], loading)
});

const mapDispatch = (dispatch) => ({
  resetPassword: dispatch.user.resetPassword
});

export default connect(mapState, mapDispatch)(ResetPassword);
