import React, { useState } from 'react';
import { array, func, object } from 'prop-types';
import { Box, MenuItem, Typography, Container, Button } from '@material-ui/core';
import { faIndustry, faHistory } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { costCenterString } from 'utils/tempWorkers';
import IconSelect from 'components/IconSelect';
import IconAutocomplete from 'components/IconAutocomplete';

const useStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const FilterOrders = ({ costCenters, onFilter, search }) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  const [filters, setFilters] = useState(search);
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box flex="1" overflow="auto">
        <Container maxWidth="md">
          <Box p={4} width={[1, 1, 2 / 3]} display="flex" flexDirection="column" alignItems="center" mx="auto">
            <Typography variant="h6" component="h2">
              {t('general:filters')}
            </Typography>
            <Box width="100%" my={4}>
              <IconAutocomplete
                multiple
                icon={faIndustry}
                options={costCenters}
                getOptionLabel={costCenterString}
                value={filters.cost_centers}
                noValueMessage={t('general:docs.allCostCenters')}
                onChange={(_, value) => {
                  setFilters((f) => ({ ...f, cost_centers: value }));
                }}
              />
            </Box>
            <Box width="100%">
              <IconSelect
                icon={faHistory}
                fullWidth
                defaultValue={search.months}
                onChange={(e) => setFilters((f) => ({ ...f, months: e.target.value }))}>
                <MenuItem value={3}>{t('general:orders.time.three')}</MenuItem>
                <MenuItem value={6}>{t('general:orders.time.six')}</MenuItem>
                <MenuItem value={12}>{t('general:orders.time.twelve')}</MenuItem>
                <MenuItem value="all">{t('general:orders.time.all')}</MenuItem>
              </IconSelect>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box display="flex" justifyContent="flex-end" className={classes.box} py={4}>
        <Container maxWidth="xl" className={classes.container}>
          <Button
            className={classes.button}
            width="100%"
            type="submit"
            form="newcontract"
            data-testid="firstButton"
            name="confirm"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => onFilter(filters)}>
            {t('general:filter')}
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

FilterOrders.propTypes = {
  costCenters: array.isRequired,
  onFilter: func.isRequired,
  search: object.isRequired
};

export default FilterOrders;
