import { createStyles } from '@material-ui/core/styles';

export default createStyles((theme) => ({
  input: {
    display: 'none'
  },
  nameTitle: {
    marginBottom: '2rem',
    textAlign: 'center'
  },
  file: {
    display: 'flex',
    marginLeft: '1rem',
    minHeight: '2rem'
  },
  icon: {
    padding: 0,
    height: 'auto',
    verticalAlign: 'middle',
    marginLeft: '.5rem'
  },
  uploadButton: {
    fontSize: '2rem',
    padding: 0,
    minWidth: '4rem',
    height: '4rem'
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  checkBox: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  footerStyle: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main,
    '& > *': { margin: theme.spacing(1) }
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block'
    }
  },
  button: {
    [theme.breakpoints.down('md')]: { width: '100%' }
  },
  buttonMobile: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 12
    }
  }
}));
