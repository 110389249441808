export const events = {
  state: {
    snackBar: {
      isOpen: false,
      message: null
    }
  },
  reducers: {
    snackMessage: (state, message) => ({
      ...state,
      snackBar: {
        isOpen: true,
        message
      }
    }),
    clearSnackbar: (state) => ({
      ...state,
      snackBar: {
        isOpen: false,
        message: null
      },
      isNewTempWorkerOpen: false,
      isNewProlongationOpen: false
    })
  },
  effects: {
    async sendMessage(message) {
      this.snackMessage(message);
      setTimeout(() => this.clearSnackbar(), 3500);
    }
  }
};
