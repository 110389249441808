import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, pipe } from 'ramda';
import { Grid, Typography, Box, Container } from '@material-ui/core';

import { Footer, Translate } from 'components';

import UserShortForm from 'user/User.short.form';
import { withStyles } from '@material-ui/styles';
import { ArrowBackIos } from '@material-ui/icons';

const UpdatePassword = ({ isLoading, updatePassword, logout, classes }) => {
  const { t } = useTranslation('user');

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Container maxWidth="lg">
        <Box mt={4} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" className={classes.back} onClick={() => logout()}>
            <ArrowBackIos color="secondary" fontSize="small" />
            <Typography variant="body2" color="secondary">
              {t('auth:backToLogin')}
            </Typography>
          </Box>
          <Box>
            <Translate large isSelect />
          </Box>
        </Box>
      </Container>
      <Box flexGrow="1">
        <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
          <Container maxWidth="sm">
            <Box mb={3} display="flex" flexDirection="column" justifyContent="center">
              <Box mb={3}>
                <Typography component="h5" variant="h5">
                  {t('user:setPassword')}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {t('user:setPasswordSub')}
              </Typography>
            </Box>
            <UserShortForm
              onSubmit={({ password, passwordConfirmation }) => {
                updatePassword({
                  newPassword: password,
                  confirmPassword: passwordConfirmation
                });
              }}
              submitting={isLoading}
              type="updatePassword"
            />
          </Container>
          <Footer />
        </Grid>
      </Box>
    </Box>
  );
};

UpdatePassword.propTypes = {
  isLoading: bool.isRequired,
  updatePassword: func.isRequired,
  logout: func.isRequired,
  classes: object.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'updatePassword'], loading)
});

const mapDispatch = (dispatch) => ({
  logout: dispatch.user.logout,
  updatePassword: dispatch.user.updatePassword
});

export default pipe(
  withStyles((theme) => ({
    back: {
      cursor: 'pointer'
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'none',
      fontSize: '1.6rem'
    }
  })),
  connect(mapState, mapDispatch)
)(UpdatePassword);
