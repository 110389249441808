import { createContext } from 'react';

export const HeaderContext = createContext({
  search: null,
  setSearch: () => null,
  renderSearch: () => null,
  isSearchOpen: false,
  components: [],
  setComponents: () => [],
  title: null,
  setTitle: () => null
});
