import React from 'react';
import { equals, cond, either, always, groupBy, values, omit } from 'ramda';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { array, func, bool, object } from 'prop-types';
import { AmountCell, TimeCell, CheckBoxCell } from './PerformanceScheduleCell';
import { PerformanceScheduleRow } from './PerformanceScheduleRow';

const getComponent = cond([
  [either(equals(1), equals(3)), always(TimeCell)],
  [equals(2), always(AmountCell)],
  [equals(4), always(CheckBoxCell)]
]);

export const BonusesDisplay = ({
  isHomePage,
  editMode,
  weekDays,
  storedBonuses,
  onBonusesChange,
  classes,
  onBonusClick
}) => {
  const { t, i18n } = useTranslation();
  const rows = values(
    groupBy((bonus) => `${bonus[`description_${i18n.language}`]}-${bonus.premium_code}`, storedBonuses)
  );

  return (
    <>
      <PerformanceScheduleRow
        isHomePage={isHomePage}
        label={t('general:performed.bonus')}
        icon={faAward}
        editMode={editMode}
        classes={!rows.length ? omit(['borderLessCell'], classes) : classes}
        weekDays={weekDays}
        storedBonuses={storedBonuses}
        childrenProps={{
          align: 'right',
          className: rows.length ? clsx(classes.dayCell, classes.borderLessCell) : classes.dayCell
        }}
      />
      {rows.map((bonusesRow, index) => {
        const { premium_code, smile_code_type, source, is_active, ...props } = bonusesRow[0];
        return (
          <PerformanceScheduleRow
            isHomePage={isHomePage}
            key={premium_code}
            label={props[`description_${i18n.language}`]}
            editMode={editMode}
            isEditable={source === 1 && is_active === 1}
            premiumCode={premium_code}
            classes={rows.length === index + 1 ? omit(['borderLessCell'], classes) : classes}
            weekDays={weekDays}
            storedBonuses={bonusesRow}
            cellComponent={getComponent(smile_code_type)}
            childrenProps={{
              align: 'right',
              className: clsx(classes.dayCell, classes.borderLessCell),
              currency: smile_code_type === 2,
              premiumCode: premium_code,
              storedBonuses,
              onClick: onBonusClick,
              onBonusesChange
            }}
            {...(smile_code_type === 4 && {
              total: bonusesRow.reduce((acc, curr) => acc + curr.minutes, 0)
            })}
          />
        );
      })}
    </>
  );
};

BonusesDisplay.propTypes = {
  isHomePage: bool,
  premiums: array,
  editMode: bool,
  weekDays: array,
  bonuses: array,
  storedBonuses: array,
  onBonusesChange: func,
  classes: object.isRequired,
  onBonusClick: func
};

BonusesDisplay.defaultProps = {
  isHomePage: false,
  premiums: [],
  editMode: false,
  weekDays: [],
  bonuses: [],
  storedBonuses: [],
  onBonusesChange: () => {},
  onBonusClick: () => null
};
