import React from 'react';
import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { or, pathOr } from 'ramda';

import { renderOnCondition } from 'utils/component';
import { OrderName, WorkerCard } from 'components';

import OrderPeriod from './OrderPeriod';

const OrderWorkerCard = ({ order, isMobile }) => {
  const { t } = useTranslation(['general']);

  return (
    <Box>
      <WorkerCard
        reference={order.invoice_reference}
        jobFunction={[2, 3, 4].includes(order.order_type_id) ? order.title : ''}
        costCenter={order.costCenter}
        customer={!order.costCenter && pathOr('', ['customer', 'name'], order)}
        reasonId={order.reason_id}
        isStudent={!!order.is_student}
        isSeasonal={!!order.is_seasonal}
        isFlexi={!!order.is_flexi}
        vacancies={order?.openings}
        showWorkerInfos={!!order?.firstCandidate}
        renderHead={() =>
          !isMobile && (
            <Typography variant="h6">
              <OrderName order={order} /> {order.order_type_id === 3 && <FontAwesomeIcon icon={faRedoAlt} />}
            </Typography>
          )
        }
        renderDates={() => !isMobile && <OrderPeriod noIcon start={order.start} end={order.end} />}
      />
      {renderOnCondition(
        order.order_type_id === 4,
        <Typography color="textSecondary" variant="body2" align="left">
          {`${t('general:description')} ${or(order.description, '-')}`}
        </Typography>
      )}
    </Box>
  );
};

OrderWorkerCard.propTypes = {
  order: object.isRequired,
  isMobile: bool
};

OrderWorkerCard.defaultProps = {
  isMobile: false
};

export default OrderWorkerCard;
