import { toast } from 'react-toastify';
import i18n from 'translate/i18n';
import {
  groupContractsByCostCenter,
  groupContractsByWeekAndCostCenter,
  handleWorkersList,
  groupContracts
} from 'utils/tempWorkers';
import * as service from './service';

export const tempWorkers = {
  state: {
    list: [],
    rawList: [],
    nextContractDates: null,
    toConfirmList: [],
    toConfirmRaw: [],
    filteredWorkers: [],
    costCenters: [],
    jobFunctions: [],
    search: ''
  },
  reducers: {
    gotNext: (state, nextContractDates) => ({ ...state, nextContractDates }),
    gotList: (state, list) => ({ ...state, list }),
    gotRawList: (state, rawList) => ({ ...state, rawList }),
    gotToConfirmList: (state, toConfirmList, toConfirmRaw) => ({ ...state, toConfirmList, toConfirmRaw }),
    gotFilteredWorkers: (state, filteredWorkers) => ({ ...state, filteredWorkers }),
    gotCostCenters: (state, costCenters) => ({ ...state, costCenters }),
    gotJobFunctions: (state, jobFunctions) => ({ ...state, jobFunctions }),
    setSearch: (state, search) => ({ ...state, search })
  },
  effects: (dispatch) => ({
    async confirmTimesheet({ contractIds, impersonateValidatorId, count = 1 }, rootState) {
      const data = await service[`confirmTimesheet${count > 1 ? 's' : ''}`]({ contractIds, impersonateValidatorId });
      if (data?.results) {
        const { failed, success } = data.results;
        const filtered = rootState.tempWorkers.toConfirmRaw.filter((contract) => {
          return !success.includes(contract.id);
        });
        this.gotToConfirmList(groupContractsByWeekAndCostCenter(filtered), filtered);
        if (failed.length) {
          await toast.warn(i18n.t(`errors:failedSaveTimesheet${count > 1 ? '_plural' : ''}`));
        } else {
          dispatch.events.sendMessage(i18n.t('general:savedSuccessfully'));
        }
        return data.results;
      }
      return null;
    },
    async getCostCenters() {
      const data = await service.getCostCentersList();
      if (data) {
        this.gotCostCenters(data);
        return data;
      }
      return null;
    },
    async getJobFunctions() {
      const data = await service.getJobFunctions();
      if (data) this.gotJobFunctions(data.filter((job) => !job.inactive));
    },
    async getList({ from, to } = {}) {
      const data = await service.getList(from, to);
      if (data) {
        const rawList = groupContracts(data);
        this.gotRawList(rawList);
        const newList = groupContractsByCostCenter(data);
        this.gotList(newList);
        return { rawList, newList };
      }
      return [];
    },
    async getToConfirmList({ from, to } = {}) {
      this.gotToConfirmList([], []);
      const data = await service.getToConfirmList(from, to);
      if (data) {
        this.gotToConfirmList(groupContractsByWeekAndCostCenter(data), data);
      }
    },
    async searchWorkerList(fullName) {
      const data = await service.searchWorkerList(fullName);
      if (data) this.gotFilteredWorkers(handleWorkersList(data));
    },
    async getNextContractDates(params) {
      this.gotNext(await service.getNextContractDates(params));
    }
  })
};
