/* eslint-disable complexity, max-lines */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { array, bool, func, object, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Box, Button, Checkbox, FormControlLabel, TableCell, TableRow, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { pathOr, pipe, prop, clone, equals, sortBy } from 'ramda';
import { isDate } from 'ramda-adjunct';
import { format, isSameDay } from 'date-fns';
import { usePrevious } from 'hooks';

import {
  days,
  hoursFromMinutes,
  convertMinutesToTime,
  getDayPickerValue,
  valueToMinutes,
  areSameHours,
  toIsoDateString
} from 'utils/date';

import ConfirmDialog from 'components/ConfirmDialog';
import { fillBonusesForEmptyDays } from 'components/PerformanceSchedule/common';
import { getNewBonuses } from 'pages/ConfirmPrestation/utils';
import { eventGA } from 'utils/analytics';
import { EditDaySchedule, PerformanceScheduleRow, ReadOnlyTimeCell, BonusSelector } from './common';
import {
  getHoursWithDate,
  getNewHoursAndPremiums,
  filterBonuses,
  AlignMiddleTableCell,
  getTotal,
  getInputsValues
} from './common/utils';
import BonusesDisplay from './common/BonusesDisplay';
import PerformedCell from './PerformedCell';
import HourCell from './HourCell';
import Status from './Status';
import styles from './TempWorkerRow.styles';

const TempWorkerRowForm = ({
  classes,
  show,
  showDetails,
  showBonuses,
  data,
  endActions = [],
  weekDays,
  rowLabel,
  isLast,
  checked,
  onCheckChange,
  getAppendableCodes,
  appendableCodes,
  handleHoursModification,
  fillPremiumsForEmptyDays,
  fillPerformedHours
}) => {
  const { t } = useTranslation(['tempWorkers']);
  const [inputsValues, setInputsValues] = useState([]);
  const [storedPremiums, setStoredPremiums] = useState([]);
  const [tempBonuses, setTempBonuses] = useState([]);
  const [savedHours, setSavedHours] = useState(data.info.timesheetScheduleDays);
  const [storedHours, setStoredHours] = useState(savedHours);
  const [changedHours, setChangedHours] = useState(savedHours);
  const [savedBonuses, setSavedBonuses] = useState(data.info.contractHasPremiumCodes);
  const [changedBonuses, setChangedBonuses] = useState(savedBonuses);
  const [storedBonuses, setStoredBonuses] = useState(savedBonuses);
  const [isOpen, setIsOpen] = useState(false);
  const [isBonusOpen, setIsBonusOpen] = useState(false);
  const [currentDayData, setCurrentDayData] = useState({});
  const [showNextDayButton, setShowNextDayButton] = useState(false);
  const [nextDays, setNextDays] = useState([]);
  const formRef = useRef();
  const shouldMoveToNextDay = useRef(false);
  const prevData = usePrevious(data);
  const [hours, setHours] = useState(savedHours);
  const [bonus, setBonus] = useState(savedBonuses);

  const submitForm = async (applyToNextDay) => {
    shouldMoveToNextDay.current = applyToNextDay;
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
  };

  const openModal = (newDayHours) => {
    const dayIndex = newDayHours.date.getDay();
    const daysLeft = weekDays.slice(dayIndex);
    const hoursWithDate = getHoursWithDate(daysLeft, data.hours);

    setNextDays(hoursWithDate);
    setShowNextDayButton(!!prop('length', hoursWithDate));
    setCurrentDayData(newDayHours);
    setIsOpen(true);
  };

  const handleBonusSelectSubmit = (selected) => {
    const hoursWithDate = getHoursWithDate(weekDays, hours);
    const newBonuses = getNewBonuses(hoursWithDate, selected, bonus, appendableCodes);
    setStoredBonuses([...newBonuses, ...storedPremiums]);
    setIsBonusOpen(false);
    handleHoursModification({ info: data.info, hours: storedHours, premiums: [...newBonuses, ...storedPremiums] });
  };

  const getAppendableCodesBonus = () => {
    getAppendableCodes(data.info.contractGroupId);
  };

  const handleEditSubmit = (formData, isUpdatingPremiums, bonuses) => {
    if (formData.premiumCode || isUpdatingPremiums) {
      const newHoursAndPremiums = getNewHoursAndPremiums(
        formData,
        storedHours,
        data.hours,
        storedPremiums,
        isUpdatingPremiums
      );
      const newBonuses = isUpdatingPremiums
        ? bonuses
        : [...storedBonuses.filter((b) => b.smile_code_type !== 3), ...newHoursAndPremiums.premiums];
      setStoredBonuses(newBonuses);
      setStoredHours(newHoursAndPremiums.hours);
      //  update list to be sent to the back.
      handleHoursModification({ info: data.info, hours: newHoursAndPremiums.hours, premiums: newBonuses });
    } else {
      const currentDate = new Date(formData.date);
      const dayName = days[currentDate.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const defaultContractHours = prop(dayName, data.hours);
      const newHours = {
        ...storedHours,
        [dayName]: {
          ...defaultContractHours,
          date: formData.date,
          performedMinutes: valueToMinutes(defaultContractHours.total_hours),
          difference: 0
        }
      };
      setStoredHours(newHours);
      setStoredBonuses([
        ...storedBonuses,
        ...storedPremiums.filter((pm) => toIsoDateString(new Date(pm.date)) !== toIsoDateString(storedDayHours.date)),
        {
          ...storedPremiums.find(
            (pm) =>
              toIsoDateString(new Date(pm.date)) === toIsoDateString(storedDayHours.date) && pm.premium_code === 1101
          ),
          minutes: defaultContractHours.total_hours
        }
      ]);
    }
    setIsOpen(false);
    if (shouldMoveToNextDay.current) {
      const nextDay = nextDays[0];
      const nextDate = new Date(nextDay.date);
      const dayName = days[nextDate.getDay()];
      const storedDayHours = prop(dayName, storedHours);
      const contractDayHours = prop(dayName, data.hours);
      const { performedMinutes, reason } = formData;
      const newItem = {
        ...storedDayHours,
        performedMinutes,
        total_hours: contractDayHours.total_hours,
        difference: performedMinutes - parseInt(contractDayHours.total_hours, 10),
        dayName,
        date: nextDate,
        reason
      };

      openModal(newItem);
    }
  };

  const handleBonusChange = useCallback(
    (nb, day) => {
      const dayName = days[day.getDay()];
      const dayHours = prop(dayName, storedHours);
      const newBonuses = [...storedPremiums, ...nb];
      handleEditSubmit(dayHours, true, newBonuses);
    },
    [handleEditSubmit, days, storedHours, storedPremiums]
  );

  useEffect(() => {
    const populatedBonuses = filterBonuses(bonus, hours);
    const filteredPremiums = clone(populatedBonuses).filter((pc) => pc.is_phantom === 1 && pc.premium_code !== 2060);
    const filteredBonuses = sortBy(
      (item) => item.premium_code,
      clone(populatedBonuses).filter(
        (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
      )
    );
    setStoredPremiums(filteredPremiums);
    setChangedBonuses(filteredBonuses);
    setChangedHours(hours);
    setInputsValues(getInputsValues(hours, bonus, filteredBonuses));
  }, [bonus, hours]);

  useEffect(() => {
    const date = new Date(data.info.from);
    const filledPremiums = fillPremiumsForEmptyDays({
      changedHours: storedHours,
      contractHours: data.hours,
      allBonuses: clone(storedBonuses),
      date
    });
    const newStoredHours = fillPerformedHours(date, storedHours, data.hours, filledPremiums);
    const filledBonuses = fillBonusesForEmptyDays({
      changedHours: newStoredHours,
      allBonuses: clone(storedBonuses),
      date
    });
    setBonus([...filledBonuses, ...filledPremiums]);
    setHours(newStoredHours);
    if (!equals(prevData, data)) {
      setSavedHours(newStoredHours);
      setSavedBonuses([...filledBonuses, ...filledPremiums]);
    }
  }, [storedBonuses, storedHours, data, prevData]);

  return (
    <>
      <ConfirmDialog
        title={t('general:performed.addBonus')}
        isMobile
        actions={{
          confirm: () => {
            eventGA('prestation_to_confirm_add_premium_confirm');
            handleBonusSelectSubmit(tempBonuses);
          },
          cancel: () => {
            eventGA('prestation_to_confirm_add_premium_cancel');
            setIsBonusOpen(false);
          }
        }}
        onClose={() => setIsBonusOpen(false)}
        isOpen={isBonusOpen}>
        <BonusSelector
          appendableCodes={appendableCodes}
          getAppendableCodesBonus={getAppendableCodesBonus}
          data={changedBonuses}
          onChange={setTempBonuses}
        />
      </ConfirmDialog>
      <ConfirmDialog
        isOpen={isOpen}
        title={
          currentDayData.date
            ? `${t(`helpers:dates.days.${currentDayData.date.getDay()}`)} ${format(currentDayData.date, 'dd/MM/yyyy')}`
            : ''
        }
        isMobile
        showNextDayButton={showNextDayButton}
        showCloseButton
        actions={{
          confirm: () => {
            eventGA('prestation_to_confirm_hours_confirm');
            submitForm(false);
          },
          confirmAndCopyToNextDay: () => {
            eventGA('prestation_to_confirm_hours_copy_next_day');
            submitForm(true);
          }
        }}
        onClose={() => {
          setStoredHours(clone(changedHours));
          setIsOpen(false);
        }}>
        <EditDaySchedule
          data={currentDayData}
          onSubmit={handleEditSubmit}
          formRef={formRef}
          contractGroupId={data.info.contractGroupId}
        />
      </ConfirmDialog>
      <TableRow
        key={rowLabel}
        className={clsx(classes.clickableRow, classes.tableRow, {
          [classes.noBorderRow]: showDetails || showBonuses || isLast,
          [classes.noPaddingBottomRow]: showDetails || showBonuses,
          [classes.hiddenRow]: !show
        })}>
        <TableCell component="th" scope="row" className={classes.firstAndLastCell}>
          {onCheckChange ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => onCheckChange(e.target.checked, data)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <Box whiteSpace="nowrap">
                  <Typography variant="body2">{rowLabel}</Typography>
                </Box>
              }
            />
          ) : (
            <Typography variant="body2">{rowLabel}</Typography>
          )}
        </TableCell>
        <TableCell align="right">
          <Status data={data} />
        </TableCell>
        {weekDays.map((day) => {
          const dayName = days[day.getDay()];
          const dayHours = prop(dayName, changedHours);
          const contractDayHours = prop(dayName, data.hours);
          return (
            <PerformedCell
              value={inputsValues[day.getDay()]?.pickerValue}
              stringValue={inputsValues[day.getDay()]?.stringValue}
              storedPremiums={storedPremiums}
              key={day}
              day={day}
              classes={classes}
              editMode
              onChange={(e) => {
                setInputsValues((iv) =>
                  Object.assign([], iv, {
                    [day.getDay()]: {
                      pickerValue: e || new Date('invalid'),
                      stringValue: e ? convertMinutesToTime(e.getHours() * 60 + e.getMinutes(), 'h') : ''
                    }
                  })
                );
              }}
              onValidDateChange={(e) => {
                if (!isDate(e)) {
                  return setHours(clone(changedHours));
                }
                if (!areSameHours(e, getDayPickerValue(data.hours, day))) {
                  return openModal({
                    ...dayHours,
                    total_hours: contractDayHours.total_hours,
                    performedMinutes: valueToMinutes(e),
                    difference: valueToMinutes(e) - parseInt(contractDayHours.total_hours, 10),
                    dayName,
                    date: day
                  });
                }
                const newHours = {
                  ...changedHours,
                  [dayName]: { ...contractDayHours, date: day, performedMinutes: valueToMinutes(e), difference: 0 }
                };
                const newBonuses = [
                  ...changedBonuses,
                  ...storedPremiums.filter(
                    (pm) => toIsoDateString(new Date(pm.date)) !== toIsoDateString(dayHours.date)
                  ),
                  {
                    ...storedPremiums.find(
                      (pm) =>
                        toIsoDateString(new Date(pm.date)) === toIsoDateString(dayHours.date) &&
                        pm.premium_code === 1101
                    ),
                    minutes: contractDayHours.total_hours
                  }
                ];
                handleHoursModification({ info: data.info, hours: newHours, premiums: newBonuses });
                setStoredHours(newHours);
                return setStoredBonuses(newBonuses);
              }}
            />
          );
        })}
        <TableCell align="right">{pipe(pathOr(0, ['info', 'timesheetWeekHours']), hoursFromMinutes)(data)}</TableCell>
        <TableCell align="right" className={classes.firstAndLastCell}>
          {endActions.map((renderAction) => (
            <Box px={1} key={renderAction}>
              {renderAction()}
            </Box>
          ))}
        </TableCell>
      </TableRow>
      {!!show && !!storedPremiums.find((item) => item.premium_code !== 1101) && (
        <>
          <PerformanceScheduleRow
            label={t('general:performed.contractHours')}
            classes={classes}
            weekDays={weekDays}
            isEditable
            premiumCode={1101}
            storedBonuses={storedBonuses}
            cellComponent={ReadOnlyTimeCell}
            childrenProps={{ mode: 'total_hours', contractHours: data.hours, storedHours, classes }}
            total={hoursFromMinutes(getTotal('total_hours', data.hours))}
          />
          <PerformanceScheduleRow
            label={t('general:difference')}
            classes={classes}
            weekDays={weekDays}
            premiumCode={1001}
            storedBonuses={storedBonuses}
            cellComponent={ReadOnlyTimeCell}
            childrenProps={{ mode: 'difference', contractHours: data.hours, storedHours, classes }}
            total={hoursFromMinutes(getTotal('difference', changedHours), true)}
          />
        </>
      )}
      {!!show && !!showDetails && (
        <TableRow
          className={clsx(classes.tableRow, classes.noPaddingTopRow, {
            [classes.noBorderRow]: showBonuses || isLast,
            [classes.noPaddingBottomRow]: showBonuses,
            [classes.hiddenRow]: !show
          })}>
          <TableCell component="th" scope="row">
            <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
              <FontAwesomeIcon fixedWidth icon={faClock} className={clsx(classes.iconColor)} />
              <Box ml={1}>
                <Typography variant="body2" color="secondary">
                  {t('tempWorkers:timesheet')}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell align="right" />
          {weekDays.map((day) => (
            <AlignMiddleTableCell
              key={day.getDay()}
              align="right"
              className={isSameDay(day, new Date()) ? classes.todayCell : ''}>
              <HourCell
                hours={pathOr({}, [days[day.getDay()]], storedHours)}
                contractHours={pathOr({}, ['info', 'scheduleDays', days[day.getDay()]], data)}
                showDetails
              />
            </AlignMiddleTableCell>
          ))}
          <TableCell align="right" />
        </TableRow>
      )}
      {!!show && !!storedBonuses.length && !!showBonuses && (
        <BonusesDisplay
          editMode
          weekDays={weekDays}
          storedBonuses={changedBonuses}
          onBonusesChange={(nb, day) => {
            handleBonusChange(nb, day);
          }}
          classes={classes}
        />
      )}
      {!!show && !!showBonuses && (
        <TableRow>
          <Box component="td" data-testid="premiumButton" pl={4} p={2}>
            <Button
              onClick={() => {
                eventGA('prestation_to_confirm_add_premium_button');
                setIsBonusOpen(true);
              }}
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              fullWidth>
              {t('general:performed.addBonus')}
            </Button>
          </Box>
        </TableRow>
      )}
    </>
  );
};

TempWorkerRowForm.propTypes = {
  classes: object.isRequired,
  show: bool,
  showDetails: bool,
  showBonuses: bool,
  data: object,
  endActions: array,
  weekDays: array,
  rowLabel: string,
  isLast: bool,
  checked: bool,
  onCheckChange: func,
  getAppendableCodes: func.isRequired,
  handleHoursModification: func,
  fillPremiumsForEmptyDays: func,
  fillPerformedHours: func,
  appendableCodes: array
};

TempWorkerRowForm.defaultProps = {
  show: false,
  showDetails: false,
  showBonuses: false,
  data: {},
  endActions: [],
  weekDays: [],
  rowLabel: '',
  isLast: false,
  checked: false,
  onCheckChange: null,
  handleHoursModification: () => null,
  fillPremiumsForEmptyDays: () => null,
  fillPerformedHours: () => null,
  appendableCodes: []
};

export default withStyles(styles)(withRouter(TempWorkerRowForm));
