import React, { useEffect, useState, useMemo } from 'react';
import { FormControl, FormHelperText, FilledInput, InputLabel, Box, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isNil, pathOr, propOr } from 'ramda';
import { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { startOfWeek, endOfWeek } from 'date-fns';

import useDebounce from 'hooks/useDebounce';
import { hasError, getError } from 'components/helpers';
import { getNextWorkingDay } from 'utils/date';
import { costCenterString } from 'utils/tempWorkers';
import WeekSelector from 'components/WeekSelector/WeekSelector';
import validations from './newContractForm.validation';

const nextWorkingDay = getNextWorkingDay();

// `FormControl` wraps every field for the sake of design consistency.
const NewContractForm = ({
  candidate,
  searchCandidates,
  isLoadingCandidates,
  getParentContract,
  customer,
  defaultValues,
  onSubmit,
  costCenters,
  jobFunctions,
  workers,
  isNewWorker
}) => {
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 300);
  const { t, i18n } = useTranslation(['general']);
  const { language } = i18n;

  const { errors, handleSubmit, control, getValues, setValue, watch, register } = useForm({
    mode: 'onBlur',
    defaultValues: {
      from: pathOr(startOfWeek(nextWorkingDay, { weekStartsOn: 1 }), ['from'], defaultValues),
      to: pathOr(endOfWeek(nextWorkingDay, { weekStartsOn: 1 }), ['to'], defaultValues),
      reference: pathOr('', ['reference'], defaultValues),
      interimWorker: candidate || pathOr('', ['interimWorker'], defaultValues),
      costCenter: pathOr(null, ['costCenter'], defaultValues),
      jobFunction: pathOr('', ['jobFunction'], defaultValues),
      vacancies: pathOr(1, ['vacancies'], defaultValues)
    }
  });
  const validators = useMemo(() => validations(getValues, nextWorkingDay, customer), [getValues]);

  const interimWorker = watch('interimWorker');
  const jobFunction = watch('jobFunction');
  const costCenter = watch('costCenter');
  const from = watch('from');

  useEffect(() => {
    register('from', validators.from);
    register('to', validators.to);
  }, [register, validators]);

  useEffect(() => {
    if (!isNil(debouncedSearchTerm)) {
      searchCandidates(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, searchCandidates]);

  useEffect(() => {
    const candidateId = pathOr(null, ['id'], interimWorker);
    const jobFunctionId = pathOr(null, ['id'], jobFunction);
    const costCenterId = pathOr(null, ['id'], costCenter);

    if (candidateId && jobFunctionId) {
      getParentContract({ customerId: customer?.customer_id, candidateId, jobFunctionId, costCenterId });
    }
  }, [interimWorker, jobFunction, customer, costCenter, getParentContract]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="newcontract">
      <Box display="flex" flexDirection="column">
        {!isNewWorker && (
          <Box py={2}>
            <FormControl fullWidth variant="filled" disabled={!!candidate}>
              <Controller
                name="interimWorker"
                as={
                  <Autocomplete
                    id="interimWorker"
                    data-testid="interimWorker"
                    options={workers}
                    clearOnBlur={false}
                    autoHighlight
                    getOptionLabel={(option) => (option ? `${option.lastName} ${option.firstName}` : '')}
                    renderOption={(option) => `${option.lastName} ${option.firstName}`}
                    filterOptions={(options) => options}
                    noOptionsText={t('general:noOptions')}
                    loading={isLoadingCandidates}
                    loadingText={t('general:loading')}
                    renderInput={(params) => (
                      <TextField {...params} label={t('general:interimWorker')} fullWidth variant="filled" />
                    )}
                  />
                }
                onChange={([_event, data]) => data}
                onInputChange={(_event, newInputValue) => {
                  setSearch(newInputValue);
                }}
                rules={validators.interimWorker}
                control={control}
              />
              <FormHelperText error={hasError('interimWorker', errors)}>
                {t(getError(['interimWorker', 'message'], errors))}
              </FormHelperText>
            </FormControl>
          </Box>
        )}

        <Box py={2}>
          <FormControl fullWidth variant="filled">
            <Controller
              name="jobFunction"
              as={
                <Autocomplete
                  id="jobFunction"
                  options={jobFunctions}
                  data-testid="jobFunction"
                  autoHighlight
                  getOptionLabel={(option) => propOr('', `label_${language}`, option)}
                  noOptionsText={t('general:noOptions')}
                  renderInput={(params) => (
                    <TextField {...params} label={t('general:jobFunction')} fullWidth variant="filled" />
                  )}
                />
              }
              onChange={([_event, data]) => data}
              rules={validators.jobFunction}
              control={control}
            />
            <FormHelperText error={hasError('jobFunction', errors)}>
              {t(getError(['jobFunction', 'message'], errors))}
            </FormHelperText>
          </FormControl>
        </Box>

        {isNewWorker && (
          <Box py={2}>
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="vacancies">{t('general:orders.vacancies')}</InputLabel>
              <Controller
                name="vacancies"
                type="number"
                fullWidth
                as={FilledInput}
                control={control}
                rules={validators.vacancies}
              />
              <FormHelperText error={hasError('vacancies', errors)}>
                {t(getError(['vacancies', 'message'], errors), { min: 1, max: 999 })}
              </FormHelperText>
            </FormControl>
          </Box>
        )}

        <Box py={2}>
          <FormControl fullWidth variant="filled">
            <Controller
              name="costCenter"
              as={
                <Autocomplete
                  id="costCenter"
                  data-testid="costCenter"
                  options={costCenters}
                  autoHighlight
                  getOptionLabel={costCenterString}
                  renderOption={costCenterString}
                  noOptionsText={t('general:noOptions')}
                  renderInput={(params) => (
                    <TextField {...params} label={t('general:costcenter')} fullWidth variant="filled" />
                  )}
                />
              }
              onChange={([_event, data]) => {
                return data;
              }}
              rules={validators.costCenter}
              control={control}
            />
            {customer?.is_cost_center_mandatory ? (
              <FormHelperText error={hasError('costCenter', errors)}>
                {t(getError(['costCenter', 'message'], errors))}
              </FormHelperText>
            ) : (
              <FormHelperText>{t('general:optional')}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box py={2}>
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="reference">{t('general:extend.reference')}</InputLabel>
            <Controller
              as={FilledInput}
              name="reference"
              id="reference"
              inputProps={{ maxLength: 25, 'data-testid': 'reference' }}
              fullWidth
              control={control}
            />
            <FormHelperText>{t('general:optional')}</FormHelperText>
          </FormControl>
        </Box>
        <Box py={2} mx={-2} display="flex" flexDirection="row" justifyContent="space-between">
          <Box px={2} width={1}>
            <WeekSelector
              data-testid="from"
              isBlock
              dateChange={(val) => {
                setValue('from', val.from, true);
                setValue('to', val.to, true);
                return val;
              }}
              value={from}
            />
            <FormHelperText error={hasError('from', errors)}>{t(getError(['from', 'message'], errors))}</FormHelperText>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

NewContractForm.propTypes = {
  candidate: object,
  defaultValues: object.isRequired,
  onSubmit: func.isRequired,
  searchCandidates: func.isRequired,
  getParentContract: func.isRequired,
  customer: object.isRequired,
  costCenters: array,
  jobFunctions: array,
  workers: array,
  isLoadingCandidates: bool.isRequired,
  isNewWorker: bool.isRequired
};

NewContractForm.defaultProps = {
  candidate: null,
  costCenters: [],
  jobFunctions: [],
  workers: []
};

export default NewContractForm;
