import React from 'react';
import { object, bool, string, oneOfType, array } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faBan } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      marginBottom: 8
    }
  },
  boxIcon: {
    [theme.breakpoints.down('md')]: {
      lineHeight: 2.2
    }
  }
}));

const getColor = (isColored, color, other) => (isColored ? color : other);

const makeIconAndText = (statusLabel) => {
  if (statusLabel === 'Done') {
    return { icon: faCheckCircle };
  }
  if (statusLabel === 'Canceled') {
    return { icon: faBan };
  }
  if (statusLabel === 'In progress' || statusLabel === 'To fill') {
    return { icon: faFileSignature };
  }
  return { icon: null };
};

const PlanningCandidateStatus = ({ isColored, variant, statusLabel }) => {
  const { i18n } = useTranslation(['general']);
  const { language } = i18n;
  const theme = useTheme();
  const classes = useStyles();

  const { icon } = makeIconAndText(statusLabel.label_en);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" className={isColored ? classes.box : null}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          color={getColor(isColored, theme.palette.primary.main, theme.palette.secondary.main)}
          style={{ fontSize: 14 }}
        />
      )}
      <Box ml={2}>
        <Typography variant={variant} color={getColor(isColored, 'primary', 'secondary')}>
          {statusLabel[`label_${language || 'fr'}`]}
        </Typography>
      </Box>
    </Box>
  );
};

PlanningCandidateStatus.propTypes = {
  statusLabel: oneOfType([array, object]),
  variant: string,
  isColored: bool
};

PlanningCandidateStatus.defaultProps = {
  variant: 'body2',
  isColored: false,
  statusLabel: []
};

export default PlanningCandidateStatus;
