import React, { useMemo } from 'react';
import { pipe, groupBy, values, path, sortBy, filter, addIndex, uniqBy } from 'ramda';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { convertMinutesToTime } from 'utils/date';
import { Euro } from 'utils/number';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  date: {
    color: theme.palette.secondary.dark
  }
}));

const actionTypes = [
  'createdNewTimesheet',
  'editedTimesheet',
  'confirmedTimesheet',
  'automaticConfirmedTimesheet',
  'editedTimesheet'
];

const premiumTypes = [
  { type: 'minutes', format: convertMinutesToTime },
  { type: 'amount', format: Euro.format },
  { type: 'minutes', format: convertMinutesToTime },
  { type: 'minutes', format: (m) => m }
];

const filterWithIndex = addIndex(filter);

const formatHistory = (history) => {
  return pipe(
    sortBy((i) => i.date),
    filterWithIndex((i, index) => {
      if (!i.premium_code) {
        if (i.action_type_id === 1) return index === 0;
        return [3, 4].includes(i.action_type_id);
      }
      if ([1, 3, 4].includes(i.premium_code.smile_code_type)) {
        return Number(i.new_minutes_value) !== Number(i.old_minutes_value);
      }
      return Number(i.new_amount_value) !== Number(i.old_amount_value);
    }),
    groupBy((i) => i.date + i.action_type_id),
    values
  )(history);
};

const TimesheetHistory = ({ history }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const formattedHistory = useMemo(() => formatHistory(history), [history]);
  return (
    <Box display="flex" flexDirection="column-reverse" component="ul">
      {formattedHistory.map((entry) => {
        return (
          <li key={`${entry[0].date}-${entry[0].action_type_id}}`}>
            <Box>
              <span className={classes.date}>
                {format(new Date(entry[0].date.replace(' ', 'T')), t('general:fullDateFormat'))}
              </span>
              &nbsp;&nbsp;
              {t(`general:contract.${actionTypes[entry[0].action_type_id - 1]}`, { author: entry[0].customer_contact })}
              {[2, 5].includes(entry[0].action_type_id) && entry.find((ed) => ed.premium_code) && (
                <Box component="ul" mb={1}>
                  {uniqBy((item) => JSON.stringify(item), entry).map((edition) => {
                    const premium_date = new Date(edition.premium_code_date);
                    const premiumType = premiumTypes[path(['premium_code', 'smile_code_type'], edition) - 1];
                    const oldValue = edition[`old_${premiumType.type}_value`] || 0;
                    const newValue = edition[`new_${premiumType.type}_value`] || 0;
                    return (
                      <Box component="li" lineHeight="1.1" key={JSON.stringify(edition)}>
                        - {t(`helpers:dates.days.${premium_date.getDay()}`).slice(0, 2).toUpperCase()}{' '}
                        {premium_date.getDate()} {t(`helpers:dates.months.short.${premium_date.getMonth()}`)}
                        &nbsp;&nbsp;
                        {`${path(['premium_code', `description_${i18n.language}`], edition)}: 
                        ${oldValue ? `${premiumType.format(oldValue)} → ` : ''}${premiumType.format(newValue)}`}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </li>
        );
      })}
    </Box>
  );
};

TimesheetHistory.propTypes = {
  history: array.isRequired
};

export default TimesheetHistory;
