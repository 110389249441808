import React from 'react';
import { bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Rating } from '@material-ui/lab';

const StyledRatingMUI = withStyles(({ palette }) => ({
  iconEmpty: {
    borderColor: palette.primary.main
  },
  iconFilled: {
    color: palette.primary.main
  },
  iconHover: {
    color: palette.primary.main
  }
}))(Rating);

const StyledRating = ({ readOnly, ...props }) => {
  return (
    <StyledRatingMUI
      emptyIcon={!readOnly ? <StarBorderIcon color="primary" fontSize="inherit" /> : null}
      readOnly={readOnly}
      {...props}
    />
  );
};

StyledRating.propTypes = {
  readOnly: bool
};

StyledRating.defaultProps = {
  readOnly: false
};

export default StyledRating;
