import { flatten, isNil, pathOr, path as ramdaPath } from 'ramda';

export const transformValue = (type, multiple, value) => {
  // used in Select, Select.Items and Slider
  // transform value received into an array for easier manipulation
  // transform value emitted into a single string value if single, array if multiple
  const flat = !isNil(value) ? flatten([value]) : [];
  return type === 'out' && !multiple ? flat[0] : flat;
};

export const getError = (path, errors, nameSpace = 'validation') => {
  const errorName = pathOr(null, flatten([path]), errors);
  const link = nameSpace ? ':' : '';
  return errorName ? `${nameSpace || ''}${link}${errorName}` : null;
};

export const hasError = (fieldName, err) => !!ramdaPath([fieldName, 'message'], err);

export const getErrorOrOptional = (name, errors) =>
  hasError(name, errors) ? getError([name, 'message'], errors) : 'general:optional';
