import React, { useState, useEffect } from 'react';
import { array, bool, func } from 'prop-types';
import { Button, Box, Typography, Container, MenuItem } from '@material-ui/core';
import { Add as AddIcon, Restore as RestoreIcon } from '@material-ui/icons';
import { faHistory, faIndustry } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import { useDebounce, useHeader } from 'hooks';

import { SearchField } from 'components';

import { costCenterString } from 'utils/tempWorkers';
import IconAutocomplete from 'components/IconAutocomplete';
import IconSelect from 'components/IconSelect';
import { useAllowedActions, userRoles } from 'auth';
import { eventGA } from 'utils/analytics';
import HistoryButton from '../common/HistoryButton';
import DocumentTable from './DocumentTable';
import DocumentCategoryFilterOptions from '../common/documentFilterOptions';

const StyledHistoryButton = withStyles(() => ({
  root: {
    minWidth: 0,
    padding: 3
  }
}))(Button);

const DesktopView = ({ costCenters, documents, onRequest, getDocument, isLoading, getDocumentsList }) => {
  const { t } = useTranslation();
  const { search: headerSearch, setSearch: setHeaderSearch } = useHeader();
  const [search, setSearch] = useState({ cost_centers: [], document_type_id: 10, title: headerSearch, months: 'all' });
  const debouncedSearch = useDebounce(search, 400);
  const [costCentersOpen, setCostCentersOpen] = useState(false);

  const allowedCategories = useAllowedActions([userRoles.documentCenterAccountExtracts]);

  const handleEventGACenter = (centers) => {
    const lastCenter = centers[centers.length - 1];
    eventGA('document_CC_filter', costCenterString(lastCenter));
  };

  useEffect(() => {
    if (!isNil(debouncedSearch?.title)) {
      setHeaderSearch(debouncedSearch.title.trim());
    }
  }, [debouncedSearch, setHeaderSearch]);

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={5}>
          <Typography variant="h6" component="h2">
            {t('general:menu.documents')}
          </Typography>
          {allowedCategories?.documentCenterAccountExtracts && (
            <Button onClick={() => onRequest()} variant="contained" color="primary" startIcon={<AddIcon />}>
              {t('general:docs.requestDocument')}
            </Button>
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row" flexWrap="wrap" flex="1">
            <Box mr={4} flex="1" maxWidth={costCentersOpen || !!search.cost_centers.length ? '100%' : 233}>
              <IconAutocomplete
                multiple
                icon={faIndustry}
                options={costCenters}
                onOpen={() => setCostCentersOpen(true)}
                onClose={() => setCostCentersOpen(false)}
                getOptionLabel={costCenterString}
                value={search.cost_centers}
                noValueMessage={t('general:docs.allCostCenters')}
                onChange={(_, value) => {
                  handleEventGACenter(value);
                  setSearch((f) => ({ ...f, cost_centers: value }));
                }}
              />
            </Box>
            <Box mr={4} flexShrink="1" overflow="hidden">
              <DocumentCategoryFilterOptions
                value={search.document_type_id}
                onChange={(document_type_id) => {
                  eventGA('document_category_filter', t(`general:docs.category.${document_type_id}`));
                  setSearch((f) => ({ ...f, document_type_id }));
                }}
              />
            </Box>
            <Box mr={4}>
              <IconSelect
                icon={faHistory}
                defaultValue={search.months}
                onChange={(e) => {
                  eventGA('document_date_range_filter', e.target.value);
                  setSearch((f) => ({ ...f, months: e.target.value }));
                }}>
                <MenuItem value={3}>{t('general:orders.time.three')}</MenuItem>
                <MenuItem value={6}>{t('general:orders.time.six')}</MenuItem>
                <MenuItem value={12}>{t('general:orders.time.twelve')}</MenuItem>
                <MenuItem value="all">{t('general:docs.allDocs')}</MenuItem>
              </IconSelect>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <Box mr={4}>
              <SearchField
                value={search.title}
                onChange={(e) => {
                  e.persist();
                  eventGA('document_search', e.target.value);
                  setSearch((f) => ({ ...f, title: e.target.value }));
                }}
              />
            </Box>
            {allowedCategories?.documentCenterAccountExtracts && (
              <HistoryButton>
                <StyledHistoryButton size="small" variant="outlined">
                  <RestoreIcon />
                </StyledHistoryButton>
              </HistoryButton>
            )}
          </Box>
        </Box>
        <Box mt={4}>
          <DocumentTable
            data={documents}
            loadMore={getDocumentsList}
            isLoading={isLoading}
            getDocument={getDocument}
            search={search}
            debouncedSearch={debouncedSearch}
          />
        </Box>
      </Box>
    </Container>
  );
};

DesktopView.propTypes = {
  documents: array.isRequired,
  costCenters: array.isRequired,
  onRequest: func.isRequired,
  getDocument: func.isRequired,
  getDocumentsList: func.isRequired,
  isLoading: bool.isRequired
};

export default DesktopView;
