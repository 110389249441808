import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardActions, CardContent, LinearProgress, Typography } from '@material-ui/core';
import { pathOr, prop, path, clone, propOr, isEmpty } from 'ramda';
import { isSameDay } from 'date-fns';

import { days, getWeekDays } from 'utils/date';
import { useHeader } from 'hooks';

import { filterBonuses } from 'components/PerformanceSchedule/common';

import Prestations from 'components/Prestations';
import WeekSelector from 'components/WeekSelector/WeekSelector';
import HourCell from 'components/TempWorkerRow/HourCell';
import Status from './Status';
import TitleBar from '../common/TitleBar';
import RequestMenu from '../common/RequestMenu';

import styles from './TempWorkersListMobile.styles';

const TempWorkersListMobile = ({ classes, history, list, date, setDate, isLoading, onProlongationEvent }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);
  const { setComponents, renderSearch, setTitle } = useHeader();

  const weekDays = getWeekDays(date);
  const handleProlongation = (info) => {
    onProlongationEvent(info);
  };

  useEffect(() => {
    setTitle('general:menu.home');
    setComponents([renderSearch, () => <Prestations key="prestations" />]);
  }, [renderSearch, setComponents, setTitle]);

  const getHours = (day, data) => {
    const hours = pathOr({}, ['info', 'timesheetScheduleDays', days[day.getDay()]], data);
    const changedHours = path(['info', 'timesheetScheduleDays'], data);
    const allBonuses = clone(pathOr([], ['info', 'contractHasPremiumCodes'], data));
    const populatedBonuses = filterBonuses(allBonuses, changedHours);
    const filteredBonuses = clone(populatedBonuses).filter(
      (pc) => (pc.is_phantom === 0 || pc.premium_code === 2060) && pc.premium_code !== 1101
    );
    const validatedSources = !!filteredBonuses.find((b) => b.source === 2);
    const bonus = allBonuses.find((b) => b.premium_code === 1101 && isSameDay(new Date(b.date), day) && b.source === 2);
    if (validatedSources && !isEmpty(hours)) {
      const minutes = propOr(0, 'minutes', bonus);
      const newHours = { ...hours, performedMinutes: minutes, total_hours: minutes };
      return newHours;
    }
    return hours;
  };

  return (
    <>
      <Box width="100%" position="relative">
        <Box
          display="flex"
          height="40px"
          className={classes.header}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <WeekSelector dateChange={setDate} isMobile />
          <Box mr={4}>
            <Typography variant="body2" color="secondary">
              {`${list.reduce((acc, curr) => acc + pathOr(0, ['contracts', 'length'], curr), 0)} ${t(
                'general:deliveries'
              )}`}
            </Typography>
          </Box>
        </Box>
        <Box pt="40px" pb={18}>
          {isLoading && <LinearProgress />}
          {!!prop('length', list) &&
            list.map((center) => (
              <Box key={center.title}>
                <TitleBar title={center.title} />
                <Box pt={4} overflow="hidden">
                  {center.contracts.map((row) => {
                    return (
                      <Card
                        className={classes.card}
                        variant="outlined"
                        key={pathOr(row.id, ['info', 'contractId'], row)}>
                        <CardContent
                          onClick={() =>
                            history.push({
                              pathname: '/prestation',
                              state: { data: row, navigationBack: '' }
                            })
                          }>
                          <Typography variant="h6" component="h6">
                            {row.name}
                          </Typography>
                          <Status data={row} />
                          <table className={classes.table}>
                            <tbody>
                              <tr>
                                {weekDays.map((day) => (
                                  <td key={day.getDay()}>
                                    <Typography variant="body2">
                                      {t(`helpers:dates.days.${day.getDay()}`).slice(0, 2).toUpperCase()}
                                    </Typography>
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                {weekDays.map((day) => (
                                  <td key={day.getDay()}>
                                    <HourCell hours={getHours(day, row)} />
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </CardContent>
                        <CardActions className={classes.cardAction}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.cardActionButton}
                            onClick={() => handleProlongation({ ...row.info, id: row.id })}>
                            {t('tempWorkers:actions.extend')}
                          </Button>
                        </CardActions>
                      </Card>
                    );
                  })}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      <RequestMenu isMobile />
    </>
  );
};

TempWorkersListMobile.propTypes = {
  classes: object.isRequired,
  history: object.isRequired,
  list: array.isRequired,
  date: PropTypes.instanceOf(Date),
  setDate: func,
  isLoading: bool,
  onProlongationEvent: func
};

TempWorkersListMobile.defaultProps = {
  date: new Date(),
  setDate: () => null,
  onProlongationEvent: () => null,
  isLoading: false
};

export default withStyles(styles)(withRouter(TempWorkersListMobile));
