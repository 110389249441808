import { prop, path, equals } from 'ramda';
import store from '../store';
import * as service from './service';

export const workers = {
  state: {
    // initial state
    workers: null,
    search: null
  },
  reducers: {
    fetchedWorkers: (state, myWorkers, stack) => ({
      ...state,
      workers: [...(stack ? state.workers : []), ...myWorkers]
    }),
    setSearch: (state, search) => ({
      ...state,
      search
    })
  },
  effects: (_) => ({
    async fetchWorkers({ stack, ...params }) {
      const { page, ...search } = params;
      this.setSearch(search);
      if (page === 1) this.fetchedWorkers([]);
      const fetchedWorkers = await service.fetchWorkers(params);
      if (prop('length', fetchedWorkers) && equals(search, path(['workers', 'search'], store.getState()))) {
        this.fetchedWorkers(fetchedWorkers, stack);
        return fetchedWorkers.length === 50;
      }
      return false;
    }
  })
};
