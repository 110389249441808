/* istanbul ignore file */
import { useEffect, useRef } from 'react';

export default (value, init = false) => {
  const valueRef = useRef(init ? value : null);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
};
