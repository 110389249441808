import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { Container, Grid, Link, Typography, Box } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import UserShortForm from 'user/User.short.form';
import { Footer, Translate } from 'components';

const ForgotPassword = ({ isLoading, forgotPassword }) => {
  const { t } = useTranslation('auth');

  const submit = async (data) => {
    const success = await forgotPassword(data);
    if (success) toast(t('auth:forgotPasswordToast', { email: data.email }));
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
      <Box display="flex" flexDirection="column" width="100%">
        <Container maxWidth="lg">
          <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
            <Link component={RouterLink} to="/login" color="secondary">
              <Box display="flex" alignItems="center">
                <ArrowBackIos fontSize="small" />
                <Typography variant="body2">{t('auth:backToLogin')}</Typography>
              </Box>
            </Link>
            <Box>
              <Translate large isSelect />
            </Box>
          </Box>
        </Container>
        <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
          <Box mt={11}>
            <Container maxWidth="sm">
              <Box mb={3}>
                <Typography component="h5" variant="h5">
                  {t('auth:forgotPassword')}
                </Typography>
              </Box>
              <Box color="secondary.main" letterSpacing={0.5} mb={16}>
                <Typography>{t('auth:forgotPasswordText')}</Typography>
              </Box>
              <UserShortForm onSubmit={submit} submitting={isLoading} type="forgotPassword" />
            </Container>
          </Box>
        </Grid>
        <Footer />
      </Box>
    </GoogleReCaptchaProvider>
  );
};

ForgotPassword.propTypes = {
  isLoading: bool.isRequired,
  forgotPassword: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'forgotPassword'], loading)
});

const mapDispatch = (dispatch) => ({
  forgotPassword: dispatch.user.forgotPassword
});

export default connect(mapState, mapDispatch)(ForgotPassword);
