import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { array, bool, func } from 'prop-types';
import { isNil, pathOr } from 'ramda';
import { Container } from '@material-ui/core';

import { useHeader } from 'hooks';
import DesktopView from './Desktop/DesktopView';
import DesktopDetailView from './Desktop/DesktopDetailView';
import MobileView from './Mobile/MobileView';
import MobileDetailView from './Mobile/MobileDetailView';

const getDate = (months) => {
  if (!months || months === 'all') return null;
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() - months));
};

const Orders = ({ orders, getOrders, isFetchingOrders, isMobile, getCostCenters, costCenters }) => {
  const { orderId } = useParams();
  const { search: headerSearch } = useHeader();

  const [search, setSearch] = useState({ search: headerSearch, months: 3, cost_centers: [] });

  useEffect(() => {
    getCostCenters();
  }, [getCostCenters]);

  if (!isNil(orderId)) {
    return isMobile ? <MobileDetailView /> : <DesktopDetailView />;
  }

  return isMobile ? (
    <MobileView
      orders={orders}
      costCenters={costCenters}
      isLoading={isFetchingOrders}
      getOrders={getOrders}
      getDate={getDate}
      search={search}
      setSearch={setSearch}
    />
  ) : (
    <Container maxWidth="xl">
      <DesktopView
        orders={orders}
        costCenters={costCenters}
        isLoading={isFetchingOrders}
        getOrders={getOrders}
        getDate={getDate}
        search={search}
        setSearch={setSearch}
      />
    </Container>
  );
};

Orders.propTypes = {
  orders: array.isRequired,
  getOrders: func.isRequired,
  getCostCenters: func.isRequired,
  costCenters: array.isRequired,
  isMobile: bool.isRequired,
  isFetchingOrders: bool.isRequired
};

const mapState = ({ user, orders, loading, tempWorkers }) => ({
  isMobile: user.isOnMobile,
  orders: orders.list,
  costCenters: tempWorkers.costCenters,
  isFetchingOrders: pathOr(false, ['effects', 'orders', 'getOrders'], loading)
});

const mapDispatch = ({ orders: { getOrders }, tempWorkers: { getCostCenters } }) => ({ getOrders, getCostCenters });

export default connect(mapState, mapDispatch)(Orders);
