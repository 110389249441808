import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Img, StyledRating } from 'components';
import DaoustIcon from 'assets/icon-daoust.svg';
import PayrollIcon from 'assets/icon-payroll.svg';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import { getRank, isNotEmpty, useJobLabel, useCostCenter, useLastContractEnd } from '../common/common';

const useStyles = makeStyles((theme) => ({
  textColor: {
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 12
    }
  },
  cell: {
    padding: 10
  },
  borderBottomLess: {
    borderBottom: 'none'
  },
  iconColor: {
    color: theme.palette.secondary.main
  },
  bigIcon: {
    fontSize: 20
  }
}));

const WorkerDesktopRow = ({ workerData, onAskContract }) => {
  const { t, i18n } = useTranslation();
  const jobFunctionLabel = useJobLabel(workerData, i18n);
  const costCenterLabel = useCostCenter(workerData, i18n);
  const lastContractEnd = useLastContractEnd(workerData);
  const rank = getRank(workerData);
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={clsx(classes.cell, classes.borderBottomLess)}>
        <Img src={workerData.isPayroll === 'P' ? PayrollIcon : DaoustIcon} alt="" />
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" component="span" color="secondary">
          {workerData.fullname}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        {isNotEmpty(rank) && <StyledRating name="workerRating" size="small" readOnly value={rank} />}
      </TableCell>
      <TableCell className={classes.cell}>{jobFunctionLabel}</TableCell>
      <TableCell className={classes.cell}>{costCenterLabel}</TableCell>
      <TableCell className={classes.cell}>{!!lastContractEnd && lastContractEnd}</TableCell>
      <TableCell className={classes.cell} align="right">
        <Tooltip title={t('myWorkers:askForContract')}>
          <IconButton
            aria-label="prolong"
            onClick={() => {
              onAskContract(workerData);
            }}>
            <FontAwesomeIcon icon={faCalendarPlus} className={clsx(classes.iconColor, classes.bigIcon)} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

WorkerDesktopRow.propTypes = {
  workerData: object,
  onAskContract: func
};

WorkerDesktopRow.defaultProps = {
  workerData: null,
  onAskContract: () => {}
};

export default WorkerDesktopRow;
