import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default () => (
  <Box position="absolute" bottom="16px" width="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="body2" color="secondary">
      Copyright © daoust {new Date().getFullYear()}
    </Typography>
  </Box>
);
