export const formatType = ({ t, worker }) => {
  if (worker.is_flexi === 1) return t('general:pool.flexi');
  if (worker.is_student === 1) return t('general:pool.student');
  if (worker.is_activa === 1) return t('general:pool.activa');
  return '';
};

export const formatStatus = ({ t, worker }) => {
  if (worker.is_active === 1) return t('general:pool.active');
  if (worker.is_active === 0) return t('general:pool.selected');
  return '';
};

export const handleSelectWorker = ({ innerPool, setInnerPool, worker, isRemoved }) => {
  const list = innerPool.map((el) => {
    if (worker.id === el.id) {
      return {
        ...el,
        preselected: isRemoved ? false : !el?.preselected
      };
    }

    return el;
  });
  setInnerPool(list);
};
