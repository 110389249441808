import React, { useEffect, useState } from 'react';
import { array, bool, func } from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { Add, Restore } from '@material-ui/icons';
import { MobileFab } from 'components';
import { useHeader, useDebounce } from 'hooks';
import DocumentList from './DocumentList';
import HistoryButton from '../common/HistoryButton';

const MobileView = ({ costCenters, documents, onRequest, getDocumentsList, isLoading, getDocument }) => {
  const { setComponents, renderSearch, setTitle, search: headerSearch } = useHeader();
  const [search, setSearch] = useState({ cost_centers: [], document_type_id: 10, months: 'all', title: headerSearch });
  const debouncedSearch = useDebounce(search, 400);

  useEffect(() => {
    setSearch((s) => ({ ...s, title: headerSearch }));
  }, [headerSearch]);

  useEffect(() => {
    setTitle('general:menu.documents');
    setComponents([
      renderSearch,
      () => (
        <HistoryButton key="history">
          <IconButton>
            <Restore />
          </IconButton>
        </HistoryButton>
      )
    ]);
  }, [setComponents, renderSearch, setTitle]);

  return (
    <Box position="relative" width="100%">
      <Box width="100%" position="absolute" top="0" left="0" pb={22}>
        <DocumentList
          data={documents}
          loadMore={getDocumentsList}
          isLoading={isLoading}
          getDocument={getDocument}
          search={search}
          debouncedSearch={debouncedSearch}
          onSearch={setSearch}
          costCenters={costCenters}
        />
        <MobileFab color="primary" aria-label="add" onClick={() => onRequest()}>
          <Add />
        </MobileFab>
      </Box>
    </Box>
  );
};

MobileView.propTypes = {
  documents: array.isRequired,
  costCenters: array.isRequired,
  onRequest: func.isRequired,
  getDocumentsList: func.isRequired,
  isLoading: bool.isRequired,
  getDocument: func.isRequired
};

export default MobileView;
