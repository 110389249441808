import { sum, isNil, prop, propOr, uniqBy } from 'ramda';
import { isValidDate } from 'ramda-adjunct';
import dateEaster from 'date-easter';
import { add, format, getWeek, isWeekend, isSameDay, startOfWeek } from 'date-fns';
import { twoDigits } from './number';

export const localeDateFormat = 'dd/MM/yyyy';

export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const mondayWeek = [6, 0, 1, 2, 3, 4, 5];

export const getDate = (months) => {
  if (!months || months === 'all') return null;
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() - months));
};

/**
 * Returns ISO 8601 date string from Date object (format: yyyy-MM-dd)
 * @param  {Date}  Date object
 * @return {String}
 */
export const toIsoDateString = (date) => format(date, 'yyyy-MM-dd');

/**
 * Returns list of Date object for a given week (7 days)
 * @param  {Date}  Date object as reference
 * @param  {Number} weekStartsOn index for start day of the week (defaults to 1, monday)
 * @return {Object} list of Date objects
 */
export const getWeekDays = (date, weekStartsOn = 1) =>
  [1, 2, 3, 4, 5, 6, 0].map((day) => {
    const lastMonday = startOfWeek(date, { weekStartsOn });
    const diffFromMonday = (day - 1 + 7) % 7; // Monday as the first day of the week and avoid negative numbers
    const _date = new Date(lastMonday);
    return new Date(_date.setDate(_date.getDate() + diffFromMonday));
  });

export const getWeekNumber = (date = new Date()) =>
  getWeek(date, {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  });

export const hoursFromMinutes = (time, isSum = false) => {
  const sign = time < 0 ? '-' : '+';
  const absTime = Math.abs(time);
  return `${isSum ? sign : ''}${Math.floor(absTime / 60)}h${twoDigits(absTime % 60)}`;
};
export const timefromfloat = (float) => {
  return `${twoDigits(parseInt(float, 10))}h${
    float.toString().includes('.') ? twoDigits(parseInt(float.toString().split('.')[1], 10)) : '00'
  }`;
};

export const areSameHours = (date1, date2) => {
  if (!date1 || !date2) return false;
  return date1.getHours() * 100 + date1.getMinutes() === date2.getHours() * 100 + date2.getMinutes();
};

/**
 * Get date time string from float number (current day)
 * @param  {Number} floatng point number
 * @return {string} "MM/DD/YY hh:mm:ss"
 */
export const datetimefromfloat = (float) => {
  let dateTimeString = null;
  const makeMinute = (num) => (num < 10 ? 10 * num : num);
  if (float && typeof float === 'number') {
    const date = new Date().toISOString();
    dateTimeString = `${date.split('T')[0]} ${twoDigits(parseInt(float, 10))}:${
      float.toString().includes('.') ? twoDigits(makeMinute(parseInt(float.toString().split('.')[1], 10))) : '00'
    }:00`;
  }
  return dateTimeString;
};

/**
 * Formats a date
 * @param  {Date|string}  _date  Date à formatter
 * @param  {Boolean} [time=true] booléen qui définit si on ajoute la date
 * @return {string} "DD/MM/YY" ou "MM/DD/YY hh:mm:ss"
 */
export const formatDate = (_date, time = true) => {
  if (!_date) return null;
  const date = new Date(_date);
  return `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}${
    time ? ` ${twoDigits(date.getUTCHours())}:${twoDigits(date.getMinutes())}:${twoDigits(date.getSeconds())}` : ''
  }`;
};

/**
 * format time string with format 'hh:mm[:ss]' to "hh'h'mm"
 * @param  {Date}  `Date` object to format
 * @return {string} "HH'h'mm"
 */
export const formatHour = (h) => {
  let time = '00h00';
  const regex = /^[0-9]{2}:[0-9]{2}/;
  if (typeof h === 'string' && regex.test(h.trim())) {
    const [hrs, mins] = h.trim().split(':');
    time = hrs && mins ? `${hrs.padStart(2, '0')}h${mins.padStart(2, '0')}` : time;
  }
  return time;
};

/**
 * Convert minutes time string with format 'hh:mm'
 * returns empty string if number is falsy.
 * @param  {number}  minutes as intiger
 * @return {string} time string
 */
export const convertMinutesToTime = (n, delim = ':') =>
  n ? `${Math.floor(n / 60)}${delim}${twoDigits(n % 60)}` : `00${delim}00`; // eslint-disable-line no-bitwise

/**
 * Get Schedule List and return total hours as string with format "HH'h'mm"
 * @param  {Object}  `scheduleDays` list with object and property`contractHours`
 * @return {string} "HH'h'mm"
 */
export const formatTotalHours = (scheduleDays) => {
  let total = '00h00';
  if (Array.isArray(scheduleDays)) {
    const sumMinutes = sum(
      uniqBy(prop('dayNumber'), scheduleDays).map((n) => {
        if (n.contractHour) {
          const split = n.contractHour.toFixed(2).split('.');
          return parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
        }
        return 0;
      })
    );
    total = convertMinutesToTime(sumMinutes, 'h');
  }
  return total;
};

export const valueToMinutes = (val) => (val.getHours ? val.getHours() * 60 + val.getMinutes() : 0);

export const minutesToPickerValue = (minutes) => {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(minutes || 0);
  return date;
};

export const formatMonthAndYear = (date) => {
  return date ? `${twoDigits(date.getMonth() + 1)}/${date.getFullYear()}` : '';
};

export const getDayPickerValue = (hours, day) => {
  const dayName = days[day.getDay()];
  const storedDayHours = hours[dayName] || null;
  const contractHours = prop('total_hours', storedDayHours);
  return !isNil(contractHours) ? minutesToPickerValue(parseInt(contractHours, 10)) : null;
};

/**
 * Get Datetime and return float number with format 'HH.mm'
 * @param  {Date}  `Date` object to format
 * @return {string} "HH.mm"
 */
export const makeFloat = (datetime) => (isValidDate(datetime) ? parseFloat(format(datetime, 'HH.mm')) : null);

/**
 * Get Datetime and return (UTC) time concatinated as integer (hours and minutes)
 * @param  {Date}  `Date` object to format
 * @return {number}
 */
export const timeInteger = (d, utc = true) => {
  const intTime = utc
    ? parseInt(`${d.getUTCHours()}${d.getUTCMinutes().toString().padStart(2, '0')}`, 10)
    : parseInt(`${d.getHours()}${d.getMinutes().toString().padStart(2, '0')}`, 10);
  return intTime !== 0 && intTime >= 60
    ? intTime
    : parseInt(
        `24${utc ? d.getUTCMinutes().toString().padStart(2, '0') : d.getMinutes().toString().padStart(2, '0')}`,
        10
      );
};

/**
 * Check if given date falls on a work week
 * @param  {Date}  `Date` object to format
 * @return {Boolean}
 */
export const isWorkDay = (date = new Date(), ignoreHours = false, ignoreWeekend = false) => {
  const withHoursOr = (cond) => (ignoreHours ? cond : cond && timeInteger(date) > 830 && timeInteger(date) < 1700);
  const currentYear = date.getFullYear();
  const isNotHoliday = [
    new Date(`${currentYear}-01-01`),
    new Date(dateEaster.easter(currentYear).toString()),
    new Date(`${currentYear}-05-01`),
    new Date(`${currentYear}-07-21`),
    new Date(`${currentYear}-08-15`),
    new Date(`${currentYear}-11-01`),
    new Date(`${currentYear}-11-11`),
    new Date(`${currentYear}-12-25`)
  ]
    .map((d) => isSameDay(d, date))
    .every((bool) => bool === false);
  let condition;
  if (ignoreWeekend) {
    condition = withHoursOr(isNotHoliday);
  } else {
    condition = withHoursOr(!isWeekend(date) && isNotHoliday);
  }
  return condition;
};

export const getPreviousWorkDay = (date) => {
  const prevDay = new Date(date);
  prevDay.setDate(prevDay.getDate() - 1);
  return isWorkDay(prevDay, true, false) ? prevDay : getPreviousWorkDay(prevDay);
};

const getFormattedHour = (date) => {
  if (!date) return null;
  const hourValues = [date.getHours(), date.getMinutes(), date.getSeconds()];
  return hourValues.map((value) => value.toString().padStart(2, '0')).join(':');
};

const getHourFrom = (propName) => (schedule) => getFormattedHour(schedule[propName]);
const getStartAm = getHourFrom('startAm');
const getEndAm = getHourFrom('endAm');
const getStartPm = getHourFrom('startPm');
const getEndPm = getHourFrom('endPm');
const getTotalHours = ({ contractHour }) =>
  !contractHour || !isValidDate(contractHour) ? 0 : contractHour.getHours() * 60 + contractHour.getMinutes();

/** Convert schedule list to appropiate stringified format
 *  (API)
 */
export const formatScheduleListHours = (list) => {
  if (!list) return [];
  const formatedlist = uniqBy(prop('dayNumber'), list)?.map((scheduleValue) => {
    const formatedScheduleValue = {
      day: days[[1, 2, 3, 4, 5, 6, 0][scheduleValue.dayNumber]],
      start_am: getStartAm(scheduleValue),
      end_am: getEndAm(scheduleValue),
      start_pm: getStartPm(scheduleValue),
      end_pm: getEndPm(scheduleValue),
      total_hours: getTotalHours(scheduleValue)
    };
    return formatedScheduleValue;
  });
  return formatedlist;
};

export const getNextWorkingDay = (date = new Date(), fromCurrent = false) => {
  let nextDay = fromCurrent ? new Date(date) : add(new Date(date), { days: 1 });
  nextDay.setHours(0);
  nextDay.setMinutes(0);
  nextDay.setSeconds(0);
  while (!isWorkDay(nextDay, true)) {
    nextDay = add(nextDay, { days: 1 });
  }
  return nextDay;
};

export const getRange = (data) => {
  let from = null;
  let to = null;
  if (data?.from && data?.scheduleDays) {
    const weekDays = getWeekDays(data.from);
    const orderedScheduledays = data.scheduleDays.sort((a, b) => a.dayNumber - b.dayNumber);
    from = propOr(null, orderedScheduledays?.[0]?.dayNumber, weekDays);
    to = propOr(null, orderedScheduledays?.[(orderedScheduledays?.length || 0) - 1]?.dayNumber, weekDays);
  }
  return { from, to };
};

export const convertTimeStringToHoursAndMinutes = (timeString, splitter) => {
  const [hours, minutes] = timeString ? timeString.split(splitter) : ['00', '00'];
  return {
    hours: Number(hours),
    minutes: Number(minutes)
  };
};

export const getContractDate = (dateVal) => (Array.isArray(dateVal) ? dateVal[0] : dateVal);

export const formatPoolDates = (weekDays, formatScheduleList) => {
  return weekDays.filter((day) => {
    const shortDayName = new Date(day).toDateString().slice(0, 3).toLowerCase();
    const foundInSchedule = formatScheduleList.find((sch) =>
      days[[1, 2, 3, 4, 5, 6, 0][sch.dayNumber]].includes(shortDayName)
    );
    return !!foundInSchedule;
  });
};
