import { toast } from 'react-toastify';
import { pathOr, propOr, prop, path, equals } from 'ramda';
import { cleanObj } from 'utils/object';
import i18n from 'translate/i18n';
import { getDate } from 'utils/date';
import { openFile } from 'utils/file';
import store from '../store';

import * as service from './service';

export const documents = {
  state: {
    list: [],
    history: [],
    search: null
  },
  reducers: {
    gotList: (state, list, stack) => ({
      ...state,
      list: [...(stack ? state.list : []), ...list]
    }),
    gotHistory: (state, history) => ({ ...state, history }),
    setSearch: (state, search) => ({
      ...state,
      search
    })
  },
  effects: (dispatch) => {
    return {
      async getDocumentsList({ stack, ...params }) {
        const { page, ...search } = params;
        this.setSearch(search);
        if (page === 1) this.gotList([]);
        const docs = await service.getDocumentsList({ ...params, date: getDate(params.months) });
        if (prop('length', docs) && equals(search, path(['documents', 'search'], store.getState()))) {
          this.gotList(docs, stack);
          return docs.length === 50;
        }
        return false;
      },
      async getDocument(uuid, rootState) {
        const requestedDoc = pathOr([], ['documents', 'list'], rootState).find((doc) => doc.uuid === uuid);
        openFile(async () => service.getDocument({ uuid }), propOr('document', 'file_name', requestedDoc));
      },
      async requestDocument(payload) {
        const data = await service.requestDocument(cleanObj(payload));
        if (data) dispatch.events.sendMessage(i18n.t('general:documentDemandSuccessfull'));
        else toast.error(i18n.t('errors:failedDocumentDemand'));
      },
      async getHistory() {
        const data = await service.getHistory();
        if (data) this.gotHistory(data.sort((a, b) => b.created_at.localeCompare(a.created_at)));
      }
    };
  }
};
