/* istanbul ignore file */
import { regex } from 'utils/validation';
import { prop, propOr } from 'ramda';

export default ({ passwordRequired = false, passwordValidation = true, getValues = () => null }) => ({
  picture: {
    validate: {
      type: (file) => {
        const type = propOr('', 'type', file);
        return file && (!type.startsWith('image') || type.includes('svg')) ? 'profilePicType' : true;
      },
      size: (file) => (prop('size', file) > 60000 ? 'profilePicTooLarge' : true)
    }
  },
  firstName: {
    required: 'firstNameRequired',
    pattern: {
      value: regex.name,
      message: 'firstNameInvalid'
    }
  },
  lastName: {
    required: 'lastNameRequired',
    pattern: {
      value: regex.name,
      message: 'lastNameInvalid'
    }
  },
  email: {
    required: 'emailRequired',
    pattern: {
      value: regex.email,
      message: 'emailInvalid'
    }
  },
  password: {
    required: passwordRequired ? 'passwordRequired' : false,
    ...(passwordValidation
      ? {
          pattern: {
            value: regex.password,
            message: 'passwordInvalid'
          }
        }
      : {})
  },
  passwordConfirmation: {
    required: passwordRequired ? 'passwordConfirmationRequired' : false,
    validate: (value) => value === getValues().password || 'passwordShouldMatch'
  }
});
