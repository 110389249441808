/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useHeader } from 'hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  LinearProgress,
  Checkbox,
  Button,
  TableContainer,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import DaoustIcon from 'assets/icon-daoust.svg';
import PayrollIcon from 'assets/icon-payroll.svg';
import { Img } from 'components/Img/Img';
import { formatStatus, formatType, handleSelectWorker } from './newContractPool.utils';

const useStyles = makeStyles(() => ({
  loadingRow: {
    '& th,td': {
      borderBottom: 0,
      paddingBottom: 0,
      padding: 0
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  root: {
    width: '100%'
  },
  containerTable: {
    flex: 1
  },
  ghost: {
    display: 'none'
  },
  cell: {
    padding: '6px 24px 6px 8px;'
  },
  status: {
    marginTop: 3
  }
}));

const NewContractPoolMobile = ({
  innerPool,
  setInnerPool,
  isPoolLoading,
  preSelectedWorkers,
  setViewType,
  viewType,
  nbrActive,
  nbrSelected,
  nbrPayroll,
  data
}) => {
  const { t } = useTranslation();
  const { setComponents, setTitle, renderSearch } = useHeader();
  const classes = useStyles();

  useEffect(() => {
    setTitle('general:pool.title');
    setComponents([renderSearch]);
  }, [setComponents, setTitle, renderSearch]);

  return (
    <>
      <Box position="relative" width="100%">
        <Box position="absolute" top="0" left="0" bottom="0" display="flex" flexDirection="column" width="100%">
          <Box position="sticky" bgcolor="light.dark" p={4}>
            <Typography variant="body2" component="span" color="secondary">
              {t('general:pool.availableWorkers.subtitle')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" pt={1}>
            <Button
              disableElevation
              flex={1}
              size="small"
              color={viewType === 'active' ? 'primary' : 'default'}
              variant={viewType === 'active' ? 'contained' : 'text'}
              onClick={() =>
                setViewType((v) => (['default', 'selected', 'payroll'].includes(v) ? 'active' : 'default'))
              }>
              {t('general:pool.active')} ({nbrActive})
            </Button>
            <Button
              disableElevation
              color={viewType === 'selected' ? 'primary' : 'default'}
              variant={viewType === 'selected' ? 'contained' : 'text'}
              flex={1}
              size="small"
              onClick={() =>
                setViewType((v) => (['default', 'active', 'payroll'].includes(v) ? 'selected' : 'default'))
              }>
              {t('general:pool.selected')} ({nbrSelected})
            </Button>
            <Button
              disableElevation
              color={viewType === 'payroll' ? 'primary' : 'default'}
              variant={viewType === 'payroll' ? 'contained' : 'text'}
              flex={1}
              size="small"
              onClick={() =>
                setViewType((v) => (['default', 'active', 'selected'].includes(v) ? 'payroll' : 'default'))
              }>
              {t('general:payroll.title')} ({nbrPayroll})
            </Button>
          </Box>
          <Box flex={1} overflow="hidden" display="flex">
            <TableContainer className={classes.containerTable}>
              <Table aria-label="pool table" stickyHeader>
                <TableBody>
                  {isPoolLoading && (
                    <TableRow className={classes.loadingRow}>
                      <TableCell colSpan={100} className={classes.loadingRow}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {innerPool &&
                    innerPool.map((worker) => (
                      <TableRow key={worker.uuid} className={worker.display ? '' : classes.ghost}>
                        <TableCell>
                          <Checkbox
                            onChange={() => handleSelectWorker({ innerPool, setInnerPool, worker })}
                            color="primary"
                            checked={worker.preselected}
                          />
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Box mb={1}>
                            <Typography variant="body2" component="span" color="secondary">
                              {worker.fullname}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body2" component="span" color="secondary">
                              {formatType({ t, worker })}
                            </Typography>
                          </Box>
                          <Box display="flex" justifyContent="space-between" align-items="center">
                            <Typography variant="body2" component="span" color="secondary" className={classes.status}>
                              {formatStatus({ t, worker })}
                            </Typography>
                            <Img src={worker.isPayroll === 'P' ? PayrollIcon : DaoustIcon} alt="" />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box bgcolor="light.dark" p={4}>
            <Typography variant="body2" component="span" color="secondary">
              {t('general:pool.selectedWorkers.title')} ({preSelectedWorkers.length} / {data?.vacancies})
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

NewContractPoolMobile.propTypes = {
  innerPool: array,
  setInnerPool: func.isRequired,
  isPoolLoading: bool.isRequired,
  preSelectedWorkers: array.isRequired,
  viewType: string.isRequired,
  setViewType: func.isRequired,
  nbrActive: number.isRequired,
  nbrSelected: number.isRequired,
  nbrPayroll: number.isRequired,
  data: object
};

NewContractPoolMobile.defaultProps = {
  innerPool: null,
  data: null
};

export default NewContractPoolMobile;
