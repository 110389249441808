import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { createStyles, withStyles } from '@material-ui/styles';
import { node, object, func, string } from 'prop-types';
import { faSortAlphaDown, faSortAlphaDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const getTitle = (order, field, values) => {
  const [v1, v2, v3] = values;
  let title = v1;
  const currDir = order?.order_by_direction;
  if (order?.order_by_field === field) {
    if (currDir === 'ASC') title = v2;
    if (currDir === 'DESC') title = v3;
  }
  return title;
};

const Content = withStyles(
  createStyles(() => ({
    root: {
      padding: '8px',
      display: 'inline-block'
    },
    click: {
      cursor: 'pointer'
    }
  }))
)(({ classes, clickable, ...props }) => {
  return <span {...props} className={clsx(classes.root, { [classes.click]: clickable })} />;
});

const HeadCell = withStyles(
  createStyles(() => ({
    root: {
      padding: 0
    }
  }))
)((props) => {
  return <TableCell color="default" {...props} />;
});

const Icon = withStyles(
  createStyles((theme) => ({
    iconColor: {
      color: theme.palette.secondary.main,
      marginLeft: 6
    }
  }))
)(({ classes, ...props }) => {
  return <FontAwesomeIcon {...props} className={classes.iconColor} />;
});

const OrderHeadCell = ({ order, onOrder, field, children, ...props }) => {
  const { t } = useTranslation();
  const title = field ? t(getTitle(order, field, ['orderAsc', 'orderDesc', 'removeOrder'])) : '';
  return (
    <HeadCell {...props}>
      <Tooltip title={title}>
        <span>
          <Content
            clickable={field}
            onClick={() => {
              const direction = getTitle(order, field, ['ASC', 'DESC', null]);
              onOrder({ order_by_field: direction ? field : null, order_by_direction: direction });
            }}>
            {children}
          </Content>
        </span>
      </Tooltip>
      {field === order.order_by_field && (
        <Icon icon={order.order_by_direction === 'ASC' ? faSortAlphaDown : faSortAlphaDownAlt} />
      )}
    </HeadCell>
  );
};

OrderHeadCell.propTypes = {
  order: object,
  onOrder: func,
  children: node,
  field: string
};

OrderHeadCell.defaultProps = {
  order: {},
  onOrder: () => null,
  field: null,
  children: null
};

export default OrderHeadCell;
