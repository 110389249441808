import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Container, Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import { ArrowBackIos } from '@material-ui/icons';
import { func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Footer, Translate } from 'components';

const Gdpr = ({ logout, acceptGdpr, classes }) => {
  const { t, i18n } = useTranslation('general');
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Container maxWidth="lg">
        <Box mt={4} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" className={classes.back} onClick={() => logout()}>
            <ArrowBackIos color="secondary" fontSize="small" />
            <Typography variant="body2" color="secondary">
              {t('auth:backToLogin')}
            </Typography>
          </Box>
          <Box>
            <Translate large hasAcceptedGdpr={false} isSelect />
          </Box>
        </Box>
      </Container>
      <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
        <Box mt={11}>
          <Container maxWidth="sm">
            <Box mb={3}>
              <Typography component="h5" variant="h5">
                {t('auth:welcome')}
              </Typography>
            </Box>
            <Box color="secondary.main" letterSpacing={0.5} mb={16}>
              <Typography>
                <Trans i18nKey="auth:gdpr">
                  <a
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      i18n.language === 'fr'
                        ? 'https://www.daoust.be/fr/privacy-traitement-des-donnees-a-caractere-personnel/'
                        : 'https://www.daoust.be/nl/privacy-verwerking-van-persoonsgegevens/'
                    }>
                    /
                  </a>
                  <a
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      i18n.language === 'fr'
                        ? 'https://www.daoust.be/fr/conditions-generales/'
                        : 'https://www.daoust.be/nl/algemene-voorwaarden/'
                    }>
                    /
                  </a>
                </Trans>
              </Typography>
              <Box mt={8}>
                <Button
                  name="confirm"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth
                  onClick={() => acceptGdpr()}>
                  {t('general:accept')}
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Grid>
      <Footer />
    </Box>
  );
};

Gdpr.propTypes = {
  logout: func.isRequired,
  acceptGdpr: func.isRequired,
  classes: object.isRequired
};

const mapState = () => ({});

const mapDispatch = (dispatch) => ({
  logout: dispatch.user.logout,
  acceptGdpr: dispatch.user.acceptGdpr
});

export default pipe(
  withStyles((theme) => ({
    back: {
      cursor: 'pointer'
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'none',
      fontSize: '1.6rem'
    }
  })),
  connect(mapState, mapDispatch)
)(Gdpr);
