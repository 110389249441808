export const privacyLinks = {
  fr: 'https://www.daoust.be/fr/privacy-traitement-des-donnees-a-caractere-personnel',
  en: 'https://www.daoust.be/en/privacy-processing-of-personal-data',
  nl: 'https://www.daoust.be/nl/privacy-verwerking-van-persoonsgegevens'
};

export const conditionsLinks = {
  fr: 'https://www.daoust.be/wp-content/uploads/2020/08/Conditions-g%C3%A9n%C3%A9rales-2020.pdf',
  en: 'https://www.daoust.be/wp-content/uploads/2019/08/GC_EN.pdf',
  nl: 'https://www.daoust.be/wp-content/uploads/2020/08/Conditions-g%C3%A9n%C3%A9rales-2020-NL.pdf'
};
