import NewContract from 'components/NewContract/NewContract';
import { bool, func, number, oneOf } from 'prop-types';
import { pathOr, prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getContractType } from 'utils/contract';
import { formatScheduleListHours, getContractDate, toIsoDateString } from 'utils/date';

const NewContractHandler = ({
  dumpCandidate,
  extendContract,
  createNewContract,
  isEditing,
  orderId,
  actionType,
  ...props
}) => {
  const [isPreconfirmed, setIsPreconfirmed] = useState(false);

  useEffect(() => {
    return dumpCandidate;
  }, [dumpCandidate]);

  const handleExistingWorkerContract = async ({
    data,
    secondData,
    mergedParentContract,
    isExtension,
    range,
    description,
    onClose,
    handleAfterCreateUpdateOrder
  }) => {
    let contract;
    if (prop('id', mergedParentContract) && isExtension) {
      contract = await extendContract({
        contractId: mergedParentContract.id,
        costCenterId: data.costCenter ? data.costCenter.id : null,
        jobFunctionId: data.jobFunction.id,
        start: toIsoDateString(getContractDate(range.from)),
        end: toIsoDateString(getContractDate(range.to)),
        scheduleDays: formatScheduleListHours(data.scheduleDays),
        orderId,
        isEditing
      });
    } else {
      contract = await createNewContract({
        description,
        hourlyRate: pathOr(null, ['salaryAmount'], secondData),
        costCenterId: pathOr(null, ['costCenter', 'id'], data),
        jobFunctionId: data.jobFunction.id,
        start: toIsoDateString(getContractDate(range.from)),
        end: toIsoDateString(getContractDate(range.to)),
        reasonId: secondData.reason,
        reference: pathOr(null, ['reference'], data),
        candidateId: data.interimWorker.id,
        isStudent: [5, 6].includes(parseInt(secondData.status, 10)) ? 1 : 0,
        isStudentContingent: [9, 10].includes(parseInt(secondData.status, 10)) ? 1 : 0,
        scheduleDays: formatScheduleListHours(data.scheduleDays),
        orderId,
        isEditing,
        ...getContractType(secondData)
      });
    }
    onClose();
    handleAfterCreateUpdateOrder(contract);
  };

  return (
    <NewContract
      isNewWorker={false}
      isPreconfirmed={isPreconfirmed}
      setIsPreconfirmed={setIsPreconfirmed}
      handleExistingWorkerContract={handleExistingWorkerContract}
      isEditing={isEditing}
      orderId={orderId}
      actionType={actionType}
      {...props}
    />
  );
};

NewContractHandler.propTypes = {
  dumpCandidate: func.isRequired,
  extendContract: func.isRequired,
  createNewContract: func.isRequired,
  orderId: number,
  isEditing: bool,
  actionType: oneOf(['create', 'update', 'orderAgain', 'default']).isRequired
};

NewContractHandler.defaultProps = {
  isEditing: false,
  orderId: null
};

const mapDispatch = (dispatch) => ({
  dumpCandidate: () => dispatch.contracts.dumpCandidate(),
  extendContract: (data) => dispatch.contracts.extendContract(data),
  createNewContract: (data) => dispatch.contracts.postContract(data)
});

export default connect(null, mapDispatch)(NewContractHandler);
