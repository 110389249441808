/* eslint max-lines: 0, complexity: 0 */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useModal } from 'hooks';
import { Box, Button, Container, Divider, IconButton, TextField, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bool, number, object, func, array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { any, has, isNil, pathOr, propOr } from 'ramda';
import { startOfWeek, endOfWeek } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Banner, ConfirmDialog, WorkerCard, ConfirmScreen, HoursDialog } from 'components';
import { ScheduleFormTablet, ScheduleFormDesktop } from 'components/ScheduleForm';
import {
  formatScheduleListHours,
  formatTotalHours,
  getNextWorkingDay,
  isWorkDay,
  toIsoDateString,
  getRange
} from 'utils/date';
import { asFloatingHours } from 'utils/tempWorkers';

import { isValidDate } from 'ramda-adjunct';
import ExtendForm from './ExtendForm';

const useStyles = makeStyles((theme) => ({
  footerStyle: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const getButtonLabel = (isNext, isEditing) => {
  if (!isNext) return 'general:next';
  if (isEditing) return 'general:contract.editContract';
  return 'general:contract.orderContract';
};

const ifKeyExists = (data, key, ifNot) => (has(key, data) ? data[key] : ifNot);
const nextWorkingDay = getNextWorkingDay();

const getBoxWidth = (isTablet) => (isTablet ? 1 : 1 / 2);

const NewProlongation = ({
  getCostCenters,
  isMobile,
  isTablet,
  savedCandidate,
  dumpCandidate,
  extendContract,
  overrideIsNext,
  defaultSchedule,
  orderId,
  isEditing,
  getReasons,
  reasonsList,
  isLoading,
  order
}) => {
  const classes = useStyles({ isMobile });
  const { onClose, setLeftBtn } = useModal();
  const tzDate = zonedTimeToUtc(new Date(), { timeZone: 'Europe/Berlin' });
  const { t, i18n } = useTranslation(['general']);
  const { language } = i18n;
  const [data, setData] = useState(defaultSchedule ? { scheduleDays: defaultSchedule } : {});
  const [totalHours, setTotalHours] = useState('');
  const [description, setDescription] = useState(savedCandidate?.description || '');
  const [isNext, setIsNext] = useState(overrideIsNext);
  const [hasComeBack, setHasComeBack] = useState(false);
  const [isPreconfirmed, setIsPreconfirmed] = useState(false);
  const [hasScheduleTimeError, setHasScheduleTimeError] = useState(false);
  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [invalidHours, setInvalidHours] = useState({});
  const [reason, setReason] = useState();
  const [isValid, setIsValid] = useState(true);
  const [defaultReason, setDefaultReason] = useState();

  const range = useMemo(() => getRange(data), [data]);

  useEffect(() => {
    getReasons(language);
  }, [getReasons]);

  const checkValidHours = useCallback((values) => {
    const formattedValues = formatScheduleListHours(values);
    //  check if less than total_hours 50 * 60  = 3000 ?
    const totalWeekHours = formattedValues.reduce((acc, curr) => {
      const totalHoursCurrent = curr.total_hours;
      return acc + totalHoursCurrent;
    }, 0);
    // Check if any total_hours is over 11 * 60
    const anyOver11Hours = any((d) => d.total_hours > 11 * 60, formattedValues);
    const anyOver9Hours = any((d) => d.total_hours > 9 * 60 && d.total_hours <= 11 * 60, formattedValues);
    return {
      totalOver50: totalWeekHours > 50 * 60,
      totalOver40: totalWeekHours > 40 * 60 && totalWeekHours <= 50 * 60,
      anyOver11Hours,
      anyOver9Hours
    };
  }, []);

  const switchConfirmation = () => {
    const invalidHoursObj = checkValidHours(data.scheduleDays);
    setInvalidHours(invalidHoursObj);
    if (
      invalidHoursObj.totalOver50 ||
      invalidHoursObj.totalOver40 ||
      invalidHoursObj.anyOver11Hours ||
      invalidHoursObj.anyOver9Hours
    ) {
      // show message to say that it can't be sent to Matchbox, allow contract modification.
      return setShowModifyDialog(true);
    }
    return setIsPreconfirmed(true);
  };

  const handleScheduleChange = (scheduleDays) => {
    const floatingSchedule = asFloatingHours(scheduleDays);
    setTotalHours(formatTotalHours(floatingSchedule));
    setData({
      ...data,
      scheduleDays
    });
  };

  const handleNext = () => {
    if (isNext) {
      return switchConfirmation();
    }
    if (!reason) {
      return setIsValid(false);
    }
    return setIsNext(true);
  };
  const handleTimeErrorChange = (errorBool) => setHasScheduleTimeError(errorBool);
  const getScheduleForm = () => {
    let { from } = data;
    from = from >= nextWorkingDay ? from : nextWorkingDay;
    return isTablet ? (
      <Box mx={[-4, -3]}>
        <ScheduleFormTablet
          date={from}
          dateLimit={data.to}
          onScheduleChange={handleScheduleChange}
          onTimeError={handleTimeErrorChange}
          savedSchedule={data?.scheduleDays}
          defaultSchedule={savedCandidate?.scheduleDays}
        />
      </Box>
    ) : (
      <ScheduleFormDesktop
        date={from}
        dateLimit={data.to}
        onScheduleChange={handleScheduleChange}
        onTimeError={handleTimeErrorChange}
        savedSchedule={data?.scheduleDays}
        defaultSchedule={savedCandidate?.scheduleDays}
      />
    );
  };

  const updateData = useCallback((value) => {
    setData((current) => ({
      ...current,
      ...value,
      ...(value.from && { from: startOfWeek(value.from, { weekStartsOn: 1 }) }),
      ...(value.to && { to: endOfWeek(value.to, { weekStartsOn: 1 }) })
    }));
  }, []);

  useEffect(() => {
    const foundReason = reasonsList.find((r) => r.reason_id === order?.reason_id);
    if (foundReason) {
      setDefaultReason(foundReason);
    }
  }, [reasonsList, order]);

  const ifNext = (val, nullVal = null) => (isNext ? val : nullVal);
  const displayProp = isTablet || isNext ? 'block' : 'flex';
  const widthProp = isNext ? 'xl' : 'md';
  const getForms = () => (
    <>
      <Container maxWidth={widthProp}>
        <Box display={displayProp} justifyContent="space-between" flexWrap="wrap">
          <Box width={getBoxWidth(isTablet)}>
            <WorkerCard
              mb={4}
              firstName={savedCandidate?.firstName}
              lastName={savedCandidate?.lastName}
              dates={ifNext(
                {
                  from: new Date(pathOr(savedCandidate?.from, ['from'], data)),
                  to: new Date(new Date(pathOr(savedCandidate?.to, ['to'], data)))
                },
                {}
              )}
              costCenter={ifKeyExists(data, 'costCenter', savedCandidate?.costCenter)}
              reference={ifNext(ifKeyExists(data, 'reference', savedCandidate?.ref))}
              contractStatus={savedCandidate?.contractStatus}
              contractType={savedCandidate?.contractType}
              jobFunction={savedCandidate?.jobFunction ? savedCandidate.jobFunction[`label_${language || 'fr'}`] : null}
              reasonId={reason?.reason_id}
              isMobile={isMobile}
            />
          </Box>
          {isNext ? (
            <Box display="flex" width="100%" flexDirection="column">
              {getScheduleForm()}
              {!isTablet && <Divider />}
              <Box pt={4} width={isTablet ? '100%' : '50%'}>
                <TextField
                  variant="filled"
                  fullWidth
                  value={description}
                  multiline
                  label={t('general:comment')}
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Box width={getBoxWidth(isTablet)}>
                <ExtendForm
                  defaultData={{
                    costCenter: ifKeyExists(data, 'costCenter', savedCandidate?.costCenter),
                    reference: ifKeyExists(data, 'reference', savedCandidate?.reference),
                    from: ifKeyExists(data, 'from', savedCandidate?.from),
                    to: ifKeyExists(data, 'to', savedCandidate?.to),
                    reason: defaultReason
                  }}
                  onSend={updateData}
                  keepData={hasComeBack || !isNil(orderId)}
                  reasonsList={reasonsList}
                  setReason={setReason}
                  isValid={isValid}
                  setIsValid={setIsValid}
                  reason={reason}
                />
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
  const handleExtension = async () => {
    const contract = await extendContract({
      ...data,
      contractId: savedCandidate?.contractId,
      jobFunctionId: pathOr(null, ['jobFunction', 'id'], savedCandidate),
      costCenterId: pathOr(null, ['costCenter', 'id'], data),
      start: toIsoDateString(range.from),
      end: toIsoDateString(range.to),
      description,
      scheduleDays: formatScheduleListHours(data.scheduleDays),
      orderId,
      isEditing,
      reason_id: reason?.reason_id
    });
    onClose(contract);
  };

  const isNextDisabled = () =>
    (isNext && hasScheduleTimeError) ||
    !data.isValid ||
    !!(data.scheduleDays || []).find((day) => !isValidDate(day.contractHour));

  const dialogActions = {
    cancel: () => setIsPreconfirmed(false),
    confirm: () => handleExtension()
  };

  const getBanner = () =>
    !isWorkDay(tzDate) ? (
      <Box mb={6} margin="0 auto">
        <Banner type="warning" message={t('general:extend.openingHoursWarning')} />
      </Box>
    ) : (
      <> </>
    );

  useEffect(() => {
    getCostCenters();
    return dumpCandidate;
  }, [getCostCenters, dumpCandidate]);

  useEffect(() => {
    setLeftBtn(() => () => (
      <>
        {isNext && (
          <IconButton
            onClick={() => {
              setIsNext(false);
              setHasComeBack(true);
            }}>
            <NavigateBeforeIcon />
          </IconButton>
        )}
      </>
    ));
  }, [setLeftBtn, isNext]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
      <Box flex="1" overflow="auto">
        <ConfirmDialog
          actions={dialogActions}
          isOpen={isPreconfirmed}
          title={t('general:extend.newExtension')}
          isOrdering={isLoading}>
          <ConfirmScreen
            extension
            firstName={pathOr(propOr('', 'firstName', savedCandidate), ['interimWorker', 'firstName'], savedCandidate)}
            lastName={pathOr(propOr('', 'lastName', savedCandidate), ['interimWorker', 'lastName'], savedCandidate)}
            from={range.from}
            to={range.to}
            description={t('general:contract.confirmscreen')}
            jobFunction={savedCandidate?.jobFunction ? savedCandidate.jobFunction[`label_${language || 'fr'}`] : null}
            totalHours={totalHours}
          />
        </ConfirmDialog>
        <HoursDialog
          isOpen={showModifyDialog}
          isOverMaxHours={invalidHours.anyOver11Hours || invalidHours.totalOver50}
          data={data}
          range={range}
          onCancel={setShowModifyDialog}
          onConfirm={handleExtension}
        />
        <Box maxWidth="100%" margin="auto">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb={6}>
            <Box>
              <Typography variant="h6" component="h2">
                {t('general:extend.newExtension')}
              </Typography>
            </Box>
          </Box>
          <Container maxWidth="md">{getBanner()}</Container>
          <Box id="info-box">{getForms()}</Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" className={classes.footerStyle} py={4}>
        <Container maxWidth="xl" className={classes.container}>
          <Button
            className={classes.button}
            onClick={handleNext}
            disabled={isNextDisabled()}
            name="confirm"
            type="submit"
            variant="contained"
            color="primary"
            size="medium">
            {t(getButtonLabel(isNext, isEditing))}
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

NewProlongation.propTypes = {
  savedCandidate: object,
  dumpCandidate: func.isRequired,
  overrideIsNext: bool,
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
  getCostCenters: func.isRequired,
  extendContract: func.isRequired,
  defaultSchedule: object,
  orderId: number,
  isEditing: bool,
  reasonsList: array.isRequired,
  getReasons: func.isRequired,
  isLoading: bool.isRequired,
  order: object
};

NewProlongation.defaultProps = {
  savedCandidate: null,
  overrideIsNext: false,
  defaultSchedule: null,
  orderId: null,
  isEditing: false,
  order: null
};

const mapState = ({ user: { isOnMobile, isOnTablet }, contracts: { savedCandidate, reasonsList }, loading }) => ({
  isMobile: isOnMobile,
  isTablet: isOnTablet,
  savedCandidate,
  reasonsList,
  isLoading:
    pathOr(false, ['effects', 'tempWorkers', 'getCostCenters'], loading) ||
    pathOr(false, ['effects', 'contracts', 'extendContract'], loading) ||
    pathOr(false, ['effects', 'contracts', 'dumpCandidate'], loading) ||
    pathOr(false, ['effects', 'contracts', 'getReasons'], loading)
});

const mapDispatch = ({
  tempWorkers: { getCostCenters },
  contracts: { extendContract, dumpCandidate, getReasons }
}) => ({
  getCostCenters,
  extendContract,
  dumpCandidate,
  getReasons
});

export default connect(mapState, mapDispatch)(NewProlongation);
