import { api, secureCall } from 'utils/api';
import { cleanObj } from 'utils/object';
import { formatDate } from 'utils/date';

export const getOrders = secureCall(async ({ date, page, cost_centers, search, order_by_field, order_by_direction }) =>
  api.get(
    'order',
    cleanObj({
      ...(cost_centers.length && { 'cost_center_ids[]': cost_centers.map((c) => c.id) }),
      full_search: search,
      order_by_direction,
      order_by_field,
      date: formatDate(date, false),
      page,
      'types[]': [2, 3, 4]
    })
  )
);

export const createOrder = secureCall(
  async (payload) =>
    api.post(
      'order/create',
      cleanObj({
        cost_center_id: payload.costCenterId || null,
        job_function_id: payload.jobFunctionId,
        hourly_rate: payload.hourlyRate,
        start: payload.start,
        end: payload.end,
        description: payload.description,
        reason_id: payload.reasonId,
        invoice_reference: payload.reference,
        openings: payload.vacancies,
        is_flexi: payload.isFlexi,
        is_student: payload.isStudent,
        is_student_without_contingent: payload.isStudentContingent,
        is_seasonal: payload.isSeasonal,
        scheduleDays: payload.scheduleDays,
        candidateIds: payload.candidateIds
      })
    ),
  { message: { 400: 'errors:default' } }
);

export const updateOrder = secureCall(
  async (payload) =>
    api.post(
      `order/edit?id=${payload?.orderId}`,
      cleanObj({
        cost_center_id: payload.costCenterId || null,
        job_function_id: payload.jobFunctionId,
        hourly_rate: payload.hourlyRate,
        start: payload.start,
        end: payload.end,
        description: payload.description,
        reason_id: payload.reasonId,
        invoice_reference: payload.reference,
        openings: payload.vacancies,
        is_flexi: payload.isFlexi,
        is_student: payload.isStudent,
        is_student_without_contingent: payload.isStudentContingent,
        is_seasonal: payload.isSeasonal,
        scheduleDays: payload.scheduleDays
      })
    ),
  { message: { 400: 'errors:default' } }
);

export const cancel = secureCall(async (id) => api.get('order/cancel', { id }));

export const getCandidatesPool = secureCall(async (payload) => api.get('order/suggest-candidates', payload));

export const getListByWorker = secureCall(
  async ({ date, page, cost_center_ids, search, order_by_field, order_by_direction, start, end }) => {
    return api.get(
      'order/planning',
      cleanObj({
        'cost_center_ids[]': cost_center_ids,
        full_search: search,
        order_by_direction,
        order_by_field,
        date,
        page,
        start,
        end,
        'types[]': [2, 3, 4]
      })
    );
  }
);

export const getListByJobFunction = secureCall(
  async ({ date, page, cost_center_ids, search, order_by_field, order_by_direction, start, end }) => {
    return api.get(
      'order/planning-by-job-function',
      cleanObj({
        'cost_center_ids[]': cost_center_ids,
        full_search: search,
        order_by_direction,
        order_by_field,
        date,
        page,
        start,
        end,
        'types[]': [2, 3, 4]
      })
    );
  }
);

export const exportPlanningXLS = secureCall(
  async ({ cost_center_ids, search, order_by_field, order_by_direction, start, end }) => {
    return api.get(
      'order/download-planning',
      cleanObj({
        'cost_center_ids[]': cost_center_ids,
        full_search: search,
        order_by_direction,
        order_by_field,
        start,
        end,
        'types[]': [2, 3, 4]
      })
    );
  }
);

export const getOrderById = secureCall(async (id) => api.get(`order/${id}`));
