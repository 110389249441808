import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import * as fr from './locales/fr';
import * as nl from './locales/nl';

const resources = { fr, nl };

const navLang = navigator.language === 'nl-BE' ? 'nl' : navigator.language;

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['fr', 'nl'],
    lng: navLang || 'fr',
    defaultNS: ['general'],
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    }
  });
if (!i18next.options.supportedLngs.includes(i18next.language)) i18next.changeLanguage(i18next.options.supportedLngs[0]);

export default i18next;
