import React, { useCallback, useEffect, useState } from 'react';
import PropTypes, { array, bool, func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, Tooltip, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faAward, faDownload } from '@fortawesome/free-solid-svg-icons';
import { transparentize } from 'polished';
import { isNil } from 'ramda';

import { eventGA } from 'utils/analytics';
import { renderOnCondition } from 'utils/component';

import SearchField from 'components/SearchField';
import WeekSelector from 'components/WeekSelector/WeekSelector';
import { useDebounce, useHeader } from 'hooks';

import { openFile } from 'utils/file';
import RequestMenu from './RequestMenu';

const ListDesktopHeader = ({
  date,
  setDate,
  prestation,
  allContractIds,
  contractIds,
  exportXLS,
  classes,
  detailsShown,
  showDetails,
  bonusesShown,
  showBonuses,
  onSelectAllChange,
  prestationsCount,
  isEditing,
  setIsEditing,
  sendAnalytics
}) => {
  const { t } = useTranslation(['tempWorkers', 'helpers', 'general']);
  const { search, setSearch } = useHeader();
  const [shouldSelectAll, setShouldSelectAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [searchTerm, setSearchTerm] = useState(search || '');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const handleChange = (val) => setSearchTerm(val);
  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setIsIndeterminate(false);
    }
    onSelectAllChange(checked);
    setShouldSelectAll(checked);
  };

  const exportTWToXLS = useCallback(() => openFile(exportXLS), [exportXLS]);

  useEffect(() => {
    if (!isNil(debouncedSearchTerm)) {
      if (sendAnalytics) eventGA('home_search', debouncedSearchTerm.trim());
      setSearch(debouncedSearchTerm.trim());
    }
  }, [debouncedSearchTerm, setSearch]);

  useEffect(() => {
    setShouldSelectAll(allContractIds.length === contractIds.length && allContractIds.length > 0);
    setIsIndeterminate(allContractIds.length !== contractIds.length && contractIds.length > 0);
  }, [allContractIds, contractIds]);

  const getHead = () =>
    prestation ? (
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldSelectAll}
              indeterminate={isIndeterminate}
              onChange={handleSelectAllChange}
              name="checkedB"
              color="primary"
            />
          }
          label={
            <Typography variant="body2" color="secondary">
              {t('general:performance.selectAll')}
            </Typography>
          }
        />
      </Box>
    ) : (
      <Box>
        <WeekSelector dateChange={setDate} value={date} sendAnalytics={sendAnalytics} />
      </Box>
    );

  return (
    <>
      <Box display="flex" my={5} justifyContent="space-between">
        <Typography component="h6" variant="h6">
          {t(`tempWorkers:${prestation ? 'prestationsWithCount' : 'myTempWorkers'}`, { count: prestationsCount })}
        </Typography>
        {renderOnCondition(
          !prestation,
          <Box ml="auto">
            <RequestMenu />
          </Box>
        )}
        {renderOnCondition(
          prestation,
          <Box ml="auto">
            <Button
              onClick={() => {
                eventGA('prestation_to_confirm_edit');
                setIsEditing(true);
              }}
              disabled={isEditing}
              startIcon={<FontAwesomeIcon icon={faEdit} />}
              variant="outlined"
              color="primary">
              {t('general:edit')}
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {getHead()}
        <Box display="flex" alignItems="center">
          <Box mr={4}>
            <SearchField value={searchTerm} onChange={(e) => handleChange(e.target.value)} />
          </Box>
          <ButtonGroup color="secondary" size="small">
            <Tooltip title={t('tempWorkers:help.showHours')}>
              <Button
                onClick={() => {
                  if (sendAnalytics && !detailsShown) eventGA('home_hours_details');
                  showDetails(!detailsShown);
                }}
                className={detailsShown ? classes.activeButton : ''}>
                <FontAwesomeIcon icon={faClock} />
              </Button>
            </Tooltip>
            <Tooltip title={t('tempWorkers:help.showBonuses')}>
              <Button
                onClick={() => {
                  if (sendAnalytics && !bonusesShown) eventGA('home_premium_details');
                  showBonuses(!bonusesShown);
                }}
                className={bonusesShown ? classes.activeButton : ''}>
                <FontAwesomeIcon icon={faAward} />
              </Button>
            </Tooltip>
          </ButtonGroup>
          {renderOnCondition(
            !!exportXLS,
            <Tooltip title={t('tempWorkers:help.exportXLSX')}>
              <Button color="secondary" className={classes.exportBtn} onClick={() => exportTWToXLS()}>
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  );
};

ListDesktopHeader.propTypes = {
  prestation: bool,
  exportXLS: func,
  allContractIds: array,
  contractIds: array,
  classes: object.isRequired,
  date: PropTypes.instanceOf(Date),
  setDate: func,
  onSelectAllChange: func,
  detailsShown: bool,
  showDetails: func,
  bonusesShown: bool,
  showBonuses: func,
  prestationsCount: number,
  isEditing: bool,
  setIsEditing: func,
  sendAnalytics: bool
};

ListDesktopHeader.defaultProps = {
  allContractIds: [],
  contractIds: [],
  exportXLS: null,
  date: new Date(),
  setDate: () => null,
  prestation: false,
  detailsShown: false,
  onSelectAllChange: () => null,
  showDetails: () => null,
  bonusesShown: false,
  showBonuses: () => null,
  prestationsCount: 0,
  isEditing: false,
  setIsEditing: () => null,
  sendAnalytics: false
};

const styles = createStyles((theme) => ({
  activeButton: {
    backgroundColor: transparentize(0.8, theme.palette.primary.main)
  },
  exportBtn: {
    height: 32,
    minWidth: 32,
    marginLeft: 8,
    padding: 0
  }
}));

export default withStyles(styles)(ListDesktopHeader);
