import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Container, Dialog } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { array, bool, func, object } from 'prop-types';
import { prop, path, pathOr } from 'ramda';
import { startOfWeek, endOfWeek } from 'date-fns';

import { formatDate } from 'utils/date';
import { filterSearch, getIdsFromCenters } from 'utils/tempWorkers';
import { useHeader, useList } from 'hooks';

import { TempWorkersListDesktop, TempWorkersListMobile, NewProlongation, Modal, WhatsNew } from 'components';

const HomePage = ({
  workersList,
  exportXLS,
  fetchWorkersList,
  listLoading,
  isMobile,
  setCandidate,
  location,
  history,
  featureList,
  getFeatureList,
  markAsRead
}) => {
  const { search } = useHeader();
  const [localList, setLocalList] = useState(workersList);
  const [newProlongation, setNewProlongationOpen] = useState(false);
  const [dayFilter, setDayFilter] = useState([]);
  const [showCurrentFeature, setShowCurrentFeature] = useState(false);
  const [currentInfo, setCurrentInfo] = useState(false);

  const handleProlongation = (info) => {
    setCandidate(info);
    setCurrentInfo({ reason_id: info?.reasonId });
    setNewProlongationOpen(true);
  };

  const currentFeature = useMemo(() => featureList.find((f) => !f.is_read), [featureList]);

  const dates = useMemo(
    () => ({
      from: startOfWeek(new Date(path(['state', 'date'], location) || new Date()), { weekStartsOn: 1 }),
      to: endOfWeek(new Date(path(['state', 'date'], location) || new Date()), { weekStartsOn: 1 })
    }),
    [location]
  );

  const exportList = useCallback(() => {
    return exportXLS({ idList: getIdsFromCenters(localList) });
  }, [localList]);

  useEffect(() => {
    fetchWorkersList({ from: formatDate(dates.from, false), to: formatDate(dates.to, false) });
  }, [fetchWorkersList, dates]);

  useEffect(() => {
    if (!search && !dayFilter.length) setLocalList(workersList);
    if (prop('length', workersList) && (search || dayFilter.length)) {
      const filtered = filterSearch(workersList, search, dayFilter);
      setLocalList(filtered);
    }
  }, [workersList, search, dayFilter]);

  useEffect(() => {
    if (currentFeature) {
      setShowCurrentFeature(true);
    }
  }, [currentFeature]);

  useList(featureList, getFeatureList);

  return (
    <>
      <Modal
        isOpen={newProlongation}
        onClose={() => {
          setNewProlongationOpen(false);
        }}>
        <NewProlongation order={currentInfo} />
      </Modal>
      <Dialog
        open={showCurrentFeature}
        onClose={() => {
          setShowCurrentFeature(false);
        }}
        fullWidth
        maxWidth="md">
        <WhatsNew
          feature={currentFeature}
          markAsRead={async () => {
            await markAsRead(currentFeature.id);
            setShowCurrentFeature(false);
          }}
        />
      </Dialog>
      {isMobile ? (
        <TempWorkersListMobile
          exportXLS={exportList}
          onProlongationEvent={handleProlongation}
          list={localList}
          date={dates.from}
          setDate={(date) => history.replace(location.pathname, { date: date.from })}
          isLoading={listLoading}
        />
      ) : (
        <Container maxWidth="xl">
          <TempWorkersListDesktop
            exportXLS={exportList}
            onProlongationEvent={handleProlongation}
            list={localList}
            date={dates.from}
            setDate={(date) => history.replace(location.pathname, { date: date.from })}
            isLoading={listLoading}
            dayFilter={dayFilter}
            dayFilterChange={setDayFilter}
            sendAnalytics
          />
        </Container>
      )}
    </>
  );
};

HomePage.propTypes = {
  workersList: array.isRequired,
  fetchWorkersList: func.isRequired,
  setCandidate: func.isRequired,
  listLoading: bool.isRequired,
  isMobile: bool.isRequired,
  location: object.isRequired,
  history: object.isRequired,
  exportXLS: func.isRequired,
  featureList: array.isRequired,
  getFeatureList: func.isRequired,
  markAsRead: func.isRequired
};

const mapState = ({
  features: { list: featureList },
  tempWorkers: { list: workersList },
  loading,
  user: { isOnMobile }
}) => ({
  featureList,
  workersList,
  listLoading: pathOr(false, ['effects', 'tempWorkers', 'getList'], loading),
  isMobile: isOnMobile
});

const mapDispatch = ({
  features: { getFeatureList, markAsRead },
  tempWorkers: { getList },
  contracts: { setCandidate, exportXLS }
}) => ({
  getFeatureList,
  markAsRead,
  fetchWorkersList: getList,
  setCandidate,
  exportXLS
});

export default connect(mapState, mapDispatch)(withRouter(HomePage));
