import React from 'react';
import { object, node } from 'prop-types';
import { useAllowedActions } from '../../auth/useAllowedActions';
import { userRoles } from '../../auth/userRoles';

const NavItem = ({ requiredRoles, children }) => {
  const hasRequiredRole = useAllowedActions(requiredRoles || userRoles.any, true);
  if (!hasRequiredRole) return null;
  return <>{children}</>;
};

NavItem.propTypes = {
  requiredRoles: object.isRequired,
  children: node.isRequired
};

export default NavItem;
