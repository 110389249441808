import { omit, pathOr, prop, pick } from 'ramda';
import { toast } from 'react-toastify';

import i18n from 'translate/i18n';
import history from 'utils/history';

import * as service from './service';

export const user = {
  state: {
    // initial state
    isInitialized: false,
    isLogged: false,
    current: {},
    availableCustomers: null,
    currentCustomer: null,
    isOnMobile: false,
    isOnTablet: false,
    customers: [],
    userInfos: null
  },
  reducers: {
    loggedIn: (state, payload) => ({
      ...state,
      current: payload,
      isLogged: true,
      isInitialized: payload?.gdpr_consent ? !!state.currentCustomer : true
    }),
    loggedOut: (state, _payload) => ({
      ...state,
      current: null,
      isLogged: false,
      isInitialized: true
    }),
    updated: (state, payload) => ({
      ...state,
      current: {
        ...state.current,
        ...payload
      }
    }),
    setIsMobile: (state, isOnMobile) => ({
      ...state,
      isOnMobile
    }),
    setIsTablet: (state, isOnTablet) => ({
      ...state,
      isOnTablet
    }),
    setAvailableCustomers: (state, payload) => ({
      ...state,
      availableCustomers: pathOr([], ['customerContacts'], payload),
      currentCustomer: pathOr(null, ['selectedCustomerContact'], payload),
      isInitialized: state.isLogged
    }),
    setCurrentCustomer: (state, payload) => ({
      ...state,
      currentCustomer: payload
    }),
    gotCustomers: (state, customers) => ({ ...state, customers }),
    gotUserData: (state, userInfos) => ({ ...state, userInfos })
  },
  effects: (dispatch) => ({
    async initUser() {
      const userData = await service.initWithToken();
      if (userData) {
        if (userData?.gdpr_consent) {
          i18n.changeLanguage(userData?.language || 'fr');
          const hasAvailableCustomers = await this.getAvailableCustomers();
          if (hasAvailableCustomers) {
            this.loggedIn(userData);
            return true;
          }
        }
      }
      this.logout(false);
      return false;
    },

    async login(payload) {
      const { redirectPath } = payload;
      const userData = await service.login(omit(['redirectPath'], payload));
      if (userData) {
        // Reset available and current customer to avoid rights conflicts when changing account.
        this.setAvailableCustomers();
        this.loggedIn(userData);
        if (userData?.gdpr_consent) await this.getAvailableCustomers();
        if (redirectPath) history.push(redirectPath);
      } else this.logout();
    },

    logout(redirect = true) {
      service.logout();
      this.loggedOut();
      if (redirect) history.push('/login');
    },

    async resetPassword(payload) {
      const userData = await service.resetPassword(payload);
      if (userData) {
        this.setAvailableCustomers();
        this.loggedIn(userData);
        if (userData?.gdpr_consent) await this.getAvailableCustomers();
        toast(i18n.t('auth:resetPasswordSuccess'));
      } else this.logout();
    },

    async update(data) {
      const updated = await service.update(data);
      if (updated) this.updated(pick(['confirmation_email', 'document_notification', 'language'], updated));
    },

    async updatePassword(data) {
      const updated = await service.updatePassword(data);
      if (updated) {
        this.loggedIn({
          ...updated?.user?.selectedCustomerContact,
          language: updated?.user?.language
        });
        dispatch.events.sendMessage(i18n.t('general:savedSuccessfully'));
      }
      return updated;
    },

    async getAvailableCustomers() {
      const availableCustomers = await service.getAvailableCustomers();
      if (availableCustomers) {
        this.setAvailableCustomers(availableCustomers);
        return true;
      }
      return false;
    },

    async switchCurrentCustomer(payload) {
      const currentCustomer = await service.switchCustomerContact(payload);
      if (currentCustomer) {
        this.setCurrentCustomer(currentCustomer);
        // Force app reload to get appropriate data.
        window.location.reload();
      }
    },

    async impersonate(data) {
      const impersonatorData = await service.impersonate(data);
      if (impersonatorData) {
        this.loggedIn(impersonatorData);
        history.push('/');
        window.location.reload();
      } else this.logout();
    },

    async acceptGdpr() {
      const updated = await service.acceptGdpr();
      if (updated) {
        await this.getAvailableCustomers();
        this.loggedIn({ ...updated?.selectedCustomerContact, language: updated?.language });
      }
    },

    async getCustomers() {
      const list = await service.getCustomers();
      if (prop('customerContacts', list)) {
        this.gotCustomers(list.customerContacts);
        return list.customerContacts;
      }
      return null;
    },

    forgotPassword: service.forgotPassword,

    async getUserInfos() {
      const data = await service.getUserInfos();
      if (data) {
        this.gotUserData(data);
      }
      return null;
    },

    async requestAccountDeletion() {
      const result = await service.requestAccountDeletion();
      return !!result;
    }
  })
};
