import React, { useEffect, useState } from 'react';
import PropTypes, { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles } from '@material-ui/styles';
import { Box, Button, IconButton, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import frLocale from 'date-fns/locale/fr';
import nlLocale from 'date-fns/locale/nl';
import DateFnsUtils from '@date-io/date-fns';
import { isValid, isSameDay, endOfWeek, startOfWeek, isWithinInterval } from 'date-fns';

import { getWeekNumber } from 'utils/date';
import { eventGA } from 'utils/analytics';
import CalendarIcon from 'assets/CalendarIcon';

const WeekSelector = ({ classes, dateChange, value, isMobile, isBlock, sendAnalytics, ...props }) => {
  const { t, i18n } = useTranslation('helpers');
  const [selectedDate, setSelectedDate] = useState(value);

  const weekNumber = getWeekNumber(selectedDate);

  const handleWeekChange = (date) => {
    const from = startOfWeek(date, { weekStartsOn: 1 });
    const to = endOfWeek(date, { weekStartsOn: 1 });
    setSelectedDate(from);
    dateChange({ from, to });
    return true;
  };

  const changeWeek = (diff = 1) => {
    const newDate = new Date(selectedDate);
    handleWeekChange(new Date(newDate.setDate(newDate.getDate() + 7 * diff)));
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(start, { weekStartsOn: 1 });

    return start && isValid(start)
      ? `${start.getDate()}/${start.getMonth() + 1} -> ${end.getDate()}/${end.getMonth() + 1}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date, chosenDate, dayInCurrentMonth) => {
    const dateClone = new Date(date);
    const selectedDateClone = new Date(chosenDate);

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx(classes.disable, {
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {dateClone.getDate()} </span>
        </IconButton>
      </div>
    );
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {!isMobile && !isBlock && (
        <Button
          variant="outlined"
          className={classes.currentWeek}
          onClick={() => {
            if (sendAnalytics) eventGA('home_current_week');
            handleWeekChange(new Date());
          }}>
          {t('helpers:dates.currentWeek')}
        </Button>
      )}
      <Box className={classes.Box} width={isBlock ? '100%' : 270}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'fr' ? frLocale : nlLocale}>
          <DatePicker
            ToolbarComponent={() => null}
            label=""
            value={selectedDate}
            onChange={handleWeekChange}
            renderDay={renderWrappedWeekDay}
            labelFunc={formatWeekSelectLabel}
            className={clsx(classes.picker, {
              [classes.mr]: !isBlock
            })}
            TextFieldComponent={({ className, onClick, onKeyDown, value: val }) => {
              return (
                <Button
                  {...props}
                  className={clsx(className, classes.pickerButton)}
                  onClick={() => {
                    if (sendAnalytics) eventGA('home_date_picker');
                    onClick();
                  }}
                  onKeyDown={onKeyDown}
                  startIcon={<CalendarIcon weekNumber={weekNumber} />}
                  endIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                  {val}
                </Button>
              );
            }}
          />
        </MuiPickersUtilsProvider>
        {!isMobile && (
          <Box>
            <IconButton onClick={() => changeWeek(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} className={classes.smallIcon} />
            </IconButton>
            <IconButton onClick={() => changeWeek(1)}>
              <FontAwesomeIcon icon={faChevronRight} className={classes.smallIcon} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

WeekSelector.propTypes = {
  classes: object.isRequired,
  value: PropTypes.instanceOf(Date),
  dateChange: func,
  isMobile: bool,
  isBlock: bool,
  sendAnalytics: bool
};

WeekSelector.defaultProps = {
  dateChange: () => null,
  value: new Date(),
  isMobile: false,
  isBlock: false,
  sendAnalytics: false
};

const styles = createStyles((theme) => ({
  disable: {
    pointerEvents: 'none'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },
  mr: {
    marginLeft: '12px'
  },
  picker: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    '&:hover, &:focus': {
      backgroundColor: '#fff'
    }
  },
  pickerButton: {
    color: theme.palette.secondary.main,
    fontWeight: 400
  },
  smallIcon: {
    fontSize: 12
  },
  currentWeek: {
    textTransform: 'none'
  },
  Box: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default withStyles(styles)(WeekSelector);
