import React, { useState, useEffect } from 'react';
import { array, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@material-ui/core';
import { uniq, sortWith, ascend } from 'ramda';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    marginTop: 8,
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      marginTop: 0,
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.lightest,
    color: theme.palette.secondary.dark,
    marginBottom: 8
  }
}))(ToggleButton);

const BonusSelector = ({ data, onChange, appendableCodes, getAppendableCodesBonus }) => {
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState(uniq(data.map((item) => item.premium_code)));

  useEffect(() => {
    getAppendableCodesBonus();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column">
        <Box mb={2}>
          <StyledToggleButtonGroup
            orientation="vertical"
            value={selected}
            onChange={(_event, newValues) => {
              setSelected(newValues);
              onChange(newValues);
            }}>
            {sortWith(
              [
                ascend((bonus) => bonus.source),
                ascend((bonus) => (bonus.premium_code === 2060 ? 5000 : bonus.premium_code))
              ],
              appendableCodes
            ).map((pl) => (
              <StyledToggleButton key={pl.premium_code} value={pl.premium_code} aria-label="list">
                {pl[`description_${i18n.language}`]}
              </StyledToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      </Box>
    </Container>
  );
};

BonusSelector.propTypes = {
  data: array.isRequired,
  onChange: func.isRequired,
  appendableCodes: array,
  getAppendableCodesBonus: func.isRequired
};

BonusSelector.defaultProps = {
  appendableCodes: []
};

export default BonusSelector;
