import React, { useState } from 'react';
import PropTypes, { string, array, bool, number, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { isEmpty } from 'ramda';
import { Box, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';

import { localeDateFormat } from 'utils/date';
import { getStatus, getType, costCenterString } from 'utils/tempWorkers';
import { StyledRating } from 'components';
import { isValidDate } from 'ramda-adjunct';

const useStyles = makeStyles(() => ({
  userInfo: {
    padding: (props) => (props.isMobile ? '0' : '0 2em 0 0')
  }
}));

// eslint-disable-next-line complexity
const WorkerCard = ({
  costCenter,
  customer,
  dates,
  headless,
  firstName,
  lastName,
  isMobile,
  jobFunction,
  contractStatus,
  contractType,
  showRating,
  rating,
  onRatingChange,
  reasonId,
  reference,
  multipleRefs,
  readOnly,
  lastDate,
  isStudent,
  isSeasonal,
  isFlexi,
  renderDates,
  renderHead,
  vacancies,
  showWorkerInfos,
  ...props
}) => {
  const [ratingHoverValue, setHover] = useState(rating || -1);
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation(['general', 'helpers']);
  const classes = useStyles({ isMobile });
  const reason = reasonId || reasonId === 0 ? t(`general:extend.reasons.${reasonId}`) : '';
  const getReason = () => (reason ? `${t('general:extend.reason')} : ${reason}` : '');
  const handleType = () => {
    let typeString = '';
    if (typeof contractType === 'number') {
      const type = getType(contractType, t);
      if (type) typeString += `- ${type}`;
    } else if (Array.isArray(contractType)) {
      typeString =
        contractType.length > 0
          ? `- ${contractType
              .map((tNum) => getType(tNum, t))
              .filter((e) => !!e)
              .join(' - ')}`
          : '';
    } else {
      if (isSeasonal) typeString += ` - ${t('general:extend.seasonal')}`;
      if (isFlexi) typeString += ` - ${t('general:extend.flexi')}`;
    }
    return typeString;
  };

  const getLastPerformance = () =>
    !!lastDate && (
      <Typography color="textSecondary" variant="body2">
        {`${t('general:lastPerformance')}: ${format(new Date(lastDate), localeDateFormat)}`}
      </Typography>
    );

  const getJobHeadInfos = () => (
    <Typography variant="h6" component="h3">
      {`${jobFunction} x ${vacancies}`}
    </Typography>
  );

  const getWorkerHeadInfos = () => {
    if (renderHead) return renderHead();
    return !showRating ? (
      <Typography variant="h6" component="h3">
        {`${lastName} ${firstName}`}
      </Typography>
    ) : (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6" component="h3">
          {`${lastName} ${firstName}`}
        </Typography>
        <Box mx={1}>
          <Tooltip title={t('general:performance.ratingHelp')}>
            <IconButton size="small" aria-label="help">
              <HelpOutlineIcon fontSize="default" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <StyledRating
            name="workerRating"
            size="small"
            readOnly={readOnly}
            value={rating}
            onChange={(_event, newValue) => {
              onRatingChange(newValue);
            }}
            onChangeActive={(_event, newHover) => {
              setHover(newHover);
            }}
          />
        </Box>
        {(rating !== null || ratingHoverValue > -1) && (
          <Box ml={2}>
            <Typography color="primary" variant="body2">
              {t(`helpers:ratings.${ratingHoverValue !== -1 ? ratingHoverValue : rating}`)}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const getDates = () => {
    if (!isEmpty(dates) && isValidDate(dates?.from) && isValidDate(dates?.to))
      return (
        <Typography color="textSecondary" variant="body2" align="left">
          {`${format(dates?.from, localeDateFormat)} - ${format(dates?.to, localeDateFormat)}`}
        </Typography>
      );
    if (renderDates) return renderDates();
    return null;
  };

  const getReference = () =>
    reference && (
      <Typography color="textSecondary" variant="body2" align="left">
        {`${t('general:reference')}: ${reference}`}
      </Typography>
    );

  const getReferenceList = () => {
    if (!reference || isEmpty(reference)) return null;

    const btnText = showMore ? 'general:lessDetails' : 'general:moreDetails';
    return (
      <Box display="flex" flexDirection="column">
        {showMore && (
          <>
            <Typography color="textSecondary" variant="body2">
              {t('general:references')}
            </Typography>
            <Box pl={8}>
              {reference.map((r) =>
                !isEmpty(r) ? (
                  <Typography key={r.reference} color="textSecondary" variant="body2">
                    {`- ${r.reference}  ${format(new Date(r.from), 'dd/MM')}->${format(new Date(r.to), 'dd/MM')}`}
                  </Typography>
                ) : (
                  ''
                )
              )}
            </Box>
          </>
        )}
        <Link component="button" variant="body2" color="primary" align="left" onClick={() => setShowMore(!showMore)}>
          {t(btnText)}
        </Link>
      </Box>
    );
  };

  const getContractInfo = () => (
    <Typography color="textSecondary" variant="body2">
      {jobFunction}
      {[5, 6, 9, 10].includes(contractStatus) || isStudent ? (
        <>
          {' '}
          - <FontAwesomeIcon icon={faUserGraduate} />
        </>
      ) : (
        ''
      )}
      {` ${getStatus(contractStatus, isStudent, t)} ${handleType()}`}
    </Typography>
  );

  const getCostCenter = () =>
    !isEmpty(costCenter) && (
      <Typography color="textSecondary" variant="body2" align="left">
        {costCenterString(costCenter)}
      </Typography>
    );

  return (
    <Box display="flex" flexDirection="column" className={classes.userInfo} {...props}>
      {!headless && showWorkerInfos && getWorkerHeadInfos()}
      {!headless && !showWorkerInfos && getJobHeadInfos()}
      {getDates()}
      {getContractInfo()}
      <Typography color="textSecondary" variant="body2">
        {getReason()}
      </Typography>
      {getLastPerformance()}
      {getCostCenter()}
      {!multipleRefs && getReference()}
      <Typography color="textSecondary" variant="body2">
        {customer}
      </Typography>
      {multipleRefs && getReferenceList()}
    </Box>
  );
};

WorkerCard.propTypes = {
  costCenter: object,
  customer: PropTypes.oneOfType([string, bool]),
  dates: PropTypes.exact({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date)
  }),
  firstName: string,
  lastName: string,
  rating: number,
  contractStatus: number,
  contractType: PropTypes.oneOfType([number, array]),
  jobFunction: string,
  reasonId: PropTypes.oneOfType([number, string]),
  reference: PropTypes.oneOfType([string, array]),
  headless: bool,
  isMobile: bool,
  multipleRefs: bool,
  readOnly: bool,
  lastDate: string,
  onRatingChange: func,
  showRating: bool,
  isStudent: bool,
  isSeasonal: bool,
  isFlexi: bool,
  renderDates: func,
  renderHead: func,
  showWorkerInfos: bool,
  vacancies: PropTypes.oneOfType([string, number])
};

WorkerCard.defaultProps = {
  costCenter: {},
  customer: '',
  firstName: null,
  lastName: null,
  contractStatus: null,
  contractType: null,
  dates: {},
  headless: false,
  isMobile: false,
  jobFunction: null,
  multipleRefs: false,
  reference: null,
  reasonId: null,
  rating: null,
  lastDate: null,
  showRating: false,
  readOnly: false,
  onRatingChange: () => null,
  isStudent: false,
  isSeasonal: false,
  isFlexi: false,
  renderDates: null,
  renderHead: null,
  showWorkerInfos: false,
  vacancies: 1
};

export default WorkerCard;
