/* eslint max-lines: 0 */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHeader } from 'hooks';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { array, bool, func, number, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { has, compose, pathOr } from 'ramda';
import clsx from 'clsx';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  Badge,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faQuestionCircle, faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

import { Img, Translate, Prestations } from 'components';
import CustomerDropdown from 'components/CustomerDropdown/CustomerDropdown';
import Logo from 'assets/daoust-connect.svg';
import LogoMobile from 'assets/daoust-d.svg';

import { renderOnCondition } from 'utils/component';

import { UserContext } from 'user/userContext';
import { conditionsLinks, privacyLinks } from './common';
import { userRoles } from '../../auth/userRoles';
import NavItem from './NavItem';

const Title = ({ isMobile, isSearchOpen, ...props }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(!isSearchOpen);
  }, [isSearchOpen]);

  if (!isMobile || !show) return null;
  return (
    <Box ml={3} flex="1" overflow="hidden">
      <Typography component="h6" variant="h6" noWrap {...props} />
    </Box>
  );
};

Title.propTypes = {
  isMobile: bool.isRequired,
  isSearchOpen: bool.isRequired
};

const MarkNewsAsReadButton = ({ isMobile, unreadNews, onClick, buttonText, isLoading, title, ...props }) => {
  if (!isMobile || unreadNews <= 0 || title !== 'general:menu.news') return null;
  return (
    <Button color="primary" onClick={onClick} disabled={isLoading} {...props}>
      {isLoading ? <CircularProgress /> : buttonText}
    </Button>
  );
};

MarkNewsAsReadButton.propTypes = {
  isMobile: bool.isRequired,
  unreadNews: number.isRequired,
  onClick: func.isRequired,
  buttonText: string.isRequired,
  isLoading: bool.isRequired,
  title: string
};
MarkNewsAsReadButton.defaultProps = {
  title: ''
};

const Header = ({
  classes,
  logout,
  toggleMenu,
  isMobile,
  unreadNews,
  availableCustomers,
  getAvailableCustomers,
  isCustomersLoading,
  markAllAsRead,
  isMarkingAllAsRead,
  getUnreadCount
}) => {
  const user = useContext(UserContext);
  const { components, title, isSearchOpen } = useHeader();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation(['auth', 'general']);

  const openMenu = (event) => setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const handleParameter = () => {
    history.push('/parameters');
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!availableCustomers && !isCustomersLoading) getAvailableCustomers();
  }, [availableCustomers, getAvailableCustomers, isCustomersLoading]);

  const handleMarkAllAsReadClick = useCallback(async () => {
    await markAllAsRead();
    getUnreadCount();
  }, []);

  return (
    <Box className={clsx(classes.box, { [classes.mobileContainer]: isMobile })}>
      <Container maxWidth="xl" className={classes.container}>
        <Box display="flex" py={3} className={classes.innerBox}>
          <Box display="flex" width="100%" alignItems="center" overflow="hidden">
            {isMobile ? (
              <Img
                className={classes.logo}
                src={LogoMobile}
                alt=""
                height={29}
                onClick={() => isMobile && toggleMenu(true)}
              />
            ) : (
              <Link component={RouterLink} to="/" textDecoration="none">
                <Img src={Logo} alt="" height={45} />
              </Link>
            )}
            {!isMobile && (
              <>
                <NavItem requiredRoles={userRoles.validation}>
                  <Link component={RouterLink} to="/" exact className={classes.headerLink}>
                    {t('general:menu.home')}
                  </Link>
                </NavItem>
                <NavItem requiredRoles={userRoles.validation}>
                  <Link component={RouterLink} to="/planning" className={classes.headerLink}>
                    {t('general:menu.planning')}
                  </Link>
                </NavItem>
                <NavItem requiredRoles={userRoles.validation}>
                  <Link component={RouterLink} to="/orders" className={classes.headerLink}>
                    {t('general:menu.orders')}
                  </Link>
                </NavItem>
                <NavItem requiredRoles={userRoles.validation}>
                  <Link component={RouterLink} to="/my-workers" className={classes.headerLink}>
                    {t('general:menu.myWorkers')}
                  </Link>
                </NavItem>
                <NavItem requiredRoles={userRoles.documentAccess}>
                  <Link component={RouterLink} to="/documents" className={classes.headerLink}>
                    {t('general:menu.documents')}
                  </Link>
                </NavItem>
              </>
            )}
            <Box display="flex" width="100%" justifyContent="space-between">
              <Title isMobile={isMobile} isSearchOpen={isSearchOpen}>
                {t(title)}
              </Title>
              <MarkNewsAsReadButton
                isMobile={isMobile}
                unreadNews={unreadNews}
                onClick={handleMarkAllAsReadClick}
                buttonText={t('general:news.markAllAsRead')}
                isLoading={isMarkingAllAsRead}
                className={clsx(classes.button, classes.buttonMobile)}
                color="primary"
                title={title}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flex="1" justifyContent="flex-end">
            {isMobile && components.map((renderComponent) => renderComponent())}
            {!isMobile && (
              <>
                <NavItem requiredRoles={userRoles.validation}>
                  <Tooltip title={t('general:menu.TBCDeliveries')}>
                    <Box>
                      <Prestations />
                    </Box>
                  </Tooltip>
                </NavItem>
                <NavItem requiredRoles={userRoles.messaging}>
                  <Tooltip title={t('general:menu.news')}>
                    <IconButton className={classes.iconbutton} onClick={() => history.push('/news')}>
                      <Badge badgeContent={unreadNews} color="primary">
                        <FontAwesomeIcon icon={faNewspaper} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </NavItem>
                <Tooltip title={t('general:menu.help')}>
                  <IconButton className={classes.iconbutton} onClick={() => history.push('/help')}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('general:menu.myAccount')}>
                  <IconButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={openMenu}
                    className={classes.iconbutton}>
                    <FontAwesomeIcon icon={user.impersonator ? faUserSecret : faUserCircle} />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={closeMenu}
                  PaperProps={{
                    style: {
                      width: 270,
                      padding: 0
                    }
                  }}>
                  <Box pl={4}>
                    <Typography component="h6" variant="h6">
                      {user.getFullName()}
                      {renderOnCondition(user.isImpersonated(), <small> ({user.getImpersonatorName()})</small>)}
                    </Typography>
                  </Box>
                  <CustomerDropdown />
                  <Box mt={4}>
                    <Divider />
                  </Box>
                  <Translate />
                  <MenuItem button onClick={handleParameter}>
                    {t('general:menu.parameters')}
                  </MenuItem>
                  <MenuItem
                    component="a"
                    href={has(i18n.language, conditionsLinks) ? conditionsLinks[i18n.language] : conditionsLinks.fr}
                    target="_blank">
                    {t('general:menu.conditions')}
                  </MenuItem>
                  <MenuItem
                    component="a"
                    href={has(i18n.language, privacyLinks) ? privacyLinks[i18n.language] : privacyLinks.fr}
                    target="_blank">
                    {t('general:menu.privacy')}
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>{t('auth:logout')}</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  classes: object.isRequired,
  logout: func,
  toggleMenu: func,
  isMobile: bool.isRequired,
  unreadNews: number.isRequired,
  availableCustomers: array,
  isCustomersLoading: bool,
  getAvailableCustomers: func,
  markAllAsRead: func.isRequired,
  getUnreadCount: func.isRequired,
  isMarkingAllAsRead: bool
};

Header.defaultProps = {
  logout: () => null,
  toggleMenu: () => null,
  getAvailableCustomers: () => {},
  isCustomersLoading: false,
  availableCustomers: null,
  isMarkingAllAsRead: false
};

const styles = createStyles((theme) => ({
  menu: {
    width: 270
  },
  container: {
    backgroundColor: theme.palette.light.main
  },
  mobileContainer: {
    position: 'fixed',
    top: 0
  },
  logo: {
    cursor: 'pointer'
  },
  box: {
    height: theme.headerHeight,
    zIndex: 1000,
    borderBottom: '1px solid #e5e5e5',
    width: '100%'
  },
  innerBox: {
    height: theme.headerHeight - 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerLinkContainer: {
    marginLeft: 10,
    marginRight: 20
  },
  headerLink: {
    color: theme.palette.secondary.dark,
    marginLeft: 10,
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    '&.active': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const mapState = ({
  user: { isOnMobile: isMobile, availableCustomers },
  tempWorkers: { toConfirmList },
  news: { unreadCount },
  loading
}) => ({
  isMobile,
  toConfirmList,
  availableCustomers,
  unreadNews: unreadCount,
  isCustomersLoading: pathOr(false, ['effects', 'user', 'getAvailableCustomers'], loading),
  isMarkingAllAsRead: pathOr(false, ['effects', 'news', 'markAllAsRead'], loading)
});

const mapDispatch = ({ user: { getAvailableCustomers }, news: { markAllAsRead, getUnreadCount } }) => ({
  getAvailableCustomers,
  markAllAsRead,
  getUnreadCount
});

export default compose(withStyles(styles), connect(mapState, mapDispatch))(Header);
