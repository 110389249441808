import { pathOr, prop, uniqBy } from 'ramda';

import * as service from './service';

export const news = {
  state: {
    unreadCount: 0,
    list: []
  },
  reducers: {
    gotUnreadCount: (state, unreadCount) => ({ ...state, unreadCount }),
    gotList: (state, list) => ({ ...state, list })
  },
  effects: {
    async getUnreadCount() {
      const count = await service.getUnreadCount();

      if (prop('unread_news', count)) this.gotUnreadCount(Number(count.unread_news));
      return null;
    },
    async markAsRead(id) {
      await service.markAsRead(id);
      return null;
    },
    async markAllAsRead() {
      await service.markAllAsRead();
      return null;
    },
    async getList(page = 1, rootState) {
      if (page === 1) this.gotList([]);
      const oldList = page === 1 ? [] : pathOr([], ['news', 'list'], rootState);
      const list = await service.getList(page);
      if (prop('length', list)) {
        const newList = uniqBy((elt) => elt.title, [...oldList, ...list]);
        if (list) this.gotList(newList);
        return newList.length !== oldList.length;
      }
      return false;
    }
  }
};
