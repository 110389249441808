export const workerTypes = [
  { key: 'ALL', text: 'myWorkers:all', value: 0 },
  { key: 'WORKERS', text: 'myWorkers:workerTypes:workers', value: 'contract' },
  { key: 'PAYROLLS', text: 'myWorkers:workerTypes:payrolls', value: 'payroll' }
];

export const sinceOptions = [
  { key: 'ALL', text: 'myWorkers:allContracts', value: 0 },
  { key: 'THREE_MONTHS', text: 'myWorkers:sinceOptions:threeMonths', value: 3 },
  { key: 'SIX_MONTHS', text: 'myWorkers:sinceOptions:sixMonths', value: 6 },
  { key: 'TWELVE_MONTHS', text: 'myWorkers:sinceOptions:twelveMonths', value: 12 }
];
